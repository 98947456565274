/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {EditServiceObj} from "./EditService";
import {ItemType} from "antd/es/menu/hooks/useItems";
import {Menu} from "antd";

export const ComponentMenu: React.FunctionComponent<{x:number, y:number, setSelectedComponent: React.Dispatch<React.SetStateAction<any>>}> = (props) => {

    const items = React.useCallback(()=>{
        return EditServiceObj.availableComponents().map((component: string)=> ({
            title: component.toUpperCase(),
            label: component.toUpperCase(),
            key: component
        } as ItemType<any>))

    }, [])

    return <div className={'context-menu'} style={{ left: `${props.x + 5}px`, top: `${props.y + 5}px`}}>
        <Menu items={items()} onClick={(e)=> {
            EditServiceObj.pickComponent(e.key)
            props.setSelectedComponent(EditServiceObj.getSelectedComponent())
        }}/>
    </div>
};