/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {useCallback, useContext} from "react";
import {ConfigContext, RecipeContext} from "../util";
import {LayerDefs} from "../def";
import {ErrorSelectionContext, LayerSelectionContext} from "../../trusscheck/util"
import {isSelectedByIdList} from "../util"
import {CollisionContext} from "../collisionDetection/util";
import {EditServiceObj, ITool} from "../EditService";

export enum PaddleShapes {
    RearPaddleShape = 'rear paddle shape',
    LeftFrontPaddle = 'normal left front paddle',
    RightFrontPaddle = 'normal right front paddle',
    AdvancedLeftFrontPaddle = 'advanced left front paddle',
    AdvancedRightFrontPaddle = 'advanced right front paddle',
}

export enum PressToolShapes {
    Base = 'BASE',
    WithTool = 'WITHTOOL',
    PressPlate = 'PRESSPLATE',
    Paddle = 'PADDLE',
    Mouth = 'MOUTHDEPTH'
}

export const PressToolShape: React.FunctionComponent<{layer?:string, paddleShape?: PaddleShapes}> = (props)=> {

    return <ConfigContext.Consumer>
        {ctx => ctx?.configuration && ctx?.configuration.buildtoolshapes.PressTool.DrawingElements.Shapes.reduce((acc: JSX.Element[], shape: {[key:string]: any}) => {

            if (shape.layer === props.layer) {
                acc.push(<polygon key={shape.layer.toLowerCase()} className={shape.layer.toLowerCase()} points={shape.points}/>)
            }
            else if (shape.description.includes(props.paddleShape) ) {
                acc.push(<polygon key={shape.description.replace(/ /g, '_')} className={shape.description.replace(/ /g, '_')} points={shape.points}/>)
            }
            return acc;
        }, [])}
    </ConfigContext.Consumer>
}

export const PressTool = ()=> <PressToolShape  layer={PressToolShapes.Base}/>
export const PressToolUpperShape = ()=> <PressToolShape layer={PressToolShapes.WithTool}/>
export const PressPlate = ()=> <PressToolShape  layer={PressToolShapes.PressPlate}/>
export const RearPaddleShape = ()=> <PressToolShape paddleShape={PaddleShapes.RearPaddleShape}/>
export const LeftFrontPaddle = ()=> <PressToolShape paddleShape={PaddleShapes.LeftFrontPaddle}/>
export const RightFrontPaddle = ()=> <PressToolShape paddleShape={PaddleShapes.RightFrontPaddle}/>
export const AdvancedLeftFrontPaddle = ()=> <PressToolShape paddleShape={PaddleShapes.AdvancedLeftFrontPaddle}/>
export const AdvancedRightFrontPaddle = ()=> <PressToolShape paddleShape={PaddleShapes.AdvancedRightFrontPaddle}/>
export const Mouth = ()=> <PressToolShape layer={PressToolShapes.Mouth}/>


export const PressToolLayer: React.FunctionComponent<{id?:number}> = (props)=> {

    const collisionCtx = useContext(CollisionContext)
    const errCtx = useContext(ErrorSelectionContext)

    const getProperties = useCallback((tool: ITool) => {

        const selectedPressToolId = `${LayerDefs.PressTool}_${tool.id}`;
        const isSelected = isSelectedByIdList([selectedPressToolId], errCtx)

        const collide = collisionCtx?.collisions?.has(`${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`)? "collide" : ""

        if (props.id === undefined) {
            return{
                'data-component': tool.nailplateId,
                'data-layer': LayerDefs.PressTool,
                'className': `${LayerDefs.PressTool.toLowerCase()} ${isSelected ? "error" : ""} ${collide}`,
                'id': `${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`
            }

        } else return {
            'className': `${LayerDefs.PressTool.toLowerCase()}`,
            'id': `${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}_preview`
        }
    },[props.id, collisionCtx, errCtx])

    const renderTool = useCallback((pressTools: ITool[]) => {
        return pressTools.reduce((acc:  JSX.Element[], tool: ITool) => {

            const ovr = EditServiceObj.getPropertyChange(`${LayerDefs.PressTool}_${tool.nailplateId}`) || {}
            let angle = tool.angle;
            let x: number = tool.x;
            let y: number = tool.y;
            if(ovr.angle !== undefined)
            {
                angle = ovr.angle;
            }
            if (ovr.x !== undefined && ovr.y !== undefined) {
                x = ovr.x
                y = ovr.y
            }

            const angleByDegrees = angle * (180 / Math.PI)

            const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`)

            if ((props.id === tool.nailplateId || props.id === undefined) && !isDeleted) {

                acc.push(<g key={`${LayerDefs.PressTool}_${tool.nailplateId}`}
                            transform={`rotate(${angleByDegrees}, ${x}, ${y}) translate(${x}, ${y})`}
                            {...getProperties(tool)}
                >
                    <PressPlate/>
                    <PressTool/>
                    <Mouth/>
                </g>)
            }

            return acc

        }, [])

    },[props.id, collisionCtx, errCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
                <RecipeContext.Consumer>
                    {ctx => <g key={LayerDefs.PressTool} id={LayerDefs.PressTool.toLowerCase()}
                               className={`${LayerDefs.PressTool.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.PressTool) && (props.id === undefined) ? "hide-layer" : ""}`}>
                        {ctx?.recipe.PressTools && renderTool(ctx.recipe.PressTools)}
                    </g>}
                </RecipeContext.Consumer>}
            </LayerSelectionContext.Consumer>

}

export const MultiPressToolLayer: React.FunctionComponent<any> = ()=> {

    return <CollisionContext.Consumer>{collisionCtx=>
        <LayerSelectionContext.Consumer>{layerCtx=>
            <RecipeContext.Consumer>
                {ctx => <g key={LayerDefs.MultiPressTool} id={LayerDefs.MultiPressTool.toLowerCase()}
                           className={`${LayerDefs.MultiPressTool.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.MultiPressTool)? "hide-layer" : ""}`}>
                    {ctx?.recipe?.PressTools?.reduce((acc: JSX.Element[], tool: {[key:string]: any}) => {

                        const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`)

                        if (tool.multipress && !isDeleted) {
                            tool.multipress.map((item, index)=>{

                                const angleByDegrees = item.angle * (180 / Math.PI)

                                const collide = collisionCtx?.collisions?.has(`${LayerDefs.MultiPressTool.toLowerCase()}_${tool.nailplateId}_${index}`)? "collide" : ""

                                acc.push(<g key={`${LayerDefs.MultiPressTool}_${tool.nailplateId}_${index}`}
                                            transform={`rotate(${angleByDegrees}, ${item.x}, ${item.y}) translate(${item.x}, ${item.y})`}
                                            id={`${LayerDefs.MultiPressTool.toLowerCase()}_${tool.nailplateId}_${index}`}
                                            className={`${LayerDefs.MultiPressTool.toLowerCase()} ${collide}`}
                                            data-component={tool.nailplateId}
                                            data-layer={LayerDefs.PressTool}
                                            data-id={`${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`}
                                >
                                    <PressPlate/>
                                    <PressTool/>
                                </g>)
                            })
                        }
                        return acc
                    },[])}
                </g>}
            </RecipeContext.Consumer>}
        </LayerSelectionContext.Consumer>}
    </CollisionContext.Consumer>
}

export const PressToolEntryLayer: React.FunctionComponent<{id?:number}> = (props)=> {

    const errCtx = useContext(ErrorSelectionContext)

    const getProperties = useCallback((tool: ITool) => {

        const selectedPressToolId = `${LayerDefs.PressToolEntry}_${tool.nailplateId}`;
        const isSelected = isSelectedByIdList([selectedPressToolId], errCtx)

        if (props.id === undefined) {
            return{
                'className': `${LayerDefs.PressToolEntry.toLowerCase()} ${isSelected ? "error" : ""}`,
                'id': `${LayerDefs.PressToolEntry.toLowerCase()}_${tool.nailplateId}`
            }

        } else {
            return {
                'className': `${LayerDefs.PressToolEntry.toLowerCase()}`
            }
        }
    },[props.id, errCtx])

    const renderTool = useCallback((pressTools: ITool[]) => {
        return pressTools.reduce((acc:  JSX.Element[], tool: ITool) => {

            const angleByDegrees = tool.entryPositions[0].angle * (180 / Math.PI)

            const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`)

            if ((props.id === tool.nailplateId || props.id === undefined) && !isDeleted) {

                acc.push(<g key={`${LayerDefs.PressToolEntry}_${tool.nailplateId}`}
                            transform={`rotate(${angleByDegrees}, ${tool.entryPositions[0].x}, ${tool.entryPositions[0].y}) translate(${tool.entryPositions[0].x}, ${tool.entryPositions[0].y})`}
                            {...getProperties(tool)}
                >
                    <PressTool/>
                </g>)
            }

            return acc

        }, [])

    },[props.id, errCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
            <RecipeContext.Consumer>
                {ctx => <g key={LayerDefs.PressToolEntry} id={LayerDefs.PressToolEntry.toLowerCase()}
                           className={`${LayerDefs.PressToolEntry.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.PressToolEntry) && (props.id === undefined) ? "hide-layer" : ""}`}>
                    {ctx?.recipe.PressTools && renderTool(ctx.recipe.PressTools)}
                </g>}
            </RecipeContext.Consumer>}
        </LayerSelectionContext.Consumer>

}

export const PressToolUpperShapeLayer: React.FunctionComponent<{id?:number}> = (props)=> {

    const collisionCtx = useContext(CollisionContext)
    const errCtx = useContext(ErrorSelectionContext)

    const getProperties = useCallback((tool: ITool) => {

        const selectedPressToolId = `${LayerDefs.PressToolUpperShape}_${tool.nailplateId}`;
        const isSelected = isSelectedByIdList([selectedPressToolId], errCtx)

        const collide = collisionCtx?.collisions?.has(`${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`)? "collide" : ""

        if (props.id === undefined) {
            return{
                'className': `${LayerDefs.PressToolUpperShape.toLowerCase()} ${isSelected ? "error" : ""} ${collide}`,
                'id': `${LayerDefs.PressToolUpperShape.toLowerCase()}_${tool.nailplateId}`
            }

        } else {
            return {
                'className': `${LayerDefs.PressToolUpperShape.toLowerCase()}`,
                'id': `${LayerDefs.PressToolUpperShape.toLowerCase()}_${tool.nailplateId}_preview`
            }
        }
    },[props.id, errCtx, collisionCtx])

    const renderTool = useCallback((pressTools: ITool[]) => {
        return pressTools.reduce((acc:  JSX.Element[], tool: ITool) => {

            const ovr = EditServiceObj.getPropertyChange(`${LayerDefs.PressTool}_${tool.nailplateId}`) || {}
            let angle = tool.angle;
            let x: number = tool.x;
            let y: number = tool.y;
            if(ovr.angle !== undefined)
            {
                angle = ovr.angle;
            }
            if (ovr.x !== undefined && ovr.y !== undefined) {
                x = ovr.x
                y = ovr.y
            }

            const angleByDegrees = angle * (180 / Math.PI)

            const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`)

            if ((props.id === tool.nailplateId || props.id === undefined) && !isDeleted) {

                acc.push(<g key={`${LayerDefs.PressToolUpperShape}_${tool.nailplateId}`}
                            transform={`rotate(${angleByDegrees}, ${x}, ${y}) translate(${x}, ${y})`}
                            {...getProperties(tool)}
                >
                    <PressToolUpperShape/>
                </g>)
            }

            return acc

        }, [])

    },[props.id, errCtx, collisionCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
                <RecipeContext.Consumer>
                    {ctx => <g key={LayerDefs.PressToolUpperShape} id={LayerDefs.PressToolUpperShape.toLowerCase()}
                               className={`${LayerDefs.PressToolUpperShape.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.PressToolUpperShape) && (props.id === undefined) ? "hide-layer" : ""}`}>
                        {ctx?.recipe.PressTools && renderTool(ctx.recipe.PressTools)}
                    </g>}
                </RecipeContext.Consumer>}
            </LayerSelectionContext.Consumer>

}

export const MultiPressToolUpperShapeLayer: React.FunctionComponent<any> = ()=> {

    return <CollisionContext.Consumer>{collisionCtx=>
        <LayerSelectionContext.Consumer>{layerCtx=>
            <RecipeContext.Consumer>
                {ctx => <g key={LayerDefs.MultiPressToolUpperShape} id={LayerDefs.MultiPressToolUpperShape.toLowerCase()}
                           className={`${LayerDefs.MultiPressToolUpperShape.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.MultiPressToolUpperShape)? "hide-layer" : ""}`}>
                    {ctx?.recipe?.PressTools?.reduce((acc: JSX.Element[], tool: {[key:string]: any}) => {
                        const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`)
                        if (tool.multipress && !isDeleted) {
                            tool.multipress.map((item, index)=>{

                                const angleByDegrees = item.angle * (180 / Math.PI)

                                const collide = collisionCtx?.collisions?.has(`${LayerDefs.MultiPressToolUpperShape.toLowerCase()}_${tool.nailplateId}_${index}`)? "collide" : ""

                                acc.push(<g key={`${LayerDefs.MultiPressToolUpperShape}_${tool.nailplateId}_${index}`}
                                            transform={`rotate(${angleByDegrees}, ${item.x}, ${item.y}) translate(${item.x}, ${item.y})`}
                                            id={`${LayerDefs.MultiPressToolUpperShape.toLowerCase()}_${tool.nailplateId}_${index}`}
                                            className={`${LayerDefs.MultiPressToolUpperShape.toLowerCase()} ${collide}`}
                                            data-component={tool.nailplateId}
                                            data-layer={LayerDefs.PressTool}
                                            data-id={`${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`}
                                >
                                    <PressToolUpperShape/>
                                </g>)
                            })
                        }
                        return acc
                    },[])}
                </g>}
            </RecipeContext.Consumer>}
        </LayerSelectionContext.Consumer>}
    </CollisionContext.Consumer>
}

export const PressToolEntryUpperShapeLayer: React.FunctionComponent<{id?:number}> = (props)=> {

    const errCtx = useContext(ErrorSelectionContext)

    const getProperties = useCallback((tool: ITool) => {

        const selectedPressToolId = `${LayerDefs.PressToolEntryUpperShape}_${tool.nailplateId}`;
        const isSelected = isSelectedByIdList([selectedPressToolId], errCtx)

        if (props.id === undefined) {
            return{
                'className': `${LayerDefs.PressToolEntryUpperShape.toLowerCase()} ${isSelected ? "error" : ""}`,
                'id': `${LayerDefs.PressToolEntryUpperShape.toLowerCase()}_${tool.nailplateId}`
            }

        } else {
            return {
                'className': `${LayerDefs.PressToolEntryUpperShape.toLowerCase()}`
            }
        }
    },[props.id, errCtx])

    const renderTool = useCallback((pressTools: ITool[]) => {
        return pressTools.reduce((acc:  JSX.Element[], tool: ITool) => {

            const angleByDegrees = tool.entryPositions[0].angle * (180 / Math.PI)
            const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`)

            if ((props.id === tool.nailplateId || props.id === undefined) && !isDeleted) {

                acc.push(<g key={`${LayerDefs.PressToolEntryUpperShape}_${tool.nailplateId}`}
                            transform={`rotate(${angleByDegrees}, ${tool.entryPositions[0].x}, ${tool.entryPositions[0].y}) translate(${tool.entryPositions[0].x}, ${tool.entryPositions[0].y})`}
                            {...getProperties(tool)}
                >
                    <PressToolUpperShape/>
                </g>)
            }

            return acc

        }, [])

    },[props.id, errCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
    <RecipeContext.Consumer>
        {ctx => <g key={LayerDefs.PressToolEntryUpperShape} id={LayerDefs.PressToolEntryUpperShape.toLowerCase()}
                   className={`${LayerDefs.PressToolEntryUpperShape.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.PressToolEntryUpperShape) && (props.id === undefined) ? "hide-layer" : ""}`}>
            {ctx?.recipe.PressTools && renderTool(ctx.recipe.PressTools)}
        </g>}
    </RecipeContext.Consumer>}
        </LayerSelectionContext.Consumer>
}

export const PressToolPaddleLayer: React.FunctionComponent<{id?:number}> = (props)=> {

    const renderTool = useCallback((pressTools: ITool[]) => {

        return pressTools.reduce((acc:  JSX.Element[], tool: ITool) => {

            const ovr = EditServiceObj.getPropertyChange(`${LayerDefs.PressTool}_${tool.nailplateId}`) || {}
            let angle = tool.angle;
            let x: number = tool.x;
            let y: number = tool.y;
            if(ovr.angle !== undefined)
            {
                angle = ovr.angle;
            }
            if (ovr.x !== undefined && ovr.y !== undefined) {
                x = ovr.x
                y = ovr.y
            }
            const angleByDegrees = angle * (180 / Math.PI)
            const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.PressTool.toLowerCase()}_${tool.nailplateId}`)

            let rearPaddleId = `${LayerDefs.PressToolRearPaddle.toLowerCase()}_${tool.nailplateId}`
            let frontPaddleId = `${LayerDefs.PressToolPaddle.toLowerCase()}_${tool.nailplateId}`

            if (props.id !== undefined) {
                rearPaddleId = `${LayerDefs.PressToolRearPaddle.toLowerCase()}_${tool.nailplateId}_preview`
                frontPaddleId = `${LayerDefs.PressToolPaddle.toLowerCase()}_${tool.nailplateId}_preview`
            }

            if ((props.id === tool.nailplateId || props.id === undefined) && !isDeleted) {

                if (tool.rearPaddleDistance >0) {
                    acc.push(<g key={`${LayerDefs.PressToolRearPaddle}_${tool.nailplateId}`}
                                transform={`rotate(${angleByDegrees}, ${x}, ${y}) translate(${x - tool.rearPaddleDistance}, ${y})`}
                                id={rearPaddleId}
                                className={`${LayerDefs.PressToolPaddle.toLowerCase()}`}
                    >
                        <RearPaddleShape/>
                    </g>)
                }
                acc.push(<g key={`${LayerDefs.PressToolPaddle}_${tool.nailplateId}`}
                            transform={`rotate(${angleByDegrees}, ${x}, ${y}) translate(${x}, ${y})`}
                            id={frontPaddleId}
                            className={`${LayerDefs.PressToolPaddle.toLowerCase()}`}
                >
                    {tool.useAdvancedLeftFrontPaddle && <AdvancedLeftFrontPaddle/>}
                    {tool.useAdvancedRightFrontPaddle && <AdvancedRightFrontPaddle/>}
                    {tool.useLeftFrontPaddle && <LeftFrontPaddle/>}
                    {tool.useRightFrontPaddle && <RightFrontPaddle/>}
                </g>)
            }

            return acc

        }, [])

    },[props.id])

    return <LayerSelectionContext.Consumer>{layerCtx=>
    <RecipeContext.Consumer>
        {ctx => <g key={LayerDefs.PressToolPaddle} id={LayerDefs.PressToolPaddle.toLowerCase()}
                   className={`${LayerDefs.PressToolPaddle.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.PressToolPaddle) && (props.id === undefined) ? "hide-layer" : ""}`}>

            {ctx?.recipe.PressTools && renderTool(ctx.recipe.PressTools)}
        </g>}
    </RecipeContext.Consumer>}
        </LayerSelectionContext.Consumer>
}
