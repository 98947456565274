/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {useContext} from "react";
import {LayerDefs} from "../def";
import {RecipeContext} from "../util";
import {LayerSelectionContext} from "../../trusscheck/util"

export const MassCenterPointLayer: React.FunctionComponent<any> = ()=> {

    const recipeCtx = useContext(RecipeContext)
    const [massCenterPoint, setMassCenterPoint] = React.useState<{x: number, y: number} | undefined>(undefined)

    React.useEffect(()=>{
        const massCenterPoint = recipeCtx?.recipe?.trussInfo?.massCenterPoint
        setMassCenterPoint(massCenterPoint)
    },[recipeCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
                <g key={LayerDefs.MassCenterPoint} id={LayerDefs.MassCenterPoint.toLowerCase()}
                   className={`${LayerDefs.MassCenterPoint.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.MassCenterPoint)? "hide-layer" : ""}`} >
            {

                massCenterPoint && <circle cx={massCenterPoint.x} cy={massCenterPoint.y} r="30"/>
            }
        </g>
        }</LayerSelectionContext.Consumer>
}