/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, ReactNode} from "react";
import {DropDownProps, Dropdown, Menu, Modal} from 'antd'
import {ItemType} from "antd/es/menu/hooks/useItems";
import {If} from "../../../../util/utils";
import './index.css'
import {DefaultArgs,} from '@q4us-sw/q4us-ui-v2/dist/form/FormV2'
import {Form} from '@q4us-sw/q4us-ui-v2/dist/form'
import {FormElementProps} from "@q4us-sw/q4us-ui-v2/dist/form/def";
import {LayerDefs} from '../../def'
import {EditServiceObj} from "../../EditService";
import {MenuInfo} from "rc-menu/lib/interface";

export interface Point {
    x: number,
    y: number
}

export interface MouseEventContextProps<T, U = any> {
    type?: T,
    data?: U,
    event?: MouseEvent
    trigger?: boolean,
    close?: () => void
}

export interface DynamicMenuContextProps extends MouseEventContextProps<"context_menu" | "click"> {
    items: ItemType<any>[],
}


export interface ContextMenuProps<U = any> extends MouseEventContextProps<"context_menu", U> {
}

export interface ClickMenuProps<U = any> extends MouseEventContextProps<"click", U> {
}

export interface AllProps<U = any> extends MouseEventContextProps<"context_menu" | "click", U> {
}

export const OnContextMenuContext = React.createContext<ContextMenuProps | undefined>(undefined)
export const OnClickMenuContext = React.createContext<ClickMenuProps | undefined>(undefined)
export const OnAnyMenuContext = React.createContext<AllProps | undefined>(undefined)


export const DynamicMenuContext = React.createContext<DynamicMenuContextProps | undefined>(undefined)


export const EventContextProvider: React.FC<PropsWithChildren<{}>> = (props) => {
    return <DynamicMenuContext.Consumer>
        {
            ctx => <OnAnyMenuContext.Provider value={{...ctx, trigger: (!!ctx?.type) && (!!ctx.data)}}>
                <OnContextMenuContext.Provider value={{...ctx, trigger: ctx?.type === 'context_menu' && (!!ctx.data)}}>
                    <OnClickMenuContext.Provider value={{...ctx, trigger: ctx?.type === 'click' && (!!ctx.data)}}>
                        {props.children}
                    </OnClickMenuContext.Provider>
                </OnContextMenuContext.Provider>
            </OnAnyMenuContext.Provider>

        }
    </DynamicMenuContext.Consumer>
}

export interface DynamicMenuContextProviderProps<T> {
    items: (event: MouseEvent, props: T) => ItemType<any>[]
    filterItems: (type: 'click' | 'context_menu', event: MouseEvent, data: ItemType<any>[]) => ItemType<any>[]
    fetchDataForAction: (e: MenuInfo, items: ItemType<any>[], type: 'click' | 'context_menu')=> any
    enable: boolean
    trigger: ('click' | 'context_menu')[],
    eventHandlerComponent: ReactNode
}


export const DynamicMenuContextProvider: React.FC<PropsWithChildren<DynamicMenuContextProviderProps<any>>> = (props) => {

    const [data, setData] = React.useState<Omit<DynamicMenuContextProps,'close'>>({items: []})


    const onMouseDown = React.useCallback((e: MouseEvent) => {
        if(props.enable && props.trigger.length > 0) {
            const items = props.items(e, {})
            setData(s => ({...s, items, event: e}))
        }
    }, [props])
    const onClick = React.useCallback((e: MouseEvent) => {
        if(props.enable  && props.trigger.includes('click')) {
            setData(s => ({...s, event: e, type: 'click',trigger: true, items: props.filterItems('click', e, s.items)}))
        }else{
            exitMenu();
        }
    }, [props])
    const onContextMenu = React.useCallback((e: MouseEvent) => {
        if(props.enable && props.trigger.includes('context_menu')) {
            e.preventDefault();
            setData(s => ({...s, event: e, type: 'context_menu',trigger: true, items: props.filterItems('context_menu', e, s.items)}))
        }else{
            exitMenu();
        }
    }, [props])
    const exitMenu = React.useCallback(() => {
        if(props.enable) {
            setData(s=>({items: []}))
        }
    }, [props])




    return <DynamicMenuContext.Provider value={{items: data?.items, data: data.data, type: data.type, event: data.event, close: exitMenu, trigger: data.trigger}}>
        <div onContextMenu={onContextMenu} onMouseDown={onMouseDown} onClick={onClick} onScroll={exitMenu}>
            {props.children}
        </div>
        {(props.enable && data.trigger && data?.items?.length > 0) && <div
            style={{left: `${data.event?.clientX || 0}px`, top: `${data.event?.clientY || 0}px`}}
                           className={"context-menu"}
        >
            <Menu items={data.items} onClick={(e) => {
                const _data = props.fetchDataForAction(e, data.items, data.type || 'click')
                setData(s=>({...s, trigger: false, data: _data}))

            }}/>
        </div>}
        <EventContextProvider>
            {props.eventHandlerComponent}
        </EventContextProvider>
    </DynamicMenuContext.Provider>

}
export const DefaultProps =  {
    items: (event: MouseEvent, props: any) => [],
    filterItems: (type: 'click' | 'context_menu', event: MouseEvent, data: ItemType<any>[]) => data,
    fetchDataForAction: (e: MenuInfo, items: ItemType<any>[], type: 'click' | 'context_menu')=> ({...e}),
    enable: true,
    trigger: ['context_menu'],
    eventHandlerComponent: <></>
} as const
DynamicMenuContextProvider.defaultProps = DefaultProps
