/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {loadTrussContext} from "../../api";
import {Member, Truss} from "./trussdef";
import './styles/TrussView.css'
import { Trans } from "react-i18next";
import { PreferenceContext } from "../user/UserPreference";
import { ErrorTrans } from "../reactive/Trans";

interface BaseParam {
    value: string;
    type: string;
  }
  
  interface TrussError {
    baseMessage: string;
    baseParams: BaseParam[];
    category: string;
    message: string;
    targetId: string;
    type ?: string;
  }
  
  interface TrussErrorbarProps {
    errorWarningData: TrussError[];
}
  

export const TrussErrorWarningbar: React.FunctionComponent<TrussErrorbarProps> = ({ errorWarningData }) => {
    const prefCtx = React.useContext(PreferenceContext);
    return (
      <div className={'pan-error-pannel'}>
        <div className="selected-component">
              {errorWarningData?.map((data, index) => (
                <div key={index} className={`transparent-card ${data.type}`}>
                  <span className={"error-warring-text"} style={{ color: "black" }}>
                  <ErrorTrans i18nKey={`error_translations.${data.baseMessage}`} defaults={data.message}  
                values={prefCtx?.processDimensions(data.baseParams)}/>
                  </span>
                </div>
              ))}
        </div>
      </div>
    );
  };
  

export const TrussVisualization: React.FunctionComponent<{truss_id: number, timestamp_year: number}> = (props) => {

    const [data, setData] = React.useState<Truss>();
    const [view, setView] = React.useState([0, 0, 500, 400]);
    const [errorsWaringsData , setErrorsWarningsData] = React.useState<TrussError[]>([]);

    const updateErrorsWarningsData = (data: TrussError[], type: string) => {
        if (data && data.length !== 0) {
            setErrorsWarningsData((prevData) => {
                const existingMessages = new Set(prevData.map((item) => item.message));
                const filteredData = data.filter((item) => !existingMessages.has(item.message));
                const newList: TrussError[] = filteredData.map((obj) => ({ ...obj, type }));
                return [...prevData, ...newList];
              });
        }
      };
      

    const load = React.useCallback(async ()=>{
        const {truss_id, timestamp_year} = props
    
        const {data: {truss = {}} = {}} = await loadTrussContext({
            truss_id: truss_id,
            timestamp_year: timestamp_year
        }) || {}

        setErrorsWarningsData([]);
        setData(truss);
        updateErrorsWarningsData(truss.errors, 'error');
        updateErrorsWarningsData(truss.warnings, 'warning');

        const memberPolygons: {x: number, y: number}[] = [];
        truss.members.map((m: Member) => {
            return memberPolygons.push(...m.polygon)}
        );
        const view_box = viewBox(memberPolygons);
        setView(view_box)

    }, [props])

    React.useEffect(()=>{
        load().catch(console.error);
    }, [props.truss_id, props.timestamp_year])

    const viewBox = React.useCallback((polygon: {x: number, y: number}[]): number[]=>{
        const {xMin, xMax, yMin, yMax} = polygon.reduce(({xMax, xMin, yMax, yMin}, {x,y})=>{
            return {xMax: Math.max(xMax, x), yMax: Math.max(yMax, y), yMin: Math.min(yMin, y), xMin: Math.min(xMin, x)}
        }, {xMin: Number.MAX_SAFE_INTEGER, yMin: Number.MAX_SAFE_INTEGER, xMax: 0, yMax: 0 })
        return [xMin-10, yMin-10, xMax - xMin + 20, yMax - yMin + 20]
    }, [])

    return <React.Fragment>
        <TrussErrorWarningbar errorWarningData={errorsWaringsData}/>
        <div style={{width: '100%', height: '100%'}}>
            <svg className={"svg_component"} viewBox={view.join(" ")}>
                <g  transform={`scale(1,-1) translate(0, ${-1*(view[3]-20)})`}>
                    {data?.members.map((m) => {
                        return <g className={"truss_timber_beam"}><polygon points={m.polygon.map(({x,y })=>`${x} ${y}` ).join(" ")}/></g>
                    })}
                    {data?.plates.map((p) => {
                        return <g className={"truss_nail_plate"}><polygon points={p.polygon.map(({x,y })=>`${x} ${y}` ).join(" ")}/></g>
                    })}
                </g>
            </svg>
        </div>
    </React.Fragment>
}