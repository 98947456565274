/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import {Table} from "@q4us-sw/q4us-ui-v2/dist/table";
import {Badge, Button, Checkbox, Dropdown, notification, Progress} from "antd";
import {Trans} from "react-i18next";
import {FormattedValueProps} from "@q4us-sw/q4us-ui-v2";
import {CheckSquareOutlined, ExclamationCircleOutlined, ReloadOutlined, WarningOutlined ,WarningTwoTone} from "@ant-design/icons";
import {SelectionContextProvider} from "@q4us-sw/q4us-ui-v2/dist/table/Selection";
import {RecipeCollisionRequest, RecipeSelection, RecipeSelectionContext, Status, TrussContext,TrussContextProvider } from "./util";
import {DataContext, DataContextProvider,Response} from "@q4us-sw/q4us-ui-v2/dist/util";
import { visualizeLoad } from "../../api";
import { inlineFilterMap } from "../../util/mapping";
import {Formatter} from "@q4us-sw/q4us-ui-v2";
export const Deployment: React.FunctionComponent<any> = (props) => {
    return <div className={`tv-recipe-panel`} >
    
    <div className={`additional-panel`}>
        <div className={`tv-recipe-info`} >

            <DeploymentSummary />
        </div>
    </div>
    <div className={`additional-panel`}>
        <div className={`tv-recipe-info`} >

            <DeploymentDetail />
        </div>
    </div>

</div>
}

export const DeploymentSummary: React.FunctionComponent<any> = (props) => {
    return <TrussContext.Consumer>
            {t=><DataContextProvider key={t?.truss?.id || "empty"} data={async (req) => {
                
                const res = await visualizeLoad({
                    tableName: 'truss_deploy_summary',
                    filter: req.filter || [{name: "truss_id", value: t?.truss?.id, comparator: "="}],
                    sort: req.sort,
                    
                })
                return res as Response

                }}>
                <Table
                    key={'table'}
                    rowKey={"id"}
                    className={"deployment-view"}
                    size={"small"}
                    scroll={{y: 'calc(100% - 40px)'}}
                    register={(r) => r}
                    formatRegister={(registry) => registry}
                    bordered={true}
                    schema={"deploy_summary"}/>
            </DataContextProvider>}
        </TrussContext.Consumer>
            
       
}
export const DeploymentDetail: React.FunctionComponent<any> = (props) => {
    return <SelectionContextProvider>
            <TrussContext.Consumer>
                {t=><DataContextProvider key={t?.truss?.id || "empty"} data={async (req) => {
                const res = await visualizeLoad({
                    tableName: 'truss_deploy_detail_view',
                    filter: [{name: "truss", value: t?.truss?.id}, ...inlineFilterMap(req.inline?.filter || {})],
                    sort: req.sort || [{"date": "desc"}],
                    
                })
                return res as Response

                }}>
                    <Table
                        size={"small"}
                        scroll={{y: 'calc(100% - 40px)'}}
                        rowKey={"id"}
                        register={(r) => r}
                        formatRegister={(registry) => 
                            registry.register("DATE", DateFormatter)
                        }
                        bordered={true}
                        schema={"deploy_detail"}/>
                </DataContextProvider>}
            </TrussContext.Consumer>
        </SelectionContextProvider>
}
const DateFormatter: Formatter = (props) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(props.value);
    const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: userTimeZone,
    });

    return (
        <React.Fragment>
            <div>
                {formattedDate}
            </div>
        </React.Fragment>
    );
};