/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {Avatar, Badge, Button, Dropdown, Menu, Select, notification, Modal} from "antd";
import {BellOutlined, DownOutlined} from "@ant-design/icons";
import {AuthContext, AuthContextProps} from "../user/AuthProvider";
import {useNavigate,useLocation } from "react-router-dom";
import iso6391 from 'iso-639-1'
import {I18nContext, Trans,useTranslation} from 'react-i18next'
import {
    getAvailableLocales,
    getConfiguration,
    getNewMessageCount,
    deploySelectedRecipe,
    deployRecipe,
    fetchDefaultConfig, purgeTrusses
} from "../../api";
import {IOContext} from "../io/SocketIO";
import qs from "query-string";
import {isRecheckRecipe,availableServices} from "../../api";
import { RecipeHomeContext } from "../RecipeManagerHome";
import { PreferenceContext, UnitType } from "../user/UserPreference";
import {ErrorReportModal} from "../user/ErrorReport/ErrorReportModal";
import { DeleteOutlined } from '@ant-design/icons';
import {Header as Headerlib} from  '@trussmaticsw/commonui'
import '@trussmaticsw/commonui/dist/assets/style.css'
export const LanguageSwitch: React.FC<{}> = (props)=>{
    const prefCtx = React.useContext(PreferenceContext)
    const i = React.useContext(I18nContext)
    const [languages, setLanguages] = React.useState<string[]>([i.i18n.language])
    const [lang, setLang] = React.useState<string>(i.i18n.language)


    const load = React.useCallback(async ()=>{
        const {data = []} = await getAvailableLocales() || {}
        setLanguages(data)
    },[])
    React.useEffect(()=>{
        load().catch(console.error)
        setLang(prefCtx?.prefInfo?.pref?.language || 'en')
    },[prefCtx?.prefInfo])

    return <div style={{ display: 'flex', justifyContent: 'center' ,margin: '5px'}}><Select value={lang} className="user-select-menu" onSelect={async (e)=>{

        await i.i18n.changeLanguage(e).catch(console.error)
        setLang(i.i18n.language)
        prefCtx?.load(i.i18n.language)
    }}>
        {languages.map(o=><Select.Option value={o}>{iso6391.getName(o)}</Select.Option>)}
    </Select></div>
}
export const UnitSwitch: React.FC<{}> = (props)=>{
    const prefCtx = React.useContext(PreferenceContext)
    const [unit, setUnit] = React.useState<string>(UnitType.metric)

    React.useEffect(()=>{
        setUnit(prefCtx?.prefInfo?.pref?.units || UnitType.metric)
    },[prefCtx])

    return <div style={{ display: 'flex', justifyContent: 'center', width: '60%',marginRight : '30px' }}>
    <Select
      value={unit}
      onSelect={async (e) => {
        setUnit(e);
        prefCtx?.load(undefined, e);
      }}
      bordered={false}
    >
      <Select.Option value={UnitType.metric}>{UnitType.metric}</Select.Option>
      <Select.Option value={UnitType.imperial}>{UnitType.imperial}</Select.Option>
    </Select>
  </div>
  
}
export const parseTrussViewParams = (location: any)=>{
    const params = qs.parse(location.search)
    return {
        truss_id: parseInt(`${params["t"]}`),
        timestamp_year: parseInt(`${params["y"]}`),
        project_id: parseInt(`${params["p"]}`),
        project_name: `${params["proj"]}`,
        config: `${params["config"]}`,
        version: parseInt(`${params["version"]}`),
        truss: `${params["truss"]}`,
        recipe: parseInt(`${params["recipe"]}`),
        isRecipe: parseInt(`${params["isRecipe"]}`),
        deploylist: parseInt(`${params["deploylist"]}`),
    }
}

interface RecipeState {
    project: number;
    truss: string;
    config: string;
    recipe: number;
    timestamp_year: number;
    isRecipe:number;
  }
  interface DataItem {
    id: number | string;
    recipe_id: number;
    timestamp_year: number;
}
interface PurgeDataItem {
    truss_id: number;
}
export const ActionSwitchDeploy: React.FC<{}> = (props)=>{
    const { t } = useTranslation();
    const location = useLocation();
    const [enableAction, setenableAction] = React.useState<boolean>(false);
    const [data,setData] = React.useState<any>(location.state?.deployData || null)
    const [selection,setSelection] = React.useState<any>(location.state?.deploySelection || null)

    const selectedCtx = React.useContext(RecipeHomeContext);
    
    const deploy = React.useCallback(async () => {
        const data = location?.state?.deploySetectiondata;
        const ctx = location?.state?.deployData;
        const selectionCtx = location?.state?.deploySelection;
        if (location.state?.deploySelection && location.state?.deploySelection.keys?.length > 0) {
            setenableAction(true);
          // Update the data and selection states from location.state
          setData(location.state.deployData);
          setSelection(location.state.deploySelection);
        } else {
          // Reset data and selection when there's no data in location.state
          setData(null);
          setSelection(null);
        }
        
        const recipesToDeploy: { recipe_id: number; timestamp_year: number }[] = data
  .filter((d: DataItem) => typeof d.id === 'string' && d.id.startsWith('C-'))
  .map((d: DataItem) => ({ recipe_id: d.recipe_id, timestamp_year: d.timestamp_year }));
        const res = await deployRecipe({data: recipesToDeploy})
        if (res.status === 200) {
            notification.info({message: <Trans i18nKey={`notification.recipe_deployment_info`} defaults={`${res.data.deployedCount}/${res.data.totalCount} recipes queued for deployment for job ${res.data.jobId}`} values={{
                count: res.data.deployedCount,
                total: res.data.totalCount,
                jobId: res.data.jobId
              }}/>})
            ctx.fetch?.({
                sort: ctx.sort,
                inline: ctx.inline,
                page: ctx.page
            } as any)
            selectionCtx.clear?.()
        } else {
            notification.error({message: <Trans i18nKey={`notification.recipe_deployment_error`} defaults={'Error in recipe deployment.'}/>})
        }

    },[])

    React.useEffect(() => {
        if (location.state?.deploySelection && location.state?.deploySelection?.selection?.keys?.length > 0) {
            setenableAction(true);
          // Update the data and selection states from location.state
          setData(location.state.deployData);
          setSelection(location.state.deploySelection);
        } else {
            setenableAction(false);
            // Reset data and selection when there's no data in location.state
            setData(null);
            setSelection(null);
        }
      }, [location.state,selectedCtx?.selectedComponent]);
    

    return (
            <Button className="action-button" disabled = {!enableAction} type="primary"  style={{ width: "280px" }} onClick={async ()=>{
                                    await deploy()
                                }}><Trans i18nKey={'button.header.Deploy_to_Copy_lines'} defaults={"Deploy All To Copy lines"}/></Button>
        )

    
    
}
export const ActionSwitchPurge: React.FC<{}> = (props)=>{
    const { t } = useTranslation();
    const location = useLocation();
    const [enableAction, setenableAction] = React.useState<boolean>(false);
    const [data,setData] = React.useState<any>(location.state?.deployData || null)
    const [selection,setSelection] = React.useState<any>(location.state?.deploySelection || null)

    const selectedCtx = React.useContext(RecipeHomeContext);

    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
    const [purgeReqCount, setPurgeReqCount] = React.useState<number>(0);

    const handleOk = async () => {
        setIsModalVisible(false);
        setPurgeReqCount(0);

        const data = location?.state?.deploySetectiondata;
        const ctx = location?.state?.deployData;
        const selectionCtx = location?.state?.deploySelection;
        if (location.state?.deploySelection && location.state?.deploySelection.keys?.length > 0) {
            setenableAction(true);
            // Update the data and selection states from location.state
            setData(location.state.deployData);
            setSelection(location.state.deploySelection);
        } else {
            // Reset data and selection when there's no data in location.state
            setData(null);
            setSelection(null);
            setenableAction(false);
        }

        const purgeData: [] = data
            .filter((d: DataItem) => typeof d.id === 'string' && d.id.startsWith('C-')).
        map(d => d.truss_id);
        const res = await purgeTrusses({truss_ids: purgeData})
        if (res.status === 200) {
            notification.info({message: <Trans i18nKey={`notification.truss_purge_info`} defaults={`${res.data.trussCount} trusses queued for purge job`} values={{
                    count: res.data.trussCount
                }}/>})
            ctx.fetch?.({
                sort: ctx.sort,
                inline: ctx.inline,
                page: ctx.page
            } as any)
            selectionCtx.clear?.()
        } else {
            notification.error({message: <Trans i18nKey={`notification.truss_purge_error`} defaults={'Error in truss purge.'} />})
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setPurgeReqCount(0)
    };

    React.useEffect(() => {
        if (location.state?.deploySelection && location.state?.deploySelection?.selection?.keys?.length > 0) {
            setenableAction(true);
            // Update the data and selection states from location.state
            setData(location.state.deployData);
            setSelection(location.state.deploySelection);
        } else {
            setenableAction(false);
            // Reset data and selection when there's no data in location.state
            setData(null);
            setSelection(null);
        }
    }, [location.state,selectedCtx?.selectedComponent]);


    return (
        <>
            <Button className="delete-button" disabled = {!enableAction} type="primary"  style={{ width: "100px" }} onClick={async ()=>{
                setIsModalVisible(true);
                const purgeData = location?.state?.deploySetectiondata
                    .filter((d: DataItem) => typeof d.id === 'string' && d.id.startsWith('C-'))
                    .map(d => d.truss_id);
                setPurgeReqCount(purgeData ? purgeData.length : 0);
            }}><span style={{ marginLeft: '5px', marginRight: '5px' }}><DeleteOutlined></DeleteOutlined></span>
                <Trans i18nKey={'button.header.Purge'} defaults={"Purge"}/></Button>

            <Modal
                title={<Trans i18nKey={'modal.confirmation'} defaults={'Confirmation'} />}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>
                    <Trans i18nKey={'modal.confirm_purge_message'}
                           defaults={`Are you sure you want to purge ${purgeReqCount} trusses?`}
                           values={{ count: purgeReqCount }}/>
                </p>
            </Modal>
        </>

    )



}
export const ActionSwitch: React.FC<{}> = (props)=>{
    
    const [selectedAction, setSelectedAction] = React.useState<string>("");
    const [data, setData] = React.useState<any>()
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        const query  = parseTrussViewParams(location)
        setData(query)
      }, [location]);

    const handleActionSelect = async (action: string) => {
        //fetch config details
        const {data: {friendly_name}} = await getConfiguration(data.config);
        // Handle the selected action
        const newState:RecipeState = {
            project: data.project_id,
            truss: data.truss_id,
            config: data.config,
            recipe: data.recipe,
            timestamp_year: data.timestamp_year,
            is_recipe: data.isRecipe,
            ...(location.state || {})
        };
        if(action === "Open in Trusscheck")
        {
            navigate(`/view/truss-check/${data.project_name}/${data.truss}/${friendly_name}/${data.version}`,{ state: newState })
            
        }else if(action === "Recheck in Trusscheck")
        {
            //{recipe_id: number,timestamp_year: number, value: boolean }
            const updatedState = { ...location.state, ...newState };
            location.state = updatedState;
            const res =  await isRecheckRecipe({order:data.project_name,item:data.truss,recipe_id:data.recipe,config:data.config,version:data.version,timestamp_year:newState.timestamp_year});
            if (res?.status === 200) {
                notification.success({message: `Notified to recheck recipe.`})
            } else {
                notification.error({message: `Error sending recipe recheck notification ${res?.data?.message}`})
            }
        }else if(action == "Deploy Selected")
        {
            
            const res = await deploySelectedRecipe({data: {recipe_id : data.recipe ,config: data.config, timestamp_year : data.timestamp_year}})
            if (res.status === 200) {
                notification.info({message: <Trans i18nKey={`notification.recipe_deployment_info`} defaults={`${res.data.deployedCount}/${res.data.totalCount} recipes queued for deployment for job ${res.data.jobId}`} values={{
                    count: res.data.deployedCount,
                    total: res.data.totalCount,
                    jobId: res.data.jobId
                  }}/>})

            } else {
                notification.error({message: <Trans i18nKey={`notification.recipe_deployment_error`} defaults={'Error in recipe deployment.'}/>})
            }

        }
        else if(action == "Deploy Selected Version")
        {
            const recipesToDeploy  = [{recipe_id : data.recipe, timestamp_year : data.timestamp_year}]
            const res = await deployRecipe({data: recipesToDeploy})
            if (res.status === 200) {
                notification.info({message: <Trans i18nKey={`notification.recipe_deployment_info`} defaults={`${res.data.deployedCount}/${res.data.totalCount} recipes queued for deployment for job ${res.data.jobId}`} values={{
                    count: res.data.deployedCount,
                    total: res.data.totalCount,
                    jobId: res.data.jobId
                  }}/>})
            } else {
                notification.error({message: <Trans i18nKey={`notification.recipe_deployment_error`} defaults={'Error in recipe deployment.'}/>})
            }
            
        }
        setSelectedAction(action);
    };
    const actionMenu = () => {
        if (data?.isRecipe === 0) {
          return (
            <Menu style={{ width: " Recheck in Trusscheck " }}>
              <Menu.Item key="Open in Trusscheck" onClick={() => handleActionSelect("Open in Trusscheck")}>
              <Trans i18nKey={`button.header.Open_in_Trusscheck`} defaults={'Open in Trusscheck'}/>
                
              </Menu.Item>
              <Menu.Item key="Deploy" onClick={() => handleActionSelect("Deploy Selected")}>
              <Trans i18nKey={`button.header.Deploy_Selected`} defaults={'Deploy Selected'}/>
                
              </Menu.Item>
            </Menu>
          );
        } else if(data?.deploylist == 1){
            return (
            <Menu style={{ width: " Recheck in Trusscheck " }}>
              <Menu.Item key="Deploy" onClick={() => handleActionSelect("Deploy Selected Version")}>
              <Trans i18nKey={`button.header.Deploy_Selected_Version`} defaults={'Deploy Selected Version'}/>
                
              </Menu.Item>
            </Menu>)
        }
        else {
          return (
              <Menu style={{ width: " Recheck in Trusscheck " }}>
                  <Menu.Item key="Open in Trusscheck" onClick={() => handleActionSelect("Open in Trusscheck")}>
                  <Trans i18nKey={`button.header.Open_in_Trusscheck`} defaults={'Open in Trusscheck'}/>
                  </Menu.Item>
                  <Menu.Item key="Recheck in Trusscheck" onClick={() => handleActionSelect("Recheck in Trusscheck")}>
                  <Trans i18nKey={`button.header.Recheck_in_Trusscheck`} defaults={'Recheck in Trusscheck'}/>
                      
                  </Menu.Item>
                  <Menu.Item key="Deploy" onClick={() => handleActionSelect("Deploy Selected")}>
                  <Trans i18nKey={`button.header.Deploy_Selected`} defaults={'Deploy Selected'}/>
                  </Menu.Item>
              </Menu>
          );
        }
      };


    if(data?.config !=="undefined" || data?.deploylist == 1)
    {
        return (<Dropdown overlay={actionMenu()} >
            <Button type="primary" className={"action-button"} style={{ width: "220px" }}>
                {selectedAction ? <Trans i18nKey={`button.header.Select_Actions`} defaults={'Select Actions'}/> : <Trans i18nKey={`button.header.Select_Actions`} defaults={'Select Actions'}/>} <DownOutlined />
            </Button>
        </Dropdown>)
    }
    else
    {
        return (
            <Button className="action-button" disabled = {true} type="primary"  style={{ width: "220px" }} ><Trans i18nKey={`button.header.Select_Actions`} defaults={'Select Actions'}/></Button>
        )
    } 
}
export const MiddleComponent:React.FC<{}>= (props)=>{
    return (
        <div className="passed-dev">
            <div className={"h-grow"}/>
            {window.location.pathname === "/view/truss-view" && (
            <div className={"title"} style={{ paddingRight: '16px' }} >
                <ActionSwitch/>
            </div>)}
            {window.location.pathname === "/" && (
            <div className={"title"} style={{ paddingRight: '16px', gap: '20px' }} >
                <ActionSwitchDeploy/>
                <ActionSwitchPurge/>
            </div>)}
        </div>
    );
}
export const UserMenuItems:React.FC<{auth:AuthContextProps,navigate:(path:string)=>void,messageCount:number}> = (props)=> {
    
    const [openErrorReport, setOpenErrorReport] = React.useState<boolean>(false)
    const [defaultConfig, setDefaultConfig] = React.useState<any>();
    const {REACT_APP_BUILD_VERSION = ''} = process.env;
    const location = useLocation();
    const loadDefaultConfig = async () => {
        const {data} = await fetchDefaultConfig() || {};
        setDefaultConfig(data);
    }
    return (<React.Fragment>
        <ErrorReportModal key={'header-error-report'} open={openErrorReport} closeCallback={() => setOpenErrorReport(false)} config={defaultConfig}></ErrorReportModal>
        <Menu className={"user-menu"} mode={"horizontal"} selectedKeys={[]} onSelect={(e) => {
        if (e.key && e.key !== 'error-report') {
          props.navigate(e.key)
        }
    }}  style={{ height: '62px' , borderBottom: 'none'}}>
        <Menu.Item key={'view/notifications'}>
            <Badge count={props.messageCount} overflowCount={9} size="small"
                   style={{backgroundColor: '#800000'}} offset={[2, 0]}>
                <BellOutlined style={{fontSize:20}}/>
            </Badge>
        </Menu.Item>
                <Menu.SubMenu key={'user'} title={ <Avatar>{props?.auth?.user?.avatar || "AD"}</Avatar>}>
                        <Menu.Item key={'view/profile'}><Trans i18nKey={'page.profile.Profile'} defaults={"Profile"} /></Menu.Item>
                        <Menu.Item key={'view/change-passwd'}><Trans i18nKey={'page.profile.Change_Password'} defaults={"Change password"} /></Menu.Item>
                        <Menu.Item key={location.pathname} >
                            <a href="https://trussmatic.atlassian.net/wiki/spaces/TSP/overview" target="_blank" rel="noopener noreferrer">
                                <Trans i18nKey={'page.profile.Trussmatic_Portal'} defaults={"Trussmatic Portal"} />
                            </a>
                        </Menu.Item>
                        <Menu.Item key={'error-report'} onClick={async () => {
                            await loadDefaultConfig().catch(e => {
                                console.log(e)
                            });
                            setOpenErrorReport(true)
                        }
                        }>Report Error</Menu.Item>
                        <Menu.Item key={'view/sign_out'} onClick={() => props?.auth?.auth.signOut()}><Trans i18nKey={'page.profile.Sign_Out'} defaults={"Sign Out"} /></Menu.Item>
                        {REACT_APP_BUILD_VERSION.length > 0 && (
                            <Menu.Item disabled={true} key={'Version'} style={{ height: '18px', lineHeight: '18px' }}>
                                <div className="watermark">
                                    <Trans i18nKey={'page.profile.Version'} defaults={"Version"} />: {REACT_APP_BUILD_VERSION}
                                </div>
                            </Menu.Item>
                        )}
                </Menu.SubMenu>
    </Menu></React.Fragment>);
}

export const Header: React.FunctionComponent<{setCollapsed: any, collapsed: boolean}> = ({setCollapsed, collapsed}) => {
    const [messageCount, setMessageCount] = React.useState<number>(0);
    const ctx = React.useContext(IOContext)
    const auth = React.useContext(AuthContext)
    const navigate = useNavigate();
    const navigation=(path: string)=> {
        navigate(path);
    }
    React.useEffect(() => {
        ctx.socket?.on("notification", (...data: any) => {
            setMessageCount(data[0])
        })
        return () => {
            ctx.socket?.off("notification")
        }
    }, [ctx])
    const loadNewMessagesCount = async () => {
        const {data} = await getNewMessageCount();
        setMessageCount(data);
    }
    React.useEffect(() => {
        loadNewMessagesCount().catch(e => {
            console.log(e)
        });
    }, [])
    
    const fetchAvailableServices = async () => {
        const services = await availableServices();
        const updatedData = Object.keys(services.data.services).map((title) => {
            const serviceData = services.data.services[title];
            return {
              title: title,
              ...serviceData
            };
          });
        return {suiteUrl: services.data.cloudSuiteUrl , serviceList : updatedData};
    }
    return <Headerlib setCollapsed={setCollapsed} collapsed={collapsed}
    fetchAvailableServices = {fetchAvailableServices} user_role={auth?.user?.user_role}
    navigator={navigation} >
        <MiddleComponent/>
        <div className={"language-select"}>
            <LanguageSwitch/>
        </div>
        <UserMenuItems auth = {auth} navigate={navigation} messageCount={messageCount}/>
    </Headerlib>;
};

