/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {useContext} from "react";
import {ConfigContext, getCartTypeInUse, getRemoveDirection, RecipeContext} from "../util";
import {LayerDefs} from "../def";
import {ErrorSelectionContext, LayerSelectionContext} from "../../trusscheck/util"
import {isSelectedByIdList} from "../util"

interface IRemoveCart {
    x: number,
    wagonPositionName: string,
    positionIndex: number
}

export const RemoveCart: React.FunctionComponent<any> = () => {

    return <ConfigContext.Consumer>
        {ctx => ctx?.configuration && ctx?.configuration.buildtoolshapes.RemoveCart.DrawingElements.Shapes.map((shape, index)=>{
            return <polygon key={`${shape.layer.toLowerCase()}`} className={`${shape.layer.toLowerCase()}`} points={shape.points}/>
        })}
    </ConfigContext.Consumer>
}

export const RemoveCartLayer: React.FunctionComponent<any> = ()=> {

    const [removeCarts, setRemoveCarts] = React.useState<IRemoveCart[] | undefined>(undefined)
    const configCtx = useContext(ConfigContext)
    const recipeCtx = useContext(RecipeContext)

    React.useEffect(() => {

        if (configCtx?.configuration && recipeCtx?.recipe.RemoveCarts) {
            const cartTypeInUse = getCartTypeInUse(configCtx.configuration)
            const removeDirection = getRemoveDirection(configCtx.configuration)

            if (recipeCtx.recipe.RemoveCarts.hasOwnProperty("RemoveToRight") || recipeCtx.recipe.RemoveCarts.hasOwnProperty("RemoveToLeft")) {
                const removeCarts = cartTypeInUse ? recipeCtx.recipe.RemoveCarts[removeDirection][cartTypeInUse] : undefined
                setRemoveCarts(removeCarts)
            }
            else {
                setRemoveCarts(recipeCtx.recipe.RemoveCarts)
            }
        }
    }, [configCtx, recipeCtx])

    return <ErrorSelectionContext.Consumer>{errCtx=>
        <LayerSelectionContext.Consumer>{layerCtx=>
                removeCarts && <g key={LayerDefs.RemoveCart} id={LayerDefs.RemoveCart.toLowerCase()}
                                                      className={`${layerCtx?.selectedComponent.includes(LayerDefs.RemoveCart)? "hide-layer" : ""}`}>
                    {removeCarts.map((tool: {[key:string]: any}) => {
                        const angleByDegrees = tool.angle * (180 / Math.PI) || 0
                        const selectedRemoveCartId = `${LayerDefs.RemoveCart}_${tool.id}`;
                        const isSelected = isSelectedByIdList([selectedRemoveCartId], errCtx)

                        return (<g key={`${LayerDefs.RemoveCart}_${tool.positionIndex}`}
                                   data-component={tool.wagonPositionName}
                                   transform={`rotate(${angleByDegrees}, ${tool.x}, 0) translate(${tool.x}, 0)`}
                                   id={`${LayerDefs.RemoveCart.toLowerCase()}_${tool.positionIndex}`}
                                   className={`${LayerDefs.RemoveCart.toLowerCase()} ${isSelected ? "error" : ""}`}
                        >
                            <RemoveCart/>
                        </g>)

                    })}
                </g>}
        </LayerSelectionContext.Consumer>}
    </ErrorSelectionContext.Consumer>
}