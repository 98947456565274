/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Descriptions, Input, Modal, notification, Row, Space} from 'antd';
import {AuthContext} from "./AuthProvider";
import {getFeConfig, updateUser, userInfo} from "../../api";
// @ts-ignore
import {DefaultArgs} from "@q4us-sw/q4us-ui-v2/dist/form/FormV2";
// @ts-ignore
import {Form} from "@q4us-sw/q4us-ui-v2/dist/form";
import{Auth} from "../auth/Authentication";
import { Trans } from "../reactive/Trans";
import { UnitSwitch } from "../header";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import { PreferenceContext } from "./UserPreference";
import { unstable_batchedUpdates } from "react-dom";
import UserProfileCard from "./UserProfileCard";
interface ProfileProps{
    user: any,
    value: string
}

export const ProfileChangeConfirm: React.FunctionComponent<ProfileProps> = (props) => {
    const [visible, setVisible] = React.useState(false);

    return <React.Fragment>
            <Button key={props.value} type={"link"} icon={<EditOutlined/>}
                    onClick={async () => {
                        setVisible(true)
                    }}/>
            <Modal
                        title={<Trans i18nKey={'profile.form.Edit_User_Info'} defaults={"Edit User Info"}/>}
                        width={'50vw'}
                        open={visible}
                        onCancel={() => setVisible(false)}
                        footer={null}
                        destroyOnClose
                    >
                        {/* @ts-ignore */}
                        <Form register={(reg) => reg}
                              {...DefaultArgs}
                              formatRegister={(reg: any) => reg}
                              layout={'horizontal'}
                              schema={'user_form'}
                              config={async (schema: string) => {
                                  const res = await getFeConfig(schema) || {}
                                  const {data: {rows = []} = {}} = res;
                                  return rows;
                              }}
                              visibilityValidator={(schema: string, element: any, form: any) => {
                                  if(element.name === props.value){
                                      return true
                                  } else {
                                    return false
                                  }
                              }}
                              submit={async (values:{ [key: string]: any }) => {
                                  const response = await updateUser(
                                      {
                                          data: {...values, id: props.user.id},
                                          previous_data: {}
                                      }
                                  )
                                  const {data = {}} = response;
                                  if(data?.statusCode===200){
                                      setVisible(false)
                                      notification.success({message: <Trans i18nKey={'notification.User_updated_successfully'} defaults={"User updated successfully"}/>})
                                      return data;
                                  } else{
                                      notification.error({message: <Trans i18nKey={'notification.User_update_failed'} defaults={"User update failed"}/>})
                                  }

                              }}
                              initialValue={props.user}
                              disableAccept={true}
                        />
                    </Modal>
        </React.Fragment>}


export const ProfileSelectionChange: React.FunctionComponent<ProfileProps> = (props) => {
    const [visible, setVisible] = React.useState(false);

    return <React.Fragment>
            <Button key={props.value} type={"link"} icon={<EditOutlined/>}
                    onClick={async () => {
                        setVisible(true)
                    }}/>
            <Modal
                closable={false}
                title={null}
                visible={visible}
                width={416}
                footer={null}
                className={"ant-modal ant-modal-confirm ant-modal-confirm-confirm"}
            >
                <div className="ant-modal-confirm-body-wrapper">
                    <div className="ant-modal-confirm-body">
                        <span
                            className="ant-modal-confirm-title">Update Preference : Units.</span>
                        <div className="ant-modal-confirm-content">
                            <div className={`single-shipment-dialog`}>     
                                    <UnitSwitch/>
                            </div>
                        </div>
                    </div>
                    <div className="ant-modal-confirm-btns">
                        <Button onClick={() => {
                            unstable_batchedUpdates(() => {
                                setVisible(false)
                            })

                        }}> OK </Button>
                        
                    </div>
                </div>
            </Modal>
        </React.Fragment>}

export const Profile: React.FunctionComponent<any> = (props) => {
    const [userProfile, setUserProfile] = useState({
        email: '-',
        first_name: '-',
        last_name: '-',
        user_role: 'none',
        id: '',
        avatar: ''
    })

    const [open, setOpen] = useState<boolean>(false)

    const loadUserInfo = async () => {
        await Auth.fetchTokenFromRefreshToken();
        const {data: {email, token, avatar, user_role}} = await userInfo()
        setUserProfile({email: email, first_name: token.given_name, last_name: token.family_name, user_role: user_role, id: token.sub, avatar: avatar})
    }

   
    useEffect(() => {
        if(!open)
        {
            loadUserInfo()
        }
            
    }, [open])

    return <PreferenceContext.Consumer>
        {ctx =>
            <AuthContext.Consumer>
                { auth => (
                <React.Fragment>
                    <UserProfileCard user={userProfile} setUserProfile={setUserProfile} pref_unit={ctx?.prefInfo?.pref?.units} />
                </React.Fragment>
                )} 
            </AuthContext.Consumer>
        }
    </PreferenceContext.Consumer>
}

