/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import { TrussContext } from "./util";
import { Empty, Result, Tag, Tree ,Button,Modal } from "antd";
import {  CheckCircleOutlined } from '@ant-design/icons';
import "antd/dist/reset.css";
import { Member, Plate, Truss } from "./trussdef";
import { DataNode } from "antd/es/tree";
import { Trans } from "react-i18next";
import {ErrorTrans} from "../reactive/Trans";
import './styles/TrussPanel.css'
import { useLocation } from 'react-router-dom';
import qs from "query-string";
import { ConfigContextProvider, LayerRegistryContextProvider, RecipeContextProvider, RecipeContext ,} from "../recipeVisualize/util";
import {RecipeSelectionContext } from "./util";
import { getConfiguration, getRecipe,acknowledgeWarnings } from "../../api";
import { SVGComponent } from "../recipeVisualize";
import { PreferenceContext } from "../user/UserPreference";

export const parseTrussViewParams = (location: any)=>{
    const params = qs.parse(location.search)
    return {
        truss_id: parseInt(`${params["t"]}`),
        timestamp_year: parseInt(`${params["y"]}`),
        project_id: parseInt(`${params["p"]}`),
        project_name: `${params["proj"]}`,
        config: `${params["config"]}`,
        version: parseInt(`${params["version"]}`),
        truss: `${params["truss"]}`,
        recipe: parseInt(`${params["recipe"]}`),
    }
}
interface RecipeState {
    order: number;
    truss: number;
    config: string;
    version:number;
    recipe: number;
    timestamp_year: number;
  }

export const SVGItem: React.FunctionComponent<{ polygon: { x: number, y: number }[] }> = (props) => {

    const view = React.useCallback((polygon: { x: number, y: number }[]): number[] => {
        const { xMin, xMax, yMin, yMax } = polygon.reduce(({ xMax, xMin, yMax, yMin }, { x, y }) => {
            return { xMax: Math.max(xMax, x), yMax: Math.max(yMax, y), yMin: Math.min(yMin, y), xMin: Math.min(xMin, x) }
        }, { xMin: Number.MAX_SAFE_INTEGER, yMin: Number.MAX_SAFE_INTEGER, xMax: 0, yMax: 0 })
        return [xMin, yMin, xMax - xMin, yMax - yMin]
    }, [props])

    return <svg viewBox={view(props.polygon).join(" ")} className={"svg-item"}>
        <polygon points={props.polygon.map(({ x, y }) => `${x} ${y}`).join(" ")} />
    </svg>
}
export const AcknowledgeButton: React.FunctionComponent<{ isAcknowledged: boolean,ctx: any ,ctxrecip:any}> = ({ isAcknowledged ,ctx ,ctxrecip}) => {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [data, setData] = React.useState<any>()
    const location = useLocation();
    
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        const data  = parseTrussViewParams(location)
        const newState:RecipeState = {
            order: data.project_id,
            truss: data.truss_id,
            config: data.config,
            version:data.version,
            recipe: data.recipe,
            timestamp_year: data.timestamp_year,
            ...(location.state || {})
        };
        
        const res:any = await acknowledgeWarnings(newState)
        //setData(res)
        await ctxrecip.set({id: data.recipe, timestamp_year: data.timestamp_year, config: data.config ,version:data.version , truss: data.truss })
        await ctx.load({truss_id: data.truss_id, timestamp_year: data.timestamp_year})
        await ctx.fetch?.({truss_id: data.truss_id, timestamp_year: data.timestamp_year})
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div id="acknowledge-container" className="acknowledge-button">
            {isAcknowledged ? (
                
                    <CheckCircleOutlined className={"status-icon success"} />
                
            ) : (
                <>
                
                    <Button type="primary" onClick={showModal}>
                        Acknowledge All Warnings
                    </Button>
                    <Modal
                        title={'Confirm Acknowledgment'}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        open={isModalVisible}
                        
                        closable={false}
                        destroyOnClose
                        maskClosable={false}
                        getContainer="#acknowledge-container"
                    >
                        <span><Trans i18nKey={`acknowledge_warnings_confirm`} defaults={'Are you sure you want to acknowledge all warnings?'}/></span>

                    </Modal>
                </>
            )}
        </div>
    );
};
export const ErrorDataViewer: React.FunctionComponent<any > = (props) => {
    const prefCtx = React.useContext(PreferenceContext);
    const translate = React.useCallback((data: any): DataNode[] => {
        let arr: DataNode[] = []
        if (data.errors && data.errors.length > 0) {
            const errorNodes = data.errors.map((error: any, index: number) => ({
                title: <span className={"tdv-child-entry errors"} ><ErrorTrans i18nKey={`error_translations.${error.baseMessage}`} defaults={error.message}
                values={prefCtx?.processDimensions(error.baseParams)}/></span>,
                key: `error-${index}`,
            } as DataNode));
            arr.push({
                title: <span className={"tdv-root-entry"}>Errors ({data.errors.length})</span>,
                key: "errors",
                children: errorNodes,
            } as DataNode);
        }

        // Add warnings to the tree data
        if (data.warnings && data.warnings.length > 0) {
           
            const warningNodes = data.warnings.map((warning: any, index: number) => {
                return({
                title: <span className={"tdv-child-entry warnings"} > <ErrorTrans i18nKey={`error_translations.${warning.baseMessage}`}
                values={prefCtx?.processDimensions(warning.baseParams)}
                defaults={warning.message} /></span>,
                key: `warning-${index}`,
            } as DataNode)});
            arr.push({
                title: (
                    <div className={"tdv-root-entry"}>
                        <span>Warnings ({data.warnings.length})</span>
                        <RecipeSelectionContext.Consumer>{rct=>
                            <TrussContext.Consumer>
                                {t=><AcknowledgeButton isAcknowledged={data.warningAcknowledged} ctx={t} ctxrecip={rct}/>}
                            </TrussContext.Consumer>}
                        </RecipeSelectionContext.Consumer>
                        
                    </div>
                ),
                key: "warnings",
                children: warningNodes,
            } as DataNode);
        }
        //code to here
        return arr;
    }, [])


    return (
        <RecipeContext.Consumer>
            {ctx => ctx ? (<div className={"tdv-panel"}>
                <div className={"header"} style={{ color: "red" }}> <Trans i18nKey={"recipe-view.recipe-info-title"} defaults={"ERRORS/WARNINGS for {{title}}"} values={{ title: ctx?.recipe?.title }} /></div>
                <div className={"wrapper"}>
                    {
                        ctx?.recipe &&
                        <Tree
                            showLine
                            treeData={translate(ctx.recipe)}
                        />
                    }

                </div>

            </div>) : (
                <Empty />
            )}
        </RecipeContext.Consumer>
    )
}

export const RecipeDataViewer: React.FunctionComponent<{ data: any, req: any }> = (props) => {
    const translate = React.useCallback((data: any, req: any): DataNode[] => {
        let arr: DataNode[] = [];

        const info = Object.entries({...data.recipeInfo,'Created Date' :data.CalculationInfo?.created_time,
            'Edited Date' :data.CalculationInfo.edited_time, Configuration: req.friendly_name}).map(([k, v]) => ({
            title: <div className={"tdv-root-entry"}><Trans i18nKey={`truss-view.truss-info.${k}`} defaults={"{{key}} = {{value}}"} values={{ key: k, value: v }} /></div>,
            key: k
        } as DataNode))
        arr.push(...info);
        // Add trussInfo to the tree data
        

        return arr;
    }, []);


    return (
        <RecipeContext.Consumer>
            {ctx => ctx ? (<div className={"tdv-panel"}>
                <div className={"header"} > <Trans i18nKey={"recipe-view.recipe-info-title"} defaults={"Details for {{title}}"} values={{ title: ctx?.recipe?.title }} /></div>
                <div className={"wrapper"}>
                    {
                        ctx?.recipe &&
                        <Tree
                            showLine
                            treeData={translate(props.data, props.req)}
                        />
                    }

                </div>

            </div>) : (
                <Empty />
            )}
        </RecipeContext.Consumer>
    )
}


export const RecipePanel: React.FunctionComponent<any> = (props) => {

    return (
        <TrussContext.Consumer>
            {ctx =>
            <RecipeSelectionContext.Consumer>{ctx2 =>
                <RecipeContextProvider getRecipe={async () => {
                    const data = ctx2?.recipe;
                    
                    return data;
                }} recipeState={"completed"} isRecipeNeed={true} ignore={true}>
                    <ConfigContextProvider getConfiguration={async () => {
                        return getConfiguration(ctx2?.req.config || "") 
                    }}>
                        <LayerRegistryContextProvider init={(registry) => registry}>

                            <div className={`tv-recipe-panel`} >
                                <div className={`tv-truss-info`} style={{ height: "75%" }}>
                                {ctx?.truss && <RecipeDataViewer data={ctx2?.recipe.data} req={ctx2?.req}/>}

                                </div>
                                <div className={`tv-tp-right`} style={{ height: "75%" }}>
                                    <div className={`tc-center-diagram`}>
                                        {<SVGComponent />}
                                    </div>
                                </div>
                                <div className={`additional-panel`}>
                                    <div className={`tv-recipe-info`} >

                                        <ErrorDataViewer />
                                    </div>
                                </div>

                            </div>
                        </LayerRegistryContextProvider>
                    </ConfigContextProvider>
                </RecipeContextProvider>
                }</RecipeSelectionContext.Consumer>
            }
        
        </TrussContext.Consumer>
    )

}

