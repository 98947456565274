/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, { PropsWithChildren, useEffect, useState } from "react";
import { I18nContext, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getCloudSuiteUrl, getFullTranslations, loadUserPref, saveUserPref } from "../../api";
import frFI from 'antd/locale/fi_FI';
import frEN from 'antd/locale/en_US';
import frFR from 'antd/locale/fr_FR';
import frSE from 'antd/locale/sv_SE';
import { Locale } from "antd/es/locale";
import initI18n from "../../i18";
import { url } from "inspector";
import { AxiosResponse } from "axios";
const antdLangMap: { [key: string]: Locale } = {
    en: frEN,
    fi: frFI,
    fr: frFR,
    sv: frSE,
    // Add more language codes and locale objects as needed
  }
type BaseParamsItem = {
    value: string;
    type: string; 
};

export enum UnitType{
    metric='Metric',
    imperial='Imperial'
}
export interface PreferenceInfo {
    pref: {
        language?: string;
        units?: string;
        antdLang?:Locale;
        treeExpand: boolean;
    };
 
}
export interface PreferenceContextProps {
    prefInfo: PreferenceInfo,
    load: (language?:string , units?:string, treeExpand?: boolean)=>Promise<void>
    convertMMtoFIS :(valueInMillimeters:any)=>string
    convertFISToMM :(dimensionString: string)=>number
    processDimensions:(data: BaseParamsItem[])=>Record<number, string>
}

export const PreferenceContext = React.createContext<PreferenceContextProps | undefined>( undefined)

export const PreferenceContextProvider:React.FunctionComponent<PropsWithChildren<any>> = (props)=>{
    const [cloudSuiteUrl,SetCloudSuiteUrl] = useState('');
    const i = React.useContext(I18nContext)
    const [prefInfo, setPrefInfo] = React.useState<PreferenceInfo>({ pref: {language:'en',units:UnitType.metric, treeExpand: true}})
    const convertMMtoFIS  = React.useCallback((valueInMillimeters:any,ignoreUserPref:boolean = false) => {
        if(prefInfo?.pref?.units === UnitType.metric && !ignoreUserPref)
            return valueInMillimeters
        if(!valueInMillimeters || valueInMillimeters*1 < 0)
            return "-";
        const inches = valueInMillimeters * 0.0393701; // Convert millimeters to inches
        const feet = Math.floor(inches / 12);
        const remainingInches = inches % 12;
        const sixteenths = (remainingInches - Math.floor(remainingInches)) * 16;
        const roundedSixteenths = Math.round(sixteenths * 100) / 100; // Round to two decimal places for the 1/16th value
    
        return `${feet}' ${Math.floor(remainingInches)}'' ${roundedSixteenths} 16th`;
    }, []);
    const convertToMeters = React.useCallback((valueInMillimeters: string): string => {
        const millimeters = parseFloat(valueInMillimeters); // Parse string to float
        if (isNaN(millimeters)) {
            return valueInMillimeters; // Handle invalid input
        }
    
        const meters = Math.floor(millimeters / 1000); // Get the meters
        const remainingMillimeters = millimeters % 1000; // Get the remaining millimeters
        const roundedMillimeters = Math.round(remainingMillimeters * 100) / 100; // Round millimeters to two decimal places
    
        return `${meters}m ${roundedMillimeters}mm`;
    }, []);

    const processDimensions = (data: BaseParamsItem[]): Record<number, string> => {
        const result: Record<number, string> = {};
        data.forEach((item: BaseParamsItem, index: number) => {
            if (item.type === 'Dimension') {
                if(prefInfo.pref.units === UnitType.imperial){
                    const formattedValue = convertMMtoFIS(item.value);
                    result[index + 1] = formattedValue.toString();
                }else if(prefInfo.pref.units === UnitType.metric){
                    const formattedValue = convertToMeters(item.value);
                    result[index + 1] = formattedValue.toString();
                }else{
                    result[index + 1] = item.value;
                }
                
            } else {
                result[index + 1] = item.value;
            }
        });
        return result;
    }

    const convertFISToMM = React.useCallback((dimensionString: string) => {
        // Split the string into its parts: feet, inches, sixteenths
        const parts = dimensionString?.split(/' |'' | /);
    
        // Extract values for feet, inches, and sixteenths
        const feet = parseFloat(parts[0]) || 0;
        const inches = parseFloat(parts[1]) || 0;
        const sixteenths = parseFloat(parts[2]) || 0;
        // Calculate the total inches
        const totalInches = (feet * 12) + inches + (sixteenths / 16);
    
        // Convert total inches to millimeters
        const millimeters = totalInches / 0.0393701; // Convert inches to millimeters
    
        return millimeters;
    }, []);
    
    useEffect(() => {
        getCloudSuiteUrl().then((response:AxiosResponse<any, any>) => SetCloudSuiteUrl(response.data));
    }, []);

    React.useEffect(()=>{
        const updatedPrefInfo = { ...prefInfo };
        if(cloudSuiteUrl != '')
        {
            loadUserPref(cloudSuiteUrl).then(({data})=>{
                if(data?.language)
                {
                    const antdLang = antdLangMap[data.language] || frEN;
                    updatedPrefInfo.pref.language = data.language;
                    updatedPrefInfo.pref.antdLang = antdLang;
    
                    i.i18n.changeLanguage(data.language).catch(console.error)
                }
                if(data?.units)
                {
                    updatedPrefInfo.pref.units = data.units;
                }
                if(data?.treeExpand !== undefined)
                {
                    updatedPrefInfo.pref.treeExpand = data.treeExpand;
                }
                setPrefInfo(updatedPrefInfo);
            })
        }
        
    }, [cloudSuiteUrl])
    
    
    const load = React.useCallback(async (language?:string , units?:string, treeExpand?: boolean) => {
        const updatedPrefInfo = { ...prefInfo };
        if(language)
        {
            
            const antdLang = antdLangMap[language] || frEN;
            updatedPrefInfo.pref.language = language;
            updatedPrefInfo.pref.antdLang = antdLang;
        }else{
            language = prefInfo.pref.language;
        }
        if(units)
        {
            updatedPrefInfo.pref.units = units;
        }
        else{
            units = prefInfo.pref.units;
        }
        if (treeExpand !== undefined) {
            updatedPrefInfo.pref.treeExpand = treeExpand;
        }else {
            treeExpand = prefInfo.pref.treeExpand
        }
        await saveUserPref({language,units, treeExpand},cloudSuiteUrl)
        setPrefInfo(updatedPrefInfo);
    }, [props,cloudSuiteUrl]);

    return <PreferenceContext.Provider value={{prefInfo, load,convertMMtoFIS,convertFISToMM,processDimensions}}>
        {props.children}
    </PreferenceContext.Provider>
}



export interface LanguageLoaderContextProps {
    loaded?: any;
}

export const LanguageLoaderContext = React.createContext<LanguageLoaderContextProps>({ loaded: false });



export const LanguageLoader: React.FunctionComponent<PropsWithChildren<any>> = (props) => {
    const [loading, setLoading] = useState(true);

    const fetchData = React.useCallback(async () => {
        try {
            const res = await getFullTranslations();
    
            // Further processing with the translation response
            const translationsArray = res.data[0].translations;
            const translations: Record<string, { translations: Record<string, string> }> = {};
    
            translationsArray.forEach((langObject: { language: string | number; translations: any }) => {
                translations[langObject.language] = { translations: langObject.translations };
            });
    
            initI18n(translations);
        } catch (error) {
            console.error("Error fetching translations:", error);
        }
    },[]);

    useEffect(() => {
        const fetchDataAndSetLoading = async () => {
            await fetchData();
            setLoading(false);
        };

        fetchDataAndSetLoading();
    }, []);

    return (
        <LanguageLoaderContext.Provider value={{ loaded: !loading }}>
            {loading ? <div></div> : props.children}
        </LanguageLoaderContext.Provider>
    );
};

