/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import './styles/Tabs.css'
import {Trans} from "../reactive/Trans";
import {ProjectTreeCollapseContext} from "./util";
import {CollapseButton, TabTypes} from "./index";

export interface TabsProps {
    default?: string | number
    options: { key: string | number, label: string , disabled?: boolean  }[]

}

export const SelectedContext = React.createContext<{key:string | number| undefined}>({key: ""})


export const Tabs: React.FC<PropsWithChildren<TabsProps>> = (props) => {

    const [selected, setSelected] = React.useState<string | number | undefined>(props.default || props.options?.[0]?.key)
    const {collapse, setCollapse} = React.useContext(ProjectTreeCollapseContext)

    return <div className={"tc-tabs"}>
        <div className={"tc-tab-bar"}>
            {props.options.map(o => <div key={o.key}
                                         onClick={() => {
                                            if (o.key === TabTypes.Collapse) {
                                                setCollapse(!collapse)
                                            } else if (!o.disabled) {
                                                 setSelected(o.key)
                                                if (collapse) setCollapse(!collapse)
                                             }
                                          }}
                                          className={[
                                            "tc-tab-btn",
                                            selected === o.key ? "active" : "",
                                            o.disabled ? "disabled" : "", // Add a 'disabled' class
                                          ].join(" ")}>
                {o.key === TabTypes.Collapse? <CollapseButton/> : < div > < Trans i18nKey={`tabs.vertical.${o.key}.label`} defaults={o.label} values={o}/></div>}
            </div>)}
        </div>
        <div className={"tc-tab-content"}>
            <SelectedContext.Provider value={{key: selected}}>
                {props.children}
            </SelectedContext.Provider>
        </div>
    </div>
}


export const Tab:React.FC<PropsWithChildren<{for:string | number}>> = (props)=>{

    return <SelectedContext.Consumer>
        {
            ctx=>ctx.key === props.for && props.children
        }
    </SelectedContext.Consumer>

}
