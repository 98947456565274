/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {useLocation} from "react-router-dom";
import {RecipeVisualization} from "./RecipeVisualization";
import {RecipeStatus} from "./RecipeStatus";
import {RecipeDetailContext} from "../util";
import {IOContext} from "../../io/SocketIO";
import {
    RecipeContext
} from "../../recipeVisualize/util";
export const RecipeView: React.FunctionComponent<any> = () => {
    const location = useLocation();
    const{recipeInfo, load} = React.useContext(RecipeDetailContext)
    const ctx = React.useContext(IOContext)

    React.useEffect(() => {
        load(location?.state?.recipe, location?.state?.timestamp_year);
    }, [location])

    React.useEffect(() => {
        ctx.socket?.on('refresh.recipe', (...data: any) => {
            if (data[0] === location.state.recipe && data[1] === location.state.timestamp_year) {
                load(location.state.recipe, location.state.timestamp_year);
            }

        })
        return () => {
            ctx.socket?.off(`refresh.recipe`)
        }
    }, [ctx, location])


    return <React.Fragment>
        
            <RecipeContext.Consumer>{ctx=>
                <React.Fragment>
                    {((recipeInfo.status !== 'completed') || !ctx.loadRecipe)&& <React.Fragment>
                        <RecipeStatus status={recipeInfo.status} calc_uuid={recipeInfo.calc_uuid} truss_features={recipeInfo.features}/>
                    </React.Fragment>} 
                    {(recipeInfo.status === 'completed') && ctx.loadRecipe && <RecipeVisualization location={location}/>}
                </React.Fragment>
            }</RecipeContext.Consumer>

    </React.Fragment>
}