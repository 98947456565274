/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {useLayoutEffect} from 'react';
import './App.css';
import {
    RouterProvider,
    Route, useLocation, createBrowserRouter, createRoutesFromElements
} from "react-router-dom";
import {MainView} from "./views/MainView";

import qs from "query-string";
import {AuthorizationCode} from "./components/auth/AuthorizationCode";
import {auth_cfg, createNewRecipeForConfig} from "./api";
import {Dropdown, notification} from "antd";
import { LanguageLoader, PreferenceContext, PreferenceContextProvider } from './components/user/UserPreference';
import { ConfigProvider } from 'antd';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import {EventListenerProvider} from "./components/listeners/keybord";
export const View = (props: any) => {
    let location = useLocation();
    const {code} = qs.parse(location.search);

    return <AuthorizationCode configs={async ()=>{
        const {data: cfg} = await auth_cfg();
        return cfg;
    }} code={code ? `${code}` : undefined}>
    <LanguageLoader>
            <I18nextProvider i18n={i18n}>
                <PreferenceContextProvider>
                    <PreferenceContext.Consumer>{ctx=>
                        <ConfigProvider locale={ctx?.prefInfo?.pref.antdLang}>
                            <EventListenerProvider>
                                <MainView />
                            </EventListenerProvider>
                        </ConfigProvider>
                     }
                    </PreferenceContext.Consumer>
                </PreferenceContextProvider>
            </I18nextProvider>
            </LanguageLoader>
    </AuthorizationCode>
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/*" element={<View/>}/>
    )
);

function App() {

    useLayoutEffect(() => {
        notification.config({
            placement: 'bottomLeft',
            bottom: 10,
            duration: 3,
            rtl: false,
        })
    }, [])

    return <React.Fragment>
        <div className="App">
            <RouterProvider router={router} />
        </div>
    </React.Fragment>
}

export default App;


