/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {
    ConfigContextProvider,
    LayerRegistryContextProvider,
    RecipeContextProvider
} from "../../recipeVisualize/util";
import {getConfiguration, getRecipeForConfig} from "../../../api";
import {SVGComponent} from "../../recipeVisualize";
import {CollisionContextProvider} from "../../recipeVisualize/collisionDetection/util";
export const RecipeVisualization: React.FunctionComponent<any> = (props) => {
    return <React.Fragment>
        
            <ConfigContextProvider  getConfiguration={async ()=>{
                return getConfiguration(props.location.state.config)
            } }>
                <LayerRegistryContextProvider init={(registry) => registry}>
                    <CollisionContextProvider>
                            <SVGComponent />
                    </CollisionContextProvider>
                </LayerRegistryContextProvider>
            </ConfigContextProvider>
        

    </React.Fragment>
}