/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {useCallback, useContext} from "react";
import {ConfigContext, getAllSideTools, getSideToolAngleForVisualize, RecipeContext} from "../util";
import {LayerDefs} from "../def";
import {ErrorSelectionContext, LayerSelectionContext} from "../../trusscheck/util"
import {isSelectedByIdList} from "../util"
import {CollisionContext} from "../collisionDetection/util";
import {EditServiceObj} from "../EditService";

export enum SideToolTypes{
    LeftSideSupportTool = 1,
    RightSideSupportTool,
    LeftSideSuctionGrabber,
    RightSideSuctionGrabber

}
export interface ISideTool {
    angle: number,
    disabled: boolean,
    timberId: number,
    id: number,
    type: number,
    x: number,
    y: number,
    [key:string]: any

}
export interface SideSuctionCableDetails {
    midX: number,
    midY: number,
    homeX: number,
    homeY: number,
    cableThickness: number
}

type ToolKey = 'LeftSideSuctionGrabber' | 'RightSideSuctionGrabber' | 'SideSupportTool';

const SideTool: React.FunctionComponent<{ toolKey: ToolKey }> = ({ toolKey }) => {

    return <ConfigContext.Consumer>
        {ctx => ctx?.configuration.walltoolshapes[toolKey].DrawingElements.Shapes.map((shape)=>{
            return <polygon key={shape.layer.toLowerCase()} className={shape.layer.toLowerCase()} points={shape.points}/>
        })}
    </ConfigContext.Consumer>
};

export const LeftSideSuctionGrabber = () => <SideTool toolKey="LeftSideSuctionGrabber" />;
export const RightSideSuctionGrabber = () => <SideTool toolKey="RightSideSuctionGrabber" />;
export const SideSupportTool = () => <SideTool toolKey="SideSupportTool" />;


export const SideToolLayer: React.FunctionComponent<{id?:number}> = (props)=> {

    const [allSideTools, setAllSideTools] = React.useState<ISideTool[]>([])
    const configCtx = useContext(ConfigContext)
    const recipeCtx = useContext(RecipeContext)
    const collisionCtx = useContext(CollisionContext)
    const errCtx = useContext(ErrorSelectionContext)

    React.useEffect(() => {
        if (recipeCtx?.recipe && configCtx?.configuration) {
            setAllSideTools(getAllSideTools(recipeCtx.recipe, configCtx.configuration))
        }
    }, [configCtx, recipeCtx])

    const getProperties = useCallback((tool: ISideTool) => {

        const selectedSideTool = `${LayerDefs.SideTool}_${tool.id}`;
        const isSelected = isSelectedByIdList([selectedSideTool], errCtx)

        const collide = collisionCtx?.collisions?.has(`${LayerDefs.SideTool.toLowerCase()}_${tool.id}`)? "collide" : ""

        if (props.id === undefined) {
            return{
                'id': `${LayerDefs.SideTool.toLowerCase()}_${tool.id}`,
                'data-component': tool.id,
                'data-layer': LayerDefs.SideTool,
                'className': `${LayerDefs.SideTool.toLowerCase()} ${isSelected ? "error" : ""} ${collide}`
            }

        } else return {
            'className': `${LayerDefs.SideTool.toLowerCase()}`
        }
    },[props.id, collisionCtx, errCtx])

    const renderTool = useCallback(() => {
        return allSideTools.reduce((acc:  JSX.Element[], tool) => {

            const angleByDegrees = getSideToolAngleForVisualize(tool.type, tool.angle)
            const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.SideTool.toLowerCase()}_${tool.id}`)

            if ((props.id === tool.id || props.id === undefined) && !isDeleted) {

                switch (tool.type) {
                    case SideToolTypes.LeftSideSupportTool:
                    case SideToolTypes.RightSideSupportTool:
                        acc.push(<g key={`${LayerDefs.SideTool}_${tool.id}`}
                                    transform={`rotate(${angleByDegrees}, ${tool.x}, ${tool.y}) translate(${tool.x}, ${tool.y})`}
                                    {...getProperties(tool)}
                        >
                            <SideSupportTool/>
                        </g>)
                        break
                    case SideToolTypes.LeftSideSuctionGrabber:
                        acc.push(<g key={`${LayerDefs.SideTool}_${tool.id}`}
                                    transform={`rotate(${angleByDegrees}, ${tool.x}, ${tool.y}) translate(${tool.x}, ${tool.y})`}
                                    {...getProperties(tool)}
                        >
                            <LeftSideSuctionGrabber/>
                        </g>)
                        break
                    case SideToolTypes.RightSideSuctionGrabber:
                        acc.push(<g key={`${LayerDefs.SideTool}_${tool.id}`}
                                    transform={`rotate(${angleByDegrees}, ${tool.x}, ${tool.y}) translate(${tool.x}, ${tool.y})`}
                                    {...getProperties(tool)}
                        >
                            <RightSideSuctionGrabber/>
                        </g>)
                        break
                }
            }

            return acc

        }, [])

    },[props.id, allSideTools, collisionCtx, errCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
            <g key={LayerDefs.SideTool} id={LayerDefs.SideTool.toLowerCase()}
               className={`${layerCtx?.selectedComponent.includes(LayerDefs.SideTool) && (props.id === undefined) ? "hide-layer" : ""}`}>
                {renderTool()}
        </g>}
    </LayerSelectionContext.Consumer>
}

export const SideSuctionGrabberCableLayer: React.FunctionComponent = ()=>{

    const [data, setData] = React.useState<Map<number, SideSuctionCableDetails> | undefined>(undefined)
    const ctx = React.useContext(ConfigContext)

    const getCablePositions = React.useCallback( (toolKey: string): Map<number, { [key: string]: any }> => {

        const shapes = ctx?.configuration.walltoolshapes[toolKey].DrawingElements.Shapes || []
        const homeContactPoint = ctx?.configuration.walltoolshapes[toolKey].DrawingElements.Cable.homeContactPoint.split(',') || []
        const cableThickness = ctx?.configuration.walltoolshapes[toolKey].DrawingElements.Cable.cableThickness
        const homeX = Number(homeContactPoint[0])
        const homeY = Number(homeContactPoint[1])

        return  shapes.reduce((acc: Map<number, { [key: string]: any }>, shape: { [key: string]: any }) => {
            if (shape.layer === 'FINALIZED') {
                const tail = shape.points.split(" ").slice(0, 2)
                const [x1, y1] = tail[0].split(",")
                const [x2, y2] = tail[1].split(",")

                const midX = (Number(x1) + Number(x2))/2
                const midY = (Number(y1) + Number(y2))/2

                acc.set(SideToolTypes[toolKey], {midX, midY, homeX, homeY, cableThickness})
            }
            return acc
        }, new Map())

    }, [ctx])

    React.useEffect(()=>{

        const leftCablePositions = getCablePositions('LeftSideSuctionGrabber')
        const rightCablePositions = getCablePositions('RightSideSuctionGrabber')

        setData(new Map([...leftCablePositions, ...rightCablePositions]))
    }, [ctx])

    return <ErrorSelectionContext.Consumer>{errCtx=>
        <LayerSelectionContext.Consumer>{layerCtx=>
    <RecipeContext.Consumer>
        {recipeCtx => <React.Fragment>
            { data && recipeCtx?.recipe.SideTools && <g key={LayerDefs.SideToolCable} id={LayerDefs.SideToolCable.toLowerCase()}
                className={`${layerCtx?.selectedComponent.includes(LayerDefs.SideToolCable)? "hide-layer" : ""}`}>

                    {recipeCtx?.recipe.SideTools.reduce((acc: JSX.Element[], tool: { [key: string]: any }) => {

                        const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.SideTool.toLowerCase()}_${tool.id}`)

                        if ((tool.type === SideToolTypes.RightSideSuctionGrabber || tool.type === SideToolTypes.LeftSideSuctionGrabber) && !isDeleted) {

                            const {midX, midY, homeX, homeY, cableThickness} = data?.get(tool.type)
                            const selectedSideTool = `${LayerDefs.SideToolCable}_${tool.id}`;
                            const isSelected = isSelectedByIdList([selectedSideTool], errCtx) 

                            acc.push(<g key={`${LayerDefs.SideToolCable}_${tool.id}`}
                                        id={`${LayerDefs.SideToolCable.toLowerCase()}_${tool.id}`}
                                        className={`${LayerDefs.SideToolCable.toLowerCase()} ${isSelected ? "error" : ""}`}
                            >
                                <polygon
                                    style={{strokeWidth: cableThickness}}
                                    id={`${LayerDefs.SideTool.toLowerCase()}_${tool.id}_cable`}
                                    data-points={`${homeX},${homeY},${midX},${midY}`}
                                    points={`${homeX},${homeY} ${tool.x + midX},${tool.y + midY}`}
                                />
                            </g>)
                        }
                        return acc;
                    }, [])}
                </g> }
            </React.Fragment>}
    </RecipeContext.Consumer>}
        </LayerSelectionContext.Consumer>}
    </ErrorSelectionContext.Consumer>
}
