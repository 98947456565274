/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/



export interface IFilterEntry{
    type: string,
    value: any
    comparator?: string
}


export function inlineFilterMap(filter: {[key:string]: IFilterEntry}){
    return Object.entries(filter).map(([k, {type,...v}])=>{
        switch (type){
            case 'date-range':
                return v.value[0] == '' ?
                    {name: `${k}::date`, value: v.value[1], comparator: "<="}
                    : v.value[1] == ''?
                        {name: `${k}::date`, value: v.value[0], comparator: ">="}
                        : {name: `${k}::date`, value: `[${v.value[0]}, ${v.value[1]}]`, comparator: "<@", dataType: "daterange"}
        }
        return {name: k, ...v}
    })
}
