/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {convertVertices} from "../util";
import {
    PressToolEntryLayer, PressToolEntryUpperShapeLayer,
    PressToolLayer,
    PressToolPaddleLayer,
    PressToolUpperShapeLayer
} from "../svg-layers/PressTool";
import {ChordGrabberLayer} from '../svg-layers/ChordGrabber'
import {
    ArmToolContextProvider,
    BlockToolLayer,
    BlockToolPostLayer,
    BlockToolPreLayer
} from '../svg-layers/BlockTool'
import {SideToolLayer} from '../svg-layers/SideTool'
import {LayerDefs} from "../def";
import {SpikeGrabberLayer} from "../svg-layers/SpikeGrabber";
import {
    RemoveToolContextProvider, RemoveToolEntryLayer,
    RemoveToolLayer,
    RemoveToolSafetyAreaLayer
} from "../svg-layers/RemoveTool";
import {Labels} from "./Labels";
import {IComponent} from "../EditService";



export const Preview: React.FunctionComponent<{selectedComponent: IComponent, showLabel: boolean}> = (props)=>{

    const comps = React.useCallback((obj?: IComponent)=>{
        const {layer, vertices, id, nailplateId, timberId} = obj || {};
        if(layer) {
            switch (layer) {
                case LayerDefs.PressTool:
                    return <g id={`${layer.toLowerCase()}_${nailplateId}_preview`}>
                        <PressToolPaddleLayer id={nailplateId}/>
                        <PressToolUpperShapeLayer id={nailplateId}/>
                        <PressToolEntryUpperShapeLayer id={nailplateId}/>
                        <PressToolEntryLayer id={nailplateId}/>
                        <PressToolLayer id={nailplateId}/>
                    </g>
                case LayerDefs.BeamGrabber:
                    return <g id={`${layer.toLowerCase()}_${id}_preview`}><ChordGrabberLayer id={id}/></g>
                case LayerDefs.MidGrabber:
                    return <g id={`${layer.toLowerCase()}_${id}_preview`}><SpikeGrabberLayer id={id}/></g>
                case LayerDefs.BlockTool:
                    return <ArmToolContextProvider><g id={`${layer.toLowerCase()}_${timberId}_preview`}>
                        <BlockToolPostLayer id={timberId} />
                        <BlockToolPreLayer id={timberId} />
                        <BlockToolLayer id={timberId} />
                    </g></ArmToolContextProvider>
                case LayerDefs.SideTool:
                    return <g id={`${layer.toLowerCase()}_${id}_preview`}><SideToolLayer id={id}/> </g>
                case LayerDefs.RemoveTool:
                    return <RemoveToolContextProvider><g id={`${layer.toLowerCase()}_${id}_preview`}>
                        <RemoveToolLayer id={id}/>
                        <RemoveToolSafetyAreaLayer id={id}/>
                        <RemoveToolEntryLayer id={id}/>
                    </g></RemoveToolContextProvider>
                case LayerDefs.NailPlate:
                case LayerDefs.TimberBeam:
                case LayerDefs.TimberDiagonal:
                default:
                    return <g id={`${layer.toLowerCase()}_${id}_preview`}>
                        {props.showLabel && <Labels vertices={vertices}/>}
                        <polygon points={convertVertices(vertices)}/>
                    </g>
            }
        }
        return <></>
    }, [props])

    return <g transform="scale(1,-1)" className={props.selectedComponent?.layer?.toLowerCase() || ""}>
        {comps(props.selectedComponent )}
    </g>
}
