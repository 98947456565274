/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import { zip, head, tail, round } from "lodash";
import {calculateConvexHull} from "../util";

//sorts the vertices of a polygon counterclockwise around its centroid.
export function sortVertices(vertices: { x: number, y: number }[]) {

    //calculate the convex hull of the polygon to get the correct centroid for concave polygons
    const convertedVertices = calculateConvexHull(vertices.map(({ x, y }) => ([x, y])))
    convertedVertices.pop()

    const shapeCenter = convertedVertices.reduce(
        (acc, [ x, y ]) => {
            acc.x += x / convertedVertices.length;
            acc.y += y / convertedVertices.length;
            return acc;
        },
        { x: 0, y: 0 }
    );

    const angles = vertices.map(({ x, y }) => {
        return {
            x,
            y,
            angle: (Math.atan2(y - shapeCenter.y, x - shapeCenter.x) * 180) / Math.PI
        };
    });

    return angles.sort((a, b) => b.angle - a.angle).map(({ x, y }) => ({ x, y }));
}

export function verticesToEdges(vertices: { x: number, y: number }[]){
    const sortedVertices = sortVertices(vertices);
    const edges = zip(sortedVertices, [...tail(sortedVertices), head(sortedVertices)])
    return edges as [{ x: number, y: number }, { x: number, y: number }][]
}

export function centerOf(firstPoint: { x: number, y: number }, secondPoint: { x: number, y: number }) {
    return {
        x: (firstPoint.x + secondPoint.x) / 2,
        y: (firstPoint.y + secondPoint.y) / 2
    };
}

//calculate the angle of a edge relative to the positive y-axis.
export function edgeAngle(first: { x: number, y: number }, second: { x: number, y: number }) {
    return Math.atan2(first.x - second.x, first.y - second.y);
}

export function distanceBetween(firstPoint: { x: number, y: number }, secondPoint: { x: number, y: number }, units: string) {
    const width = firstPoint.x - secondPoint.x;
    const height = firstPoint.y - secondPoint.y;
    let distance = Math.abs(Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)));

    if (units === "inches") distance = distance * 0.0393701;

    return round(distance, 1);
}

export function rotatePointByCenter(center: { x: number, y: number }, point: { x: number, y: number }, angle: number) {
    const sin = Math.sin(angle);
    const cos = Math.cos(angle);

    const dX = point.x - center.x;
    const dY = point.y - center.y;

    const x = cos * dX - sin * dY + center.x;
    const y = sin * dX + cos * dY + center.y;

    return { x, y };
}
