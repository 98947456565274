/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";

export function convertToCoordinatesArray(points: string) {
    return points.split(' ').map(pair => {
        const [x, y] = pair.split(',').map(Number)
         return { x, y }
    })
}

export function getToolHeight(points: {x:number, y:number}[]) {

    let minY = Infinity
    let maxY = -Infinity

    for (const { y } of points) {
        minY = Math.min(minY, y)
        maxY = Math.max(maxY, y)
    }

    return maxY - minY
}

export function scalePolygonHeight(points: {x:number, y:number}[], scaleY: number) {
    const scaledPoints = []

    for (const point of points) {
        scaledPoints.push({
            x: point.x,
            y: point.y * scaleY
        });
    }

    return scaledPoints
}

export interface EditContextProps {
    collisions: Set<string>,
    setCollisions:  (data:Set<string> | undefined)=> void,

}

export const CollisionContext = React.createContext<EditContextProps | undefined>(undefined)

export const CollisionContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {

    const [collisions, setCollisions] = React.useState<Set<string> | undefined>(undefined)

    return <CollisionContext.Provider value={{collisions, setCollisions: setCollisions}}>
        {props.children}
    </CollisionContext.Provider>
}