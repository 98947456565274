/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, useEffect, useState} from "react";
import {Menu} from "antd";
import {useNavigate,useLocation } from "react-router-dom";
import {
    AuditOutlined,
    PartitionOutlined,
    FolderOutlined,
    SettingOutlined,
    DeploymentUnitOutlined,
    TeamOutlined,
    ApartmentOutlined,
    ToolOutlined, ExceptionOutlined
} from "@ant-design/icons";
import {ResponsiveMenuContext} from "../../views/MainView";
import {UserRoles} from "../../util/constants"
import {Trans} from "../reactive/Trans";

export const UserMenu: React.FunctionComponent<PropsWithChildren<any>> = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [ResponsiveMenu, setResponsiveMenu] = useState<any>(null);
    const menuCtx = React.useContext(ResponsiveMenuContext);

    const adminMenuItems = [
        { label: <Trans i18nKey={'page.title.view.recipe-manager.label'} defaults={"Recipe Manager"}/>, key: "", className: "menu-bar-button", icon: <FolderOutlined style={{ fontSize: 20 }} /> },
        { label: <Trans i18nKey={'page.title.view.truss-check.label'} defaults={"Truss Check"}/>, key: "view/truss-check", className: "menu-bar-button", icon: <PartitionOutlined style={{ fontSize: 20 }} /> },
        { label: <Trans i18nKey={'page.title.view.configurations.label'} defaults={"Configurations"}/>, key: "view/configurations", className: "menu-bar-button", icon: <SettingOutlined style={{ fontSize: 20 }} /> },
        { label: <Trans i18nKey={'page.title.view.erp-clients.label'} defaults={"ERP Clients"}/>, key: "view/erp-clients", className: "menu-bar-button", icon: <ApartmentOutlined style={{ fontSize: 20 }} /> },
        { label: <Trans i18nKey={'page.title.view.job-status.label'} defaults={"Deploy Jobs"}/>, key: "view/job-status", className: "menu-bar-button", icon: <DeploymentUnitOutlined style={{ fontSize: 20 }} /> },
        { label: <Trans i18nKey={'page.title.view.purge-job-status.label'} defaults={"Purge Jobs"}/>, key: "view/purge-job-status", className: "menu-bar-button", icon: <DeploymentUnitOutlined style={{ fontSize: 20 }} /> },
        { label: <Trans i18nKey={'page.title.view.settings.label'} defaults={"Settings"}/>, key: "view/settings", className: "menu-bar-button", icon: <ToolOutlined style={{ fontSize: 20 }} /> },
    ];

    const menuItems = [
        { label: <Trans i18nKey={'page.title.view.recipe-manager.label'} defaults={"Recipe Manager"}/>, key: "", className: "menu-bar-button", icon: <FolderOutlined style={{ fontSize: 20 }} /> },
        { label: <Trans i18nKey={'page.title.view.truss-check.label'} defaults={"Truss Check"}/>, key: "view/truss-check", className: "menu-bar-button", icon: <PartitionOutlined style={{ fontSize: 20 }} /> },
        { label: <Trans i18nKey={'page.title.view.configurations.label'} defaults={"Configurations"}/>, key: "view/configurations", className: "menu-bar-button", icon: <SettingOutlined style={{ fontSize: 20 }} /> },
        { label: <Trans i18nKey={'page.title.view.job-status.label'} defaults={"Deploy Jobs"}/>, key: "view/job-status", className: "menu-bar-button", icon: <DeploymentUnitOutlined style={{ fontSize: 20 }} /> },
        { label: <Trans i18nKey={'page.title.view.purge-job-status.label'} defaults={"Purge Jobs"}/>, key: "view/purge-job-status", className: "menu-bar-button", icon: <DeploymentUnitOutlined style={{ fontSize: 20 }} /> },
        { label: <Trans i18nKey={'page.title.view.settings.label'} defaults={"Settings"}/>, key: "view/settings", className: "menu-bar-button", icon: <ToolOutlined style={{ fontSize: 20 }} /> }
    ];

    const setView = (view: any, k: any) => {
        k.setSelectedKeys(view);
        navigate(view);
        setResponsiveMenu(k);
    }
    const isItemSelected = (key: string) => {
        return location.pathname === key;
    }

    useEffect(() => {
        if(!menuItems.some(item => location.pathname.includes(item.key)))
            clearSelectedKeys();
      }, [location]);
    const clearSelectedKeys = () => {
        if(ResponsiveMenu !== null)
            ResponsiveMenu.setSelectedKeys([]);
    }

    return <ResponsiveMenuContext.Consumer>{
        key =>
        <React.Fragment>
            {props.user_role === UserRoles.Administrator && <Menu
                mode={props.mode}
                onSelect={(e) => {
                    setView(e.key, key)
                }}
                selectedKeys={[key?.selectedKeys || '']}
                items={adminMenuItems.map(item => ({
                    ...item,
                    selected: isItemSelected(item.key)
                  }))}
            />}
            {props.user_role === UserRoles.Manager && <Menu
                mode={props.mode}
                onSelect={(e) => {
                    setView(e.key, key)
                }}
                selectedKeys={[key?.selectedKeys || '']}
                items={menuItems.map(item => ({
                    ...item,
                    selected: isItemSelected(item.key)
                  }))}
            />}
        </React.Fragment>
    }
    </ResponsiveMenuContext.Consumer>

}