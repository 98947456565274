/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react"
import './index.css'
import {Checkbox} from "antd";

interface FreeTransformControlProps {
    freeTransformEnabled: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface AutoCorrectYProps {
    autoCorrectY: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FreeTransformControl: React.FunctionComponent<PropsWithChildren<FreeTransformControlProps>> = ({freeTransformEnabled, onChange} ) => {

    return <React.Fragment>
        <div className={"free-transform-control"}>
            <Checkbox checked={freeTransformEnabled} onChange={onChange}>Free Transform</Checkbox>
        </div>
    </React.Fragment>

}

export const AutoCorrectY: React.FunctionComponent<PropsWithChildren<AutoCorrectYProps>> = ({autoCorrectY, onChange} ) => {

    return <React.Fragment>
        <div className={"free-transform-control"}>
            <Checkbox checked={autoCorrectY} onChange={onChange}>Auto Correct Y</Checkbox>
        </div>
    </React.Fragment>

}