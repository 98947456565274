/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {TrussContext} from "./util";
import {Empty, Result, Tag, Tree} from "antd";
import {Member, Plate, Truss} from "./trussdef";
import {DataNode} from "antd/es/tree";
import {Trans} from "react-i18next";
import {ErrorTrans} from "../reactive/Trans";
import './styles/TrussPanel.css'
import {TrussVisualization} from "./TrussVisualization";
import { PreferenceContext } from "../user/UserPreference";

export enum TrussErrorType {
    TRUSS_LEVEL = "truss_level",
    CONFIG_LEVEL = "config_level"
}


export const SVGItem: React.FunctionComponent<{polygon: {x: number, y: number}[]}> = (props)=>{

    const view = React.useCallback((polygon: {x: number, y: number}[]): number[]=>{
            const {xMin, xMax, yMin, yMax} = polygon.reduce(({xMax, xMin, yMax, yMin}, {x,y})=>{
                return {xMax: Math.max(xMax, x), yMax: Math.max(yMax, y), yMin: Math.min(yMin, y), xMin: Math.min(xMin, x)}
            }, {xMin: Number.MAX_SAFE_INTEGER, yMin: Number.MAX_SAFE_INTEGER, xMax: 0, yMax: 0 })
        return [xMin, yMin, xMax - xMin, yMax - yMin]
    }, [props])

    return <svg viewBox={view(props.polygon).join(" ")} className={"svg-item"}>
        <polygon points={props.polygon.map(({x,y })=>`${x} ${y}` ).join(" ")}/>
    </svg>
}




export const PlateItem: React.FunctionComponent<{plate: Plate}> = (props)=>{
    return <div className={"plate-node"}>
        <Tag className={"plate-id"} color={"blue"}>{props.plate.plateId}</Tag>
        <span className={"plate-type"}>{props.plate.nailPlateType}</span>
        <SVGItem polygon={props.plate.polygon}/>
    </div>
}
export const MemberItem: React.FunctionComponent<{member: Member}> = (props)=>{
    return <div className={"member-node "}>
        <Tag className={"member-id"} color={"blue"}>{props.member.memberId}</Tag>
        <span className={"member-type"}>{props.member.memberLabel}</span>
        <SVGItem polygon={props.member.polygon}/>
    </div>
}




export const TrussDataViewer: React.FunctionComponent<{data: Truss}> = (props)=>{

    const translate = React.useCallback((data: Truss): DataNode[]=>{
        let arr: DataNode[] = []
        const info = Object.entries(data.trussInfo)
            .filter(([k]) => k !== "config")
            .map(([k, v]) => ({
                title: (
                    <div className={"tdv-root-entry"}>
                        <Trans
                            i18nKey={`truss-view.truss-info.${k}`}
                            defaults={"{{key}} = {{value}}"}
                            values={{ key: k, value: v }}
                        />
                    </div>
                ),
                key: k,
            })); 
        arr.push(...info);
        const plates = data.plates.map(o=>({
            title: <PlateItem key={o.plateId} plate={o}/>, //<Trans i18nKey={`truss-view.plates.plate-info`} defaults={"[{{plateId}}] {{nailPlateType}}"} values={{...o}}/>,
            key: `plates-${o.plateId}`
        } as DataNode))
        arr.push({
            title: <span className={"tdv-root-entry"}><Trans i18nKey={`truss-view.plates.root`} defaults={"Plates ({{count}})"} values={{count: data.plates.length}}/></span>,
            key: "plates",
            children: plates
        } as DataNode)
        const members = data.members.map(o=>({
            title: <MemberItem key={o.memberId} member={o}/>,//<Trans i18nKey={`truss-view.members.member-info`} defaults={"[{{memberId}}] {{memberLabel}}"} values={{...o}}/>,
            key: `members-${o.memberId}`
        } as DataNode))
        arr.push({
            title: <span className={"tdv-root-entry"}><Trans i18nKey={`truss-view.members.root`} defaults={"Members ({{count}})"} values={{count: data.members.length}}/></span>,
            key: "members",
            children: members
        } as DataNode)
        return arr;
    }, [props])


    return <div className={"tdv-panel"}>
        <div className={"header"}><Trans i18nKey={"truss-view.truss-info-title"} defaults={"{{order}} / {{item}} Truss Details"} values={props.data.trussInfo}/></div>
        <div className={"wrapper"}>
            <Tree
                showLine
                treeData={translate(props.data)}
            />
        </div>

    </div>
}

export const ErrorDataViewer: React.FunctionComponent<{data: Truss}> = (props) => {
    const prefCtx = React.useContext(PreferenceContext);
    const translate = React.useCallback((data: any): DataNode[] => {
        let arr: DataNode[] = []
        if (data.errors && data.errors.length > 0) {
            const errorNodes = data.errors.map((error: any, index: number) => {
                return({
                title: <span className={"tdv-child-entry"} style={{color: "red",}}><ErrorTrans i18nKey={`error_translations.${error.baseMessage}`} defaults={error.message}  
                values={prefCtx?.processDimensions(error.baseParams)}/></span>,
                key: `error-${index}`,
            } as DataNode)});
            arr.push({
                title: <span className={"tdv-root-entry"}>Errors ({data.errors.length})</span>,
                key: "errors",
                children: errorNodes,
            } as DataNode);
        }

        // Add warnings to the tree data
        if (data.warnings && data.warnings.length > 0) {
            const warningNodes = data.warnings.map((warning: any, index: number) => ({
                title: <span className={"tdv-child-entry"} style={{ color: "orange" }}> <ErrorTrans i18nKey={`error_translations.${warning.baseMessage}`} defaults={warning.message}  
                values={prefCtx?.processDimensions(warning.baseParams)}/></span>,
                key: `warning-${index}`,
            } as DataNode));
            arr.push({
                title: <span className={"tdv-root-entry"}>Warnings ({data.warnings.length})</span>,
                key: "warnings",
                children: warningNodes,
            } as DataNode);
        }
        return arr;
    }, [])

    const translateConfig = React.useCallback((data: any, config: string): string => {
        const configObj  = data.find((c:any) => c.config === config);
        return configObj.friendly_name;
    },[]);

    return (
        <TrussContext.Consumer>
            {ctx => ctx ? (<div className={"tdv-panel"}>
                {ctx?.truss?.error_type === TrussErrorType.TRUSS_LEVEL && <div className={"header"} style={{ color: "red" }}>
                    <Trans i18nKey={"truss-view.truss-error-title"} defaults={"ERRORS/WARNINGS for {{title}} of Type {{error_type}}"}
                           values={{ title: ctx?.truss?.trussInfo?.trussLabel, error_type: ctx?.truss?.error_type?.toUpperCase()}} /></div>}

                {ctx?.truss?.error_type === TrussErrorType.CONFIG_LEVEL && <div className={"header"} style={{ color: "red" }}>
                    <Trans i18nKey={"truss-error-title-config-level"} defaults={"ERRORS/WARNINGS for {{title}} of Type {{error_type}} with Default Config {{default_config}}"}
                           values={{ title: ctx?.truss?.trussInfo?.trussLabel, error_type: ctx?.truss?.error_type?.toUpperCase(),
                               default_config: translateConfig(ctx?.config_map, ctx?.truss?.default_config || "")}} /></div>}
                <div className={"wrapper"}>
                    {
                        ctx?.truss &&
                        <Tree
                            showLine
                            treeData={translate(props.data)}
                        />
                    }

                </div>

            </div>) : (
                <Empty />
            )}
        </TrussContext.Consumer>
    )
}


export const TrussPanel: React.FunctionComponent<any> = (props)=>{

    return <TrussContext.Consumer>
        {ctx=><div className={`tv-truss-panel`}>
            <div className={`tv-truss-info`} style={{ height: "75%" }}>
                {ctx?.truss && <TrussDataViewer data={ctx.truss}/>}
            </div>
            <div className={`tv-tp-right`} style={{ height: "75%" }}>
                <div className={`tc-center-diagram`}>
                    {ctx?.query && <TrussVisualization truss_id={ctx?.query?.truss_id} timestamp_year={ctx?.query?.timestamp_year}/>}
                </div>
            </div>
            {ctx?.truss?.error_type && <div className={`additional-panel`}>
                <div className={`tv-recipe-info`} >
                    <ErrorDataViewer data={ctx.truss}/>
                </div>
            </div>}
        </div>}
    </TrussContext.Consumer>
}
