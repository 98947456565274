/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from 'react';
import { Tag } from 'antd';

interface JsonArrayResolverProps {
  value: { [key: string]: { [subKey: string]: Array<string> } };
}

export const JsonArrayResolver: React.FC<JsonArrayResolverProps> = ({ value }) => {
  function formatProdLine(inputStr: string) {
    return inputStr.split('_').map(function (word, index) {
      return index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase();
    }).join(' ');
  }

  const allowedKeys = ['requested_prod_lines', 'deployed_prod_lines', 'failed_prod_lines','purged_prod_lines'];

  return (
    <React.Fragment>
      <div>
        {value && (
          <div>
            {Object.entries(value).map(([key, innerValue]) => (
              <div key={key}>
                {Object.entries(innerValue as { [s: string]: Array<string> }).map(([k, v]) => (
                  allowedKeys.includes(k) ? (
                    <Tag key={k} color="default" style={{ marginBottom: '10px' }}>
                      {(() => {
                        if (v.length > 0) {
                          const elementCount = 4;
                          const rowCount = Math.ceil(v.length / elementCount);
                          const rows = [<div>{formatProdLine(k)} :- </div>];

                          for (let i = 0; i < rowCount; i++) {
                            const rowElements = v.slice(i * elementCount, (i + 1) * elementCount).map((item, index) => (
                              <div key={index} style={{ marginRight: '10px' }}>{item}</div>
                            ));
                            rows.push(<div key={i} style={{ display: 'flex', flexWrap: 'wrap' }}>{rowElements}</div>);
                          }
                          return rows;
                        } else {
                          return <div>{formatProdLine(k)} : - </div>
                        }
                      })()}
                    </Tag>
                  ) : (
                    <Tag key={k} color="default" style={{ marginBottom: '10px' }}>{`${formatProdLine(k)} :- ${v}`}</Tag>
                  )
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};


