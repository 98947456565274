/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, useRef} from "react";
import {useNavigate,Route, Routes} from "react-router-dom";
import {Drawer, Grid, Layout, notification, Typography} from "antd";
import {LandingPage} from "../components/LandingPage";
import {
    DoubleLeftOutlined,
    DoubleRightOutlined
} from "@ant-design/icons";
import "../index.css"
import { AuthContext } from "../components/user/AuthProvider";
import {UserMenu} from "../components/user/UserMenu";
import ChangePassword from "../components/user/ChangePassword";
import {Profile} from "../components/user/Profile";
import {ConfigurationView} from "../components/Configurations";
import {Notifications} from "../components/Notifications"
import {TrussView} from "./TrussView";
import {Header} from "../components/header";
import {UserRoles} from "../util/constants";

import {TrussCheck} from "../components/trusscheck";
import {IOContext} from "../components/io/SocketIO";
import {Trans} from "react-i18next";
import {JobStatusView} from "../components/JobStatus"
import {PurgeJobStatusView} from "../components/PurgeJobStatus"
import {ERPClientView} from "../components/user/ERPClients"
import {Settings} from "../components/settings/Settings"
import fullImage from '../images/logo-full.png'
import squareImage from '../images/logo-only.png'
import { RecipeHomeContextProvider } from "../components/RecipeManagerHome";
import {KeyboardListener} from "../components/listeners/keybord";

const RecipeManagerHome = React.lazy(()=>import('../components/RecipeManagerHome'))


export type ReactFCWithChildren = React.FC<PropsWithChildren>;

export interface ViewPortContextProps {
    isMobileView?: boolean
}

export interface ResponsiveMenuContextProps {
    collapsed?: boolean
    setCollapsed: (data: boolean) => void
    selectedKeys: string
    setSelectedKeys: (data: string)=>void

}

export const ViewPortContext = React.createContext<ViewPortContextProps>({isMobileView: false})
export const ResponsiveMenuContext = React.createContext<ResponsiveMenuContextProps | undefined>(undefined)

export const ResponsiveMenu: React.FunctionComponent<PropsWithChildren<any>> = (props) => {
    const auth = React.useContext(AuthContext);
    const navigate = useNavigate();
    const ctx = React.useContext(IOContext)

    React.useEffect(() => {
        if(auth?.user?.user_role === UserRoles.Designer )
        {
            navigate("/view/truss-check");
        }
    }, [auth]);
    React.useEffect(() => {
        ctx.socket?.on('deployment.job', (...data: any) => {
            notification.success({message: <Trans i18nKey={`deployment_job_info`}
                                       defaults={`Job ${data[0].jobId} completed - Deployed ${data[0].deployed_count}/${data[0].requested_count}`}/>})
        })
        return () => {
            ctx.socket?.off(`deployment.job`)
        }
    }, [ctx,props])
    return <ViewPortContext.Consumer>
        {
            view => <ResponsiveMenuContext.Consumer>
                {
                    state => <React.Fragment>

                    {!view.isMobileView && state && auth?.user?.user_role !== UserRoles.Designer &&
                        <Layout.Sider trigger= {state.collapsed ? <DoubleRightOutlined className="anticon"/> : <DoubleLeftOutlined className="anticon"/>}    
                                      collapsible theme={"light"}
                                      style={{borderRight: "1px solid #e4e9f0", alignItems: "center", paddingTop: 13,}}
                                      collapsed={state.collapsed}
                                      onCollapse={state.setCollapsed}>
                            
                            {props.children}
                            
                        </Layout.Sider>}
                        {view.isMobileView && state && auth?.user?.user_role !== UserRoles.Designer &&
                        <Drawer bodyStyle={{padding: 0}} open={!state.collapsed}
                                onClose={() => state?.setCollapsed(true)} title={"App Menu"} placement={"left"}>
                            {props.children}
                        </Drawer>}
                        {/* {auth?.user?.user_role === UserRoles.Designer && firstLoad &&
                            <Navigate replace to="view/truss-check" />
                        } */}
                    </React.Fragment>
                }
            </ResponsiveMenuContext.Consumer>
        }
    </ViewPortContext.Consumer>
}

export const MainView: React.FunctionComponent<PropsWithChildren<any>> = (props) => {

    const [collapsed, setCollapsed] = React.useState(false);
    const [selectedKeys, setSelectedKeys] = React.useState('');
    const {md} = Grid.useBreakpoint()
    const navigate = useNavigate();


    // const setView = (view: any) => {
    //     setSelectedKeys(view);
    //     navigate(view);
    // }


    // @ts-ignore
    return <KeyboardListener>
    <React.Fragment>
        <RecipeHomeContextProvider>
        <ViewPortContext.Provider value={{isMobileView: !md}}>
            <Layout style={{height: "100vh"}}>
                <Layout.Header style={{
                        padding: 0
                    }} className="page-header">



                        {/*<div style={{paddingLeft:20}}>*/}
                        {/*    <h1>Dashboard</h1>*/}
                        {/*</div>*/}
                        {/*<div style={{flexGrow: 1, height: '100%', width:30}}/>*/}
                        <Header setCollapsed={setCollapsed} collapsed={collapsed}/>


                </Layout.Header>
                <Layout style={{ padding: '0', borderTop: '1px solid #e4e9f0' }} >
                <ResponsiveMenuContext.Provider value={{collapsed, setCollapsed, selectedKeys, setSelectedKeys}}>
                    <ResponsiveMenu>
                        <div className="menu-container">
                            <AuthContext.Consumer>{auth =>
                                <UserMenu mode={"inline"} user_role={auth?.user?.user_role}/>}
                            </AuthContext.Consumer>
                        </div>
                    </ResponsiveMenu>

                    <Layout.Content className={'scroll-panel'} /* background of the component */ >
                        <div className={"landing-page-container-div"} >
                            <Routes>
                                <Route path="/" element={<React.Suspense><RecipeManagerHome/></React.Suspense>}/>
                                <Route path="view/truss-view" element={<TrussView/>}/>
                                <Route path="view/truss-check/*" element={<TrussCheck/>}/>
                                <Route path="view/configurations" element={<ConfigurationView/>}/>
                                <Route path="view/erp-clients" element={<ERPClientView/>}/>
                                <Route path="view/profile" element={<Profile/>}/>
                                <Route path="view/notifications" element={<Notifications/>}/>
                                <Route path="view/change-passwd" element={<ChangePassword/>} />
                                <Route path="view/job-status" element={<JobStatusView/>}/>
                                <Route path="view/purge-job-status" element={<PurgeJobStatusView/>}/>
                                <Route path="view/settings" element={<Settings/>}/>
                            </Routes>
                        </div>
                    </Layout.Content>
                </ResponsiveMenuContext.Provider>
                </Layout>
            </Layout>
        </ViewPortContext.Provider>
        </RecipeHomeContextProvider>
    </React.Fragment>
    </KeyboardListener>
}
