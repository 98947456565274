/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

export const LayerDefs = Object.freeze({
    MidGrabber:"MIDGRABBER",
    NailPlate:"NAILPLATE",
    TimberBeam: "TIMBER_BEAM",
    TimberDiagonal: "TIMBER_DIAGONAL",
    SideTool: "SIDETOOL",
    SideToolCable: "SIDETOOL_CABLE",
    PressToolRearPaddle:"PRESSTOOL_REAR_PADDLE",
    PressToolPaddle:"PRESSTOOL_PADDLE",
    PressToolUpperShape:"PRESSTOOL_UPPER_SHAPE",
    MultiPressToolUpperShape:"MULTI_PRESSTOOL_UPPER_SHAPE",
    PressToolEntryUpperShape:"PRESSTOOL_ENTRY_UPPER_SHAPE",
    PressToolEntry:"PRESSTOOL_ENTRY",
    PressTool:"PRESSTOOL",
    MultiPressTool:"MULTI_PRESSTOOL",
    BeamGrabberCable:"BEAMGRABBER_CABLE",
    BeamGrabber:"BEAMGRABBER",
    BlockToolPost:"BLOCKTOOL_POST",
    BlockToolPre:"BLOCKTOOL_PRE",
    BlockTool:"BLOCKTOOL",
    RemoveCart:"REMOVECART",
    RemoveToolSafety:"REMOVETOOL_SAFETY",
    RemoveToolExit:"REMOVETOOL_EXIT",
    RemoveToolEntry:"REMOVETOOL_ENTRY",
    RemoveToolOnMove:"REMOVETOOL_ON_MOVE",
    RemoveTool:"REMOVETOOL",
    NailPlateId:"NAILPLATE_ID",
    TimberId:"TIMBER_ID",
    MemberId:"MEMBER_ID",
    ToolId:"TOOL_ID",
    MassCenterPoint:"MASS_CENTER_POINT"
})

//from apsoftware/GenericObjects/structures.h
export const APSofwareTypes = Object.freeze({
    UpperBeam : 1,//(1 << 0),
    DownBeam : 2,//(1 << 1),
    StickDiagonal : 4,//(1 << 2),
    LeftSideGrabberDiagonal : 8,//(1 << 3),
    RightSideGrabberDiagonal : 16,//(1 << 4),
    LeftSupportToolDiagonal : 32,//(1 << 5),
    RightSupportToolDiagonal : 64,//(1 << 6),
    LeftBeamStickDiagonal : 128,//(1 << 7),
    RightBeamStickDiagonal : 256,//(1 << 8),
    NonProductionViableStick : 512,//(1 << 9),
    Press : 1024,//(1 << 10),
    BlockTool : 2014,//(1 << 11),
    RemoveTool : 4096,//(1 << 12),
    LeftBeamStickDiagonalUp : 8192,//(1 << 13),
    RightBeamStickDiagonalUp : 16384,//(1 << 14),
    LeftBeamStickDiagonalDown : 32768,//(1 << 15),
    RightBeamStickDiagonalDown : 65536,//(1 << 16),
    AfterBeamStickDiagonal : 131072,//(1 << 17),
    BlockToolWithVacuum : 262144,//(1 << 18)
})

export const TimberTypes = Object.freeze({
    UpperBeam:APSofwareTypes.UpperBeam,
    LeftBeamStickDiagonalUp:APSofwareTypes.LeftBeamStickDiagonalUp,
    RightBeamStickDiagonalUp:APSofwareTypes.RightBeamStickDiagonalUp,
    LeftBeamStickDiagonal:APSofwareTypes.LeftBeamStickDiagonal,
    RightBeamStickDiagonal:APSofwareTypes.RightBeamStickDiagonal,
    DownBeam:APSofwareTypes.DownBeam,
    LeftBeamStickDiagonalDown:APSofwareTypes.LeftBeamStickDiagonalDown,
    RightBeamStickDiagonalDown:APSofwareTypes.RightBeamStickDiagonalDown,
    StickDiagonal:APSofwareTypes.StickDiagonal,
    LeftSideGrabberDiagonal:APSofwareTypes.LeftSideGrabberDiagonal,
    RightSideGrabberDiagonal:APSofwareTypes.RightSideGrabberDiagonal,
    LeftSupportToolDiagonal:APSofwareTypes.LeftSupportToolDiagonal,
    RightSupportToolDiagonal:APSofwareTypes.RightSupportToolDiagonal
})

export const ChordGrabberOnOffDistance = Object.freeze({
    UpperChordGrabberOnOffDistance:50.5,
    DownChordGrabberOnOffDistance: 34.0,
})

export const ToolFlags = {
    MIDGRABBER: APSofwareTypes.StickDiagonal,
    UPPER_BEAM_GRABBER: APSofwareTypes.UpperBeam,
    DOWN_BEAM_GRABBER:  APSofwareTypes.DownBeam,
    BEAMGRABBER: APSofwareTypes.UpperBeam | APSofwareTypes.DownBeam,
    LEFT_SIDE_TOOL:APSofwareTypes.LeftBeamStickDiagonalUp |APSofwareTypes.LeftBeamStickDiagonalDown ,
    RIGHT_SIDE_TOOL:APSofwareTypes.RightBeamStickDiagonalUp | APSofwareTypes.RightBeamStickDiagonalDown,
    SIDE_TOOL:APSofwareTypes.LeftBeamStickDiagonalUp |APSofwareTypes.LeftBeamStickDiagonalDown | APSofwareTypes.RightBeamStickDiagonalUp | APSofwareTypes.RightBeamStickDiagonalDown,
    REMOVE_TOOL: APSofwareTypes.RemoveTool
}

export const TimberFlags = {
    UPPER_BEAM : APSofwareTypes.UpperBeam |APSofwareTypes.LeftBeamStickDiagonalUp |APSofwareTypes.RightBeamStickDiagonalUp|APSofwareTypes.LeftBeamStickDiagonal |APSofwareTypes.RightBeamStickDiagonal,
    DOWN_BEAM:  APSofwareTypes.DownBeam | APSofwareTypes.LeftBeamStickDiagonalDown | APSofwareTypes.RightBeamStickDiagonalDown|APSofwareTypes.LeftBeamStickDiagonal |APSofwareTypes.RightBeamStickDiagonal,
    DIAGONAL: APSofwareTypes.StickDiagonal |APSofwareTypes.LeftSideGrabberDiagonal | APSofwareTypes.RightSideGrabberDiagonal | APSofwareTypes.LeftSupportToolDiagonal | APSofwareTypes.RightSupportToolDiagonal
}

export interface Tool {
    DrawingElements: {
        layer: string,
        Shapes: {[key:string]: any}[],
        ContactPoints: {[key:string]: any}[],
        [key:string]: any
    },
    [key:string]: any
}

export interface ChordGrabber extends Tool{
    DrawingElements: {
        layer: string,
        Shapes: {[key:string]: any}[],
        ContactPoints: {[key:string]: any}[],
        Cable: { [key: string]: any },
        [key:string]: any
    }
}

export const initialLayerMapping = {
    "Grabbers": {
        selectAll: 1,
        data: [
            { name: "Chord Grabber Cable", selected: 1, id: LayerDefs.BeamGrabberCable },
            { name: "Chord Grabber", selected: 1, id: LayerDefs.BeamGrabber },
            { name: "Spike Grabber", selected: 1, id: LayerDefs.MidGrabber },

        ]
    },
    "Side tool": {
        selectAll: 1,
        data: [
            { name: "Side Suction Grabber Cable", selected: 1, id: LayerDefs.SideToolCable },
            { name: "Side Tool", selected: 1, id: LayerDefs.SideTool }
        ]
    },
    "Member": {
        selectAll: 1,
        data: [
            { name: "Timber Beam", selected: 1, id: LayerDefs.TimberBeam },
            { name: "Timber Diagonal", selected: 1, id: LayerDefs.TimberDiagonal }
        ]
    },
    "Nail Plate": {
        selectAll: 1,
        data: [{ name: "Nail Plate", selected: 1, id: LayerDefs.NailPlate }]
    },
    "Block Tool": {
        selectAll: 1,
        data: [
            { name: "Block Tool Post", selected: 1, id: LayerDefs.BlockToolPost },
            { name: "Block Tool Pre", selected: 1, id: LayerDefs.BlockToolPre },
            { name: "Block Tool", selected: 1, id: LayerDefs.BlockTool }
        ]
    },
    "Press Tool": {
        selectAll: 1,
        data: [
            { name: "Press Tool Paddle", selected: 1, id: LayerDefs.PressToolPaddle },
            { name: "Press Tool Upper Shape", selected: 1, id: LayerDefs.PressToolUpperShape },
            { name: "Press Tool Entry Upper Shape", selected: 1, id: LayerDefs.PressToolEntryUpperShape },
            { name: "Press Tool Entry", selected: 1, id: LayerDefs.PressToolEntry },
            { name: "Press Tool", selected: 1, id: LayerDefs.PressTool },
            { name: "Multi Press Tool", selected: 1, id: LayerDefs.MultiPressTool },
            { name: "Multi Press Tool Upper Shape", selected: 1, id: LayerDefs.MultiPressToolUpperShape }
        ]
    },
    "Remove Tool": {
        selectAll: 1,
        data: [
            { name: "Remove Tool", selected: 1, id: LayerDefs.RemoveTool },
            /** as per the requirement of ENH-511 */
            // { name: "Remove Tool Exit", selected: 1, id: LayerDefs.RemoveToolExit },
            { name: "Remove Tool Entry", selected: 1, id: LayerDefs.RemoveToolEntry },
            /** as per the requirement of ENH-511 */
            // { name: "Remove Tool On Move", selected: 1, id: LayerDefs.RemoveToolOnMove },
            { name: "Remove Cart", selected: 1, id: LayerDefs.RemoveCart },
            { name: "Remove Tool Safety", selected: 1, id: LayerDefs.RemoveToolSafety },
            { name: "Mass Center Point", selected: 1, id: LayerDefs.MassCenterPoint }
        ]
    },
    "Id": {
        selectAll: 1,
        data: [
            { name: "Nail Plate Id", selected: 1, id: LayerDefs.NailPlateId },
            { name: "Timber Id", selected: 1, id: LayerDefs.TimberId },
            { name: "Member Id", selected: 1, id: LayerDefs.MemberId },
            { name: "Tool Id", selected: 1, id: LayerDefs.ToolId }
        ]
    }
};
export const LayerGroups = [
    {
        name: "Assembly",
        layers: [LayerDefs.BeamGrabberCable, LayerDefs.BeamGrabber, LayerDefs.MidGrabber, LayerDefs.SideToolCable, LayerDefs.SideTool, LayerDefs.TimberBeam, LayerDefs.TimberDiagonal, LayerDefs.NailPlate, LayerDefs.BlockToolPost,
            LayerDefs.BlockToolPre, LayerDefs.BlockTool],
        selectSet: ["Grabbers", "Side tool", "Member", "Nail Plate", "Block Tool"]
    },
    {
        name: "Designer",
        layers: [LayerDefs.BeamGrabberCable, LayerDefs.BeamGrabber, LayerDefs.MidGrabber, LayerDefs.SideToolCable, LayerDefs.SideTool, LayerDefs.TimberBeam, LayerDefs.TimberDiagonal, LayerDefs.NailPlate, LayerDefs.BlockToolPost,
            LayerDefs.BlockToolPre, LayerDefs.BlockTool, LayerDefs.PressToolPaddle, LayerDefs.PressToolUpperShape, LayerDefs.PressToolEntryUpperShape, LayerDefs.PressToolEntry, LayerDefs.PressTool,
            LayerDefs.MultiPressToolUpperShape, LayerDefs.MultiPressTool
        ],
        selectSet: ["Grabbers", "Side tool", "Member", "Nail Plate", "Block Tool", "Press Tool"]
    },
    {
        name: "Press",
        layers: [LayerDefs.SideToolCable,LayerDefs.SideTool, LayerDefs.TimberBeam, LayerDefs.TimberDiagonal, LayerDefs.NailPlate, LayerDefs.PressToolPaddle, LayerDefs.PressToolUpperShape, LayerDefs.PressToolEntryUpperShape,
            LayerDefs.PressToolEntry, LayerDefs.PressTool, LayerDefs.MultiPressToolUpperShape, LayerDefs.MultiPressTool],
        selectSet: ["Side tool", "Member", "Nail Plate", "Press Tool"]
    },
    {
        name: "Unload",
        layers: [LayerDefs.TimberBeam, LayerDefs.TimberDiagonal, LayerDefs.NailPlate, LayerDefs.RemoveTool, LayerDefs.RemoveToolEntry,
            LayerDefs.RemoveCart, LayerDefs.RemoveToolSafety, LayerDefs.MassCenterPoint],
        selectSet: ["Member", "Nail Plate", "Remove Tool"]
    },
    {
        name: "All",
        layers: [LayerDefs.BeamGrabberCable, LayerDefs.BeamGrabber, LayerDefs.MidGrabber, LayerDefs.SideToolCable, LayerDefs.SideTool, LayerDefs.TimberBeam, LayerDefs.TimberDiagonal, LayerDefs.NailPlate, LayerDefs.BlockToolPost,
            LayerDefs.BlockToolPre, LayerDefs.BlockTool, LayerDefs.PressToolPaddle, LayerDefs.PressToolUpperShape, LayerDefs.PressToolEntryUpperShape, LayerDefs.PressToolEntry, LayerDefs.PressTool,
            LayerDefs.RemoveTool, LayerDefs.RemoveToolEntry, LayerDefs.RemoveCart, LayerDefs.NailPlateId, LayerDefs.TimberId, LayerDefs.MemberId, LayerDefs.ToolId,
            LayerDefs.MultiPressToolUpperShape, LayerDefs.MultiPressTool, LayerDefs.RemoveToolSafety, LayerDefs.MassCenterPoint],
        selectSet: ["Grabbers", "Side tool", "Member", "Nail Plate", "Block Tool", "Press Tool", "Remove Tool", "Id"]
    }
];

export interface SuctionGrabber {
    DrawingElements: {
        layer: string,
        Shapes: {[key:string]: any}[],
        ContactPoints: {[key:string]: any}[],
        Cable: { [key: string]: any },
        [key:string]: any
    }
}

export interface SideSupportTool extends Tool {}
export interface SpikeGrabber extends Tool {}
export interface PressTool extends Tool {}
export interface BlockTool extends Tool {}
export interface RemoveCart extends Tool {}
export interface RemoveTool extends Tool {}

export interface HomePosition {
    HomeX: number,
    HomeY: number,
    GrabberID: number,
    [key:string]: any
}


export interface Configuration {
    blockshapes: {
        [key:string]: any
    },
    buildtoolshapes: {
        PressTool: PressTool,
        BlockTool: BlockTool,
        RemoveCart: RemoveCart,
        RemoveTool: RemoveTool,
        [key:string]: any
    },
    forbiddenareashapes: {[key:string]: any},
    removetrackshapes: {[key:string]: any},
    walldata: {
        WallDimensions: {
            MaxX: number,
            MaxY: number
        },
        WallSetup: {
            HomePositionsOfDownSpikesGrabbers: {[key:string]: any}[],
            HomePositionsOfUpperSpikesGrabbers: {[key:string]: any}[],
            HomePositionsOfUpperBeamGrabbers: HomePosition[],
            HomePositionsOfDownBeamGrabbers: HomePosition[],
            HomePositionsOfSideTools: {[key:string]: any}[],
            [key:string]: any

        }
    },
    walltoolshapes: {
        ChordGrabber : ChordGrabber,
        LeftSideSuctionGrabber: SuctionGrabber,
        RightSideSuctionGrabber: SuctionGrabber,
        SideSupportTool: SideSupportTool,
        SpikeGrabber: SpikeGrabber
    }
    calculationconfig: {
        configuration: {
            ProdLineConfiguration: {
                LineConfiguration: {
                    RemoveCarts: {
                        CartsInUse: number,
                        [key:string]: any
                    }
                    RemoveTrack: {
                        RemoveToLeft: boolean,
                        [key:string]: any
                    }
                    RemoveTools: {
                        [key:string]: any
                    },
                    [key:string]: any
                }
            },
            [key:string]: any
        },
        [key:string]: any
    },
    [key:string]: any
}

/**
 * Layers with groups that can be moved by the user.
 */
export const MovableLayers = new Set([
    LayerDefs.BeamGrabber,
    LayerDefs.MidGrabber,
    /**Remove tool dragging is disabled as per the ENH-511*/
    // LayerDefs.RemoveTool,
    LayerDefs.SideTool,
    LayerDefs.PressTool

]);

/**
 * Layers with groups that can freeTransform by the user.
 */
export const FreeTransformLayers = new Set([
    LayerDefs.BeamGrabber,
    LayerDefs.MidGrabber,

]);

/**
 * Layers with groups that can be selected by the user.
 */
export const SelectableLayers = new Set([
    LayerDefs.BeamGrabber,
    LayerDefs.MidGrabber,
    LayerDefs.PressTool,
    LayerDefs.BlockTool,
    LayerDefs.TimberBeam,
    LayerDefs.TimberDiagonal,
    LayerDefs.RemoveToolEntry,
    LayerDefs.SideTool,
    LayerDefs.NailPlate
]);

/**
 * Layers with groups that can be removed by the user.
 */
export const RemovableLayers = new Set([
    LayerDefs.BeamGrabber,
    LayerDefs.MidGrabber,
    LayerDefs.PressTool,
    LayerDefs.BlockTool,
    LayerDefs.RemoveToolEntry,
    LayerDefs.SideTool
]);

export const toolShapeNames = [
    'beamgrabber_withfoot',
    'beamgrabber_withtool',
    'beamgrabber_base',
    'midgrabber_withfoot',
    'midgrabber_withtool',
    'midgrabber_base',
    'sidetool_withfoot',
    'sidetool_withtool',
    'sidetool_base',
    'sidetool_unfinalized',
    'sidetool_finalized',
    'presstool_base',
    'presstool_withtool',
    'presstool_mouthdepth',
    'multi_presstool_base',
    'multi_presstool_withtool',
    'multi_presstool_mouthdepth',
    'removetool_safety',
    'removetool_base',
    'removetool_neck',
    'nailplate',
    'timber',
    'blocktool_base',
    'blocktool_vacuumhousing'
]

export const AngleAdjustment = Object.freeze({
    RightSideSuctionGrabber: 135,
    LeftSideSuctionGrabber: -135,
})