/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {useEffect, useMemo, useState} from "react";
import {centerOf, distanceBetween, edgeAngle, rotatePointByCenter, verticesToEdges} from "./util";

interface LabelProps {
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    dx: number,
    dy: number,
    label: string,
    id: number
}
const Label: React.FunctionComponent<LabelProps> = ({dx, dy, ...props}) => {

    const [offSets, setOffsets] = useState({dx: 0, dy: 0})

    useEffect(() => {
        //Shifts the label position horizontally and vertically from original position, so it won't collide with the polygon
        const offsetX = dx < 0 ? -260 : 0;
        const offsetY = (dx > 0 && dy < 0) ? 50 : 0;

        setOffsets({dx: offsetX, dy: offsetY})
    }, [dx, dy]);

    return (<g
               id={`label_${props.id}`}
    >
        <text fontSize={56} dx={offSets.dx} dy={offSets.dy} transform={`translate(${props.x2}, ${props.y2}) scale(1, -1)`}>{props.label}</text>
        <line x1={props.x1} y1={props.y1} x2={props.x2} y2={props.y2}  style={{stroke: "red", strokeWidth: 2}}/>

    </g>)
}

export const Labels: React.FunctionComponent<{vertices: { x: number, y: number }[]}> = (props) => {

    const values = useMemo(() => {
        return verticesToEdges(props.vertices).map(([firstPoint, secondPoint], eIdx) => {
            const center = centerOf(firstPoint, secondPoint);
            const angle = edgeAngle(firstPoint, secondPoint);
            const distance = distanceBetween(
                firstPoint,
                secondPoint,
                "mm"
            );
            
            //calculate the label position from the center of the edge
            const other = rotatePointByCenter(
                center,
                { x: center.x + 100, y: center.y },
                Math.PI / 180 - angle
            );
            const dx = other.x - center.x;
            const dy = other.y - center.y;
            return {
                center,
                distance,
                other,
                dx,
                dy,
                eIdx
            };
        });
    }, [props.vertices]);


  return <React.Fragment>
      {
          values.map(({center, distance, other, dx, dy, eIdx}) => (
              <Label
                  x1={center.x}
                  y1={center.y}
                  x2={other.x }
                  y2={other.y }
                  dx={dx}
                  dy={dy}
                  label={`${distance} mm`}
                  key={eIdx}
                  id={eIdx}
              />
          ))
      }
  </React.Fragment>
}
