/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import {Truss} from "./trussdef";
import {useLocation,useSearchParams} from "react-router-dom";
import {use} from "i18next";
import qs from "query-string";
import {getRecipeForConfig, loadTrussContext} from "../../api";
import {STATUS_CODES} from "http";
import {StatusCodes} from "http-status-codes";
import {IOContext} from "../io/SocketIO";

export enum Status {
    Success = 1 << 0,
    Warnings = 1 << 2,
    Errors = 1 << 3,
    Deployed = 1 << 4,
    Acknowledge = 1<<5,
}

export interface Compatibility{
    version:string,
    status: {[line: string]: Status}
}



export interface TrussContextProps{
    truss: Truss,
    compatibility: Compatibility[]
    config_map: []
    query: {
        project_id: number,
        timestamp_year: number,
        truss_id: number
    }
    load: (data: {truss_id: number, timestamp_year: number})=>Promise<void>
}

export const TrussContext = React.createContext<TrussContextProps | undefined>( undefined)


export const parseTrussViewParams = (location: any)=>{
    const params = qs.parse(location.search)
    return {
        truss_id: parseInt(`${params["t"]}`),
        timestamp_year: parseInt(`${params["y"]}`),
        project_id: parseInt(`${params["p"]}`),
        project_name: parseInt(`${params["proj"]}`)
    }
}

export const TrussContextProvider:React.FunctionComponent<PropsWithChildren<{}>> = (props)=>{
    const [data, setData] = React.useState<Omit<TrussContextProps, "load">>()
    
    const ctx = React.useContext(IOContext)
    const location = useLocation();

    const load = React.useCallback(async (_data: {truss_id: number, timestamp_year: number})=>{
            setData(undefined)
            const {data:resp} = await loadTrussContext(_data) || {}
            setData({...resp, query: {...(data?.query || {}), truss_id: _data.truss_id, timestamp_year: _data.timestamp_year}})
    }, [props])

    React.useEffect(()=>{
        const query  = parseTrussViewParams(location)
        loadTrussContext({truss_id: query.truss_id, timestamp_year: query.timestamp_year}).then(({data:o})=>{          

            setData({...o, query: query});

        })     
    },[location])
    
    // @ts-ignore
    return <TrussContext.Provider value={{...data, load}}>
        {props.children}
    </TrussContext.Provider>
}

export interface RecipeCollisionRequest{
    id: number,
    timestamp_year: number,
    config: string,
    friendly_name: string,
    version:number,
    truss:string
}

export interface RecipeSelection{
    recipe: any,
    req: RecipeCollisionRequest
    set: (req?: RecipeCollisionRequest)=>Promise<void>
}


export const RecipeSelectionContext: React.Context<RecipeSelection| undefined> = React.createContext<RecipeSelection| undefined>(undefined)


export const RecipeSelectionContextProvider: React.FC<PropsWithChildren<{}>> = (props)=>{
    const [recipe, setRecipe] = React.useState<Omit<RecipeSelection, 'set'>>()
    const [params, setParams] = useSearchParams();
    const location = useLocation();

    const load = React.useCallback(async (req?: RecipeCollisionRequest)=>{

        if(req){
            const res = await getRecipeForConfig(req)
            if(res.status == StatusCodes.OK){
                setRecipe({recipe: res, req})
                const query  = parseTrussViewParams(location)
                
            }
        }else {
            setRecipe(undefined)
        }

    }, [])
    // Watch for changes in the 'recipe' state and update URL parameters
    React.useEffect(() => {
        if (recipe) {
            const updatedParams: {
                config?: string|undefined;
                version?: string|undefined;
                isRecipe?: string|undefined;
                truss?: string|undefined;
                recipe: string | undefined;
                deploylist: string | undefined;
            } = {
                ...qs.parse(location.search),
                truss: recipe.req.truss || "",
                config: recipe.req.config || "",
                version: recipe.req.version.toString() || "",
                recipe: recipe.req.id.toString() || "",
                isRecipe: recipe.recipe.data.isRecipe? '1' : '0',
                deploylist:undefined
            };

            Object.keys(updatedParams).forEach((key) => {
                if (updatedParams[key as keyof typeof updatedParams] === undefined) {
                    delete updatedParams[key as keyof typeof updatedParams];
                }
            });
            const cleanedParams: Record<string, string> = {};
            for (const key in updatedParams) {
                const value = updatedParams[key as keyof typeof updatedParams];
                if (value !== undefined) {
                    cleanedParams[key] = value;
                }
            }
            setParams(cleanedParams);
        }
    }, [recipe, location.search, setParams]);

    return <RecipeSelectionContext.Provider value={{...recipe, set: load}}>
        {props.children}
    </RecipeSelectionContext.Provider>

}

export type HeaderSelection = {
    keys?: string,
    data?: {[key:string]: any}
}

export interface HeaderSelectionContextProps {
    selection: HeaderSelection
    setSelection?: (data: HeaderSelection)=>void,
    clear?: () => void
}

export const HeaderSelectionContext = React.createContext<HeaderSelectionContextProps>({
    selection: {}
});

export const HeaderSelectionContextProvider: React.FunctionComponent<React.PropsWithChildren<any>> = (props)=>{
    const [selection, setSelection]  = React.useState<HeaderSelection>({})
    const clear = React.useCallback(()=>{
        setSelection({})
    }, [setSelection])

    return <HeaderSelectionContext.Provider value={{selection, clear, setSelection}}>
        {props.children}
    </HeaderSelectionContext.Provider>

}
