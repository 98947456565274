/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, useCallback, useContext, useState} from "react";
import {ConfigContext, RecipeContext} from "../util";
import {LayerDefs} from "../def";
import {isSelectedByIdList} from "../util"
import {ErrorSelectionContext, LayerSelectionContext} from "../../trusscheck/util"
import {CollisionContext} from "../collisionDetection/util";
import {EditServiceObj, ITool} from "../EditService";

export enum BlockToolShapes {
    Base = 'BASE',
    WithTool = 'WITHTOOL',
    Exit = 'EXIT',
    Entry = 'ENTRY',
    LeftFork = 'LEFTFORK',
    RightFork = 'RIGHTFORK',
    IShape = 'I-SHAPE',
    VacuumHousing = 'VACUUMHOUSING',
    Suction = 'SUCTION'
}

export enum BlockToolPosition {
    EntryPositions = 'entryPositions',
    ExitPositions = 'exitPositions'
}

function getBlockToolHeight(points: string) {
    const coordinates = points.split(' ').map(c => {
        const [x, y] = c.split(',').map(Number)
        return { x, y }
    })

    let minY = Infinity;
    let maxY = -Infinity;

    for (const { y } of coordinates) {
        minY = Math.min(minY, y)
        maxY = Math.max(maxY, y)
    }

    return maxY - minY
}
export const ArmToolContext = React.createContext<{height: number} | undefined>(undefined)

export const ArmToolContextProvider:React.FunctionComponent<PropsWithChildren> = (props)=>{
    const configCtx = useContext(ConfigContext)
    const [height, setHeight] = useState<number| undefined>(undefined)

    React.useEffect(() => {
        let height
        configCtx?.configuration && configCtx?.configuration.buildtoolshapes.BlockTool.DrawingElements.Shapes.forEach((shape)=>{
            if (shape.layer === BlockToolShapes.Base ){
                height = getBlockToolHeight(shape.points)
            }
        })
        setHeight(height)

    },[configCtx])

    return <ArmToolContext.Provider value={{height: height}}>
        {props.children}
    </ArmToolContext.Provider>
}

const BlockToolShape: React.FunctionComponent<{shape: BlockToolShapes}> = (props)=> {

    return <ConfigContext.Consumer>
        {ctx => ctx?.configuration && ctx?.configuration.buildtoolshapes.BlockTool.DrawingElements.Shapes.reduce((acc: JSX.Element[], shape: {[key:string]: any}) => {

            if (shape.layer === props.shape ){
                acc.push(<polygon key={shape.layer.toLowerCase()} className={shape.layer.toLowerCase()}
                                  points={shape.points}/>)
            }

            return acc;
        }, [])}
    </ConfigContext.Consumer>
}

export const BlockToolBase = () => <BlockToolShape shape={BlockToolShapes.Base}/>
export const BlockToolVacuumHousing = () => <BlockToolShape shape={BlockToolShapes.VacuumHousing}/>

export const BlockToolLayer: React.FunctionComponent<{id?:number}> = (props)=> {

    const {height} = useContext(ArmToolContext)
    const collisionCtx = useContext(CollisionContext)
    const errCtx = useContext(ErrorSelectionContext)

    const getProperties = useCallback((tool: ITool) =>{

        const selectedToolId = `${LayerDefs.BlockTool}_${tool.timberId}`;
        const isSelected = isSelectedByIdList([selectedToolId], errCtx)

        const collide = collisionCtx?.collisions?.has(`${LayerDefs.BlockTool.toLowerCase()}_${tool.timberId}`)? "collide" : ""

        if (props.id === undefined) {
            return{
                'id': `${LayerDefs.BlockTool.toLowerCase()}_${tool.timberId}`,
                'data-component': tool.timberId,
                'data-layer': LayerDefs.BlockTool,
                'className': `${LayerDefs.BlockTool.toLowerCase()} ${isSelected ? "error" : ""} ${collide}`
            }

        } else return {
            'className': `${LayerDefs.BlockTool.toLowerCase()}`
        }
    },[props.id, collisionCtx, errCtx])

    const renderTool = useCallback((blockTools: ITool[]) => {
        return blockTools.reduce((acc:  JSX.Element[], tool: ITool) => {
            const angleByDegrees = tool.angle * (180 / Math.PI)

            const scaleFactor = height? (tool.timberBreadth + 50)/height : 1
            const scale = tool.useVacuumPicker? '' : `scale(1,${scaleFactor})`

            const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.BlockTool.toLowerCase()}_${tool.timberId}`)

            if ((props.id === tool.timberId || props.id === undefined) && !isDeleted) {

                acc.push(<g key={`${LayerDefs.BlockTool}_${tool.timberId}`}
                            transform={`rotate(${angleByDegrees}, ${tool.x}, ${tool.y}) translate(${tool.x}, ${tool.y}) ${scale}`}
                            {...getProperties(tool)}
                >
                    {tool.useVacuumPicker ? <BlockToolVacuumHousing/> : <BlockToolBase/>}
                </g>)
            }

            return acc

        }, [])

    },[props.id, height, collisionCtx, errCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
                <RecipeContext.Consumer>
                    {ctx => ctx?.recipe.BlockTools && <g key={LayerDefs.BlockTool} id={LayerDefs.BlockTool.toLowerCase()}
                                                         className={`${layerCtx?.selectedComponent.includes(LayerDefs.BlockTool) && (props.id === undefined) ? "hide-layer" : ""}`}>
                        {ctx?.recipe.BlockTools && renderTool(ctx.recipe.BlockTools)}
                    </g>}
                </RecipeContext.Consumer>}
            </LayerSelectionContext.Consumer>

}

const BlockToolPrePost: React.FunctionComponent<{layer: string, position: BlockToolPosition, id?: number}> = (props) => {

    const {height} = useContext(ArmToolContext)
    const errCtx = useContext(ErrorSelectionContext)

    const getProperties = useCallback((tool: ITool, index: number) =>{

        const selectedToolId = `${LayerDefs.BlockToolPre}_${index}`;
        const selectedToolPostId = `${LayerDefs.BlockToolPost}_${index}`;
        const isSelected = isSelectedByIdList([selectedToolId,selectedToolPostId], errCtx)

        if (props.id === undefined) {
            return{
                'id': `${props.layer}_${tool.timberId}_${index}`,
                'className': `${props.layer.toLowerCase()} ${isSelected ? "error" : ""}`
            }

        } else {
            return {
                'className': `${props.layer.toLowerCase()}`
            }
        }
    },[props.id, props.layer, errCtx])

    const positionGroup = useCallback((tool: ITool, scale: string) => {
        const group = tool[props.position].map((item: { [key: string]: any }, index) => {

            const angleByDegrees = item.angle * (180 / Math.PI)

            return (<g key={`${props.layer}_${tool.timberId}_${index}`}
                       transform={`rotate(${angleByDegrees}, ${item.x}, ${item.y}) translate(${item.x}, ${item.y}) ${scale}`}
                       {...getProperties(tool, index)}
                >
                    {tool.useVacuumPicker ? <BlockToolVacuumHousing/> : <BlockToolBase/>}
                </g>
            )

        })

        return (<g key={`${props.layer}_${tool.timberId}`}
                   id={`${props.layer.toLowerCase()}_${tool.timberId}`}
        >
            {group}
        </g>)

    }, [props.position, props.layer])

    const renderTool = useCallback((blockTools: ITool[]) => {
        return blockTools.reduce((acc:  JSX.Element[], tool: ITool) => {

            const scaleFactor = height ? (tool.timberBreadth + 50)/height : 1
            const scale = tool.useVacuumPicker? '' : `scale(1,${scaleFactor})`

            const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.BlockTool.toLowerCase()}_${tool.timberId}`)

            if ((props.id === tool.timberId || props.id === undefined) && !isDeleted) {

                acc.push(positionGroup(tool, scale))
            }
            return acc

        }, [])

    },[props.id, height, errCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
            <RecipeContext.Consumer>
                {ctx => ctx?.recipe.BlockTools && <g key={props.layer} id={props.layer.toLowerCase()}
                                                     className={`${layerCtx?.selectedComponent.includes(props.layer) && (props.id === undefined) ? "hide-layer" : ""}`}>
                    {ctx?.recipe.BlockTools && renderTool(ctx.recipe.BlockTools)}
                </g>}
            </RecipeContext.Consumer>}
        </LayerSelectionContext.Consumer>

}

export const BlockToolPreLayer: React.FunctionComponent<{id?: number}> = (props) => <BlockToolPrePost layer={LayerDefs.BlockToolPre} position={BlockToolPosition.EntryPositions} id={props.id}/>
export const BlockToolPostLayer: React.FunctionComponent<{id?: number}> = (props) => <BlockToolPrePost layer={LayerDefs.BlockToolPost} position={BlockToolPosition.ExitPositions} id={props.id}/>
