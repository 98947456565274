/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import './styles/Projects.css'
import Search from "antd/es/input/Search";
import {Pagination, Radio, Switch, Tree} from "antd";
import {CreateProject} from "../CreateProject";
import {useLocation, useNavigate} from "react-router-dom";
import {LoadProjectTreeRequest, LoadRecentRequest, RecentItemTypes} from "./defs";
import {loadProjectTree} from "../../api";
import {TreeNodeElement} from "./tree/TreeNodeElement";
import {IOContext} from "../io/SocketIO";
import { CloseOutlined } from '@ant-design/icons';
import {Trans} from "../reactive/Trans";
import {I18nContext,useTranslation } from "react-i18next";
import {EditContext} from "../recipeVisualize/util";
import {getAllKeys} from "./util";
import {PreferenceContext} from "../user/UserPreference";

export interface DataContextProps {
    data: { rows: any[], total: number },
    req: LoadProjectTreeRequest,
    setReq: (r: Partial<LoadProjectTreeRequest>) => void;

}


export const ProjectTreeDataContext = React.createContext<DataContextProps | undefined>(undefined)

export const ProjectTreeProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const [data, setData] = React.useState<{ rows: any[], total: number }>({rows: [], total: 0})
    const [req, setReq] = React.useState<LoadProjectTreeRequest>({type: "pending", size: 100})


    const location = useLocation();

    const ctx = React.useContext(IOContext)

    React.useEffect(() => {
        ctx.socket?.on('refresh.projects', (...data: any) => {
            setReq({...req, ref: ((req?.itr || 0) + 1)})
        })
        return () => {
            ctx.socket?.off(`refresh.projects`)
        }
    }, [ctx,props])

    React.useEffect(() => {
        loadProjectTree(req).then(({data}) => {
            setData(data)
        }).catch(console.error)
    }, [req])


    return <ProjectTreeDataContext.Provider value={{data, req, setReq: (p) => setReq({...req, ...p})}}>
        {props.children}
    </ProjectTreeDataContext.Provider>

}






export const ProjectTree: React.FC<{expandAll: boolean}> = (props) => {

    const [sel, setSel] = React.useState<{selected: string[], defaultExp: string[]}>({selected: [], defaultExp: []})
    const location = useLocation();
    const nav = useNavigate()

    const _ctx = React.useContext(ProjectTreeDataContext)

    const keys = React.useCallback(() => {
        const {id} = location.pathname.split("/").slice(3).reduce((acc: any, cur) => {
            const f = acc?.children?.find?.((o: any) => o.title === cur)
            if (f)
                return {id: [...acc.id, f.key], children: f.children}
            else
                return acc;
        }, {children: _ctx?.data?.rows || [], id: []} as { id: string[], children: any[] })
        return id
    }, [location, _ctx])

    React.useEffect(() => {
        const k = keys() || [];
        const pathArr = location.pathname.split("/").slice(3)
        const project = (pathArr.length > 0)? pathArr[0] : ''
        const rows = _ctx?.data?.rows || []
        const expandedKeys = props.expandAll? getAllKeys(project, rows): k
        if (k.length > 0)
            setSel({selected: k.slice(-1), defaultExp: expandedKeys})

    }, [location,_ctx])


    return <ProjectTreeDataContext.Consumer>
        {ctx =>
            <Tree
                showLine={true}
                key={["tree", sel.defaultExp].join("_")}
                selectedKeys={sel.selected}
                defaultExpandedKeys={sel.defaultExp}
                titleRender={(o: any)=><TreeNodeElement data={o} key={o.key}/>}
                onSelect={(e, p) => {
                    const [_, proj, truss, config, version] = p.node.pos.split("-").map((o:string, i: number) => {
                        return parseInt(o)
                    })
                    let obj: LoadRecentRequest = {type: RecentItemTypes.Projects} as LoadRecentRequest
                    let params = []
                    if (proj != null) {
                        const p = ctx?.data?.rows?.[proj];
                        if (p) {
                            obj.project = p._id?.[0]
                            obj.timestamp_year = p._id?.[1]
                            obj.type = RecentItemTypes.Trusses;
                            params.push(p.title)

                        }
                        if (truss != null) {
                            const t = p.children?.[truss];
                            if (t) {
                                obj.truss = t._id?.[0]
                                obj.type = RecentItemTypes.Configs;
                                params.push(t.title)

                            }

                            if (config != null) {
                                const c = t.children?.[config];
                                if (c) {
                                    obj.config = c._id?.[0]
                                    obj.type = RecentItemTypes.Recipes;
                                    params.push(c.title)
                                }

                                if (version != null) {
                                    const v = c.children?.[version];
                                    if (v)
                                        obj.recipe = v._id?.[0]
                                        obj.timestamp_year = v._id?.[1]
                                        params.push(v.title)
                                }
                            }
                        }
                    }
                    const path = location.pathname.split("/").slice(0, 3)
                    nav([...path, ...params].join("/"), {state: {...obj}})

                }}
                treeData={ctx?.data?.rows}
            />
        }
    </ProjectTreeDataContext.Consumer>

}


export const Projects: React.FC<any> = (props) => {
    const ctx = React.useContext(ProjectTreeDataContext);
    const [searchValue, setSearchValue] = React.useState(ctx?.req?.name || '');
    const { t } = useTranslation();
    const {editMode} = React.useContext(EditContext)
    const [expandAll, setExpandAll] = React.useState<boolean>(true)

    const prefCtx = React.useContext(PreferenceContext)

    React.useEffect(()=>{
        const val = (prefCtx?.prefInfo.pref.treeExpand !== undefined)? prefCtx?.prefInfo.pref.treeExpand as boolean : true
        setExpandAll(val)
    }, [prefCtx?.prefInfo.pref.treeExpand])

    const changeExpandAll = React.useCallback((checked: boolean)=>{
        setExpandAll(checked)
        prefCtx?.load(undefined, undefined, checked)
    }, [])

    const onSearchProject = (value: string) => {
        ctx?.setReq?.({ name: value ,type: 'all' });
    };

    const handleClear = () => {
        setSearchValue('');
        ctx?.setReq?.({ name: '' });
    };
    return <div className={`tc-projects ${editMode ? 'disabled' : ''}`}>
        <div className={"tc-project-header"}>
            <div className={"tc-project-search"}>
                <Search
                        value={searchValue}
                        onSearch={onSearchProject}
                        onChange={(e) => setSearchValue(e.target.value)}
                        suffix={searchValue && <CloseOutlined onClick={handleClear} />}
                        style={{ padding: '4px' }}
                        placeholder={t('filter.search')}
                        autoFocus

                    />

            </div>
            <div className={"tc-project-types"}>
                <ProjectTreeDataContext.Consumer>
                    {ctx => <Radio.Group value={ctx?.req?.type} onChange={(e) => {setSearchValue(''); ctx?.setReq?.({type: e.target.value , name: ''}); }}>
                        <Radio value={"all"}><Trans i18nKey={`All`} defaults={'All'}/></Radio>
                        <Radio value={"pending"}><Trans i18nKey={`Pending`} defaults={'Pending'}/></Radio>
                        <Radio value={"deployed"}><Trans i18nKey={`Deployed`} defaults={'Deployed'}/></Radio>
                    </Radio.Group>}
                </ProjectTreeDataContext.Consumer>
            </div>
            <div className={"tc-project-switch"}>
                <label style={{ paddingRight: '8px' }}>
                    Expand all nodes
                </label>
                <Switch size={'small'} onChange={changeExpandAll} checked={expandAll}/>
            </div>
        </div>
        <div className={"tc-project-list"}>
            <ProjectTree expandAll={expandAll}/>
        </div>
        <div className={"tc-project-pagination"}>
            <ProjectTreeDataContext.Consumer>
                {ctx =>
                    <Pagination onChange={async (cur, size) => {
                        ctx?.setReq?.({size, from: (cur - 1) * size})
                    }} showSizeChanger={true} pageSize={ctx?.req?.size || 10} total={ctx?.data?.total || 0}
                                current={Math.ceil((ctx?.req?.from || 0) / (ctx?.req?.size || 1)) + 1}
                                responsive={true} size={'small'}/>
                }
            </ProjectTreeDataContext.Consumer>
        </div>
        <div className={"tc-create-project-btn"}>
            <CreateProject/>
        </div>
    </div>

}
