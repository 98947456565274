/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {Button, Input, List, Tag} from "antd";
import {parseTrussViewParams, TrussContext, RecipeSelectionContext} from "./util";
import {CloseOutlined, ReloadOutlined} from "@ant-design/icons";
import {loadTrussList} from "../../api";
import {useLocation, useSearchParams} from 'react-router-dom'
import dayjs from 'dayjs'
import qs from "query-string";
import {Trans} from "../reactive/Trans";
import {I18nContext,useTranslation } from "react-i18next";
export interface TrussItem{
    id: number,
    truss_label: string,
    created_date: string,
    item: string
}

export const TrussList: React.FunctionComponent<any> = (props)=>{
    const { t } = useTranslation();
    const [data, setData] = React.useState<TrussItem[]>([])
    const [query, setQuery] = React.useState<string| undefined>(undefined)
    const [_, setParams] = useSearchParams()
    const location = useLocation()

    const load = React.useCallback(async ()=>{

        const query = parseTrussViewParams(location)
        const {data: {rows = []} = {}} = await loadTrussList({
            project_id: query.project_id,
            timestamp_year: query.timestamp_year
        }) || {}
        setData(rows)
        setQuery(undefined)
    }, [location])

    React.useEffect(()=>{
        load().catch(console.error);
    }, [])
    const handleClear = () => {
        setQuery(undefined)

    };
    
    return <div className={"tv-truss-list"}>
        <div className={`tv-tl-search`}>

            <Input.Search value={query} onSearch={setQuery} onChange={(e) => setQuery(e.target.value)}  
            suffix={query && <CloseOutlined onClick={handleClear} />}
            placeholder={t('filter.search')}
                        autoFocus
            />
            <Button icon={<ReloadOutlined/>} onClick={load}/>
        </div>
        <div className={"tv-tl-list"}>
            <List
                itemLayout={"vertical"}
                dataSource={data.filter(o=>o.item.includes(query || ""))}
                renderItem={(item: TrussItem) => (
                    <List.Item
                        key={item.id}
                    >
                        <RecipeSelectionContext.Consumer>
                            {r=><TrussContext.Consumer>
                                {ctx=> <div onClick={async ()=>{
                                    const p = parseTrussViewParams(location)
                                    await r?.set(undefined)
                                    //@ts-ignore
                                    
                                    const queryParams = qs.parse(location.search);
                                    if (queryParams.version && queryParams.config && queryParams.truss) {
                                        delete queryParams.version;
                                        delete queryParams.config;
                                        delete queryParams.truss;
                                    }
                                    setParams({...queryParams, y: `${p.timestamp_year}`, t: `${item.id}`, })
                                }} className={["tv-tl-item",item.id == ctx?.truss?.id ? "selected": ""].join(" ")}>
                                    <div className={"tv-tl-it-details"}>
                                        <div className={"marker"}><div/></div>
                                        <span className={"tv-tl-it-title"}>{item.item} </span>
                                        <Tag color={"green"}>{item.truss_label}</Tag>
                                        <span className={"tv-tl-it-date"}>{dayjs(item.created_date).format('DD/MM/YYYY')}</span>
                                    </div>
                                </div>}
                            </TrussContext.Consumer>}
                        </RecipeSelectionContext.Consumer>

                    </List.Item>
                )}
            />
        </div>

    </div>
}
