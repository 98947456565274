/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

export const ToolShapesForCollision = Object.freeze({
    BeamGrabber: ['WITHFOOT', 'WITHTOOL', 'BASE'],
    MidGrabber: ['WITHFOOT', 'WITHTOOL', 'BASE'],
    SideTool: ['WITHFOOT', 'WITHTOOL', 'BASE', 'UNFINALIZED', 'FINALIZED'],
    SideSuctionTool: ['WITHFOOT', 'WITHTOOL', 'BASE', 'UNFINALIZED', 'FINALIZED'],
    PressTool: ['WITHTOOL', 'BASE', 'MOUTHDEPTH'],
    RemoveTool: ['BASE', 'NECK'],
    BlockTool: ['BASE', 'VACUUMHOUSING']
})

export const ToolShapes = Object.freeze({
    WithFoot: "withfoot",
    WithTool: "withtool",
    Base: "base",
    Finalized: "finalized",
    UnFinalized: "unfinalized",
    Vacuumhousing: "vacuumhousing",
    Safety: "safety",
    Neck: "neck",
    MouthDepth: "mouthdepth"

})

export const ToolName = Object.freeze({
    MidGrabber: "midgrabber",
    BeamGrabber: "beamgrabber",
    SideTool: "sidetool",
    PressTool: "presstool",
    MultiPressTool: "multi_presstool",
    RemoveTool: "removetool",
    NailPlate: "nailplate",
    Timber: "timber",
    BlockTool: "blocktool"

})
