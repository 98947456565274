/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, useCallback, useContext, useEffect} from "react";
import {
    calculateCenterPoint,
    calculateConvexHull, calculateVerticesOnOrigin,
    ConfigContext,
    convertPointsToArray, convertVertices, getCartTypeInUse, getRemoveDirection,
    moveCenterTo,
    RecipeContext,
    rotatePolygonPoints
} from "../util";
import {LayerDefs} from "../def";
import {ErrorSelectionContext, LayerSelectionContext} from "../../trusscheck/util"
import {isSelectedByIdList} from "../util"
import {CollisionContext} from "../collisionDetection/util"
import {EditServiceObj} from "../EditService";

export enum RemoveToolPosition {
    EntryPositions = 'entryPositions',
    ExitPositions = 'exitPositions',
    OnMovePositions = 'onMovePositions'
}

interface IPosition {
    x: number,
    y: number,
    angle: number
}

export interface IRemoveTool extends IPosition{
    entryPositions: IPosition[],
    onMovePositions: IPosition[],
    exitPositions: IPosition[]
}


export const RemoveToolContext = React.createContext<{ removeTools: IRemoveTool[] } | undefined>(undefined)

export const RemoveToolContextProvider:React.FunctionComponent<PropsWithChildren> = (props)=>{
    const [removeTools, setRemoveTools] = React.useState<IRemoveTool[] | undefined>(undefined)
    const configCtx = useContext(ConfigContext)
    const recipeCtx = useContext(RecipeContext)

    React.useEffect(() => {

        if (configCtx?.configuration && recipeCtx?.recipe.RemoveTools) {
            const cartTypeInUse = getCartTypeInUse(configCtx.configuration)
            const removeDirection = getRemoveDirection(configCtx.configuration)

            if (recipeCtx.recipe.RemoveTools.hasOwnProperty("RemoveToRight") || recipeCtx.recipe.RemoveTools.hasOwnProperty("RemoveToLeft")) {
                const removeTools = cartTypeInUse ? recipeCtx.recipe.RemoveTools[removeDirection][cartTypeInUse] : undefined
                setRemoveTools(removeTools)
            }
            else {
                setRemoveTools(recipeCtx.recipe.RemoveTools)
            }
        }
    }, [configCtx, recipeCtx])

    return <RemoveToolContext.Provider value={{removeTools: removeTools}}>
        {props.children}
    </RemoveToolContext.Provider>
}

export const RemoveTool: React.FunctionComponent<any> = () => {

    return <ConfigContext.Consumer>
        {ctx => ctx?.configuration && ctx?.configuration.buildtoolshapes.RemoveTool.DrawingElements.Shapes.map((shape, index)=>{
            return <polygon key={`${shape.layer.toLowerCase()}`} className={`${shape.layer.toLowerCase()}`} points={shape.points}/>
        })}
    </ConfigContext.Consumer>
}

export const RemoveToolLayer: React.FunctionComponent<{id?:number}> = (props)=> {

    const errCtx = useContext(ErrorSelectionContext)
    const collisionCtx = useContext(CollisionContext)

    const getProperties = useCallback((tool: IRemoveTool, index: number) => {

        const selectedRemoveToolId = `${LayerDefs.RemoveTool}_${index}`;
        const isSelected = isSelectedByIdList([selectedRemoveToolId], errCtx)

        const collide = collisionCtx?.collisions?.has(`${LayerDefs.RemoveTool.toLowerCase()}_${index}`)? "collide" : ""

        if (props.id === undefined) {
            return{
                'id': `${LayerDefs.RemoveTool.toLowerCase()}_${index}`,
                'className': `${LayerDefs.RemoveTool.toLowerCase()} ${isSelected ? "error" : ""} ${collide}`,
                'data-component': index,
                'data-layer': LayerDefs.RemoveTool
            }

        } else {
            return {
                'className': `${LayerDefs.RemoveTool.toLowerCase()}`
            }
        }
    },[props.id, errCtx, collisionCtx])

    const renderTool = useCallback((removeTools: IRemoveTool[]) => {
        if (removeTools && removeTools.length > 0) {
            return removeTools?.reduce((acc:  JSX.Element[], tool, index) => {

                const angleByDegrees = tool.angle * (180 / Math.PI) || 0

                const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.RemoveTool.toLowerCase()}_${index}`)

                if ((props.id === index || props.id === undefined) && !isDeleted) {

                    acc.push(<g key={`${LayerDefs.RemoveTool}_${index}`}
                                transform={`rotate(${angleByDegrees}, ${tool.x}, ${tool.y}) translate(${tool.x}, ${tool.y})`}
                                {...getProperties(tool, index)}
                    >
                        <RemoveTool/>
                    </g>)
                }

                return acc

            }, [])
        }

    },[props.id, errCtx, collisionCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
                <RemoveToolContext.Consumer>
                    {removeToolCtx => removeToolCtx?.removeTools && <g key={LayerDefs.RemoveTool} id={LayerDefs.RemoveTool.toLowerCase()}
                                                          className={`${layerCtx?.selectedComponent.includes(LayerDefs.RemoveTool) && (props.id === undefined)? "hide-layer" : ""}`}>
                        {removeToolCtx.removeTools && renderTool(removeToolCtx.removeTools)}
                    </g>}
                </RemoveToolContext.Consumer>}
            </LayerSelectionContext.Consumer>
}

export const RemoveToolSafetyAreaLayer: React.FunctionComponent<{id?:number}> = (props)=> {

    const [points, setPoints] = React.useState<string|undefined>(undefined)
    const configCtx = useContext(ConfigContext)
    const collisionCtx = useContext(CollisionContext)

    useEffect(()=>{
        const points = configCtx? configCtx.configuration.buildtoolshapes.RemoveTool.DrawingElements.Shapes.find((s)=> s.layer === 'FRAME').points : undefined
        setPoints(points)
    },[configCtx])

    const getProperties = useCallback((toolIndex: number) => {

        const collide = collisionCtx?.collisions?.has(`${LayerDefs.RemoveTool.toLowerCase()}_${toolIndex}`) ? "collide" : ""

        if (props.id === undefined) {
            return{
                'id': `${LayerDefs.RemoveToolSafety}_${toolIndex}`,
                'className': `${LayerDefs.RemoveToolSafety.toLowerCase()} ${collide}`,
                'data-component': toolIndex,
                'data-layer': LayerDefs.RemoveTool,
                'data-id': `${LayerDefs.RemoveTool.toLowerCase()}_${toolIndex}`
            }

        } else {
            return {
                'className': `${LayerDefs.RemoveToolSafety.toLowerCase()}`
            }
        }
    },[props.id, collisionCtx])

    const renderTool = useCallback((removeTools: IRemoveTool[], points: string) => {
        if (removeTools && removeTools.length > 0) {
            return removeTools?.reduce((acc:  JSX.Element[], tool, toolIndex) => {

                const pointArray = convertPointsToArray(points)
                const grabbingPolygon = moveCenterTo(rotatePolygonPoints(pointArray, tool.angle), tool.x, tool.y)
                const entryPosition = tool.entryPositions[0]
                const entryPolygon = moveCenterTo(rotatePolygonPoints(pointArray, entryPosition.angle), entryPosition.x, entryPosition.y)

                const safetyAreaPolygonVertices = calculateConvexHull([...grabbingPolygon, ...entryPolygon])
                const {centerX, centerY} = calculateCenterPoint(safetyAreaPolygonVertices)
                const safetyAreaPolygonInOrigin = calculateVerticesOnOrigin(safetyAreaPolygonVertices, centerX, centerY)

                const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.RemoveTool.toLowerCase()}_${toolIndex}`)

                if ((props.id === toolIndex || props.id === undefined) && !isDeleted) {

                    acc.push(<g key={`${LayerDefs.RemoveToolSafety}_${toolIndex}`}
                                transform={`translate(${centerX}, ${centerY})`}
                                {...getProperties(toolIndex)}
                    >
                        <polygon points={convertVertices(safetyAreaPolygonInOrigin)}/>
                    </g>)
                }

                return acc

            }, [])
        }

    },[props.id, collisionCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
        <RemoveToolContext.Consumer>
            {removeToolCtx => removeToolCtx?.removeTools && points && <g key={LayerDefs.RemoveToolSafety} id={LayerDefs.RemoveToolSafety.toLowerCase()}
                                                            className={`${layerCtx?.selectedComponent.includes(LayerDefs.RemoveToolSafety) && (props.id === undefined)? "hide-layer" : ""}`}>
                {removeToolCtx.removeTools && points && renderTool(removeToolCtx.removeTools, points)}
            </g>}
        </RemoveToolContext.Consumer>}
        </LayerSelectionContext.Consumer>
}

const RemoveToolEntryExit: React.FunctionComponent<{layer: string, position: RemoveToolPosition, id?:number}> = (props)=> {

    const errCtx = useContext(ErrorSelectionContext)

    const getProperties = useCallback((tool: IRemoveTool, toolIndex: number, index: number) => {

        const selectedRemoveToolExit = `${LayerDefs.RemoveToolExit}_${index}`;
        const selectedRemoveToolEntry = `${LayerDefs.RemoveToolEntry}_${index}`;
        const isSelected = isSelectedByIdList([selectedRemoveToolExit, selectedRemoveToolEntry], errCtx)

        if (props.id === undefined) {
            return{
                'id': `${props.layer}_${toolIndex}_${index}`,
                'className': `${props.layer.toLowerCase()} ${isSelected ? "error" : ""}`,
                'data-component': toolIndex,
                'data-layer': LayerDefs.RemoveTool,
                'data-id': `${LayerDefs.RemoveTool.toLowerCase()}_${toolIndex}`
            }

        } else {
            return {
                'className': `${props.layer.toLowerCase()}`
            }
        }
    },[props.id, errCtx])

    const positionGroup = useCallback((tool: IRemoveTool, toolIndex:number) => {

        const group = tool[props.position].map((item: { [key: string]: any }, index) => {
            const angleByDegrees = item.angle * (180 / Math.PI)

            return (<g key={`${props.layer}_${toolIndex}_${index}`}
                       transform={`rotate(${angleByDegrees}, ${item.x}, ${item.y}) translate(${item.x}, ${item.y})`}
                       {...getProperties(tool, toolIndex, index)}
                >
                    <RemoveTool/>
                </g>
            )
        })

        return (<g key={`${props.layer}_${toolIndex}`}
                   id={`${props.layer.toLowerCase()}_${toolIndex}`}
        >
            {group}
        </g>)

    }, [props.position, props.layer])

    const renderTool = useCallback((removeTools: IRemoveTool[]) => {
        if (removeTools && removeTools.length > 0) {
            return removeTools.reduce((acc:  JSX.Element[], tool, toolIndex) => {

                if (tool[props.position]){
                    const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.RemoveTool.toLowerCase()}_${toolIndex}`)
                    if ((props.id === toolIndex || props.id === undefined) && !isDeleted) {

                        acc.push(positionGroup(tool, toolIndex))
                    }
                    return acc
                }

            }, [])
        }

    },[props.id, props.position, errCtx])

    return <ErrorSelectionContext.Consumer>{errCtx=>
        <LayerSelectionContext.Consumer>{layerCtx=>
    <RemoveToolContext.Consumer>
        {removeToolCtx => removeToolCtx?.removeTools && <g key={props.layer} id={props.layer.toLowerCase()}
                                              className={`${layerCtx?.selectedComponent.includes(props.layer)  && (props.id === undefined)? "hide-layer" : ""}`}>
            {removeToolCtx?.removeTools && renderTool(removeToolCtx.removeTools)}
        </g>}
    </RemoveToolContext.Consumer>}
        </LayerSelectionContext.Consumer>}
    </ErrorSelectionContext.Consumer>
}

export const RemoveToolExitLayer: React.FunctionComponent = () => <RemoveToolEntryExit layer={LayerDefs.RemoveToolExit} position={RemoveToolPosition.ExitPositions}/>
export const RemoveToolOnMove: React.FunctionComponent = () => <RemoveToolEntryExit layer={LayerDefs.RemoveToolOnMove} position={RemoveToolPosition.OnMovePositions}/>
export const RemoveToolEntryLayer: React.FunctionComponent<{id?: number}> = (props) => <RemoveToolEntryExit layer={LayerDefs.RemoveToolEntry} position={RemoveToolPosition.EntryPositions} id={props.id}/>