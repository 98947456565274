/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, useState} from "react";
import {fetchSettings, getRecipeDetails, getRecipeForRedirect} from "../../api";
import {StatusCodes} from "http-status-codes";
import {EditContextProps, RecipeContextProps, RecipeContextProviderProps} from "../recipeVisualize/util";
import {useLocation, useNavigate} from "react-router-dom";
import {notification} from "antd";
import {Trans} from "../reactive/Trans";
import {UnitType} from "../user/UserPreference";
export interface Recipe{
    project_id?: number,
    timestamp_year?: number,
    truss_id?: number,
    recipe_id?: number,
    config?: string

}

export type MetaProps = (Partial<Recipe> & Required<Pick<Recipe, "project_id"| "timestamp_year">>) |
    (Partial<Recipe> & Required<Pick<Recipe, "project_id"| "timestamp_year"| "truss_id">>) |
    (Partial<Recipe> & Required<Pick<Recipe, "project_id"| "timestamp_year"| "truss_id" | "config">>) |
    Required<Recipe>

export interface TrussCheckContextProps{
    meta?: MetaProps
    setMeta: (props: MetaProps)=>void
}

export interface RecipeInfo {
    status: string,
    is_deployed: boolean,
    [key: string]: any
}
export interface RecipeDetailContextProps {
    recipeInfo: RecipeInfo,
    load: (id: number, timestamp_year: number)=>Promise<void>

}

export const RecipeDetailContext = React.createContext<RecipeDetailContextProps | undefined>( undefined)

export const RecipeDetailContextProvider:React.FunctionComponent<PropsWithChildren<any>> = (props)=>{
    const [recipeInfo, setRecipeInfo] = React.useState<RecipeInfo>({status: 'started', is_deployed: false})
    //fix tree
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(()=>{
        setRecipeInfo({status: 'started', is_deployed: false})
    }, [location])

    const load = React.useCallback(async (id: number, timestamp_year: number) => {
        
        if(id == undefined){
            const pathParts = location.pathname.split("/").slice(3); // Split and remove the first two elements ("/view" and "truss-check")
            const [project_name, truss, config, version] = pathParts;
            const resdata = await getRecipeForRedirect({truss_label:truss,project:project_name,friendly_name:config})
            if(resdata.status === StatusCodes.OK )
            {
                const newState = {
                    config: resdata.data.configuration,
                    recipe: resdata.data.id,
                    timestamp_year: resdata.data.timestamp_year,
                    truss: resdata.data.truss_id,
                    ...(location.state || {})
                };
                location.state =newState;
                if(Number(version) !== Number(resdata.data.version))//,navigate(url,{ state: newState });
                    navigate(`/view/truss-check/${project_name}/${resdata.data.truss_label}/${config}/${resdata.data.recipe_version}`,{ state: newState })
                id = resdata.data.id;
                timestamp_year = resdata.data.timestamp_year
                
            } 
        }
        if(id !==undefined && timestamp_year !== undefined)
        {
            const res = await getRecipeDetails({id, timestamp_year})
            if(res.status === StatusCodes.OK){
                setRecipeInfo(res.data)
            }
        }
            
    }, [location]);

    return <RecipeDetailContext.Provider value={{recipeInfo, load}}>
        {props.children}
    </RecipeDetailContext.Provider>
}

export interface ErrorSelectionRequest{
    id: any,
    key:any,
    message:string,
    className:string,
    type:string
}

export interface ErrorSelection{
    selectedComponent: any,
    req: ErrorSelectionRequest
    set: (req?: ErrorSelectionRequest)=>Promise<void>
    clear: (req?: any)=>Promise<void>
}
export enum ErrorType {
    Error = 'error',
    Warning = 'warning',
}
export interface ErrorClearRequest{
    type: ErrorType;
}
export const ErrorSelectionContext: React.Context<ErrorSelection| undefined> = React.createContext<ErrorSelection| undefined>(undefined)


export const ErrorSelectionContextProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const [selectedComponent, setSelectedComponents] = React.useState<{ id: any; isClicked: boolean ,message:string ,className:string ,type:string}[]>([]);
    const location = useLocation();

    const load = React.useCallback(async (req?: ErrorSelectionRequest)=>{
        
        if(req){
            toggleComponentSelection(req)
        }

    }, [])
    const clear = React.useCallback(async (req?: ErrorClearRequest)=>{
        
        switch(req?.type){
            case ErrorType.Error:
            {
                setSelectedComponents((prevSelectedComponents) =>
                    prevSelectedComponents.filter((component) => component.type !== ErrorType.Error)
                );
                break;
            }   
            case ErrorType.Warning:
            {
                setSelectedComponents((prevSelectedComponents) =>
                    prevSelectedComponents.filter((component) => component.type !== ErrorType.Warning)
                );
                break;
            }
            default:
                setSelectedComponents([])
                break;
        }
    }, [])

    React.useEffect(()=>{
        setSelectedComponents([])
    }, [location])


    const toggleComponentSelection = (componentId: any) => {
      setSelectedComponents((prevSelectedComponents) => {
        const componentIndex = prevSelectedComponents.findIndex((item) => (item.id === componentId.id && item.type === componentId.type));
        const updatedComponents = [...prevSelectedComponents];
        if (componentIndex !== -1 ) {
          // Component is already in the list, toggle its clicked status
            if(updatedComponents[componentIndex].type == componentId.type)
            {
                updatedComponents[componentIndex].isClicked = !updatedComponents[componentIndex].isClicked;
                console.log("updatedComponents", updatedComponents);
                return updatedComponents;
            }else
            {
                return [...prevSelectedComponents, { id: componentId.id,key:componentId.key, isClicked: true ,message:componentId.message ,className:componentId.className ,type:componentId.type}];
            }          
        } else {
          // Component is not in the list, add it with isClicked set to true
          return [...prevSelectedComponents, { id: componentId.id,key:componentId.key, isClicked: true ,message:componentId.message ,className:componentId.className ,type:componentId.type}];
        }
      });
      
    };
    return <ErrorSelectionContext.Provider value={{selectedComponent:{...selectedComponent}, set: load ,clear:clear}}>
        {props.children}
    </ErrorSelectionContext.Provider>

   
  };

export interface LayerSelectionRequest{
    req: any
}
export interface LayerSelection{
    selectedComponent: any,
    req: LayerSelectionRequest
    set: (req?: LayerSelectionRequest)=>Promise<void>
    clear: (req?: any)=>Promise<void>
}


export const LayerSelectionContext: React.Context<LayerSelection| undefined> = React.createContext<LayerSelection| undefined>(undefined)


export const LayerSelectionContextProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const [selectedComponent, setSelectedComponents] = React.useState<string[]>([]);
    const location = useLocation();

    const load = React.useCallback(async (req?: LayerSelectionRequest)=>{
        
        if(req){
            req?.id?.forEach((layerId) => {
                toggleComponentSelection(layerId);
            });
        }

    }, [])
    const clear = React.useCallback(async (req?: LayerSelectionRequest) => {
        
        if (req) {
            // If selectAll is 0, iterate through each category
            const newSelectedComponents = [];
            for (const category in req) {
                if (req.hasOwnProperty(category)) {
                    const categoryData = req[category];
                    if (categoryData.selectAll === 0) {
                        // If selectAll is 0 for this category, process its data
                        categoryData.data.forEach((item) => {
                            if (item.selected === 0) {
                                newSelectedComponents.push(item.id);
                            }
                        });
                    }
                }
            }
    
            setSelectedComponents(newSelectedComponents);
        }
    }, []);

    React.useEffect(()=>{
        setSelectedComponents([])
    }, [location])


    const toggleComponentSelection = (componentId: string) => {
        setSelectedComponents((prevSelectedComponents) => {
            if (prevSelectedComponents.includes(componentId)) {
                // If the component ID is already in the list, remove it
                
                return prevSelectedComponents.filter((id) => id !== componentId);
            } else {
                // If the component ID is not in the list, add it
                
                return [...prevSelectedComponents, componentId];
            }
        });
    };
    return <LayerSelectionContext.Provider value={{selectedComponent:[...selectedComponent], set: load ,clear:clear}}>
        {props.children}
    </LayerSelectionContext.Provider>

   
  };


export interface ProjectTreeCollapseContextProps {
    collapse : boolean,
    setCollapse: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

export const ProjectTreeCollapseContext: React.Context<ProjectTreeCollapseContextProps> = React.createContext({collapse: false, setCollapse: ()=> {}})

export const ProjectTreeCollapseContextProvider: React.FC<PropsWithChildren<{}>> = (props) => {
    const [collapse, setCollapse] = React.useState<boolean>(false)

    return <ProjectTreeCollapseContext.Provider value={{collapse, setCollapse}}>
        {props.children}
    </ProjectTreeCollapseContext.Provider>
}

export const FileUploadSettingContext: React.Context<{isFileUploadEnabled: boolean}> = React.createContext({isFileUploadEnabled: false})

export const FileUploadSettingContextProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const [isFileUploadEnabled, setIsFileUploadEnabled] = useState<boolean>(false)

    React.useEffect(() => {
        fetchSettings().then((res:any) => {
            if (res?.status === 200) {
                const {data: {is_file_upload_mode_enabled}} = res;
                setIsFileUploadEnabled(is_file_upload_mode_enabled === 'true')
            } else {
                notification.error({message: <Trans i18nKey={`notification.fetch_setting_error`} defaults={'Error in fetching system settings.'}/>})
                setIsFileUploadEnabled(false);
            }
        })
    },[])

    return <FileUploadSettingContext.Provider value={{isFileUploadEnabled}}>
        {props.children}
    </FileUploadSettingContext.Provider>
}

export function convertMMtoInchesIfPreferred(value: number, unitType: string) {
    if(unitType === UnitType.metric) {
        return `${value} mm`
    } else {
        const inches = value * 0.0393701;
        return `${inches} "`
    }
}

export function convertInchesToMMIfPreferred(value: any, unitType: string) {
    if(unitType === UnitType.imperial) {
        return `${value} "`
    } else {
        const millimeters = value / 0.0393701;
        return `${millimeters} mm`
    }
}

export function convertKGToLbIfPreferred(value: any, unitType: string) {
    if(unitType === UnitType.metric) {
        return `${value} kg`
    } else {
        const pounds = value * 2.20462
        return `${pounds} lb`
    }
}

export interface TreeNode {
    key: string,
    children: TreeNode,
    [key: string]: any
}

export function getAllKeys(project: string, rows: any) {
    const keys: string[] = [];

    function traverse(node: TreeNode) {
        if (node.key) {
            keys.push(node.key);
        }

        if (node.children && node.children.length > 0) {
            node.children.forEach(child => {
                traverse(child);
            });
        }
    }
    const node = rows.find((o: any) => o.title === project)
    if (node) {

        traverse(node);
    }

    return keys;
}