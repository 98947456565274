/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {Fragment, PropsWithChildren, useState} from "react";
import {Table} from "@q4us-sw/q4us-ui-v2/dist/table";
import {createERPClient, getFeConfig, loadERPClients, updateERPClient} from "../../api";
import {DataContext, DataContextProvider, Request, Response} from "@q4us-sw/q4us-ui-v2/dist/util";
import {Button, Modal, notification, Space} from "antd";
import {AuthContext} from "./AuthProvider";
import {Form} from "@q4us-sw/q4us-ui-v2/dist/form";
import {DefaultArgs} from "@q4us-sw/q4us-ui-v2/dist/form/FormV2";
import {Formatter} from "@q4us-sw/q4us-ui-v2";
import "../../styles/ERPClients.css";
import {inlineFilterMap} from "../../util/mapping";
import {EnumFilter} from "@q4us-sw/q4us-ui-v2/dist/table/filters/Enum";

import {Trans} from "../reactive/Trans";
interface RegistrationProps{
    title: string,
    btnText: string,
    schema: string,
    client_id?: string
    row?: any,
    clientData?: [key: string][],
    onDataSubmitted:any
}

interface RegistrationModalProps{
    title: string,
    schema: string,
    open: boolean,
    closeCallback: () => void,
    client_id?: string
    row?: any,
    clientData?: [key: string][],
    onDataSubmitted:any
}

const RegistrationModal: React.FunctionComponent<RegistrationModalProps> = (props)=>{
    let clientData  = props.clientData;

    return <Fragment>
        <AuthContext.Consumer>
            {auth => <Modal
                title={props.title}
                open={props.open}
                onCancel={() => props.closeCallback()}
                footer={null}
                destroyOnClose>
                {/* @ts-ignore */}
                <Form register={(reg) => reg}
                      {...DefaultArgs}
                      formatRegister={(reg: any) => reg}
                      layout={'horizontal'}
                      schema={'erp_client_form'}
                      config={async (schema: string) => {
                          const res = await getFeConfig(schema) || {}
                          const {data: {rows = []} = {}} = res;
                          return rows;
                      }}
                      visibilityValidator={(schema: string, element: any, form: any) => {
                          return !(props.row && element.name === 'client_id');
                      }}
                      submit={async (values:{ [key: string]: any }) => {
                          let response

                          if(props.row) {
                              const req = {
                                  ...values,
                                  client_id: clientData?.client_id
                              }
                              response = await updateERPClient(req)
                          } else {
                              response = await createERPClient(values)
                          }
                          const {data = {}} = response;
                          if(data?.statusCode===200){
                              props.closeCallback();
                              notification.success({message: <Trans i18nKey={'erp_client.form.Client_created/updated_successfully'} defaults={"Client created/updated successfully"}/>})
                              const d = props.onDataSubmitted;

                              {await d.fetch?.({
                                  sort: d.sort,
                                  filter: d.filter
                              } as Request)}
                              return data;
                          } else{
                              notification.error({message: <Trans i18nKey={'erp_client.form.Client_created/updated_failed'} defaults={"Client creation/update failed"}/>})
                          }

                      }}
                      initialValue={clientData}
                      disableAccept={true}
                />
            </Modal>
            }
        </AuthContext.Consumer>
    </Fragment>
}

const ClientRegistration: React.FunctionComponent<RegistrationProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false)

    return <Fragment>
        <Button onClick={() => setOpen(true)}>{props.btnText}</Button>
        <RegistrationModal
            title={props.title}
            schema={props.schema}
            open={open}
            closeCallback={()=>setOpen(false)}
            client_id={props?.client_id}
            row={props?.row}
            clientData={props.clientData}
            onDataSubmitted={props.onDataSubmitted}
        />
    </Fragment>
}


export const ERPClients: React.FunctionComponent<any> = (props) => {

    const registerEnumFilterWithEnums = (enums: any) => (props: any) => (
        <EnumFilter {...props} enums={enums} />
    );

    const EditClient: Formatter = (props)=> {
        return <React.Fragment>
            <DataContext.Consumer>
                {d=> <Space>
                    <ClientRegistration
                        title={<Trans i18nKey={'erp_client.form.ERP_Client_Edit'} defaults={"ERP Client - Edit"}/>}
                        btnText={<Trans i18nKey={'button.erp_clients.Update'} defaults={"Update"}/>}
                        schema={'erp_client_form'}
                        client_id={d.rows[props.row].client_id}
                        row={d.rows[props.row]}
                        clientData={d.rows[props.row]}
                        onDataSubmitted={d}
                    />
                </Space>}
            </DataContext.Consumer></React.Fragment>}

    return <React.Fragment>
        <div>
            <DataContext.Consumer>
                {data => <div className={"client-action-container"}>
                    <ClientRegistration
                        title={<Trans i18nKey={'erp_client.form.Create_New_ERP_Client'} defaults={"Create New ERP Client"}/>}
                        btnText={<Trans i18nKey={'button.erp_clients.Add_New_Client'} defaults={"Add New Client"}/>}
                        schema={'erp_client_form'}
                        clientData={props.clientData}
                        onDataSubmitted={data}
                    />
                </div>}
            </DataContext.Consumer>
            <DataContext.Consumer>
                {data => <div className={"table-container"}>
                        <Table key={'erp_client'}
                               schema="erp_client"
                               size="small"
                               rowKey={"client_id"}
                               scroll={{y: 'calc(100vh - 128px)'}}
                               register={(registry) =>
                                   registry.register("FILTER__BOOLEAN",registerEnumFilterWithEnums([ ["true", <Trans i18nKey={`StatusIcon.ACTIVE`} defaults={"ACTIVE"} />],["false", <Trans i18nKey={`StatusIcon.INACTIVE`} defaults={"INACTIVE"} />],]))
                               }
                               formatRegister={(registry) => {
                                   registry.register("ACTION", EditClient)
                                   return registry
                               }}/>
                    </div>}
            </DataContext.Consumer>
        </div>
    </React.Fragment>
}

export const ERPClientView: React.FunctionComponent<PropsWithChildren<any>> = (props) => {

    return <React.Fragment>
        <DataContextProvider save={async (d) => true} delete={async (d) => true} data={async (req) => {
            return await loadERPClients({
                filter: [...inlineFilterMap(req.inline?.filter || {})],
                sort: req.sort
            }) as Response
        }}>
           <ERPClients/>
        </DataContextProvider>
    </React.Fragment>
}