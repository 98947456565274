/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import {AngleAdjustment, Configuration, HomePosition, TimberFlags, ToolFlags} from "./def";
import {useLocation} from "react-router-dom";
import {IGrabber} from "./svg-layers/ChordGrabber";
import {ISideTool, SideToolTypes} from "./svg-layers/SideTool";
const hull = require('hull.js')

export interface RecipeContextProps {
    recipe: {[key: string]: any}
}

export interface Recipe {
    recipe:{[key: string]: any}
}

export interface RecipeContextProviderProps {
    getRecipe: ()=> Promise<any>
    recipeState: String;
    isRecipeNeed:boolean;
    ignore:boolean;
}


export interface ConfigContextProviderProps {
    getConfiguration: ()=> Promise<any>
}


export const RecipeContext = React.createContext<RecipeContextProps | undefined>( undefined)

export const RecipeContextProvider:React.FunctionComponent<PropsWithChildren<RecipeContextProviderProps>> = (props)=>{
    const [recipe, setRecipe] = React.useState<RecipeContextProps | undefined>(undefined)
    const [loadRecipe,setLoadRecipe] = React.useState(false);
    const location = useLocation()

    const load = React.useCallback(async ()=>{

        const res = await props.getRecipe().catch(e=> {
            console.error(e)
            setRecipe(undefined)
        }) || {}
        setRecipe( res.data)
    },[props])

    React.useEffect(()=>{
        if(!loadRecipe)
        {
            setLoadRecipe(true) 
        }             
    }, [recipe])

    React.useEffect(()=>{
        if(props.recipeState === "completed" && props.isRecipeNeed)
        {
            if(location?.state?.recipe || props.ignore)
                load().catch(e=>console.error(e));
        }else
        {
            setRecipe(undefined)
            setLoadRecipe(false)
        }          
    }, [props.isRecipeNeed, props.recipeState, props.ignore])

    return <RecipeContext.Provider value={{recipe ,loadRecipe}}>
        {props.children}
    </RecipeContext.Provider>
}

export class LayerRegistry {
    protected layers: string[] = []
    register(layer: string): LayerRegistry {
        this.layers.push(layer)
        return this;
    }
    getLayers() {
        return this.layers
    }
}

export const LayerRegistryContext = React.createContext<LayerRegistry | undefined>( undefined)

export const LayerRegistryContextProvider:React.FunctionComponent<PropsWithChildren<{ init: (registry: LayerRegistry) => LayerRegistry }>> = (props)=>{
    const [registry, setRegistry] = React.useState<LayerRegistry>()

    React.useEffect(() => {
        setRegistry(props.init(
            new LayerRegistry()
                .register("MIDGRABBER")
                .register("TIMBER_BEAM")
                .register("TIMBER_DIAGONAL")
                .register("NAILPLATE")
        ))
    }, [])

    return <LayerRegistryContext.Provider value={registry}>
        {props.children}
    </LayerRegistryContext.Provider>
}

interface ConfigContextProps {
    configuration: Configuration
}

export const ConfigContext = React.createContext<ConfigContextProps | undefined>( undefined)

export const ConfigContextProvider:React.FunctionComponent<PropsWithChildren<ConfigContextProviderProps>> = (props)=>{
    const [configuration, setConfiguration] = React.useState<ConfigContextProps | undefined>(undefined)

    const load = React.useCallback(async ()=>{
        try {
            const res = await props.getConfiguration()
            setConfiguration({configuration: res.data})
        } catch (e) {
            console.error(e)
            setConfiguration(undefined)
        }
    },[props])

    React.useEffect(()=>{
        load().catch(e=>console.error(e));
    }, [])

    return <ConfigContext.Provider value={configuration}>
        {props.children}
    </ConfigContext.Provider>
}

export interface EditContextProps {
    editMode: boolean,
    setEditMode:  React.Dispatch<React.SetStateAction<boolean>>,

}

const defaultEditContextValue: EditContextProps = {
    editMode: false,
    setEditMode(value: ((prevState: boolean) => boolean) | boolean): void {
    }
};

export const EditContext = React.createContext<EditContextProps>( defaultEditContextValue)

export const EditContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {

    const [editMode, setEditMode] = React.useState(false)

    return <EditContext.Provider value={{editMode, setEditMode}}>
        {props.children}
    </EditContext.Provider>
}

export function isSelectedByIdList(idList:any, errCtx:any) {
    return idList.some((id:any) => {
      const selectedObject = Object.values(errCtx?.selectedComponent || {}).find(
        (comp) => {
          const compKey = (comp as { key?: string, isClicked?: boolean })?.key;
          return compKey === id && (comp as { key?: string, isClicked?: boolean })?.isClicked === true;
        }
      )as { key?: string; isClicked?: boolean };
      return    selectedObject ? selectedObject?.isClicked : false;
    });
}

export function convertVertices(vertices: {x:number, y:number}[]) {
    return vertices.map(point => `${point.x},${point.y}`).join(' ')
}

export function convertPointsToArray(points: string) {
    return points.split(' ').map(pair => {
        return pair.split(',').map(Number)
    })
}

export function rotatePoint(x:number, y:number, angle:number) {
    const newX = x * Math.cos(angle) - y * Math.sin(angle)
    const newY = x * Math.sin(angle) + y * Math.cos(angle)
    return [newX, newY]
}

export function rotatePolygonPoints(points: [number, number][], angle:number) {
    return points.map(point => {
        const [x, y] = point
        return  rotatePoint(x, y, angle)
    });
}

export function moveCenterTo(points: [number, number][], x:number, y:number) {
    return points.map(point => ([point[0]+ x, point[1]+ y]))
}

export function calculateConvexHull(points:[number, number][]) {
    return hull(points, Infinity)
}

export function convertVerticesToString(vertices: [number, number][]) {
    return vertices.map(point => point.join(',')).join(' ')
}

export function calculateCenterPoint(points: [number, number][]) {
    return {
        centerX: points.reduce((sum, p) => sum + p[0], 0) / points.length,
        centerY: points.reduce((sum, p) => sum + p[1], 0) / points.length
    }
}

export function calculateVerticesOnOrigin(points: [number, number][], centerX: number, centerY: number) {

    return points.map(point => ({
        x: point[0] - centerX,
        y: point[1] - centerY
    }))
}

export function getSideToolAngleForVisualize(type: number, angle: number) {
    const angleByDegree = angle * (180 / Math.PI)
    switch (type) {
        case SideToolTypes.RightSideSuctionGrabber:
            return angleByDegree + AngleAdjustment.RightSideSuctionGrabber
        case SideToolTypes.LeftSideSuctionGrabber:
            return angleByDegree + AngleAdjustment.LeftSideSuctionGrabber
        default:
            return angleByDegree
    }
}

export function getAllSpikeGrabbers (recipe: {[key: string]: any}, configuration: Configuration) {
    let usedSpikeGrabbers: any[], usedSpikeGrabberIds: any[];

    if (recipe.Grabbers && recipe.Grabbers.length > 0) {
        usedSpikeGrabbers = recipe.Grabbers.filter((tool:any)=> tool.type === ToolFlags.MIDGRABBER)
        usedSpikeGrabberIds = usedSpikeGrabbers.map((tool)=> tool.id)
    } else {
        usedSpikeGrabbers = [];
        usedSpikeGrabberIds = [];
    }

    const upperHomeSpikeGrabbers = configuration.walldata.WallSetup.HomePositionsOfUpperSpikesGrabbers?.reduce((acc: IGrabber[], tool: HomePosition)=>{
        if (!usedSpikeGrabberIds.includes(tool.GrabberID)) {
            acc.push({
                angle: tool.HomeAngle,
                disabled: tool.disabled,
                id: tool.GrabberID,
                timberId: -1,
                type: ToolFlags.MIDGRABBER,
                x: tool.HomeX,
                y: tool.HomeY
            })
        }
        return acc

    },[])
    const downHomeSpikeGrabbers = configuration.walldata.WallSetup.HomePositionsOfDownSpikesGrabbers.reduce((acc: IGrabber[], tool: HomePosition)=>{
        if (!usedSpikeGrabberIds.includes(tool.GrabberID)) {
            acc.push({
                angle: tool.HomeAngle,
                disabled: tool.disabled,
                id: tool.GrabberID,
                timberId: -1,
                type: ToolFlags.MIDGRABBER,
                x: tool.HomeX,
                y: tool.HomeY
            })
        }
        return acc

    },[])
    // @ts-ignore
    return ([...usedSpikeGrabbers, ...upperHomeSpikeGrabbers, ...downHomeSpikeGrabbers])
}

export function getAllBeamGrabbers (recipe: {[key: string]: any}, configuration: Configuration) {
    let usedBeamGrabbers: any[], usedBeamGrabberIds: any[];

    if (recipe.Grabbers && recipe.Grabbers.length > 0) {
        usedBeamGrabbers = recipe.Grabbers.filter((tool:any)=> tool.type !== ToolFlags.MIDGRABBER)
        usedBeamGrabberIds = usedBeamGrabbers.map((tool)=> tool.id)
    } else {
        usedBeamGrabbers = []
        usedBeamGrabberIds = []
    }
    const upperHomeBeamGrabbers = configuration.walldata.WallSetup.HomePositionsOfUpperBeamGrabbers?.reduce((acc: IGrabber[], tool: HomePosition)=>{
        if (!usedBeamGrabberIds.includes(tool.GrabberID)) {
            acc.push({
                angle: tool.HomeAngle,
                disabled: tool.disabled,
                id: tool.GrabberID,
                timberId: -1,
                type: ToolFlags.UPPER_BEAM_GRABBER,
                x: tool.HomeX,
                y: tool.HomeY
            })
        }
        return acc

    },[])
    const downHomeBeamGrabbers = configuration.walldata.WallSetup.HomePositionsOfDownBeamGrabbers.reduce((acc: IGrabber[], tool: HomePosition)=>{
        if (!usedBeamGrabberIds.includes(tool.GrabberID)) {
            acc.push({
                angle: tool.HomeAngle,
                disabled: tool.disabled,
                id: tool.GrabberID,
                timberId: -1,
                type: ToolFlags.DOWN_BEAM_GRABBER,
                x: tool.HomeX,
                y: tool.HomeY
            })
        }
        return acc

    },[])

    return ([...usedBeamGrabbers, ...upperHomeBeamGrabbers, ...downHomeBeamGrabbers])
}

export function getAllSideTools (recipe: {[key: string]: any}, configuration: Configuration) {
    const usedSideTools = recipe.SideTools || []
    const usedSideToolIds = usedSideTools.map((tool)=> tool.id)
    const homeSideTools = configuration.walldata.WallSetup.HomePositionsOfSideTools?.reduce((acc: ISideTool[], tool: HomePosition)=>{
        if (!usedSideToolIds.includes(tool.ToolID)) {
            acc.push({
                angle: tool.HomeAngle,
                disabled: tool.disabled,
                id: tool.ToolID,
                timberId: -1,
                type: tool.Type,
                x: tool.HomeX,
                y: tool.HomeY
            })
        }
        return acc

    },[])

    return ([...usedSideTools, ...homeSideTools])
}

export function getAllGrabbers(recipe: {[key: string]: any}, configuration: Configuration) {
    const allSpikeGrabbers = getAllSpikeGrabbers(recipe, configuration)
    const allBeamGrabbers = getAllBeamGrabbers(recipe, configuration)

    return ([...allSpikeGrabbers, ...allBeamGrabbers])
}

export const Flags = {
    is: (type, flag) => ((type & flag) > 0)
};

export function isFreeTransformEnabledTool (toolType : number) {
    return (Flags.is(toolType,ToolFlags.MIDGRABBER)|| Flags.is(toolType,ToolFlags.BEAMGRABBER))
}

export function ToolTimberCompatible (toolType: number,timberType: number) {
    return (Flags.is(toolType, ToolFlags.UPPER_BEAM_GRABBER) && Flags.is(timberType, TimberFlags.UPPER_BEAM)) ||
        (Flags.is(toolType, ToolFlags.DOWN_BEAM_GRABBER) && Flags.is(timberType, TimberFlags.DOWN_BEAM)) ||
        (Flags.is(toolType, ToolFlags.MIDGRABBER) && Flags.is(timberType, TimberFlags.DIAGONAL))
}

export function computeAngleForTool(toolType:number, timberAngle: number, toolAngle: number) {
    if(Flags.is(toolType, ToolFlags.MIDGRABBER)){
        return (timberAngle > 0) ? (timberAngle - (Math.PI/2)) : (timberAngle + (Math.PI/2))
    } else if(Flags.is(toolType, ToolFlags.BEAMGRABBER)){
        return toolAngle;
    }
    else{
        return 0;
    }
}

/// @brief Get intersection from two lines predefined with start/end points
/// @param x1 Line one start x
/// @param y1 Line one start y
/// @param x2 Line one end x
/// @param y2 Line one end y
/// @param x3 Line two start x
/// @param y3 Line two start y
/// @param x4 Line two end x
/// @param y4 Line two end y
/// @param intersectionToReturn Return intersection x,y in this object reference
/// @return If True, intersection is found and references are valid. Otherwise no intersection and return reference values are not valid
export const GetIntersection = (x1:number, y1:number, x2:number, y2:number, x3:number, y3:number, x4:number, y4:number, intersectionToReturn:{x:number, y:number})=>{
    let d = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4)
    intersectionToReturn.x = -1000
    intersectionToReturn.y = -1000
    if (Math.abs(d) < 0.00001) {
        return false
    }

    // Get the x and y
    let pre = (x1 * y2 - y1 * x2)
    let post = (x3 * y4 - y3 * x4)
    let x = (pre * (x3 - x4) - (x1 - x2) * post) / d
    let y = (pre * (y3 - y4) - (y1 - y2) * post) / d

    // Check if the x and y coordinates are within both lines
    let epsilon = 1e-5
    if (x < (Math.min(x1, x2) - epsilon) || x >(Math.max(x1, x2) + epsilon) || x < (Math.min(x3, x4) - epsilon) || x >(Math.max(x3, x4) + epsilon)) {
        return false
    }
    if (y < (Math.min(y1, y2) - epsilon) || y >(Math.max(y1, y2) + epsilon) || y < (Math.min(y3, y4) - epsilon) || y >(Math.max(y3, y4) + epsilon)) {
        return false
    }

    intersectionToReturn.x = x
    intersectionToReturn.y = y
    return true
}

/// @brief Get intersections with vertical line
/// @param componentCoordinates All corners of the polygon
/// @param x The vertical line X position to check the intersections
/// @param intersectionPoints All intersection points gathered to this array
/// @param startY From Which Y -level to start look for
/// @param endY To which level of Y to stop looking for
/// @return True, if any intersections
export const  GetIntersectionPointsOfCurrentComponentWithVerticalLine = (componentCoordinates: {x:number, y:number}[], x:number, intersectionPoints:{x:number, y:number}[], startY:number , endY:number)=>
{
    let intersection = {x:-1000, y:-1000}
    let retVal = false
    let i = 0
    for (; i < componentCoordinates.length - 1; i++) {
        if (GetIntersection(x, startY, x, endY, componentCoordinates[i].x, componentCoordinates[i].y, componentCoordinates[i + 1].x, componentCoordinates[i +1].y, intersection))
        {
            intersectionPoints.push({x:intersection.x, y:intersection.y})
            retVal = true
        }
    }

    if (GetIntersection(x, startY, x, endY, componentCoordinates[i].x, componentCoordinates[i].y, componentCoordinates[0].x, componentCoordinates[0].y, intersection)) {
        intersectionPoints.push({x:intersection.x, y:intersection.y})
        retVal = true
    }

    return retVal
};

export function getCartTypeInUse(configuration: Configuration) {
    const cartsInUse = configuration.calculationconfig.configuration.ProdLineConfiguration.LineConfiguration.RemoveCarts.CartsInUse

    switch (cartsInUse) {
        case 3:
            return "ThreeCarts"
        case 4:
            return "FourCarts"
        default:
            return undefined
    }
}

export function getRemoveDirection(configuration: Configuration) {
    return configuration.calculationconfig.configuration.ProdLineConfiguration.LineConfiguration.RemoveTrack.RemoveToLeft ? "RemoveToLeft" : "RemoveToRight"
}