/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {Modal, Button, Form, Input, notification} from "antd";
import {loadAllProjects, reportError} from "../../../api";
import {StatusCodes} from "http-status-codes";
import Dragger from "antd/es/upload/Dragger";
import {InboxOutlined} from "@ant-design/icons";
import JSZip from "jszip";
import CustomDropDown from "./CustomDropDown"

export const ErrorReportModal: React.FunctionComponent<any> = (props) => {

    const [form] = Form.useForm();
    const [defaultConfig, setDefaultConfig] = React.useState<any>(props.config);
    const {REACT_APP_BUILD_VERSION = 'Dev Version'} = process.env;
    const [project, setProject] = React.useState<number>();
    const [truss, setTruss] = React.useState<number>();
    const [defaultValues, setDefaultValues] = React.useState<any>({
        values: {project: undefined, truss: undefined, recipe: undefined}, labels: {}
    });

    React.useEffect(()=>{
        form.resetFields();
        setDefaultConfig(props.config);
        if (props.defaultValues) setDefaultValues(props.defaultValues)
    },[props.config, props.defaultValues, form])

    return <React.Fragment>
        <Modal
            title={"Report Error"}
            open={props.open}
            onCancel={() => props.closeCallback()}
            footer={null}
            style={{ maxWidth: 600 }}
            destroyOnClose
            keyboard={false}
        >
        <div>
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={async (values)=>{

                    const {summary, description, build_version, default_config, attachments, project, truss, recipe} = values

                    const formData = new FormData();
                    formData.append('summary', summary);
                    formData.append('description', description);
                    formData.append('build_version', build_version);
                    formData.append('default_config', default_config);
                    formData.append('default_config_internal', defaultConfig?.production_line || null);
                    // For generic error reporting
                    if (!defaultValues.values.project) {
                        formData.append('project', project);
                        formData.append('truss', truss);
                        formData.append('recipe', recipe);
                    } else { // For specific error reporting
                        formData.append('project', defaultValues.values.project);
                        formData.append('truss', defaultValues.values.truss);

                        if (defaultValues.values.recipe === 'undefined') {
                            formData.append('recipe', recipe);
                        } else {
                            formData.append('recipe', defaultValues.values.recipe);
                        }
                    }


                    const zip = new JSZip();
                    if (attachments && attachments.fileList.length > 0) {
                        for (let file of attachments.fileList) {
                            zip.file(file.name, file.originFileObj);
                        }

                    } else {
                        // Adding a text file as default
                        const indexFile = new Blob(
                            [`Build version of the Recipe Manager System: ${build_version}. \nDefault config: ${default_config}`],
                            {type: 'text/plain'});
                        zip.file('default', indexFile);
                    }
                    await zip.generateAsync({type:"blob",compression: "DEFLATE"}).then(function(content) {
                        const zipFile = new File([content], 'attachments.zip', {type: "zip"});
                        formData.append('file', zipFile);
                    });

                    const {data: {statusCode} = {}} = await reportError(formData)
                    if (statusCode===StatusCodes.OK) {
                        notification.success({message: 'Error reported successfully.'})
                        form.resetFields();
                        props.closeCallback()
                    }
                }}>
                <Form.Item name={'summary'} label="Error Summary" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={'description'} label="Description" rules={[{ required: true }]}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item name={'build_version'} label="Build Version" initialValue={REACT_APP_BUILD_VERSION}>
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item name={'default_config'} label="Default Configuration" initialValue={defaultConfig?.friendly_name}>
                    <Input disabled={true}/>
                </Form.Item>
                {defaultValues.values.project && <Form.Item name={'project'} label="Project" initialValue={defaultValues?.labels.project}>
                    <Input disabled={true}/>
                </Form.Item>}
                {defaultValues.values.truss && <Form.Item name={'truss'} label="Truss" initialValue={defaultValues.labels?.truss}>
                    <Input disabled={true}/>
                </Form.Item>}
                {defaultValues.values.recipe && defaultValues.values.recipe !== 'undefined' && <Form.Item name={'recipe'} label="Recipe" initialValue={defaultValues.labels?.recipe}>
                    <Input disabled={true}/>
                </Form.Item>}
                {!defaultValues.values.project && <Form.Item name={'project'} label="Project">
                    <CustomDropDown
                        name={'project'}
                        schema={'project'}
                        tableName={'project'}
                        initiallyDisabled={false}
                        enum_map={['project_id', 'project_name']}
                        onChange={(value)=> {
                            setProject(value)
                            setTruss(undefined)
                            form.resetFields(['truss', 'recipe'])
                        }}
                        fetch={loadAllProjects}
                    />
                </Form.Item>}
                {!defaultValues.values.truss &&<Form.Item name={'truss'} label="Truss">
                    <CustomDropDown
                        name={'truss'}
                        schema={'truss'}
                        initiallyDisabled={true}
                        tableName={'truss_details'}
                        enum_map={['id', 'truss']}
                        filter={'project_id'}
                        filterValue={project}
                        onChange={(value)=> {
                            setTruss(value)
                            form.resetFields(['recipe'])
                        }}
                    />
                </Form.Item>}
                {!defaultValues.values.recipe && <Form.Item name={'recipe'} label="Recipe">
                    <CustomDropDown
                        name={'recipe'}
                        schema={'recipe'}
                        initiallyDisabled={true}
                        tableName={'recipe_config_view'}
                        enum_map={['id', 'configuration', 'version']}
                        filter={'truss_id'}
                        filterValue={truss}
                    />
                </Form.Item>}
                {defaultValues.values.truss && defaultValues.labels.recipe === 'None' && <Form.Item name={'recipe'} label="Recipe">
                    <CustomDropDown
                        name={'recipe'}
                        schema={'recipe'}
                        initiallyDisabled={true}
                        tableName={'recipe_config_view'}
                        enum_map={['id', 'configuration', 'version']}
                        filter={'truss_id'}
                        filterValue={defaultValues.values.truss}
                    />
                </Form.Item>}
                <Form.Item label="Attachments" name={"attachments"}>
                    <Dragger beforeUpload={() => false}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to add attachments</p>
                    </Dragger>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button htmlType="reset" style={{marginRight:'10px'}}>Reset</Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
        </Modal>
    </React.Fragment>
}