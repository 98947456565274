/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {useCallback, useContext} from "react";
import {ConfigContext, getAllSpikeGrabbers, RecipeContext} from "../util";
import {LayerDefs} from "../def";
import {ErrorSelectionContext, LayerSelectionContext} from "../../trusscheck/util"
import {isSelectedByIdList} from "../util"
import {CollisionContext} from "../collisionDetection/util"
import {IGrabber} from "./ChordGrabber";
import {EditServiceObj} from "../EditService";

export const SpikeGrabber: React.FunctionComponent<any> = ()=> {

    return <ConfigContext.Consumer>
        {ctx => ctx?.configuration.walltoolshapes.SpikeGrabber.DrawingElements.Shapes.map((shape)=>{
            return <polygon key={shape.layer.toLowerCase()} className={shape.layer.toLowerCase()} points={shape.points}/>
        })}
    </ConfigContext.Consumer>
}

export const SpikeGrabberLayer: React.FunctionComponent<{id?:number}> = (props)=> {

    const [allSpikeGrabbers, setAllSpikeGrabbers] = React.useState<IGrabber[]>([])
    const configCtx = useContext(ConfigContext)
    const recipeCtx = useContext(RecipeContext)
    const collisionCtx = useContext(CollisionContext)
    const errCtx = useContext(ErrorSelectionContext)

    React.useEffect(() => {
        if (recipeCtx?.recipe && configCtx?.configuration) {
            const all = getAllSpikeGrabbers(recipeCtx.recipe, configCtx.configuration)
            setAllSpikeGrabbers(all)
        }
    }, [configCtx, recipeCtx])

    const getProperties = useCallback((tool: IGrabber) => {

        const selectedMidGrabberId = `${LayerDefs.MidGrabber}_${tool.id}`;
        const selectedGrabberId = `GRABBER_${tool.id}`;
        const isSelected = isSelectedByIdList([selectedMidGrabberId,selectedGrabberId], errCtx)

        const collide = collisionCtx?.collisions?.has(`${LayerDefs.MidGrabber.toLowerCase()}_${tool.id}`)? "collide" : ""
        const homeMidGrabberClassName = tool.timberId < 0 ? "in-home": ""

        if (props.id === undefined) {
            return{
                'id': `${LayerDefs.MidGrabber.toLowerCase()}_${tool.id}`,
                'data-component': tool.id,
                'data-layer': LayerDefs.MidGrabber,
                'className': `${LayerDefs.MidGrabber.toLowerCase()} ${isSelected ? "error" : ""} ${collide} ${homeMidGrabberClassName}`
            }

        } else return {
            'className': `${LayerDefs.MidGrabber.toLowerCase()}`
        }
    },[props.id, collisionCtx, errCtx])

    const renderTool = useCallback(() => {
        return allSpikeGrabbers.reduce((acc:  JSX.Element[], tool) => {

            const angleByDegrees = tool.angle * (180 / Math.PI)
            const angle = tool.timberId > -1 ? angleByDegrees: angleByDegrees + 90

            const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.MidGrabber.toLowerCase()}_${tool.id}`)

            if ((props.id === tool.id || props.id === undefined) && !isDeleted) {

                acc.push(<g key={`${LayerDefs.MidGrabber}_${tool.id}`}
                            transform={`rotate(${angle}, ${tool.x}, ${tool.y}) translate(${tool.x}, ${tool.y})`}
                    {...getProperties(tool)}
                >
                    <SpikeGrabber/>
                </g>)
            }

            return acc

        }, [])

    },[props.id, allSpikeGrabbers, collisionCtx, errCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
            <g key={LayerDefs.MidGrabber} id={LayerDefs.MidGrabber.toLowerCase()}
               className={`${layerCtx?.selectedComponent.includes(LayerDefs.MidGrabber) && (props.id === undefined) ? "hide-layer" : ""}`}>
            {renderTool()}
        </g>}
        </LayerSelectionContext.Consumer>
}