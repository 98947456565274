/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, useCallback, useContext, useRef, useState} from "react";
import {Button, Col, Divider, Form, Input, Modal, notification, Row, Spin, Tag, Tooltip, Upload} from "antd";
import {Table} from "@q4us-sw/q4us-ui-v2/dist/table";
import {DeleteOutlined, EditOutlined, FolderAddOutlined, InboxOutlined, UploadOutlined} from "@ant-design/icons";
import {DataContext, DataContextProps, DataContextProvider, Request, Response} from "@q4us-sw/q4us-ui-v2/dist/util";
import {deleteProject, deleteTruss, fetchSettings, importProjectWithTrusses, visualizeLoad} from "../api";
import {inlineFilterMap} from "../util/mapping";
import {Formatter} from "@q4us-sw/q4us-ui-v2";
import {unstable_batchedUpdates} from "react-dom";
import {Trans} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {ProjectTreeDataContext} from "./trusscheck/Projects";
import {InvalidFileInfo, TrussImportProgress, TrussProgress, TrussRow} from "./CreateProject";
import JSZip from "jszip";
import {ButtonType} from "./trusscheck/defs";
import {IOContext} from "./io/SocketIO";
import {FileUploadSettingContext} from "./trusscheck/util";


interface EditProjectProps{
    setOpen?: (value: boolean) => void,
    isOpen?: boolean,
    projectSelected?: React.Key | undefined,
    setProjectSelected?: (value: React.Key | undefined) => void,
    buttonType? : ButtonType
}

interface EditProjectState {
    confirmationOpen: boolean,
    deleteProjectConfirm: boolean,
    row: number
}

interface TrussUploadProps {
    projectSelected : string,
    isFileUploadModeEnabled: boolean,
    setDisabled: (value: boolean) => void
    setLoading: (value: boolean) => void
}

const TrussUpload : React.FunctionComponent<PropsWithChildren<TrussUploadProps>> = (props) => {

    const [trusses, setTrusses] = React.useState<TrussRow[]>([])

    const [modal, contextHolder] = Modal.useModal();
    const ctx = useContext(DataContext)
    const hasUploaded = useRef(false);
    const [progress, setProgress] = useState<TrussProgress>({ko: 0, ok: 0, status: "", total: 0});
    const [loading, setLoading] = useState<boolean>(false);
    const IOCtx = React.useContext(IOContext);

    const cancel = React.useCallback(() => {
        setTrusses([])
        hasUploaded.current = false
    }, [])


    const upload = React.useCallback(async (type: string, trussList: TrussRow[], projectName:string) => {
        const formData = new FormData();
        formData.append('project_name', projectName || 'project');
        formData.append('import_type', type);
        formData.append('truss_count', trussList.length.toString());

        const zip = new JSZip();
        for (let trussRow of trussList) {
            const {file} = trussRow
            zip.file(file.name, file);
        }

        await zip.generateAsync({type:"blob",compression: "DEFLATE"}).then(function(content) {
            const zipFile = new File([content],'test.zip',{type: "zip"});
            formData.append('file',zipFile);
            importProjectWithTrusses(formData).then((res) => {
                if (res?.data?.statusCode === 200) {
                    notification.success({message: <Trans i18nKey={`notification.files_successfully_imported`} defaults={'Truss upload started'}/>})

                } else {
                    notification.error({message: res?.data?.message || <Trans i18nKey={`notification.Error`} defaults={'Error'}/>})
                    props.setDisabled(false)
                }
                cancel()
            })
        })
    },[])

    const processFile = React.useCallback((file: any) => {
        return new Promise((resolve, reject) => {
            const readFile = new FileReader()
            readFile.onload = function (e: ProgressEvent<FileReader>) {
                let contents = e.target?.result
                if (contents && typeof contents === "string") {

                    try {
                        const jsonData = JSON.parse(contents)
                        const match = contents.match(/trussInfo/i)
                        if (match) {
                            const truss_info_key = match[0]
                            const {trussLabel, item, order} = jsonData[truss_info_key]

                            if (!trussLabel || !item)
                                reject('Missing truss info')

                            const truss_row: TrussRow = {
                                key: item,
                                project_name: order,
                                truss_label: trussLabel,
                                json: jsonData,
                                file: file.originFileObj,
                                file_name: file.name,
                                item: item
                            }
                            resolve(truss_row)
                        }
                        reject("No trussInfo")
                    } catch (e) {
                        reject(e)
                    }
                }
            }
            readFile.readAsText(file.originFileObj)
        })
    },[])

    const processAllFiles = React.useCallback(async (fileList: any) => {
        const truss_rows: TrussRow[] = []
        const duplicateMap = new Map<TrussRow, TrussRow[]>()
        const invalidFiles: string[] = []

        for (const file of fileList) {
            try{
            const truss_row = await processFile(file)
                if (truss_row){
                    const duplicates = truss_rows.find(row => row.key === truss_row.key)
                    if (!duplicates) {
                        truss_rows.push(truss_row as TrussRow)
                    } else {
                        if (duplicateMap.has(duplicates)) {
                            const d = duplicateMap.get(duplicates);
                            duplicateMap.set(duplicates, [...d, truss_row]);
                        } else {
                            duplicateMap.set(duplicates, [truss_row as TrussRow]);
                        }
                    }
                }
            } catch (e) {
                invalidFiles.push(file.name)
                console.error('Error processing files:', e)
            }
        }
        if (invalidFiles.length > 0 || duplicateMap.size > 0 ) {
            invalidFileInfo(invalidFiles, duplicateMap, truss_rows)
        } else {
            setTrusses(truss_rows)
        }

    },[trusses])

    const invalidFileInfo = React.useCallback((invalidFiles: string[], duplicates: Map<TrussRow, TrussRow[]>, newTrussList: TrussRow[]) => {

        const title = (invalidFiles.length > 0 && duplicates.size > 0)
            ? 'Invalid and duplicate files found'
            : (invalidFiles.length > 0)
                ? 'Invalid files found'
                : 'Duplicate files found';
        Modal.confirm({
            title: <Trans i18nKey={`modal.valid_invalid_truss.title.${title}`} defaults={title} />,
            width: '40vw',
            content: (<InvalidFileInfo invalidFiles={invalidFiles} duplicates={duplicates}/>),
            okText: <Trans i18nKey={"modal.valid_invalid_truss.button.continue"} defaults={"Continue"} />,
            cancelText: <Trans i18nKey={"modal.valid_invalid_truss.button.cancel"} defaults={"Cancel"} />,
            onOk: ()=>{
                setTrusses(newTrussList)
                props.setLoading(false)
                Modal.destroyAll()
            },
            onCancel: ()=>{
                cancel()
                props.setLoading(false)
                Modal.destroyAll()
            }
        });
    }, [])

    const importTrusses = React.useCallback((fileList: any) => {

        if (hasUploaded.current) return
        hasUploaded.current = true
        props.setLoading(true)
        processAllFiles(fileList)

    },[trusses])

    React.useEffect(() => {

        if (trusses.length > 0) {
            const unMatchingTrussesFound = trusses.find((row: TrussRow) => row.project_name !== props.projectSelected)
            if (unMatchingTrussesFound) {

                modal.confirm({
                    width: 600,
                    title: <Trans i18nKey={`force_update_confirm_title`} defaults={'Force update truss projects'}/>,
                    content: <Trans i18nKey={`force_update_confirm_content`} defaults={'There are trusses not related to the project. Please confirm update to project name in the input truss design?'}/>,
                    footer: (
                        <div className={'edit-project-modal'}>
                            <Button key="cancel" onClick={() => {
                                cancel()
                                props.setLoading(false)
                                Modal.destroyAll()
                            }}>
                                Cancel
                            </Button>
                            <Button key="ignore" onClick={() => {
                                props.setLoading(false)
                                setLoading(true)
                                props.setDisabled(true)
                                upload('ignore', trusses, props.projectSelected)
                                Modal.destroyAll()
                            }}>
                                Ignore mismatching trusses
                            </Button>
                            <Button key="ok" type="primary" onClick={() => {
                                props.setLoading(false)
                                setLoading(true)
                                props.setDisabled(true)
                                upload('update', trusses, props.projectSelected)
                                Modal.destroyAll()
                            }}>
                                Update and continue
                            </Button>
                        </div>
                    ),
                })
            }
            else {
                upload('ignore', trusses, props.projectSelected)
            }
        }
    }, [trusses, props.projectSelected]);

    React.useEffect(() => {

        IOCtx.socket?.on(`truss.analysis.${props.projectSelected}`, (data: TrussProgress) => {
            setProgress(data)
            setLoading(false)
            props.setDisabled(true)
            if (data.status === 'completed') {
                notification.success({message: <Trans i18nKey="notification.imported_files_count" values={{ count: data.ok || 0}} defaults="{{count}} files successfully imported." />})
                props.setDisabled(false)
            }
            ctx.fetch?.({
                sort: ctx.sort,
                filter: ctx.filter,
                page: ctx.page
            } as Request)
        })

        return () => {
            IOCtx.socket?.off(`truss.analysis.${props.projectSelected}`)
        }
    }, [IOCtx, props, ctx])

    return <React.Fragment>
        <Spin spinning={loading}>
        <div>
            <Tooltip placement="top" title={(props.isFileUploadModeEnabled) ? "" : "File upload mode disabled by the administration"}>
                <span><Upload.Dragger disabled={(!props.isFileUploadModeEnabled || progress.status === 'processing')}
                                customRequest={()=>{}}
                                fileList={[]}
                                onChange={({fileList})=>{
                                    importTrusses(fileList)}}
                                accept={".json"} multiple={true} showUploadList={false}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to import</p>
                </Upload.Dragger></span>
            </Tooltip>
            {progress.status !== "" && <TrussImportProgress {...progress}/>}
        </div>
        {contextHolder}
        </Spin>
    </React.Fragment>
}

export const EditProject : React.FunctionComponent<PropsWithChildren<EditProjectProps>> = (props) => {

    const [isOpen, setOpen] = React.useState(false)
    const [editProjectState, setEditProjectState] = useState<EditProjectState>({confirmationOpen:false, deleteProjectConfirm: false, row: 0})
    const [disabled, setDisabled] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false);

    const location = useLocation();
    const order = decodeURIComponent(location.pathname.split("/")[3])
    const nav = useNavigate();
    const ctx = React.useContext(ProjectTreeDataContext)
    const {isFileUploadEnabled} = React.useContext(FileUploadSettingContext)

    const close = React.useCallback(async () => {
        setOpen(false);
    }, []);

    const delete_Truss = useCallback(async (data: DataContextProps) => {
        await deleteTruss({
            order: data.rows[editProjectState.row].project_name,
            item: data.rows[editProjectState.row].item}).then(res => {
            if (res?.data?.statusCode === 200) {
                notification.success({message: <Trans i18nKey={`notification.deleteTruss_success`} defaults={'Successfully delete the truss.'}/>})
                data.fetch?.({
                    sort: data.sort,
                    filter: data.filter,
                    page: data.page
                } as Request)
                ctx?.setReq({...(ctx?.req || {}), from:0 , type: 'all'})
            } else {
                notification.error({message: <Trans i18nKey={`notification.deleteTruss_error`} defaults={'Error: truss deletion failed.'}/>})
            }
            setEditProjectState((prevState)=>(
                {
                    ...prevState,
                    confirmationOpen: false
                }
            ))
        });
    }, [editProjectState])

    const deleteProjectWithTruss = useCallback(async ()=> {

        await deleteProject({
            order: order}).then(res => {
            if (res?.data?.statusCode === 200) {
                notification.success({message: <Trans i18nKey={`notification.deleteProject_success`} defaults={'Successfully delete the project.'}/>})
                unstable_batchedUpdates(() => {
                    setEditProjectState((prevState)=>(
                        {
                            ...prevState,
                            deleteProjectConfirm: false
                        }
                    ))
                    setOpen(false)
                    nav(location.pathname.split("/").slice(0,3).join("/"))
                    ctx?.setReq({...(ctx?.req || {}), from:0 , type: 'all'})
                })
            } else {
                notification.error({message: <Trans i18nKey={`notification.deleteProject_error`} defaults={'Error: project deletion failed.'}/>})
                setEditProjectState((prevState)=>(
                    {
                        ...prevState,
                        deleteProjectConfirm: false
                    }
                ))
            }
        });
    }, [order])

    const DeleteButton: Formatter = (props)=> {
        return <React.Fragment>
            <Button
                onClick={()=> {
                    setEditProjectState((prevState)=>(
                        {
                            ...prevState,
                            confirmationOpen: true,
                            row: props.row
                        }
                    ))
                }}
                icon={<DeleteOutlined/>}/>
        </React.Fragment>
    }


    return <React.Fragment>
        <Modal
            title={<div><Trans i18nKey={`edit.project.title`} defaults={'Edit Project'}/><Tag>{order}</Tag></div>}
            width={'60vw'}
            open={isOpen}
            onCancel={() => {setOpen(false)}}
            footer={null}
            closable={true}
            destroyOnClose
            maskClosable={false}
        >
            <Spin spinning={loading}>
            <div>
                <Divider style={{marginBottom:10}}/>
                <Form
                    name="basic"
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    <DataContextProvider save={async (d) => true} delete={async (d) => true} data={async (req) => {
                        const res = await visualizeLoad({
                            tableName: 'truss_details',
                            filter: [{name: "project_name", value: order}, ...inlineFilterMap(req.inline?.filter || {})],
                            sort: req.sort,
                        })
                        return res as Response
                    }}>
                        <div>
                            <TrussUpload
                                setLoading={(v)=>setLoading(v)}
                                setDisabled={(v)=>setDisabled(v)}
                                projectSelected={order}
                                isFileUploadModeEnabled={isFileUploadEnabled} />
                        </div>
                        <Divider style={{marginBottom:10, marginTop:10}} />
                        <div className={'table-div'}>

                                <DataContext.Consumer>
                                    {data=><Modal title="Delete truss"
                                                  open={editProjectState.confirmationOpen}
                                                  onOk={async ()=>{
                                                      await delete_Truss(data)}}
                                                  onCancel={()=> {
                                                      setEditProjectState((prevState)=>(
                                                          {
                                                              ...prevState,
                                                              confirmationOpen: false
                                                          }
                                                      ))
                                                  }}
                                                  okText="Delete">
                                        <p><Trans i18nKey={`delete_truss_confirm`} defaults={'Are you sure you want to delete the truss?'}/></p>
                                    </Modal>}

                                </DataContext.Consumer>
                                <Table key={'truss_table'}
                                                    schema="edit_truss"
                                                    size="small"
                                                    rowKey={"id"}
                                                    scroll={{y: 'calc(100vh - 48px - 40px - 24px - 64px - 72px)'}}
                                                    register={(registry) => registry}
                                                    formatRegister={(registry) => registry.register("ACTION", DeleteButton)}/>


                        </div>
                    </DataContextProvider>

                    <Divider style={{marginTop:10}}/>
                    <Row>
                        <Col span={12}>
                            <Tooltip title={disabled ? 'Truss upload in progress' : ''}>
                                <Button style={{margin: 6}}
                                        danger
                                        disabled={disabled}
                                        onClick={()=> {
                                            setEditProjectState((prevState)=>(
                                                {
                                                    ...prevState,
                                                    deleteProjectConfirm: true
                                                }
                                            ))}}>
                                    Discard Project
                                </Button>
                            </Tooltip>
                            <Modal
                                title="Delete project"
                                open={editProjectState.deleteProjectConfirm}
                                onOk={deleteProjectWithTruss}
                                onCancel={()=> {
                                    setEditProjectState((prevState)=>(
                                        {
                                            ...prevState,
                                            deleteProjectConfirm: false
                                        }
                                    ))
                                }}
                            >
                                <span><Trans i18nKey={`delete_project_confirm`} defaults={'Are you sure you want to delete the Project?'}/></span>
                            </Modal>
                        </Col>
                        <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button  style={{margin: 6}} onClick={close}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            </Spin>
        </Modal>
        {props.buttonType === ButtonType.Icon &&
            <Button disabled={location.pathname.split("/").length !== 4} style={{flexShrink: 1}} onClick={()=>setOpen(!isOpen)} icon={<EditOutlined/>}/>
        }
        {props.buttonType === ButtonType.Text &&
            <Button style={{width: 250}} disabled={location.pathname.split("/").length !== 4} icon={<EditOutlined/>} onClick={()=>setOpen(!isOpen)}><Trans i18nKey={'edit.project.button.label'} defaults={"Edit Project"}/></Button>
        }
    </React.Fragment>
}
