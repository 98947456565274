/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {ChangeEvent, PropsWithChildren, useCallback, useContext, useRef} from "react";
import {RemoveComponentControl} from "./RemoveComponentControl";
import {EditServiceObj, IComponent} from "../EditService";
import {CollisionContext} from "../collisionDetection/util";
import {Preview} from "./Preview";
import {AutoCorrectY, FreeTransformControl} from "./FreeTransformControl";
import {Button, Modal} from "antd";
import {ZoomInOutlined} from "@ant-design/icons";
import {ReactSVGPanZoom, Tool, TOOL_PAN, Value} from "react-svg-pan-zoom";
import {CustomToolbar} from "../index";

export const ComponentSidebar: React.FunctionComponent<{selectedComponent: IComponent,setSelectedComponent: React.Dispatch<React.SetStateAction<any>>}> = (props) => {

    const collisionCtx = useContext(CollisionContext)
    const [freeTransformEnabled, setFreeTransformEnabled] = React.useState<boolean| undefined>()
    const [autoCorrectYEnabled, setAutoCorrectYEnabled ] = React.useState<boolean| undefined>()

    React.useEffect(()=>{
        setFreeTransformEnabled(EditServiceObj.getSelectedComponent()?.freeTransform)
        setAutoCorrectYEnabled(EditServiceObj.getSelectedComponent()?.autoCorrectY)
    }, [EditServiceObj.getSelectedComponent()])

    const changeFreeTransform = React.useCallback((e:  ChangeEvent<HTMLInputElement>)=>{
        const selectedComponent = EditServiceObj.getSelectedComponent()
        if (selectedComponent) {
            EditServiceObj.setSelectedComponent({...selectedComponent, freeTransform: e.target.checked })
            EditServiceObj.setFreeTransform(selectedComponent.id, e.target.checked)
            setFreeTransformEnabled(EditServiceObj.getSelectedComponent()?.freeTransform)
        }
    },[])

    const changeAutoCorrectY = React.useCallback((e:  ChangeEvent<HTMLInputElement>)=>{
        const selectedComponent = EditServiceObj.getSelectedComponent()
        if (selectedComponent) {
            EditServiceObj.setSelectedComponent({...selectedComponent, autoCorrectY: e.target.checked })
            EditServiceObj.setAutoCorrectY(selectedComponent.id, e.target.checked)
            setAutoCorrectYEnabled(EditServiceObj.getSelectedComponent()?.autoCorrectY)
        }
    },[])

    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [tool, setTool] = React.useState<Tool>(TOOL_PAN)
    const [viewBox, setViewBox] = React.useState<any>('0, 0, 200, 200')
    const [bBox, setBBox] = React.useState<any>({})

    const viewerRef = useRef<ReactSVGPanZoom> ()
    const previewSvg = useRef()


    const [value, setValue] = React.useState<Value | null>({
        SVGHeight: 0,
        SVGWidth: 0,
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0,
        endX: undefined,
        endY: undefined,
        f: 0,
        focus: false,
        miniatureOpen: false,
        mode: "idle",
        startX: undefined,
        startY: undefined,
        version: 2,
        viewerHeight: 0,
        viewerWidth: 0
    });

    React.useEffect(() => {
        //@ts-ignore
        const bBox = previewSvg.current.children[0].getBBox()
        setBBox(bBox)

    }, [props, previewSvg.current]);

    const handleZoomIn = useCallback(() => {
        viewerRef.current?.zoomOnViewerCenter(1.2);
    }, [viewerRef.current])

    const handleZoomOut = useCallback(() => {
        viewerRef.current?.zoomOnViewerCenter(0.8);
    }, [viewerRef.current])

    const handleCenter = useCallback(() => {
        //@ts-ignore
        const bbox = previewSvg.current.getBBox()
        const r = Math.max(bbox.width/300, bbox.height/ 300)

        const sVGPointX = props.selectedComponent.x
        const sVGPointY = -1 * props.selectedComponent.y
        const zoomLevel = 1 / r

        viewerRef.current?.setPointOnViewerCenter(sVGPointX, sVGPointY, zoomLevel)

    }, [viewerRef.current, props, previewSvg.current ])

    const onClick = useCallback(()=>{
        handleCenter()
        setIsModalOpen(true)

    }, [])

    const onCancel = useCallback(()=>{
        setIsModalOpen(false)
    }, [])

    React.useEffect(() => {
        //@ts-ignore
        const bBox = previewSvg.current.getBBox()
        setViewBox(`${bBox.x} ${bBox.y} ${bBox.width} ${bBox.height}`)

    }, [ bBox]);

    return <div className="component-sidebar">
        <div className="titlebar">
            {EditServiceObj.getSelectedComponent()?.component_id}
        </div>
        <div className="btn-detail">
            <Button
                icon={<ZoomInOutlined />}
                onClick={onClick}/>
        </div>

        <Modal title={EditServiceObj.getSelectedComponent()?.component_id}
               open={isModalOpen}
               footer={<CustomToolbar handleZoomOut={handleZoomOut} handleZoomIn={handleZoomIn} handleCenter={handleCenter}/>}
               onCancel={onCancel}
               centered = {true}
               afterOpenChange={handleCenter}
               width={800}
        >
            <ReactSVGPanZoom
                detectAutoPan={false}
                onChangeTool={tool => setTool(tool)}
                value={value}
                onChangeValue={value => setValue(value)}
                width={750} height={450}
                background={'#FFFFFF'}
                miniatureProps={{
                    position: 'none',
                    background: '',
                    width: 0,
                    height: 0
                }}
                tool={tool}
                toolbarProps={{
                    position: 'none'
                }}
                ref={viewerRef}
                preventPanOutside={false}
            >
                <svg height={300} width={500} id={'detailed-svg'} viewBox={viewBox} className={"svg-item"}>
                    <Preview selectedComponent={EditServiceObj.getSelectedComponent()} showLabel={true}/>
                </svg>
            </ReactSVGPanZoom>
        </Modal>

        <div className="preview" >
            <svg height={180} width={180} id={`${props.selectedComponent.component_id}_preview-svg`} ref={previewSvg} viewBox={viewBox} className={"svg-item"}>
                <Preview selectedComponent={EditServiceObj.getSelectedComponent()} showLabel={false}/>
            </svg>
        </div>
        {EditServiceObj.getSelectedComponent()?.isRemovable && (
            <RemoveComponentControl
                onClick={() => {
                    EditServiceObj.deleteComponent()
                    collisionCtx?.setCollisions(EditServiceObj.getCollisions())
                    props.setSelectedComponent(undefined)
                }}
            />
        )}

        {EditServiceObj.isFreeTransformable(EditServiceObj.getSelectedComponent()) && (
            <FreeTransformControl
                freeTransformEnabled={freeTransformEnabled}
                onChange={changeFreeTransform}
            />
        )}

        {EditServiceObj.isAutoCorrectYEnabled(EditServiceObj.getSelectedComponent()) && (
            <AutoCorrectY
                autoCorrectY={autoCorrectYEnabled}
                onChange={changeAutoCorrectY}
            />
        )}
    </div>
}
