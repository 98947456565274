/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from 'react';
import App from './App';
import "./index.css"
import './styles/index.css'
import "@q4us-sw/q4us-ui-v2/dist/index.css"
import i18n from 'i18next';
import {I18nextProvider} from 'react-i18next'
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom/client";
import {ConfigProvider} from "@q4us-sw/q4us-ui-v2/dist/util";
import {getFeConfig} from "./api";
import {TrussView} from './views/TrussView'
import {SocketIO} from "./components/io/SocketIO";
import {AuthContextProvider} from "./components/user/AuthProvider";
import { LanguageLoader } from './components/user/UserPreference';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
            <AuthContextProvider>
                <SocketIO>
                    <ConfigProvider config={async (s) => {
                        const {data = {rows: []}} = await getFeConfig(s) || {}
                        return data.rows || []
                    }}>
                        <App/>
                    </ConfigProvider>
                </SocketIO>
            </AuthContextProvider>
    </React.StrictMode>
);

reportWebVitals();
