/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {TrussContext} from "./util";
import {Empty, Tree,Select } from "antd";
import {DataNode} from "antd/es/tree";
import {Trans} from "react-i18next";
import './styles/TrussPanel.css'
import {HeaderSelectionContext} from "./util";
import { getConfigurationView } from "../../api";
import { unstable_batchedUpdates } from "react-dom";

interface ConfigDropdownProps {
    configMap: any[]|undefined; // Replace with the actual type of your config_map
    defaultValue: string;
    onChange: (value: string) => void;
}
const ConfigDropdown: React.FC<ConfigDropdownProps> = ({ configMap, defaultValue, onChange }) => {
    

    return (
        <Select style={{ width: 400 }} value={defaultValue} onChange={onChange}>
            <Select.Option value="" disabled>
                <Trans i18nKey={"recipe-view.Select_Wall"} defaults={"Select Wall"}  />
            </Select.Option>
            {configMap?.map((config) => (
                <Select.Option key={config.config} value={config.config}>
                    {config.friendly_name}
                </Select.Option>
            ))}
        </Select>
    );
};
interface WallData {
    production_line: string;
    is_default: boolean;
    friendly_name: string;
    short_name: string;
    wall_dimensions: {
        MaxX: string;
        MaxY: string;
    };
    wall_tool_count: {
        [key: string]: number;
    };
    build_tool_versions: {
        [key: string]: string;
    };
    prod_server_connection: {
        Host: string;
        Port: string;
    };
    config_status: string;
}
export const WallDataViewer: React.FunctionComponent<{wallData: WallData}> = (props)=>{
    const translate = React.useCallback((data: WallData): DataNode[] => {
        let arr: DataNode[] = [];
        
        const info = Object.entries({'Short name' :data.short_name,'Configuration' :data.friendly_name,'Location' :data.prod_server_connection.Host+':' +data.prod_server_connection.Port ,'Max Width' :data.wall_dimensions.MaxX,'Max Height' :data.wall_dimensions.MaxY,
            "Config Status" :data.config_status}).map(([k, v]) => ({
            title: <div className={"tdv-root-entry"}><Trans i18nKey={`truss-view.truss-info.${k}`} defaults={"{{key}} = {{value}}"} values={{ key: k, value: v }} /></div>,
            key: k
        } as DataNode))
        

        arr.push(...info);
        const wallToolCountNode = {
            title: <span className={"tdv-root-entry"}>
                <Trans i18nKey={"recipe-view.Wall_Count"} defaults={"Wall Tool Count"}  />
            </span>,
            key: "Wall Tool Count",
            children: Object.entries(data.wall_tool_count).map(([k, v]) => ({
                title: <div className={"tdv-root-entry"}>
                    <Trans i18nKey={`truss-view.truss-info.${k}`} defaults={"{{key}} = {{value}}"} values={{ key: k, value: v }} />
                </div>,
                key: k
            } as DataNode))
        };
        arr.push(wallToolCountNode);
        const buildtoolversionsNode = {
            title: <span className={"tdv-root-entry"}>
                <Trans i18nKey={"recipe-view.Build_Tool_Version"} defaults={"Build Tool Version"}  />
            </span>,
            key: "Build Tool Version",
            children: Object.entries(data.build_tool_versions).map(([k, v]) => ({
                title: <div className={"tdv-root-entry"}>
                    <Trans i18nKey={`truss-view.truss-info.${k}`} defaults={"{{key}} = {{value}}"} values={{ key: k, value: v }} />
                </div>,
                key: k
            } as DataNode))
        };
        arr.push(buildtoolversionsNode);
        // Add trussInfo to the tree data
        

        return arr;
    }, []);


    return (props.wallData ? (<div className={"tdv-panel"}>
        <div className={"header"} > <Trans i18nKey={"recipe-view.recipe-info-title"} defaults={"Details for {{title}}"} values={{ title: props?.wallData?.friendly_name }} /></div>

        <div className={"wrapper"}>
            {
                <Tree
                    showLine
                    treeData={translate(props.wallData)}
                />
            }

        </div>

    </div>) : (
        <Empty description={"Select a Wall first"} />
    )

    )
}

export const WallPanel: React.FunctionComponent<any> = (props)=>{

    return <TrussContext.Consumer>
    {(trussctx) => (
        <div className={`tv-wall-info`}>
            <HeaderSelectionContext.Consumer>
                {(ctx) =>
                    ctx.selection.keys ? (
                        <HeaderSelect selectctx={ctx} />
                    ) : (
                        <div className="centered-dropdown">
                            <ConfigDropdown
                                configMap={trussctx?.config_map}
                                defaultValue={''} // Set a default value if needed
                                onChange={(value) => {
                                    if(ctx.setSelection)
                                    ctx.setSelection({
                                        keys: value,
                                        data: {}
                                    })
                                    // Handle the onChange event if needed
                                }}
                            />
                        </div>
                    )
                }
            </HeaderSelectionContext.Consumer>
        </div>
    )}
</TrussContext.Consumer>
}
const HeaderSelect: React.FunctionComponent<any> = ({  selectctx }:any) => {
    const [wallData, setWallData] = React.useState<any>(null);
    const [selectedOption, setSelectedOption] = React.useState<string>('');
    const handleOptionChange = React.useCallback((value: string) => {
        if(value !== selectedOption)
        {
            unstable_batchedUpdates(() => {
                setSelectedOption(value);
                selectctx.setSelection({
                    keys: value,
                    data: {}
                })
            })
        }   
        
    },[])
    React.useEffect(() => {
            if( selectedOption!== selectctx.selection.keys)
            {   
                setSelectedOption(selectctx.selection.keys ? selectctx.selection.keys : '');
                getConfigurationView(selectctx.selection.keys).then((res)=>{ 
                    if (res?.data) {
                        if(wallData?.production_line !== res.data.production_line)
                        {
                            setWallData(res.data); // Update wallData state

                        selectctx.setSelection({
                            keys: res.data.production_line,
                            data: {}
                        })
                    }
                    }
                })
            }
    }, [selectctx]);
    
    return  <div className={"tdv-panel"}>
        <div className={"header"}>
        <TrussContext.Consumer>
                {t=>
        <ConfigDropdown configMap={t?.config_map} defaultValue={selectedOption} onChange={handleOptionChange} />
        }</TrussContext.Consumer>
        </div>
    <WallDataViewer wallData={wallData} />
</div>
            
    
};