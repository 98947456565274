/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import {Button, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {Keys, ShortCutKey} from "../../trusscheck/defs";
import {EventListenerContext, listenerRefContext} from "../../listeners/keybord";
import {Trans} from "../../reactive/Trans";

export const RemoveComponentControl: React.FunctionComponent<PropsWithChildren<any>> = ({ onClick }) => {

    const {registerHandlers, unregisterHandlers} = React.useContext(EventListenerContext)
    const ctx = React.useContext(listenerRefContext)

    const handleKeyPress = React.useCallback((event: KeyboardEvent)=>{
        if (event.code === ShortCutKey.Delete) {
            onClick()
        }
    }, [onClick])

    React.useEffect(()=>{
        registerHandlers('keyup', 'delete_tool_key_handler', handleKeyPress)

        return () => {
            unregisterHandlers('keyup','delete_tool_key_handler')
        };
    }, [])

    React.useEffect(()=>{
        ctx.setFocus()
    }, [ctx.setFocus])

    return <div style={{padding: 10}}>
        <Tooltip title={<Trans i18nKey={'edit.recipe.tooltip.delete'} defaults={"Delete {{key}}"} values={{key: Keys.Delete}}/>}>
            <Button icon={<DeleteOutlined />} onClick={onClick}>
                <Trans i18nKey={'edit.recipe.button.delete'} defaults={"Delete tool"}/>
            </Button>
        </Tooltip>
    </div>
}
