/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import {DataNode} from "rc-tree/lib/interface";
import {IOContext} from "../../io/SocketIO";
import {CheckCircleFilled, CloseCircleFilled, LoadingOutlined, StopFilled, LockOutlined, LockFilled, CheckCircleOutlined} from "@ant-design/icons";
import './styles/TreeNodeElement.css'
import {If} from "../../../util/utils";
import {Dropdown, Tag, Tooltip} from "antd";
import {MenuProps} from "antd/es/menu";
import {createNewVersion} from "../../../api";
import {Trans} from "react-i18next";
export interface TreeNodeElementProps extends Omit<DataNode, "title">{
    _id: [string|number, number]
    status: 'pending'|  'started' | 'completed' | 'error' | 'cancelled',
    type: 'project' | 'truss' | 'config' | 'recipe'
    title?: React.ReactNode,
    item?: string
    sub?: string
    deployed?: boolean
}

export interface Progress extends Pick<TreeNodeElementProps, "status">{
     progress: number
}


export const TreeNodeElement: React.FC<{data: TreeNodeElementProps}> = (props)=>{
    const [progress, setProgress] = React.useState<Progress>({status:'started', progress: 0})
    const [toottip, setTooltip] = React.useState("")
    const ctx = React.useContext(IOContext)

    React.useEffect(() => {
        if(['started', 'pending'].includes(props.data.status) && props.data.sub ) {
            ctx.socket?.on(props.data.sub, (...data: any) => {
                const status = data[1];
                const progress = data[0]
                setProgress({status, progress})
            })
        }
        return () => {
            ctx.socket?.off(`${props.data.key}`)
        }
    }, [props])

    React.useEffect(() => {
        if (props.data.type === "recipe" && props.data?.deployed === false) {
          if (props.data.status === "completed") {
            if (props.data.error_count !== 0) {
              setTooltip("Recipe available with errors");
            } else if (props.data.warning_count !== 0) {
              setTooltip("Recipe available with warnings only");
            } else {
              setTooltip("Recipe available without errors/warnings");
            }
          } else if (props.data.status === "error" ) {
            setTooltip("Recipe generation failed");
          } else if (props.data.status === "cancelled") {
            setTooltip("Recipe generation cancelled");
          }
        }
      }, [props.data]);
    



    return <Tooltip title={`${toottip}`}>
        <div className={"tree-node-element"} title="">
        <If if={props.data.type === "recipe"}>
            <Dropdown menu={{items: [
                {
                    key: 'new',
                    onClick: async ()=>{
                        await createNewVersion({id: Number(props.data._id[0]), timestamp_year: props.data._id[1]})
                    },
                    label: <Trans i18nKey={'clone.version.button.label'} defaults={"Create New Version"}/>
                }
                ]}} trigger={"contextMenu"}>
    
                    <span>Version {props.data.title} </span>

                
            </Dropdown>
        </If>
        <If if={props.data.type === "truss"}>
            <span>{`${props.data.title} ` }<Tag>{props.data.item}</Tag></span>
        </If>
        <If if={props.data.type !== "recipe" && props.data.type !== "truss"}>
            <span>{props.data.title}</span>
        </If>
        <If if={["recipe", "truss"].includes(props.data.type)}>
            <If if={props.data.status !== "completed" && props.data.status !== "error" && props.data.status !== "cancelled"}>
                <span className={"tc-node-status"}>{(progress?.progress || 0)} %</span>
                <If if={props.data.status === 'started'}>
                    <span className={"tc-node-status"}><LoadingOutlined spin={true}/></span>
                </If>
            </If>
            <If if={props.data.status === "completed" && !props.data?.deployed && props.data.type === "recipe"}>
                <If if={props.data.error_count !== 0}>
                    <CheckCircleOutlined className={["sig-check", props.data.type, "errors"].join(" ")} />
                </If>
                <If if={props.data.error_count === 0 && props.data.warning_count !== 0}>
                    <CheckCircleOutlined className={["sig-check", props.data.type, "warnings"].join(" ")} />
                </If>
                <If if={props.data.error_count === 0 && props.data.warning_count === 0}>
                    <CheckCircleOutlined className={["sig-check", props.data.type, "success"].join(" ")} />
                </If>
            </If>
            <If if={props.data.status === "error" && props.data?.deployed === false}>
                <CloseCircleFilled className={["sig-error", props.data.type].join(" ")}/>
            </If>
            <If if={props.data.status === "cancelled" && props.data?.deployed === false}>
                <StopFilled className={["sig-cancel", props.data.type].join(" ")}/>
            </If>
            <If if={props.data?.deployed === true}>
                <LockFilled className={["sig-deployed", props.data.type].join(" ")}/>
                <span className={"tc-node-deployed-text"}> (Deployed) </span>
            </If>
        </If>
    </div>   
    </Tooltip>

}
