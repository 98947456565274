/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {useCallback, useContext} from "react";
import {ConfigContext, Flags, getAllBeamGrabbers, RecipeContext} from "../util";
import {ErrorSelectionContext, LayerSelectionContext} from "../../trusscheck/util"
import {APSofwareTypes, HomePosition, LayerDefs, ToolFlags} from "../def";
import {isSelectedByIdList} from "../util"
import {CollisionContext} from "../collisionDetection/util";
import {EditServiceObj, ITool} from "../EditService";

export interface IGrabber {
    angle: number,
    disabled: boolean,
    timberId: number,
    id: number,
    type: number,
    x: number,
    y: number,
    [key:string]: any

}

export const ChordGrabber: React.FunctionComponent<any> = () => {

    return <ConfigContext.Consumer>
        {ctx => ctx?.configuration.walltoolshapes.ChordGrabber.DrawingElements.Shapes.map((shape, index)=>{
            return <polygon key={`${shape.layer.toLowerCase()}_${index}`} className={`${shape.layer.toLowerCase()}_${index}`} points={shape.points}/>
        })}
    </ConfigContext.Consumer>
}

export const ChordGrabberLayer: React.FunctionComponent<{id?:number}> = (props) => {

    const [upperChordGrabberIds, setUpperChordGrabberIds] = React.useState<number[]>([])
    const [allChordGrabbers, setAllChordGrabbers] = React.useState<IGrabber[]>([])
    const configCtx = useContext(ConfigContext)
    const recipeCtx = useContext(RecipeContext)

    const collisionCtx = useContext(CollisionContext)
    const errCtx = useContext(ErrorSelectionContext)

    React.useEffect(() => {
        const ids = configCtx?.configuration.walldata.WallSetup.HomePositionsOfUpperBeamGrabbers.map((grabber) => {
            return grabber.GrabberID
        }) || []
        setUpperChordGrabberIds(ids)
    }, [configCtx])

    React.useEffect(() => {
        if (recipeCtx?.recipe && configCtx?.configuration) {
            setAllChordGrabbers(getAllBeamGrabbers(recipeCtx.recipe, configCtx.configuration))
        }
    }, [configCtx, recipeCtx])

    const getProperties = useCallback((tool: ITool) => {

        const selectedGrabberId = `${LayerDefs.BeamGrabber}_${tool.id}`;
        const selectedGrabber = `GRABBER'${tool.id}`;
        const isSelected = isSelectedByIdList([selectedGrabberId, selectedGrabber], errCtx)

        const collide = collisionCtx?.collisions?.has(`${LayerDefs.BeamGrabber.toLowerCase()}_${tool.id}`)? "collide" : ""

        if (props.id === undefined) {
            return{
                'id': `${LayerDefs.BeamGrabber.toLowerCase()}_${tool.id}`,
                'data-component': tool.id,
                'data-layer': LayerDefs.BeamGrabber,
                'className': `${LayerDefs.BeamGrabber.toLowerCase()} ${isSelected ? "error" : ""} ${collide}`
            }

        } else {
            return {
                'className': `${LayerDefs.BeamGrabber.toLowerCase()}`
            }
        }
    },[props.id, collisionCtx, errCtx])

    const renderTool = useCallback(() => {
        return allChordGrabbers.reduce((acc:  JSX.Element[], tool: ITool) => {
            const angleByDegrees = upperChordGrabberIds.includes(tool.id) ? (tool.angle * (180 / Math.PI)) + 180 : tool.angle * (180 / Math.PI)

            const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.BeamGrabber.toLowerCase()}_${tool.id}`)

            if ((props.id === tool.id || props.id === undefined) && !isDeleted) {

                acc.push(<g key={`${LayerDefs.BeamGrabber}_${tool.id}`}
                            transform={`rotate(${angleByDegrees}, ${tool.x}, ${tool.y}) translate(${tool.x}, ${tool.y})`}
                            {...getProperties(tool)}
                >
                    <ChordGrabber/>
                </g>)
            }

            return acc

        }, [])

    },[props.id, allChordGrabbers, upperChordGrabberIds, collisionCtx, errCtx])

    return <LayerSelectionContext.Consumer>{layerCtx=>
                <g key={LayerDefs.BeamGrabber} id={LayerDefs.BeamGrabber.toLowerCase()}
                   className={`${layerCtx?.selectedComponent.includes(LayerDefs.BeamGrabber) && (props.id === undefined) ? "hide-layer" : ""}`}>
                    {renderTool()}
                </g>}
            </LayerSelectionContext.Consumer>
}

interface ChordGrabberCableDetails {
    positionOnTool: {x:number, y:number},
    homeContactPoint: {hx:number, hy:number},
    homePosition: Map<number, HomePosition>
    cableThickness: number
}


export const ChordGrabberCableLayer: React.FunctionComponent<any> = ()=>{

    const [data, setData] = React.useState<ChordGrabberCableDetails | undefined>(undefined)
    const configCtx = React.useContext(ConfigContext)

    const getHomePositions = React.useCallback( (key: string): Map<number, { [key: string]: any }> => {
        const homePosition = configCtx?.configuration.walldata.WallSetup[key] || []
        return  homePosition.reduce((acc: Map<number, HomePosition>, item: HomePosition)=>{
            acc.set(item.GrabberID, item)
            return acc
        }, new Map())
    }, [configCtx])

    React.useEffect(()=>{

        const positionOnTool = configCtx?.configuration.walltoolshapes.ChordGrabber.DrawingElements.Cable.toolContactPoint.split(',') || []
        const homeContactPoint = configCtx?.configuration.walltoolshapes.ChordGrabber.DrawingElements.Cable.homeContactPoint.split(',') || []
        const cableThickness = configCtx?.configuration.walltoolshapes.ChordGrabber.DrawingElements.Cable.cableThickness

        const upperHomePositions = getHomePositions('HomePositionsOfUpperBeamGrabbers')
        const downHomePositions = getHomePositions('HomePositionsOfDownBeamGrabbers')

        setData({
            positionOnTool: {x : Number(positionOnTool[0]), y: Number(positionOnTool[1])},
            homeContactPoint: {hx : Number(homeContactPoint[0]), hy: Number(homeContactPoint[1])},
            homePosition: new Map([...upperHomePositions, ...downHomePositions]),
            cableThickness: cableThickness
        })
    }, [configCtx])

    return <ErrorSelectionContext.Consumer>{errCtx=>
        <LayerSelectionContext.Consumer>{layerCtx=>
            <RecipeContext.Consumer>
                {recipeCtx => data && recipeCtx?.recipe.Grabbers && <g key={LayerDefs.BeamGrabberCable} id={LayerDefs.BeamGrabberCable.toLowerCase()}
                                                                       className={`${layerCtx?.selectedComponent.includes(LayerDefs.BeamGrabberCable)? "hide-layer" : ""}`}>

                    {recipeCtx?.recipe.Grabbers.reduce((acc: JSX.Element[], tool: { [key: string]: any }) => {

                        const isDeleted = EditServiceObj.isDeleted(`${LayerDefs.BeamGrabber.toLowerCase()}_${tool.id}`)

                        if (tool.type !== ToolFlags.MIDGRABBER && !isDeleted) {

                            const selectedGrabber = `${'GRABBER'}_${tool.id}`;
                            const isSelected = isSelectedByIdList([ selectedGrabber], errCtx)


                            const {HomeX, HomeY, ...value} = data?.homePosition.get(tool.id) || {}
                            const {x, y} = data?.positionOnTool
                            const {hx, hy} = data?.homeContactPoint

                            const _y = (tool.type === ToolFlags.UPPER_BEAM_GRABBER)? y : (tool.type === ToolFlags.DOWN_BEAM_GRABBER)? y*-1 : 0
                            const _x = (Flags.is(tool.type, ToolFlags.RIGHT_SIDE_TOOL))? y : (Flags.is(tool.type, ToolFlags.LEFT_SIDE_TOOL))? y*-1 : 0

                            const homeY = (tool.type === ToolFlags.UPPER_BEAM_GRABBER || tool.type === APSofwareTypes.LeftBeamStickDiagonalUp
                                || tool.type === APSofwareTypes.RightBeamStickDiagonalUp)? (HomeY + hy) : (HomeY - hy)

                            acc.push(<g key={`${LayerDefs.BeamGrabberCable}_${tool.id}`}
                                        id={`${LayerDefs.BeamGrabberCable.toLowerCase()}_${tool.id}`} className={`${LayerDefs.BeamGrabberCable.toLowerCase()} ${isSelected ? "error" : ""}`}
                            >
                                <polygon
                                    style={{strokeWidth: data?.cableThickness}}
                                    id={`${LayerDefs.BeamGrabber.toLowerCase()}_${tool.id}_cable`}
                                    data-points={`${HomeX},${homeY},${_x},${_y}`}
                                    points= {`${HomeX},${homeY} ${tool.x + _x},${tool.y + _y}`}
                                />
                            </g>)
                        }
                        return acc;
                    }, [])}
                </g>}
            </RecipeContext.Consumer>}
        </LayerSelectionContext.Consumer>}
    </ErrorSelectionContext.Consumer>
}
