/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import {LayerDefs, TimberTypes} from "../def";
import React, { Fragment } from "react";
import { FormInstance, Input, InputNumber, Modal, Segmented} from "antd";
import {Form} from "@q4us-sw/q4us-ui-v2/dist/form";
import {DefaultArgs} from "@q4us-sw/q4us-ui-v2/dist/form/FormV2";
import {FormElementProps} from "@q4us-sw/q4us-ui-v2/dist/form/def";
import {EditServiceObj} from "../EditService";
import {OnContextMenuContext} from "./menu";
import { Trans } from "../../reactive/Trans";
import { PreferenceContext, UnitType } from "../../user/UserPreference";
import  {Q4USUtil} from '@q4us-sw/q4us-util'
import { useLocation } from 'react-router-dom';
import {listenerRefContext} from "../../listeners/keybord";
import {CollisionContext} from "../collisionDetection/util";

const getPropertyTypeByValue = (v)=>{
    switch (typeof(v)){
        case "boolean":
            return "BOOLEAN"
        case "number":
        case "bigint":
            return "NUMBER";
        case "object":
            return "SCHEMA";
        case "string":
            return "STRING"
    }

}

const getTimberTypeEnum = ()=>{
    return  Object.entries(TimberTypes)
        .map(([key, value]) => [String(value), key])
}

const extraPropertiesToRemove = ['component_id', 'isRemovable', 'freeTransform', 'autoCorrectY', 'layer']

export const isFieldEditable = (layer,prop)=> {
    switch (layer) {
        case LayerDefs.BeamGrabber:
        case LayerDefs.MidGrabber:
            return ["angle", "timberId"].includes(prop);
        case LayerDefs.SideTool:
            return ["angle", "timberId"].includes(prop);
        case LayerDefs.TimberBeam:
            return ["angle", "type","x","y"].includes(prop);
        case LayerDefs.TimberDiagonal:
            return ["angle", "type","x","y"].includes(prop);
        case LayerDefs.NailPlate:
            return ["angle"].includes(prop);
        case LayerDefs.BlockTool:
            return ["angle", "timberId", "torqueEnabled", "useVacuumPicker"].includes(prop);
        case LayerDefs.PressTool:
            return ["angle", "nailplateId", "rearPaddleDistance", "useLeftFrontPaddle", "useRightFrontPaddle", "useAdvancedLeftFrontPaddle", "useAdvancedRightFrontPaddle"].includes(prop);
        case LayerDefs.RemoveToolEntry:
            return ["angle"].includes(prop);
        default:
            return false
    }
};
interface SpanProps {
    initialValue: {fis:string |String , mm:number}
    onChange: any
}
interface DegreeProps {
    initialValue: number
    onChange: any
}
const DegreeView: React.FunctionComponent<DegreeProps> = ({initialValue, onChange}) => {
    const [type, setType] = React.useState<string>('rad')
    const [degValue, setDegValue] = React.useState<number>()
    const [radValue, setRadtValue] = React.useState<number>()
    
    const options = ['rad', 'deg']
    React.useEffect(() => {
        setRadtValue(initialValue || 0)
        setDegValue((initialValue * 180) / Math.PI)
    }, [initialValue]);
    React.useEffect(() => {
        onChange(radValue || 0);
    }, [radValue, onChange]);

    const unit = <Segmented
        value={type}
        size={"small"}
        options={options}
        onChange={value => setType(value as string)}
    />

    return <Fragment>
        {type==='deg'?
            <InputNumber
                addonAfter={unit}
                style={{width: '95%'}}
                value={degValue || (initialValue * 180) / Math.PI }
                onChange={value => {
                    setDegValue(value || 0)
                    const val = value || 0
                    setRadtValue((val * Math.PI) / 180)
                    onChange(value)
                }}
            />:
            <InputNumber
                addonAfter={unit}
                style={{width: '95%'}}
                value={radValue || initialValue}
                onChange={value => {
                    setRadtValue(value || 0)
                    const val = value || 0
                    setDegValue((val * 180) / Math.PI)
                    onChange(value)
                }}
            />}
    </Fragment>
}

const Span: React.FunctionComponent<SpanProps> = ({initialValue, onChange}) => {
    const prefCtx = React.useContext(PreferenceContext)
    const [invalid, setInvalid] = React.useState<boolean>(false)
    const [type, setType] = React.useState<string>('mm')
    const [curValue, setCurValue] = React.useState<number>(initialValue.mm)
    const [curFeetValue, setCurFeetValue] = React.useState<string |String>(initialValue.fis)
    
    const options = ['mm', 'fis']
    React.useEffect(() => {
        onChange(curValue || 0);
    }, [curValue, onChange]);

    const unit = <Segmented
        value={type}
        size={"small"}
        options={options}
        onChange={value => setType(value as string)}
    />
    React.useEffect(()=>{
        if(prefCtx?.prefInfo.pref.units === UnitType.imperial){
            setType('fis')
        }else{
            setType('mm')
        }
        setCurValue(initialValue.mm)
        setCurFeetValue(initialValue.fis)
    },[initialValue])
    return <Fragment>
        {type==='mm'?
            <InputNumber
                addonAfter={unit}
                style={{width: '95%'}}
                value={curValue || initialValue.mm}
                onChange={value => {
                    setCurValue(value || 0)
                    setCurFeetValue(prefCtx?.convertMMtoFIS(value) ||"")
                    onChange(value)
                }}
            />:
            <Input
                status={invalid?'error':undefined}
                addonAfter={unit}
                placeholder={'4-3-2'}
                style={{width: '95%'}}
                value={curFeetValue ||  initialValue.fis }
                onChange={e => {
                    const value = e.target.value
                    setCurFeetValue(e.target.value)

                    const regex = /^(\d+)\' (\d+)'' (\d+(\.\d+)?) 16th$/;
                    //const regex = /^\d+-\d{1,2}-\d{1,2}$/
                    if(value.match(regex)) {
                            const valueInInches = prefCtx?.convertFISToMM(value)
                            setCurValue(valueInInches || 0)
                            setInvalid(false)
                            onChange(valueInInches as number)
                        }
                     else {
                        setInvalid(true)
                    }
                }}
            />}
    </Fragment>
}
export const PropertyEditor: React.FC<{ data: Record<string, any>, layer: string, onSubmit: (data: any) => void }> = ({ data: d, onSubmit, layer }) => {
    const [iniData, setIniData] = React.useState(d);
    const [length, setlength] = React.useState(d.length);
    const [breadth, setBreadth] = React.useState(d.length);
    const [timberBreadth, settimberBreadth] = React.useState(d.timberBreadth);
    const [rearPaddleDistance, setrearPaddleDistance] = React.useState(Q4USUtil.roundToDecimal(d.rearPaddleDistance * 1,3));
    const [angleRad, setAngleRad] = React.useState(d.angle * 1);
    const prefCtx = React.useContext(PreferenceContext)
    const [rearPaddleDistanceImp, setrearPaddleDistanceImp] = React.useState(prefCtx?.convertMMtoFIS(d.rearPaddleDistance) || "");
    const ctx = React.useContext(listenerRefContext)
    const collisionCtx = React.useContext(CollisionContext)

    React.useEffect(()=>{
        const data = {...d, id: Number(d.id)}
        extraPropertiesToRemove.forEach(prop => {
            if (data.hasOwnProperty(prop)) {
                delete data[prop]
            }
        })
        setIniData(data)
    },[d])

    React.useEffect(()=>{
        return () => {
            ctx.setFocus()
        }
    }, [ctx.setFocus])

    React.useEffect(() => {
        if (prefCtx?.prefInfo?.pref?.units === UnitType.metric) {
            setlength(iniData.length)
            setBreadth(iniData.breadth)
            settimberBreadth(iniData.timberBreadth)
        }
        else {
            if (typeof iniData.length == 'number')
                setlength(prefCtx?.convertMMtoFIS(iniData.length))
            if (typeof iniData.breadth == 'number')
                setBreadth(prefCtx?.convertMMtoFIS(iniData.breadth))
            if (typeof iniData.timberBreadth == 'number')
                settimberBreadth(prefCtx?.convertMMtoFIS(iniData.timberBreadth))

        }
    }, [prefCtx?.prefInfo.pref.units, iniData])
    React.useEffect(() => {
        setrearPaddleDistanceImp(prefCtx?.convertMMtoFIS(rearPaddleDistance,true) || '')
    }, [rearPaddleDistance])
    const setRearDistance = (value: number | null) => {
        setrearPaddleDistance(value || 0)
    };
    return <Modal
         width={720}
        centered={true}
        footer={null}
        open={true}
        closable={true}
        title={<Trans i18nKey={`form.tittle.Component_Properties`} values={{ component: `${layer}_${iniData.id}`}} defaults={"{{component}} Properties"} />}
        onCancel={() => onSubmit(undefined)}
         maskClosable={false}
         keyboard={false}
    >
        <Form {...DefaultArgs} initialValue={{ ...iniData, rearPaddleDistance: rearPaddleDistance, timberBreadth: timberBreadth, length: length, breadth: breadth, angle: angleRad }} schema={"property"}
            config={(s) => {
                const data = Object.keys(iniData).filter(o => typeof (iniData[o]) !== "object").map((o) => {
                        const e = (iniData.memberLabel && o === 'type')? getTimberTypeEnum() : undefined
                    return {
                        name: o,
                        title: o.toUpperCase(),
                        type: isFieldEditable(layer, o) ? getPropertyTypeByValue(iniData[o]) : "LABEL",
                        enums: e
                    } as FormElementProps
                })
                return data;
            }} submit={async (e) => {
                setAngleRad(e.angle)
                const updatedData = { ...e, timberBreadth: iniData.timberBreadth, length: iniData.length, breadth: iniData.breadth };
                EditServiceObj.setPropertyChange(`${layer}_${iniData.id}`, updatedData)
                collisionCtx?.setCollisions(EditServiceObj.getCollisions())
                onSubmit(updatedData)
                return true;

            }}
            rejected={() => {
                EditServiceObj.removePropertyChange(`${layer}_${iniData.id}`)
                onSubmit({})

            }}
            overrideBaseFormProps={{
                labelCol: { span: 14 },
                wrapperCol: { span: 10 }
            }}
            rejectText={"Reset"}
            register={r => r} layout={"horizontal"}
            formatRegister={(registry) => {
                return registry
            }}
            overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                if (element.name === 'rearPaddleDistance') {

                    return <Span initialValue={{ mm: rearPaddleDistance, fis: rearPaddleDistanceImp }} onChange={setRearDistance} />
                }
                else if (element.name === 'angle') {
                    return <DegreeView initialValue={angleRad} onChange={setAngleRad} />
                }
            }}
        />
    </Modal>

}


export const PropertyEditorMouseEvent: React.FC<any> = (props)=>{

    return <OnContextMenuContext.Consumer>
        {ctx=> ctx?.trigger && <PropertyEditor data={ctx.data} layer={ctx.data?.layer} onSubmit={ctx.close}/>}
    </OnContextMenuContext.Consumer>

}
