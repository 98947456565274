/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/



export enum RecentItemTypes{
    Projects= "project",
    Trusses = "truss",
    Configs = "config",
    Recipes = "recipe"

}

export enum ButtonType{
    Text = "text",
    Icon = "icon"
}


export interface  LoadRecentRequest {
    type: RecentItemTypes,
    project?: number,
    truss?: number,
    config?: string,
    timestamp_year?: number,
    size?: number,
    recipe?: number
}


export interface  LoadProjectTreeRequest extends Record<string, any>{
    type: "all" | "pending" | "deployed",
    name?: string,
    from?: number,
    size?: number
    itr?: number
}
export enum TrussUnit{
    Metrics='metrics',
    Imperial='imperial'
}

export enum TrussInfoInRecipe{
    TrussLabel= "trussLabel",
    Item = "item",
    Order= "order",
    Config = "config",
    Configuration= "configuration",
    TrussType = "trussType",
    Plates= "plates",
    Lumber = "lumber",
    Thickness= "thickness",
    TrussUnits = "trussUnits",
    Camber= "camber",
    CalculatedMass = "calculatedMass",
    Width = "width",
    Height= "height",
    MassCenterPoint= "massCenterPoint"
}

export enum ShortCutKey {
    Done = 'KeyA',
    Cancel = 'Escape',
    Edit = 'KeyE',
    Delete = 'Delete'

}

export enum Keys {
    E = 'E',
    A = 'A',
    Esc = 'Esc',
    Delete = 'Delete'
}