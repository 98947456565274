/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import {Button, Modal, notification, Select, Spin, Tabs, Typography} from "antd";
import { InfoGroup } from "./InfoGroup";
import {fetchSettings, updateSetting} from "../../api";
import {unstable_batchedUpdates} from "react-dom";
import '../../styles/Settings.css';
import {StatusCodes} from "http-status-codes";
import {EditOutlined, InfoCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {FormElementProps} from "@q4us-sw/q4us-ui-v2/dist/form/def";
import {Trans} from "../reactive/Trans";

export const SettingsContext = React.createContext<{ [key: string]: any }>({})

export const SettingChangeConfirm: React.FunctionComponent<{ value: string, columnProps: FormElementProps }> = (props) => {
    const [selected, setSelected] = React.useState(props.value)
    const [visible, setVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [setting_name, setSetting_name] = React.useState(modifyString(props.columnProps.name)); 

    React.useEffect(() => {
        setSetting_name(modifyString(props.columnProps.name));
    }, [props.columnProps.name]);

    function modifyString(inputString: string): string {
        return inputString.replace(/_/g, ' ').replace(/\b\w/g, match => match.toUpperCase());
    }


    return <SettingsContext.Consumer>
        {setting => <React.Fragment>
            <Button key={props.columnProps.name} type={"link"} icon={<EditOutlined/>}
                    onClick={async () => {
                        setVisible(true)
                    }}/>
            <Modal
                closable={false}
                title={null}
                visible={visible}
                width={416}
                footer={null}
                className={"ant-modal ant-modal-confirm ant-modal-confirm-confirm"}
            >
                <div className="ant-modal-confirm-body-wrapper">
                    <div className="ant-modal-confirm-body">
                        <InfoCircleOutlined/>
                        <span
                            className="ant-modal-confirm-title">{<Trans i18nKey={'settings.form.confirm_enable_disable'} defaults={"Do you want to change setting for {{setting_name}}"} values={{ setting_name }}/>}</span>
                        <div className="ant-modal-confirm-content">
                            <div className={`single-shipment-dialog`}>  
                                <div className={`title`}><Trans i18nKey={'settings.form.Setting Enabled'} defaults={"{{setting_name}} Enabled"} values={{ setting_name }}/></div>
                                <div>
                                    <Select onSelect={setSelected} value={selected} defaultValue={props.value}>
                                        {
                                            props.columnProps.enums?.map(o => <Select.Option key={o[0]}
                                                                                             value={o[0]}>{o[1]}</Select.Option>)
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ant-modal-confirm-btns">
                        <Button onClick={() => {
                            unstable_batchedUpdates(() => {
                                setVisible(false)
                                setSelected(props.value);
                            })

                        }}> <Trans i18nKey={'No'} defaults={"No"}/></Button>
                        <Button icon={loading ? <LoadingOutlined/> : undefined} className="ant-btn-dangerous"
                                onClick={async () => {
                                    setLoading(true)
                                    try {
                                        const {status} = await updateSetting({key:props.columnProps.name, value:selected});
                                        if (status === StatusCodes.OK) {
                                            setting.setLoading(true)
                                            setting.refresh()

                                            notification.success({message: <Trans i18nKey={'settings.form.Setting_Updated'} defaults={"Setting Updated"}/>})
                                        } else {
                                            notification.error({message: <Trans
                                                i18nKey="settings.form.settingUpdateFailed"
                                                defaults="Setting update failed with status code {{status}}"
                                                values={{ status }}
                                              />})
                                        }
                                    } catch (e: any) {
                                        const {response = {}} = e || {}
                                        const {status} = response;
                                        notification.error({message: <Trans
                                            i18nKey="settings.form.settingUpdateFailed"
                                            defaults="Setting update failed with status code {{status}}"
                                            values={{ status }}
                                          />})
                                    }
                                    unstable_batchedUpdates(() => {
                                        setVisible(false)
                                        setSelected(props.value);
                                        setLoading(false)
                                    })
                                }}> <Trans i18nKey={'Yes'} defaults={"Yes"}/></Button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>}
    </SettingsContext.Consumer>
}






export const Settings: React.FunctionComponent<any> = (props) => {


    const [data, setData] = React.useState<{ [key: string]: any }>({})
    const [loading, setLoading] = React.useState(true)

    const load = React.useCallback(async () => {
        const {data = []} = await fetchSettings();
        if (data) {
            unstable_batchedUpdates(() => {
                setLoading(false)
                setData(data)
                console.log(`data : ${JSON.stringify(data)}`)
            })
        }
    }, [])

    React.useEffect(() => {
        load().catch(e => console.error(e))
    }, [])
    return <React.Fragment>
        <div className={`settings-outer`}>
            <div className={`settings-content`}>
                <Spin spinning={loading}>
                    <SettingsContext.Provider value={{data, refresh: load, setLoading}}>
                        <InfoGroup schema={`settings`}
                                   data={data}
                                   // @ts-ignore
                                   label={<Trans i18nKey={'settings.form.General_Settings'} defaults={"General Settings"}/>}
                                   customRenderValue={{
                                       is_file_upload_mode_enabled: (value, row, index, column) => {
                                           if (column.name === 'is_file_upload_mode_enabled') {
                                               const formattedValue = (value === 'true' ? <Trans i18nKey={'Yes'} defaults={"Yes"}/> : <Trans i18nKey={'No'} defaults={"No"}/>)
                                               return <Typography.Text>{formattedValue}</Typography.Text>
                                           }
                                       },
                                       continue_on_warnings: (value, row, index, column) => {
                                        if (column.name === 'continue_on_warnings') {
                                            const formattedValue = (value === 'true' ? <Trans i18nKey={'Yes'} defaults={"Yes"}/> : <Trans i18nKey={'No'} defaults={"No"}/>)
                                            return <Typography.Text>{formattedValue}</Typography.Text>
                                        }
                                    }
                                   }}
                                   customRenderAction={{
                                       is_file_upload_mode_enabled: (value, row, index, column) => {
                                           if (column.name === 'is_file_upload_mode_enabled') {
                                                // @ts-ignore
                                               column.enums = [['true', <Trans i18nKey={'Yes'} defaults={"Yes"}/>],['false',<Trans i18nKey={'No'} defaults={"No"}/>]]
                                               return <SettingChangeConfirm value={value} columnProps={column}/>
                                           }
                                       },
                                       continue_on_warnings: (value, row, index, column) => {
                                        if (column.name === 'continue_on_warnings') {
                                             // @ts-ignore
                                            column.enums = [['true', <Trans i18nKey={'Yes'} defaults={"Yes"}/>],['false',<Trans i18nKey={'No'} defaults={"No"}/>]]
                                            return <SettingChangeConfirm value={value} columnProps={column}/>
                                        }
                                    }
                                   }}
                        />
                    </SettingsContext.Provider>

                </Spin>
            </div>
        </div>
    </React.Fragment>
}
export default Settings