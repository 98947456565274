/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, useContext} from "react";
import {Table} from "@q4us-sw/q4us-ui-v2/dist/table";
import {Badge, Button, Checkbox, Dropdown, Empty, notification, Progress, Select,Tooltip} from "antd";
import {Trans} from "react-i18next";
import {FormattedValueProps} from "@q4us-sw/q4us-ui-v2";
import {CheckSquareOutlined, ExclamationCircleOutlined, ReloadOutlined, WarningOutlined ,WarningTwoTone} from "@ant-design/icons";
import {SelectionContextProvider,SelectionContext} from "@q4us-sw/q4us-ui-v2/dist/table/Selection";
import { HeaderSelectionContext, RecipeCollisionRequest, RecipeSelection, RecipeSelectionContext, Status, TrussContext, TrussContextProvider } from "./util";
import {DataContext, DataContextProvider} from "@q4us-sw/q4us-ui-v2/dist/util";
import {ConfigProvider} from "@q4us-sw/q4us-ui-v2/dist/util";
import {If} from "../../util/utils";
import './styles/DeploymentInfo.css'
import {useLocation, useSearchParams ,useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {IOContext} from "../io/SocketIO";
import { createNewRecipeForConfig, createNewVersion, getFeConfig, calculateRecipeByLine } from "../../api";
import qs from "query-string";
interface JobStatus{
    status: 'pending'|  'started' | 'completed' | 'error' | 'cancelled',
}
export const Deployed: React.FunctionComponent<PropsWithChildren<{ deployed?: boolean }>> = (props) => {
    return (!!props.deployed) ? <Badge.Ribbon text={<Trans i18nKey={"deployment.status.ok"}
                                                           defaults={"OK"}/>}>{props.children}</Badge.Ribbon> : <span>{props.children}</span>
}

export interface StatusIconProps extends Omit<FormattedValueProps, "value"> {
    value?: {status:Status,calcUUid:string,jobStatus:string}
}

function getIconClassName( columnName: string,data: Record<string, any>, req?: RecipeCollisionRequest){
    return data.id === req?.id && data.timestamp_year === req?.timestamp_year && columnName === req?.config ? "selected" : 'default'

}
export const parseTrussViewParams = (location: any)=>{
    const params = qs.parse(location.search)
    return {
        truss_id: parseInt(`${params["t"]}`),
        timestamp_year: parseInt(`${params["y"]}`),
        project_id: parseInt(`${params["p"]}`),
        project_name: parseInt(`${params["proj"]}`)
    }
}
export interface RecipeStatusIconProps {
    title: string;
    icon: React.ReactElement;
    className: string;
  }
 
export  const RecipeStatusIcon: React.FC<RecipeStatusIconProps> = ({title, icon, className }) => (
    <Tooltip title={<Trans i18nKey={`status_panel.tool_tip.${className}`} defaults={title} />}>
      {React.cloneElement(icon, { className: `status-icon ${className}` })}
    </Tooltip>
);

const ViewNew: React.FunctionComponent<{
    value?: {
        status: number;
        calcUUid: string;
        jobStatus:string;
    };
    row?: number;
    columnName: string;
    ctxTruss:any
}> = ({ value = { status: 0, calcUUid: "",jobStatus:"" }, row = 0, columnName ,ctxTruss})  => {

    const [progress, setProgress] = React.useState<any>({status: 'pending', progress: 0})
    const [status,setStatus] = React.useState<any>();
    const ctx = React.useContext(IOContext)
    const location = useLocation();
    React.useEffect(() => {
        if(value?.status)
            setStatus(value?.status)
        else
            setStatus(0)

    },[])
    React.useEffect(() => {
        setProgress((prevState:any)=>({...prevState, status: value.jobStatus}))
        if(['started', 'pending'].includes(value.jobStatus)) {
            ctx.socket?.on(`calculation.${value.calcUUid}`, (...data: any) => {
                const status = data[1];
                const progress = data[0];
                setProgress({status, progress})
            })
        }
        return () => {
            ctx.socket?.off(`calculation.${value.calcUUid}`)
        }
    }, [value.jobStatus, progress.status])

    const renderReloadIcon = () => {
        if (['started', 'pending'].includes(value.jobStatus) && value.calcUUid) {
            const progressDis  = progress.progress==0?1:progress.progress;
            return (
                <div className="progress-reload-icon">
                    <Progress type="circle" percent={progressDis} size={20} />
                </div>
            );
        } else if(value.jobStatus === 'error'|| (value.jobStatus === 'started' && !value.calcUUid) || progress.status == 'error') {
            return (
                <div className="progress-reload-icon">
                    <Progress type="circle" percent={100} size={20} status="exception" format={() => <Trans i18nKey={`status_panel.tool_tip.failed`} defaults={"Error while calculating recipe for the production line using collision detection"}></Trans>}/>
                </div>
            );
        }else {
            return (
                <RecipeStatusIcon
                    title="Recipe calculation for other lines using collision detection not done"
                    icon={<ReloadOutlined />}
                    className="pending"
                />
            );
        }
    }
    return <SelectionContext.Consumer>{selection=>
    
    <TrussContext.Consumer>
    {t=><RecipeSelectionContext.Consumer>
        {ctx=><DataContext.Consumer>
            {data=><Dropdown menu={{items: [
                    {
                        key: 'new',
                        onClick: async ()=>{
                            const {default: x} = data.rows[row]
                            if(!x){
                                await createNewRecipeForConfig({id: data.rows[row].id, timestamp_year: data.rows[row].timestamp_year, config: columnName})                                
                                notification.info({message: <Trans i18nKey={'notification.Recipe_is_submitted_to_be_generated'} defaults={"Recipe is submitted to be generated"}/>})
                                await data?.reset?.()
                                await t?.load({truss_id: t?.query.truss_id, timestamp_year: t?.query.timestamp_year})                               
                            }else{
                                notification.error({message: <Trans i18nKey={'notification.Recipe_is_already_generated_for_this_configuration'} defaults={"Recipe is already generated for this configuration"}/>})
                            }
                        },
                        label: <Trans i18nKey={"status_panel.create"} defaults={"Create New Recipe for Configuration"} />
                    }
                ]}} trigger={"contextMenu"}><Button
                onClick={async ()=>{
                const d = data.rows[row]
                const config_name_map: Record<string, any> = t?.config_map.find((c: {config: string, friendly_name: string}) => c.config === columnName) || {}
                                await ctx?.set({
                                    id: d.id, timestamp_year: t?.query.timestamp_year || 0, config: columnName,
                                    version: d.version, truss: t?.truss.trussInfo.trussLabel || "", friendly_name: config_name_map?.friendly_name
                                })
                selection?.clear();


            }} disabled={ status  == 0}
                className={["collision-btn", getIconClassName(columnName, {...data.rows[row], timestamp_year: t?.query.timestamp_year || 0}, ctx?.req)].join(" ")}
                icon={<>
                    <If
                        if={(status & ~Status.Deployed) == Status.Success}
                    >
                        <RecipeStatusIcon
                            title="Recipe available without any warnings or errors"
                            icon={<CheckSquareOutlined />}
                            className="success"
                        />
                    </If>
                    <If if={(status & Status.Errors) > 0}>
                        <RecipeStatusIcon
                            title="Recipe has with errors (and warnings)"
                            icon={<ExclamationCircleOutlined />}
                            className="error"
                        />
                    </If>
                    <If
                        if={
                            (status & Status.Errors) == 0 &&
                            (status & Status.Warnings) > 0 &&
                            (status & Status.Acknowledge) == 0
                        }
                    >
                        <RecipeStatusIcon
                            title="Recipe available with warnings"
                            icon={<WarningOutlined />}
                            className="warning"
                        />
                    </If>
                    <If
                        if={
                            (status & Status.Errors) == 0 &&
                            (status & Status.Warnings) > 0 &&
                            (status & Status.Acknowledge) > 0
                        }
                    >
                        <RecipeStatusIcon
                            title="Recipe available with warnings and all the warnings have been acknowledged"
                            icon={<WarningTwoTone />}
                            className="warning"
                        />
                    </If>
                <If if={ status  == 0}>
                    {renderReloadIcon()}
                </If>
                </>}/></Dropdown>}
        </DataContext.Consumer>
        }
    </RecipeSelectionContext.Consumer>}
</TrussContext.Consumer>
}</SelectionContext.Consumer>
}

export const StatusIcon: React.FunctionComponent<StatusIconProps> = ({value = {status:0,calcUUid:'',jobStatus:''}, row = 0, columnName}) => {


    return <Deployed deployed={(value?.status & Status.Deployed) > 0}>
        <TrussContext.Consumer>{t=>
            <ViewNew value={{
                    status: value.status,
                    calcUUid: value.calcUUid,
                    jobStatus:value.jobStatus
                }}
                row={row}
                columnName={columnName} ctxTruss={t}/>
            }</TrussContext.Consumer>
    </Deployed>
}


export interface MetaProps extends Omit<FormattedValueProps, "value"> {
    value?: number
}
export interface HeaderColumnProps {
    className?: string
    [key: string]: any
}
export const HeaderColumn: React.FC<PropsWithChildren<HeaderColumnProps>> = ({  className, ...props }) => {   
    const recpCtx = React.useContext(RecipeSelectionContext);
    const headerSelectionCtx = React.useContext(HeaderSelectionContext);
    const location = useLocation();
    const [params, setParams] = useSearchParams();
    React.useEffect(()=>{
        
        if(recpCtx?.recipe){
            if (headerSelectionCtx?.setSelection && recpCtx?.req?.config === props?.dataIndex) {
                headerSelectionCtx?.setSelection({
                    keys: props.dataIndex,
                    data: props
                })
            }
        }
    },[recpCtx])

    return props.headerCellSelection?<HeaderSelectionContext.Consumer>
        {ctx => <RecipeSelectionContext.Consumer>{respCtx=>
        <th      
            className={ props.dataIndex === headerSelectionCtx.selection.keys ? 'clickable-header-cell selected' : 'clickable-header-cell'} 
            onClick={(e) => {
                if (headerSelectionCtx?.setSelection) {
                    respCtx?.set();
                    const updatedParams: {
                        config?: string|undefined;
                        version?: string|undefined;
                        isRecipe?: string|undefined;
                        truss?: string|undefined;
                        recipe: string | undefined;
                        deploylist: string | undefined;
                    } = {
                        ...qs.parse(location.search),
                        config: undefined,
                        version: undefined,
                        isRecipe: undefined,
                        truss:undefined,
                        recipe: undefined,
                        deploylist:undefined
                    };
                    Object.keys(updatedParams).forEach((key:any) => {
                        if (updatedParams[key as keyof typeof updatedParams ] === undefined) {delete updatedParams[key as keyof typeof updatedParams];
                        }
                    });
                    const cleanedParams: Record<string, string> = {};
                    for (const key in updatedParams) {
                        const value = updatedParams[key as keyof typeof updatedParams];
                        if (value !== undefined) {
                            cleanedParams[key] = value;
                        }
                    }
                    setParams(cleanedParams);

                    headerSelectionCtx?.setSelection({
                        keys: props.dataIndex,
                        data: props
                    })
                }
            }
            }
            {...props}
            title={props.is_default?`${props.label} (default)`:props.label}
            
        ><span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',textDecoration: props.is_default ? 'underline' : 'none',color: props.is_default ? 'green' : 'inherit',fontStyle: props.is_default?'italic':'none' }}>{props.title}</span>
        </th>}</RecipeSelectionContext.Consumer>}
    </HeaderSelectionContext.Consumer>:<th {...props}>{props.title}</th>

}
export const DeploymentInfo: React.FunctionComponent<any> = (props) => {
    const ctx = React.useContext(IOContext)
    const ctxTruss = React.useContext(TrussContext);
    const location = useLocation();
    const query = parseTrussViewParams(location)
    React.useEffect(() => {      
        ctx.socket?.on(`refresh.truss`, (...data: any) => {
            if(data[0] === query.truss_id)
            {
                ctxTruss?.load({truss_id: query.truss_id, timestamp_year: query?.timestamp_year})
            }                       
        })
        return () => {
            ctx.socket?.off(`refresh.truss`)
        }
    }, [location])
    React.useEffect(() => {      
        ctx.socket?.on(`refresh.trussDeploy`, (...data: any) => {
            if(Array.isArray(data[0]) && data[0].includes(query.truss_id))
            {
                ctxTruss?.load({truss_id: query.truss_id, timestamp_year: query?.timestamp_year})
            }                    
        })
        return () => {
            ctx.socket?.off(`refresh.trussDeploy`)
        }
    }, [ location])
    return <ConfigProvider config={async (s) => {
        const { data = { rows: [] } } = await getFeConfig(s) || {}
        
        const datawith = data.rows.map((row) => ({
            ...row,
            ...(row.headerCellSelection ? { onHeaderCell: (col: ColumnsType) => { return { ...col } } } : {}),
        }));
        return datawith || []
    }}
    ><SelectionContextProvider>
            <TrussContext.Consumer>
                {t => <DataContextProvider key={t?.truss?.id || "empty"} data={async (req) => {
                    return { data: { rows: t?.compatibility || [], statusCode: 200 } }
                }}>             
                        <DeployTable />
                    <DataContext.Consumer>
                        {data => <RecipeSelectionContext.Consumer>{selectctx =>
                            <SelectionContext.Consumer>
                                {selection =>
                                    <DeployAction selection={selection} selectctx={selectctx} />
                                }
                            </SelectionContext.Consumer>
                        }</RecipeSelectionContext.Consumer>}
                    </DataContext.Consumer>
                </DataContextProvider>}
            </TrussContext.Consumer>
        </SelectionContextProvider>
    </ConfigProvider>

}
const DeployTable: React.FunctionComponent<any> = () => {

    return <Table
        key={'table'} 
        components={{ header: { cell: HeaderColumn } }}
        rowSelection={{ type: 'radio' }}
        className={"deployment-view"}
        size={"small"}
        scroll={{ y: 'calc(100% - 40px)' }}
        rowKey={"id"}
        register={(r) => r}
        formatRegister={(f) => f
            .register("DEPLOYMENT_STATUS", StatusIcon)
        }
        locale= {{
            emptyText: (
                <React.Fragment>
                    <GenerateRecipeByLine/>
                </React.Fragment>
            )
        }}
        bordered={true}
        schema={"deploy_configs"} />
};

const DeployAction: React.FunctionComponent<any> = ({ selection, selectctx }:any) => {
    const [params, setParams] = useSearchParams();
    const headerSelectionCtx = React.useContext(HeaderSelectionContext);
    const location = useLocation();
    React.useEffect(() => {
        if (selection?.selection?.keys?.length > 0) {

            const updatedParams: {
                config?: string|undefined;
                version?: string|undefined;
                isRecipe?: string|undefined;
                truss?: string|undefined;
                recipe: string | undefined;
                deploylist: string | undefined;
            } = {
                ...qs.parse(location.search),
                config: undefined,
                version: undefined,
                isRecipe: undefined,
                truss:undefined,
                recipe: selection?.selection?.keys[0] || "",
                deploylist:"1"
            };
            Object.keys(updatedParams).forEach((key:any) => {
                if (updatedParams[key as keyof typeof updatedParams ] === undefined) {delete updatedParams[key as keyof typeof updatedParams];
                }
            });
            const cleanedParams: Record<string, string> = {};
            for (const key in updatedParams) {
                const value = updatedParams[key as keyof typeof updatedParams];
                if (value !== undefined) {
                    cleanedParams[key] = value;
                }
            }
            setParams(cleanedParams);
            selectctx?.set(undefined);
            headerSelectionCtx?.clear?.();

        }
        
    }, [selection.selection.keys]);
    
    return null;
};

const GenerateRecipeByLine: React.FunctionComponent<any> = (props) => {
    async function onChange(value: any, truss: any) {
        const {project_id, truss_id, timestamp_year} = truss.query;
        await calculateRecipeByLine({
            truss_id,
            project_id,
            timestamp_year,
            configuration: value
        });
    }

    return <TrussContext.Consumer>{t=>
        <DataContext.Consumer>{data =>
        <div>
            {t?.truss?.error_type === 'config_level' && <div>
                <Select style={{ width: 400 }} value={''} onChange={async (value) => {
                    await onChange(value, t);
                    notification.info({message: "Recipe is submitted to be generated"})
                    await data?.reset?.()
                    await t?.load({truss_id: t?.query.truss_id, timestamp_year: t?.query.timestamp_year})
                }}>
                    <Select.Option value="" disabled>
                        Select Wall and Generate Recipe
                    </Select.Option>
                    {t?.config_map?.map((config: any) => (
                        <Select.Option key={config.config} value={config.config}>
                            {config.friendly_name}
                        </Select.Option>
                    ))}
                </Select>
            </div>}
            { t?.truss?.error_type !== 'config_level' && <div>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data"
                />
            </div>

            }
        </div>
        }
        </DataContext.Consumer>

    }
    </TrussContext.Consumer>
}