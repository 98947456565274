/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import axios from "axios"
import {notification} from "antd"
import {Auth} from '../components/auth/Authentication'
import {LoadProjectTreeRequest, LoadRecentRequest} from "../components/trusscheck/defs";

if(process.env.REACT_APP_SERVER_URL){
    axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
}

axios.interceptors.request.use(async (cfg)=>{
    //skip auto header attachment from auth requests
    if(Auth.isConfigured() && Auth.isSignedIn() && cfg.url !== "/api/authentication/open-id/connect"){
        const token = await Auth.getToken();
        cfg.headers["Authorization"] = `Bearer ${token}`;
    }
    return cfg;
}, async err=>{
    notification.error({message: err.message});
})

axios.interceptors.response.use(res => {return res}, err => {
    notification.error({message: err.response?.data?.message || err.message || 'Error'});

    if(err.response?.status === 401){
        Auth.signOut()
    }
    // notification.error({message: err.message || 'Error'});
    return {};
});



export enum Comparator{
    Equal = "=",
    LessThan = "<",
    GreaterThan = ">",
    LessThanOrEqual = "<=",
    GreaterThanOrEqual = ">=",
    NotEqual = "!="
}

export interface Filter{
    name:string,
    value: any
    comparator?: Comparator | string
}

export interface Page{
    from?: number,
    size?: number
}

export interface PersistenceBody{
    tableName: string,
    data: any
}


export interface ChangePasswordBody {
    current_password: string,
    new_password: string
}

export interface DeleteTrussBody {
    order: string,
    item: string
}
export interface  DeleteProjectBody {
    order: string,
}
export interface  DeployRecipeBody {
    data: {recipe_id : number, timestamp_year : number}[]
}
export interface  PurgeRequestBody {
    truss_list: {truss_id : number, project_id : number, truss_label : string}[]
}
export interface  DeploySelectedRecipeBody {
    data: {recipe_id : number,config: string, timestamp_year : number}
}
export interface  IsRecheckRecipeBody {
    order:string, item:string,recipe_id : number, config:string,version:number,timestamp_year : number
}

export interface  AcknowledgeWarningsBody {
    order:number, truss:number, config : string, version:number ,recipe: number,timestamp_year : number
}

export enum Schedules {
    PAST_ERROR_REPORT_DELETE = 'persistence.deletePastErrorReports'
}

//authentication

export const signIn = async (user:string,pass:string)=>{
    const {data} = await axios.post('/api/authentication/open-id/connect', {
        password:pass,
        username:user
    }) || {}

    return data || {};
}
export const signInWithCode = async (code:any,redirect_uri:string)=>{
    const {data} = await axios.post('/api/authentication/open-id/connect', {
        grant_type:"authorization_code",
        code,
        redirect_uri
    }) || {}

    return data || {};
}
export const signOut = async (refresh_token: string)=>{
    const {data} = await axios.post(`/api/authentication/open-id/logout`, {
        token:refresh_token
    }) || {}

    return data || {};
}
export const auth_cfg = async ()=>{
    return axios.get('/api/authentication/cfg')
}
export const refreshToken = async (token: string)=>{
    const {data} = await axios.post('/api/authentication/open-id/connect', {
        grant_type:"refresh_token",
        refresh_token:token,
    }) || {}

    return data || {};
}

export const userInfo = async ()=>{
    return axios.get(`/api/v1/user_info`)
}

export const changePassword = async (data: ChangePasswordBody)=>{
    return axios.post(`/api/v1/change_password`, data)
}

export const passwordPolicy = async ()=>{
    return axios.get(`/api/authentication/password/policy`)
}

export const getFeConfig = async (id: string)=> {
    return await axios.get(`/api/v1/configuration/config/${id}`)
}

export const visualizeLoad = async (request: any)=>{
    return await axios.post(`/api/v1/visualize/load`,request)
}
export const visualizeLoadTrussSummary = async (request: any)=>{
    return await axios.post(`/api/v1/visualize/loadTrussSummary`,request)
}

export const checkProjectExists = async (project_name: string)=> {
    return await axios.get(`/api/v1/check_project_exists/${project_name}`)
}
export const importProjectWithTrusses = async (data: any)=>{
    return await axios.post(`/api/upload/project_with_trusses`, data)
}

export const insert = async (data: PersistenceBody)=>{
    return axios.post(`/api/v1/insert`, data)
}
export const createProject = async (data: Record<string, any>)=>{
    return axios.post(`/api/v1/persistence/trusscheck/project/create`, data)
}

export const deleteTruss = async (data: DeleteTrussBody)=>{
    return axios.post(`/api/v1/delete_truss`, data)
}

export const deleteProject = async (data: DeleteProjectBody)=>{
    return axios.post(`/api/v1/delete_project`, data)
}

export const importTruss = async (data: any)=>{
    return await axios.post(`/api/v1/import_truss`, data)
}

export const setDefault = async (id: string)=>{
    return await axios.put(`/api/v1/trussmatic-config/default/${id}`)
}

export const syncConfig = async ()=>{
    return await axios.get(`/api/v1/trussmatic-config/sync`)
}

export const loadTrussList = async (data: {project_id: number, timestamp_year: number}) =>{
    return await axios.post(`/api/v1/visualize/truss_view/truss_list`, data)
}
export const loadTrussContext = async (data: {truss_id: number, timestamp_year: number}) =>{
    return await axios.post(`/api/v1/visualize/truss_view/truss`, data)
}
export const getAvailableLocales = async () =>{
    return await axios.get(`/api/v1/locales`)
}
export const markAsRead = async (data: {id: number, timestamp_year: number})=>{
    return axios.post(`/api/v1/mark_as_read`, data);
}

export const getNewMessageCount = async ()=>{
    return await axios.get(`/api/v1/get_new_message_count`)
}

export const deployRecipe = async (data: DeployRecipeBody )=> {
    return axios.post(`/api/v1/deploy_recipe`, data)
}

export const purgeTrusses = async (truss_ids: { truss_ids: [] })=> {
    return axios.post(`/api/v1/purge`, truss_ids)
}
export const deploySelectedRecipe = async (data: DeploySelectedRecipeBody )=> {
    return axios.post(`/api/v1/deploy_selected_recipe`, data)
}
export const isRecheckRecipe = async (data: IsRecheckRecipeBody )=> {
    return axios.post(`/api/v1/visualize/recipe/checkRecipe`, data)
}
export const markAllAsRead = async ()=>{
    return axios.get(`/api/v1/mark_all_as_read`);
}

export const loadRecent = async ({type, ...data}: LoadRecentRequest )=> {
    return axios.post(`/api/v1/visualize/trusscheck/recent/${type}`, data)
}

export const loadProjectTree = async ({type, ...data}: LoadProjectTreeRequest )=> {
    return axios.post(`/api/v1/visualize/trusscheck/projects/${type}`, data)
}

export const fetchTrussFeatures = async (recipeId: number, timestamp_year: number)=> {
    return axios.post(`/api/v1/visualize/trusscheck/truss_features`, {recipe_id: recipeId, timestamp_year: timestamp_year})
}

export const recalculateRecipe = async (data: Record<string, any>)=>{
    return axios.post(`/api/v1/recalculate_recipe`, data)
}

export const cancelRecipe = async (data: Record<string, any>)=>{
    return axios.post(`/api/v1/cancel_recipe`, data)
}

export const getConfiguration = async (production_line: string)=>{
    return axios.get(`/api/v1/get_configuration/${production_line}`);
}
export const getConfigurationView = async (production_line: string)=>{
    return axios.get(`/api/v1/get_configuration_view/${production_line}`);
}

export const checkConnectivity = async (data: object)=> {
    return await axios.post(`/api/v1/check_connection`, data)
}

export const updateUser = async (request: any)=>{
    return await axios.post(`/api/v1/user/update`,request)
}

export const updateUserInfo = async (request: any) => {
    return await axios.post('/api/v1/user/update_info', request);
}

export const getDefaultRecipe = async (data: {id: number, timestamp_year:number})=> {
    return await axios.get(`/api/v1/visualize/recipe/${data.id}/${data.timestamp_year}`)
}
export const getRecipeForConfig = async (data: {id: number, timestamp_year: number, config: string})=> {
    return await axios.get(`/api/v1/visualize/recipe/${data.id}/${data.timestamp_year}/${data.config}`)
}

export const getRecipeDetails = async (data: {id: number, timestamp_year:number})=> {
    return await axios.get(`/api/v1/visualize/recipe_info/${data.id}/${data.timestamp_year}`)
}
export const getRecipeForRedirect = async (data: {truss_label: string, project:string, friendly_name: string})=> {
    return await axios.get(`/api/v1/visualize/recipe_for_redirect/${data.truss_label}/${data.project}/${data.friendly_name}`)
}
export const acknowledgeWarnings = async (request: AcknowledgeWarningsBody)=>{
    return await axios.post(`/api/v1/acknowledge_warnings`,request)
}
export const loadERPClients = async (request: any)=>{
    return await axios.post(`/api/v1/clients/fetch_all`, request)
}

export const updateERPClient = async (request: any)=>{
    return await axios.post(`/api/v1/clients/update`,request)
}

export const createERPClient = async (request: any)=>{
    return await axios.post(`/api/v1/clients/create`,request)
}

export const recipeRecalculate = async (request: any)=>{
    return await axios.post(`/api/v1/calculation/edit/submit`,request)
}


export const createNewVersion = async (req: {id: number, timestamp_year: number})=>{
    return await axios.post(`/api/v1/calculation/edit/clone`,req)
}

export const createNewRecipeForConfig = async (req: {id: number, timestamp_year: number, config: string})=>{
    return await axios.post(`/api/v1/calculation/edit/gen`,req)
}

export const fetchSettings = async ()=>{
    return await axios.get(`/api/v1/settings`)
}

export const updateSetting = async (request: {key: string, value: string})=>{
    return await axios.post(`/api/v1/settings/update`,request)
}

export const cloneLatestVersion = async (req: {truss_id: number, config: string, timestamp_year: number})=>{
    return await axios.post(`/api/v1/calculation/edit/clone_latest`,req)
}

export const saveUserPref = async (req: { language: string|undefined ,units: string|undefined, treeExpand: boolean|undefined}, url:string)=>{
    return await axios.post(`${url}/api/v1/user/updatePref`,req)
}
export const loadUserPref = async (url:string)=>{
    return await axios.post(`${url}/api/v1/user/loadPref`)
}

export const calculateRecipeByLine = async (data: Record<string, any>)=>{
    return axios.post(`/api/v1/calculate_recipe_by_line`, data)
}

export const reportError = async (data: Record<string, any>)=>{
    return axios.post(`/api/upload/report/error`, data)
}

export const fetchDefaultConfig = async ()=>{
    return await axios.get(`/api/v1/fetchDefaultConfiguration`)
}

export const downloadAttachment = async (id:number)=>{
    const {data} = await axios({
        url: `/api/v1/fetchErrorReportAttachment`,
        method: 'POST',
        responseType: 'blob',
        data: {id: id}
    });
    const blobStore = new Blob([data], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blobStore);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = `error-report_${id}_attachment.zip`;
    a.click();
    window.URL.revokeObjectURL(url);
}

export const discardErrorReport = async (id: number)=>{
    return axios.post(`/api/v1/discardErrorReport`, {id: id})
}

export const postErrorReport = async (data: Record<string, any>)=>{
    return axios.post(`/api/v1/postErrorReport`, data)
}
export const getFullTranslations = async ()=>{
    return axios.get(`/api/v1/translate`);
}

export const deletePastErrorReports = async () => {
    return axios.post(`/api/v1/invokeSchedule`, {action_name: Schedules.PAST_ERROR_REPORT_DELETE});
}

export const loadAllProjects = async () =>{
    return await axios.get(`/api/v1/persistence/load/projects`)
}

export const fetchErrorReportTrussInfo = async (data: Record<string, any>)=>{
    return axios.post(`/api/v1/fetchErrorReportTrussInfo`, data)
}
export const availableServices = async ()=>{
    return  axios.get('/v1/registeredComp');
}
export const getCloudSuiteUrl = async () =>{
    return await axios.get(`/v1/getCloudSuiteUrl`);
}

export const updateConnection = async (values:any,id:string)=> {
    return await axios.post(`/api/v1/update_connection` ,{values : values, id : id})
}