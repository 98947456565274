/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {Button, notification, Progress, Space} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {IOContext} from "../../io/SocketIO";
import {cancelRecipe, recalculateRecipe} from "../../../api";
import '../styles/Recipe.css'
import {Trans} from "react-i18next";
export const RecipeStatus: React.FunctionComponent<any> = (props) => {

    const [progress, setProgress] = React.useState<any>({status: props.status, progress: 0})
    const location = useLocation();
    const navigate = useNavigate();

    const ctx = React.useContext(IOContext)

    React.useEffect(() => {
        setProgress((prevState:any)=>({...prevState, status: props.status}))
        if(['started', 'pending'].includes(props.status)) {
            ctx.socket?.on(`calculation.${props.calc_uuid}`, (...data: any) => {
                const status = data[1];
                const progress = data[0]
                setProgress({status, progress})
            })
        }
        return () => {
            ctx.socket?.off(`calculation.${props.calc_uuid}`)
        }
    }, [props.status, progress.status,props.calc_uuid])

    const cancel_recipe = React.useCallback(async () => {
        const res = await cancelRecipe(
            {
                calc_uuid: props.calc_uuid,
                recipe_id: location.state.recipe,
                timestamp_year: location.state.timestamp_year
            })
        if (res.data) {
            notification.success({message: <Trans i18nKey={'notification.Cancelled_recipe_calculation'} defaults={"Cancelled recipe calculation"}/>})
            
        } else {
            notification.error({message: <Trans i18nKey={'notification.Error_cancelling_recipe_calculation'} defaults={"Error while cancelling recipe calculation"}/>})
        }
    }, [props]);

    const recalculate_recipe = React.useCallback(async () => {
        const req = {
            configuration: location.state.config,
            project_id: location.state.project,
            recipe_id: location.state.recipe,
            timestamp_year: location.state.timestamp_year,
            truss_design_info: props.truss_features,
            truss_id: location.state.truss
        }
        setProgress({status:'started', progress:0})
        recalculateRecipe(req).then(resp => {
            if (resp.data) {
                notification.success({message: <Trans i18nKey={'notification.Recipe_recalculation_started'} defaults={"Recipe recalculation started"}/>});
                
            } else {
                notification.error({message: <Trans i18nKey={'notification.Recipe_recalculation_started_error'} defaults={"Error starting recipe recalculation"}/>})
            }
        })
    },[props])

    return <React.Fragment>
        {progress.status === 'started' && <span className={"recipe_container"}>
            <Space direction="vertical">
                <Progress percent={(progress?.progress || 0)} size={[300, 20]} />
            </Space>
        </span>}
        <div className="recipe_container">
            { progress.status === 'started' && <Button className={"recipe_button"} onClick={cancel_recipe}><Trans i18nKey={'edit.recipe.status.button.cancel'} defaults={"Cancel"}/></Button>}
            {(progress.status === 'cancelled' || progress.status === 'error') && <Button className={"recipe_button"} onClick={recalculate_recipe}><Trans i18nKey={'edit.recipe.status.button.regenerate'} defaults={"Regenerate Recipe"}/></Button>}
        </div>
    </React.Fragment>
}
