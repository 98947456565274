/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import './styles/TrussView.css'
import {Button, Input, List, Radio, Tag, Result, Empty} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
import {DeploymentInfo} from "../components/trussview/DeploymentInfo";
import {Deployment} from "../components/trussview/DeploymentPanel";
import {TrussPanel} from "../components/trussview/TrussPanel";
import {RecipePanel} from "../components/trussview/RecipePanel"
import {TrussList} from "../components/trussview/TrussList";
import {useLocation,useSearchParams} from "react-router-dom";
import {
    HeaderSelectionContext,
    RecipeSelectionContext,
    RecipeSelectionContextProvider,
    TrussContextProvider
} from "../components/trussview/util";
import { HeaderSelectionContextProvider} from "../components/trussview/util";
import { WallPanel } from "../components/trussview/WallPanel";
import {Trans} from "../components/reactive/Trans";
import { RecipeContextProps } from "../components/recipeVisualize/util";

export enum TrussViewTab {
    Truss,
    Wall,
    Recipe,
    Deployment
}

export const TabResult: React.FunctionComponent<{ type: TrussViewTab }> = (props) => {
    switch (props.type) {
        case TrussViewTab.Truss:
            return <TrussPanel/>
        case TrussViewTab.Recipe:
            return <RecipeSelectionContext.Consumer>
                {r=> r?.recipe ? <RecipePanel /> : <Empty description={<Trans i18nKey={`truss-view.tabs.recipe.recipe_not_selected`} defaults={"Select a recipe first"}/>}/>}
            </RecipeSelectionContext.Consumer>
        case TrussViewTab.Deployment:
            return <Deployment/>
        case TrussViewTab.Wall:
            return <WallPanel/>
        default:
            return <Empty/>

    }
}
export const TrussViewChild: React.FunctionComponent<any> = (props) => {
    const [tab, setTab] = React.useState<TrussViewTab>(TrussViewTab.Truss)
    const recpCtx = React.useContext(RecipeSelectionContext)
    const headerCtx = React.useContext(HeaderSelectionContext)

    React.useEffect(()=>{
        if(recpCtx?.recipe)
            setTab(TrussViewTab.Recipe)
    },[recpCtx?.recipe])
    React.useEffect(()=>{
        if(headerCtx?.selection?.keys && !recpCtx?.recipe)
            setTab(TrussViewTab.Wall)
    },[headerCtx?.selection])
    return  <div className={"tv-frame"}>
                <TrussList/>
                <div className={"tv-main-view"}>
                    <div className={"tv-deployment"}>
                        <DeploymentInfo/>
                    </div>
                    <div className={"tv-details"}>
                        <div className={"tv-tabs"}>
                            <Radio.Group onChange={(e) => setTab(e.target.value)} value={tab} buttonStyle={"solid"}>
                                {Object.entries(TrussViewTab).filter(([k, v]) => isNaN(Number(k))).map(([k, v]) =>
                                    <Radio.Button key={v} value={v}>{<Trans i18nKey={`truss_view.tabs.${k}`} defaults={k}/>}</Radio.Button>)}
                            </Radio.Group>
                        </div>
                        <div className={"tv-tab-view"}>
                            <TabResult type={tab}/>
                        </div>

                    </div>
                </div>

            </div>
}
export const TrussView: React.FunctionComponent<any> = (props) => {

    return <TrussContextProvider>
        <HeaderSelectionContextProvider>
        <RecipeSelectionContextProvider>
            <TrussViewChild/>
        </RecipeSelectionContextProvider>
        </HeaderSelectionContextProvider>
    </TrussContextProvider>
}
