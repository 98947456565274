/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {useLocation} from "react-router-dom";
import {Button, Tooltip} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import {Trans} from "react-i18next";
import { cloneLatestVersion } from "../../../api/index"

export const CloneLatestVersion: React.FunctionComponent<any> = () => {

    const location = useLocation();

    const cloneLatest = React.useCallback(async (e: any) => {
        const {truss, config, timestamp_year} = location.state;
        await cloneLatestVersion({truss_id: truss, config: config, timestamp_year: timestamp_year});
    },[location])

    return <React.Fragment>
        <Tooltip placement="top" title={<Trans i18nKey={'clone.version.tooltip.latestversion'} defaults={"Cloning from the latest version"}/>}>
            <Button style={{width: 250}} icon={<CopyOutlined/>} onClick={cloneLatest}><Trans i18nKey={'clone.version.button.label'} defaults={"Create New Version"}/></Button>
        </Tooltip>
    </React.Fragment>
}