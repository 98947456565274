/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {useContext} from "react";
import {ConfigContext, getAllGrabbers, getAllSideTools, RecipeContext} from "../util";
import {LayerDefs, ToolFlags} from "../def";
import {IGrabber} from "./ChordGrabber";
import {ISideTool} from "./SideTool";
import {LayerSelectionContext} from "../../trusscheck/util";
import {EditServiceObj} from "../EditService";


export const SideToolId: React.FunctionComponent<any> = ()=> {
    const [allSideTools, setAllSideTools] = React.useState<ISideTool[]>([])
    const configCtx = useContext(ConfigContext)
    const recipeCtx = useContext(RecipeContext)

    React.useEffect(() => {
        if (recipeCtx?.recipe && configCtx?.configuration) {
            setAllSideTools(getAllSideTools(recipeCtx.recipe, configCtx.configuration))
        }
    }, [configCtx, recipeCtx])

    return <g key={'sidetool_tool_id'} id={'sidetool_tool_id'}>
            {allSideTools.reduce((acc, tool) => {
                const isDeleted = EditServiceObj.isDeleted(`sidetool_${tool.id}`)
                if (!isDeleted) {
                    acc.push(<g key={`sidetool_${tool.id}_tool_id`}
                                id={`sidetool_${tool.id}_tool_id`}
                                transform={`translate(${tool.x}, ${tool.y})`}
                    >
                        <circle r="35"/>
                        <text dy="0.10em" transform=" scale(1, -1)">{tool.id}</text>
                    </g>)
                }

                return acc;
            }, [])
            }
        </g>
}

export const GrabberId: React.FunctionComponent<any> = ()=> {

    const [allGrabbers, setAllGrabbers] = React.useState<IGrabber[]>([])
    const configCtx = useContext(ConfigContext)
    const recipeCtx = useContext(RecipeContext)

    React.useEffect(() => {

        if (recipeCtx?.recipe && configCtx?.configuration) {
            setAllGrabbers(getAllGrabbers(recipeCtx.recipe, configCtx.configuration))
        }
    }, [configCtx, recipeCtx])

    return (<g key={'grabber_tool_id'} id={'grabber_tool_id'}>
        {allGrabbers.reduce((acc, tool) => {
            const toolName = tool.type === ToolFlags.MIDGRABBER ? 'midgrabber' : 'beamgrabber';
            const isDeleted = EditServiceObj.isDeleted(`${toolName}_${tool.id}`)
            if (!isDeleted) {
                acc.push(<g key={`${toolName}_${tool.id}_tool_id`}
                            id={`${toolName}_${tool.id}_tool_id`}
                            transform={`translate(${tool.x}, ${tool.y})`}
                >
                    <circle r="35"/>
                    <text dy="0.10em" transform=" scale(1, -1)">{tool.id}</text>
                </g>)
            }

            return acc;
        }, [])
        }

        </g>)
}


export const ToolIdLayer: React.FunctionComponent<any> = ()=> {

    return <LayerSelectionContext.Consumer>{layerCtx=>
        <g key={LayerDefs.ToolId} id={LayerDefs.ToolId.toLowerCase()}
           className={`${LayerDefs.ToolId.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.ToolId)? "hide-layer" : ""}`}>
        <GrabberId/>
        <SideToolId/>
    </g>}
    </LayerSelectionContext.Consumer>
}

export const NailPlateIdLayer: React.FunctionComponent<any> = ()=> {

    return <LayerSelectionContext.Consumer>{layerCtx=>
    <RecipeContext.Consumer>
        {ctx => <g key={LayerDefs.NailPlateId} id={LayerDefs.NailPlateId.toLowerCase()}
                   className={`${LayerDefs.NailPlateId.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.NailPlateId)? "hide-layer" : ""}`}>
            {ctx?.recipe.NailPlates.map((tool: {[key:string]: any}) => {
                return (<g key={`${LayerDefs.NailPlateId}_${tool.id}`}
                           id={`${LayerDefs.NailPlateId.toLowerCase()}_${tool.id}`}
                >
                    <circle cx={tool.x} cy={tool.y} r="30"/>
                    <text x={tool.x} y={-tool.y} dy="0.10em" transform=" scale(1, -1)">{tool.id}</text>
                </g>)

            })}
        </g>}
    </RecipeContext.Consumer>}
        </LayerSelectionContext.Consumer>
}

export const TimberIdLayer: React.FunctionComponent<any> = ()=> {

    return <LayerSelectionContext.Consumer>{layerCtx=>
        <RecipeContext.Consumer>
        {ctx => <g key={LayerDefs.TimberId} id={LayerDefs.TimberId.toLowerCase()}
                   className={`${LayerDefs.TimberId.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.TimberId)? "hide-layer" : ""}`}>
            {ctx?.recipe.Timbers.map((tool: {[key:string]: any}) => {
                return (<g key={`${LayerDefs.TimberId}_${tool.id}`}
                           id={`${LayerDefs.TimberId.toLowerCase()}_${tool.id}`}
                >
                    <circle cx={tool.x - 32} cy={tool.y} r="30"/>
                    <text x={tool.x - 32} y={-tool.y} dy="0.10em" transform=" scale(1, -1)">{tool.id}</text>
                </g>)

            })}
        </g>}
    </RecipeContext.Consumer>}
        </LayerSelectionContext.Consumer>
}

export const MemberIdLayer: React.FunctionComponent<any> = ()=> {

    return <LayerSelectionContext.Consumer>{layerCtx=>
        <RecipeContext.Consumer>
        {ctx => <g key={LayerDefs.MemberId} id={LayerDefs.MemberId.toLowerCase()}
                   className={`${LayerDefs.MemberId.toLowerCase()} ${layerCtx?.selectedComponent.includes(LayerDefs.MemberId)? "hide-layer" : ""}`}>
            {ctx?.recipe.Timbers.map((tool: {[key:string]: any}) => {
                const memberId = tool.originalInputId
                return (<g key={`${LayerDefs.MemberId}_${memberId}`}
                           id={`${LayerDefs.MemberId.toLowerCase()}_${memberId}`}
                >
                    <circle cx={tool.x + 32} cy={tool.y} r="30"/>
                    <text x={tool.x + 32} y={-tool.y} dy="0.10em" transform=" scale(1, -1)">{memberId}</text>
                </g>)

            })}
        </g>}
    </RecipeContext.Consumer>}
        </LayerSelectionContext.Consumer>
}