/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {convertVertices, RecipeContext} from "../util";
import {LayerDefs} from "../def";
import {ErrorSelectionContext, LayerSelectionContext} from "../../trusscheck/util"
import {CollisionContext} from "../collisionDetection/util"
import {isSelectedByIdList} from "../util"

export enum TimberTypes{
    UpperBeam = 1<<0,
    DownBeam = 1<<1,

}

function getLayer(type: number) {
    return (type === TimberTypes.UpperBeam || type === TimberTypes.DownBeam) ? "TIMBER_BEAM" : "TIMBER_DIAGONAL"
}

const TimberLayer: React.FunctionComponent<{layer: string}> = (props)=> {

    return <CollisionContext.Consumer>{collisionCtx=>
        <ErrorSelectionContext.Consumer>{errCtx=>
            <LayerSelectionContext.Consumer>{layerCtx=>
                <RecipeContext.Consumer>
                    {ctx => <g key={props.layer} id={props.layer} className={`${layerCtx?.selectedComponent.includes(props.layer)? "hide-layer" : ""}`}>
                        {
                            ctx?.recipe.Timbers.reduce((acc: JSX.Element[], member:{[key:string]: any}) => {
                                const layer = getLayer(member.type)

                                const selectedTimberId = `${LayerDefs.TimberBeam}_${member.id}`;
                                const selectedTimberDiagonalId = `${LayerDefs.TimberDiagonal}_${member.id}`;
                                const isSelected = isSelectedByIdList([selectedTimberId, selectedTimberDiagonalId], errCtx)

                                const collide = collisionCtx?.collisions?.has(`timber_${member.id}`)? "collide" : ""

                                if (layer === props.layer) {
                                    const points = convertVertices(member.vertices)
                                    acc.push(<g key={`${layer}_${member.id}`}
                                                id={`${layer.toLowerCase()}_${member.id}`}
                                                data-component={member.id}
                                                data-layer={layer}
                                                className={`${props.layer.toLowerCase()} ${isSelected ? "error" : ""} ${collide}`}
                                    >
                                        <polygon points={points}/>
                                    </g>)
                                }
                                return acc
                            }, [])

                        }
                    </g>}
                </RecipeContext.Consumer>}
            </LayerSelectionContext.Consumer>}
        </ErrorSelectionContext.Consumer>}
    </CollisionContext.Consumer>
}

export const TimberBeamLayer = ()=> <TimberLayer layer={LayerDefs.TimberBeam}/>
export const TimberDiagonalLayer = ()=> <TimberLayer layer={LayerDefs.TimberDiagonal}/>
