/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, createRef, useRef, useState} from "react";
import {createProject, importProjectWithTrusses, insert, fetchSettings, checkProjectExists} from "../api";
import {
    Button,
    Divider,
    Form,
    Modal,
    Input,
    Row,
    Col,
    Table,
    Upload,
    Popconfirm,
    notification,
    Tooltip,
    Progress,
    Spin, Tag, List
} from "antd";
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    DeleteOutlined,
    FolderAddOutlined,
    InboxOutlined,
    UploadOutlined
} from "@ant-design/icons";
import JSZip from "jszip";
import {Trans} from "./reactive/Trans";
import {ProjectTreeDataContext} from "./trusscheck/Projects";
import {IOContext} from "./io/SocketIO";
import {FileUploadSettingContext} from "./trusscheck/util";
import {StatusCodes} from "http-status-codes";

export interface TrussRow{
    key: string,
    file_name: string,
    item: string,
    project_name: string,
    truss_label: string,
    file: any,
    json: any
}

export interface Truss{
    project_name: string,
    truss_list: TrussRow[],
    is_unMatching_truss_exist: boolean
}

interface CreateProjectProps{
    setOpen?: (value: boolean) => void,
    isOpen?: boolean
}

export interface TrussProgress {
    ok: number,
    ko: number,
    status: string,
    total: number
}

export interface invalidFileInfoProps {
    invalidFiles: string[],
    duplicates: Map<TrussRow, TrussRow[]>,
}

export const InvalidFileInfo: React.FunctionComponent<invalidFileInfoProps> = (props) => {

    return <div className={'invalid-info'}>
        {(props.invalidFiles.length > 0) && <div>
            <p><Trans i18nKey="modal.valid_invalid_truss.content.invalid_files" defaults={'Invalid files'}/></p>
            <List itemLayout="horizontal" split= {false}>
                {props.invalidFiles.map((file) =>
                    <List.Item>
                        <List.Item.Meta
                            avatar={<CloseCircleTwoTone twoToneColor="#c70c0c" />}
                            title={file}
                        />
                    </List.Item>
                )}
            </List>
        </div>
        }
        {(props.duplicates.size > 0) && <div>
            <Divider/>
            <p><Trans i18nKey="modal.valid_invalid_truss.content.files_with_duplicates" defaults={'Files with duplicates truss item'}/></p>
            <List itemLayout="horizontal" split= {false}>
                {Array.from(props.duplicates.entries()).map((value) =>
                    <List.Item>
                        <List.Item.Meta
                            avatar={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                            title={`${value[0].file_name} (${value[0].item})`}
                            description={
                                <List itemLayout="horizontal" split= {false}>
                                    {value[1].map((file) =>
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<CloseCircleTwoTone twoToneColor="#c70c0c" />}
                                                title={`${file.file_name} (${file.item})`}
                                            />
                                        </List.Item>
                                    )}
                                </List>
                            }
                        />
                    </List.Item>
                )}
            </List>
        </div>
        }
    </div>
}

export const TrussImportProgress : React.FunctionComponent<PropsWithChildren<TrussProgress>> = (props) => {
    return <React.Fragment>
        <div className={"truss-progress-main"}>
            <div className={"truss-progress-title"}>
                <span>{`Truss analysis - ${props.status}`}</span>
                <Tag className={"progress-stat"} color="green" > {`Imported : ${props.ok}`}</Tag >
                <Tag className={"progress-stat"} color="red" > {`Failed : ${props.ko}`}</Tag >
            </div>
            <div className={"truss-progress"}>
                <div className={"progress-bar"}>
                    <Progress percent={(props.ok + props.ko) / props.total * 100} showInfo={false} />
                </div>
                <Tag className={"progress-number"}>{`${props.ok + props.ko}/${props.total}`}</Tag >

            </div>
        </div>
    </React.Fragment>
}

export const CreateProject: React.FunctionComponent<PropsWithChildren<CreateProjectProps>> = (props) => {

    const [truss, setTruss] = React.useState<Truss>({project_name: '', truss_list: [], is_unMatching_truss_exist: false});
    const [isOpen, setOpen] = React.useState(false)
    var popconfirmRef = React.useRef<HTMLDivElement | null>(null);;
    const [visible, setVisible] = React.useState(false);
    const [projectName, setProjectName] = useState<string>('');
    const [progress, setProgress] = useState<TrussProgress>({ko: 0, ok: 0, status: "", total: 0});
    const [loading, setLoading] = useState<boolean>(false);

    const {isFileUploadEnabled} = React.useContext(FileUploadSettingContext)

    const IOCtx = React.useContext(IOContext);
    const hasUploaded = useRef(false);

    const handleVisibleChange = (visible : any) => {
        if(truss.is_unMatching_truss_exist)
            setVisible(visible);
      };

    React.useEffect(() => {
        handleVisibleChange(truss.is_unMatching_truss_exist)
    }, [truss.is_unMatching_truss_exist]);



    const handleDocumentClick = (event: any) => {
        if (popconfirmRef.current && !popconfirmRef.current.contains(event.target)) {
            setVisible(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const columns = [
        {
            title: 'Action',
            key: 'test',
            render: (record:any) => {
                return <Button type={"primary"} icon={<DeleteOutlined />} onClick={(e) => {removeTruss(record)}}/>
            },
        },
        {
            title: 'Project Name',
            dataIndex: 'project_name',
            key: 'project_name'
        },
        {
            title: 'Truss',
            dataIndex: 'truss_label',
            key: 'truss_label'
        },
    ];

    const cancel = React.useCallback(() => {
        setTruss({project_name: '', truss_list: [], is_unMatching_truss_exist: false});
        setProgress({ko: 0, ok: 0, status: "", total: 0})
        setOpen(false);
    }, []);

    const createAnother = React.useCallback(() => {
        setTruss({project_name: '', truss_list: [], is_unMatching_truss_exist: false});
    },[truss])

    const processFile = React.useCallback((file: any) => {
        return new Promise((resolve, reject) => {
            const readFile = new FileReader()
            readFile.onload = function (e: ProgressEvent<FileReader>) {
                let contents = e.target?.result
                if (contents && typeof contents === "string") {

                    try {
                        const jsonData = JSON.parse(contents)
                        const match = contents.match(/trussInfo/i)
                        if (match) {
                            const truss_info_key = match[0]
                            const {trussLabel, item, order} = jsonData[truss_info_key]

                            if (!trussLabel || !item)
                                reject('Missing truss info')
                            const truss_row: TrussRow = {
                                key: item,
                                project_name: order,
                                truss_label: trussLabel,
                                json: jsonData,
                                file: file.originFileObj,
                                file_name: file.name,
                                item: item
                            }
                            resolve(truss_row)
                        }
                        reject("No trussInfo")
                    } catch (e) {
                        reject(e)
                    }
                }
            }
            readFile.readAsText(file.originFileObj)
        })
    },[])

    const processAllFiles = React.useCallback(async (fileList: any) => {

        const trussList = truss.truss_list || []
        const invalidFiles: string[] = []
        const duplicateMap = new Map<TrussRow, TrussRow[]>()

        for (const file of fileList) {
            try{
                const truss_row = await processFile(file)
                if (truss_row){
                    const duplicates = trussList.find(row => row.key === truss_row.key)
                    if (!duplicates) {
                        trussList.push(truss_row as TrussRow)
                    } else {
                        if (duplicateMap.has(duplicates)) {
                            const d = duplicateMap.get(duplicates);
                            duplicateMap.set(duplicates, [...d, truss_row]);
                        } else {
                            duplicateMap.set(duplicates, [truss_row as TrussRow]);
                        }
                    }
                }
            } catch (e) {
                invalidFiles.push(file.name)
                console.error('Error processing files:', e)
            }
        }
        if (invalidFiles.length > 0 || duplicateMap.size > 0 ) {
            invalidFileInfo(invalidFiles, duplicateMap, trussList)
        } else {
            setTruss((prevState: Truss) => (
                {
                    ...prevState,
                    truss_list: [...trussList]
                }))
            hasUploaded.current = false
            setLoading(false)
        }

    },[truss])

    const invalidFileInfo = React.useCallback((invalidFiles: string[], duplicates: Map<TrussRow, TrussRow[]>, trussList: TrussRow[]) => {
        const title = (invalidFiles.length > 0 && duplicates.size > 0)
            ? 'Invalid and duplicate files found'
            : (invalidFiles.length > 0)
            ? 'Invalid files found'
            : 'Duplicate files found';
        Modal.confirm({
            title: <Trans i18nKey={`modal.valid_invalid_truss.title.${title}`} defaults={title} />,
            width: '40vw',
            content: (<InvalidFileInfo invalidFiles={invalidFiles} duplicates={duplicates}/>),
            okText: <Trans i18nKey={"modal.valid_invalid_truss.button.continue"} defaults={"Continue"} />,
            cancelText: <Trans i18nKey={"modal.valid_invalid_truss.button.cancel"} defaults={"Cancel"} />,
            onOk: ()=>{
                setTruss((prevState: Truss) => (
                    {
                        ...prevState,
                        truss_list: [...trussList]
                    }))
                hasUploaded.current = false
                setLoading(false)
                Modal.destroyAll()
            },
            onCancel: ()=>{
                hasUploaded.current = false
                setLoading(false)
                Modal.destroyAll()
            }
        });
    }, [truss.truss_list])

    const importTrusses = React.useCallback((fileList: any) => {

        if (hasUploaded.current) return
        hasUploaded.current = true

        setLoading(true)
        processAllFiles(fileList)


    },[truss])

    const removeTruss = React.useCallback(async (value: TrussRow) => {
        const trussList = truss.truss_list
        setTruss((prevState: Truss) => (
            {
                ...prevState,
                truss_list: trussList.filter(truss_row => truss_row.key !== value.key)
            }
        ))
    }, [truss]);

    const ctx = React.useContext(ProjectTreeDataContext)

    const onFinish = React.useCallback(async (values: any) => {
        const projectNameInput = values.project_name;
        const trussList = truss.truss_list;
        let is_unMatching_truss_exist = false

        setProjectName(values.project_name)

        if (trussList.length > 0) {
            const unMatchingTrussesFound = trussList.find((row: TrussRow) => row.project_name !== projectNameInput)
            if (unMatchingTrussesFound) {
                is_unMatching_truss_exist = true;
            }
            setTruss((prevState) => ({...prevState, project_name: projectNameInput, is_unMatching_truss_exist: is_unMatching_truss_exist}));
            console.log('un matching', is_unMatching_truss_exist)

            if (!is_unMatching_truss_exist) {
                await upload('ignore', truss.truss_list, projectNameInput)
            }
        } else {
            await createProjectOnly(projectNameInput);
        }
        ctx?.setReq({...(ctx?.req || {}), from:0 , type: 'all'})


    },[truss, props]);

    const confirm = React.useCallback(async (e: any) => {
        setLoading(true)
        const {truss_list, project_name} = truss;
        const res = await checkProjectExists(project_name);
        if (res.status === StatusCodes.OK) {
            await upload('update', truss_list, project_name);
        } else {
            setLoading(false)
        }
    },[truss, props])

    const ignore = React.useCallback(async () => {
        setLoading(true)
        const {truss_list, project_name} = truss;
        const matchingTrusses = truss_list.filter(truss_row => truss_row.project_name === project_name);
        const res = await checkProjectExists(project_name);
        if (res.status === StatusCodes.OK) {
            if (matchingTrusses.length > 0) {
                await upload('ignore', matchingTrusses, project_name);
            } else {
                await createProjectOnly(project_name);
            }
        } else {
            setLoading(false)
        }
    },[truss, props])

    React.useEffect(() => {

        IOCtx.socket?.on(`truss.analysis.${projectName}`, (data: TrussProgress) => {
            setProgress(data)
            setLoading(false)
            if (data.status === 'completed') {
                notification.success({message: <Trans i18nKey="notification.imported_files_count" values={{ count: data.ok || 0}} defaults="{{count}} files successfully imported." />})
                cancel()
            }
        })

        return () => {
            IOCtx.socket?.off(`truss.analysis.${projectName}`)
        }
    }, [IOCtx, projectName])


    const upload = React.useCallback(async (type: string, trussList: TrussRow[], projectName:string) => {
        const formData = new FormData();
        formData.append('project_name', projectName || 'project');
        formData.append('import_type', type);
        formData.append('truss_count', trussList.length.toString());

        const zip = new JSZip();
        for (let trussRow of trussList) {
            const {file} = trussRow
            zip.file(file.name, file);
        }

        await zip.generateAsync({type:"blob",compression: "DEFLATE"}).then(function(content) {
            const zipFile = new File([content],'test.zip',{type: "zip"});
            formData.append('file',zipFile);
            importProjectWithTrusses(formData).then((res) => {
                if (res?.data?.statusCode === 200) {
                    notification.success({message: <Trans i18nKey="notification.project_successfully_created" defaults="Successfully created a new project and truss upload started." />})
                    setTruss({project_name: '', truss_list: [], is_unMatching_truss_exist: false});
                } else {
                    notification.error({message: res?.data?.message || <Trans i18nKey={`notification.Error`} defaults={'Error'}/>})
                    cancel()
                }

            })
        })
    },[truss, props])

    const createProjectOnly = React.useCallback(async (projectNameInput: string) => {
        await createProject({project_name: projectNameInput}).then(res => {
            if (res?.data?.project_id) {
                notification.success({message: <Trans i18nKey={`notification.created_project_without_truss_success`} defaults={'Successfully created a new project without Trusses.'}/>})
            } else {
                notification.error({message: <Trans i18nKey={`notification.created_project_without_truss_fail`} defaults={'Error: Project creation failed.'}/>})
            }
            setLoading(false)
            cancel();
        });
    }, [props])

    const onFinishFailed = React.useCallback((errorInfo: any) => {
        console.log('Failed:', errorInfo);
        notification.error({message: <Trans i18nKey={`notification.created_project_with_truss_fail`} defaults={'Error : Project with trusses create action failed'}/>})
    },[props]);


    return <React.Fragment>
        <Modal
            title={<Trans i18nKey={`create.project.title`} defaults={'Create New Project'}/>}
            width={'40vw'}
            open={isOpen}
            onCancel={() => {cancel()}}
            footer={null}
            closable={true}
            destroyOnClose
            maskClosable={false}
            keyboard={false}
        >
            <Spin spinning={loading}>
            <div>
                <Divider />
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Form.Item
                            label="Project Name"
                            name="project_name"
                            rules={[
                                {
                                    required: true,
                                    pattern: /^[a-zA-Z0-9]+([_-][a-zA-Z0-9]+)*$/,
                                    message: 'Project name should only contain alphanumeric characters.'
                                }
                            ]}
                        >
                            <Input disabled={progress.status === 'processing'} type="text" id={"project_name_input"}/>
                        </Form.Item>
                    </div>

                    <Upload.Dragger
                        multiple={true}
                        customRequest={()=>{}}
                        onChange={({fileList})=>{
                            importTrusses(fileList)}}
                        fileList={[]}
                        showUploadList={false}
                        accept={".json"}
                        disabled={progress.status === 'processing'}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to import</p>
                    </Upload.Dragger>

                    {progress.status === 'processing' && <TrussImportProgress {...progress}/>}

                    <Divider />
                    <Table columns={columns} dataSource={truss.truss_list} pagination={false} scroll={{ y: 240 }} />
                    <Divider />
                    <Row>
                        <Col span={12}>
                            {/* TODO : Need to clarify the 'Create Another' functionality and implement it */}
                            {/*<Button style={{margin: 6}} onClick={createAnother}>*/}
                            {/*    Create Another*/}
                            {/*</Button>*/}
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="primary"
                                    disabled={progress.status === 'processing'}
                                    style={{ margin: 6 }}
                                    onClick={cancel}>
                                Cancel
                            </Button>
                            <Popconfirm
                                title="Force update truss projects"
                                description={`There are trusses not related to the given project. Please confirm update to project name in the input truss design?`}
                                onConfirm={confirm}
                                onCancel={ignore}
                                okText="Update and continue"
                                cancelText="Ignore mismatching trusses"
                                open={visible}                               
                                onOpenChange={handleVisibleChange}                             
                            >
                            <div ref={popconfirmRef}>
                            <Button
                                disabled={progress.status === 'processing'}
                                type="primary"
                                style={{ margin: 6 }}
                                htmlType="submit">
                                Create
                            </Button>
                            </div>
                            </Popconfirm>
                            
                        </Col>
                    </Row>
                </Form>
            </div>
        </Spin>
        </Modal>

        <Tooltip placement="top" title={(isFileUploadEnabled) ? "" : "File upload mode disabled by the administration"}>
            <Button style={{width: 250}} onClick={()=>setOpen(!isOpen)} icon={<FolderAddOutlined/>} disabled={!isFileUploadEnabled}><Trans i18nKey={'create.project.button.label'} defaults={"Create Project"}/></Button>
        </Tooltip>
    </React.Fragment>
}
