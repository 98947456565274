/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

// UserProfile.tsx

import React from 'react';
import { Card, Avatar, Typography, Button, Input, Space, notification } from 'antd';
import { CloseOutlined, EditOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons';
import { updateUserInfo } from '../../api';
import { UnitSwitch } from '../header';
import '../../styles/UserProfileCard.css'; // Import the stylesheet
import { Trans } from 'react-i18next';

const { Title, Text } = Typography;

interface UserProfileProps {
  user: {
    user_role: string;
    first_name: string;
    last_name: string;
    email: string;
    avatar: string;
  };
  setUserProfile: (user: any) => void;
  pref_unit?: string;
}

const EditableText: React.FC<{ labelName: string; labelValue: string; onEdit: (value: string) => void; email: string }> = ({
  labelName,
  labelValue,
  onEdit,
  email,
}) => {
  const [isEditing, setEditing] = React.useState(false);
  const [editedValue, setEditedValue] = React.useState(labelValue);

  const saveFunction = async (value: string) => {
    try {
      const updateData: Record<string, string> = { [labelName.toLowerCase().replace(/\s+/g, '_')]: value };
      const response = await updateUserInfo({
        email,
        ...updateData,
      });

      const { data = {} } = response;
      if (data?.statusCode === 200) {
        notification.success({message: <Trans i18nKey={'notification.User_updated_successfully'} defaults={"User updated successfully"}/>})
      } else {
        notification.error({message: <Trans i18nKey={'notification.User_update_failed'} defaults={"User update failed"}/>})
      }
    } catch (error) {
      console.error('Error while updating user info:', error);
    }
  };

  React.useEffect(() => {
    setEditedValue(labelValue);
  }, [labelValue]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    onEdit(editedValue);
    saveFunction(editedValue);
    setEditing(false);
  };

  const handleCancelClick = () => {
    setEditing(false);
  };

  return (
    <div className="form-field-container">
      <Text className="label">{labelName}</Text>
      {isEditing ? (
        <>
          <Space.Compact className='editable-input'>
            <Input
                value={editedValue}
                onChange={(e) => setEditedValue(e.target.value)}
                bordered={false}
                autoFocus 
              />
              <Button
                type="link"
                icon={<SaveOutlined />}
                onClick={handleSaveClick}
                size="small"
              />
          </Space.Compact> 
          <Button
            onClick={handleCancelClick}
            icon={<CloseOutlined />}
            size="small"
            type="link"
          />
        </>
      ) : (
        <>
          <div className="text-value">{labelValue}</div>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={handleEditClick}
            size="small"  // Adjust the size as needed (small, middle, large)
          />
        </>
      )}
    </div>
  );
};

const EditableDropDown: React.FC<{ name: string; value: any; }> = ({
  name,
  value
}) => {
  const [isEditing, setEditing] = React.useState(false);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleCancelClick = () => {
    setEditing(false);
  };

  return (
    <div className="form-field-container">
      <Text className="label">{name}</Text>
      {isEditing ? (
        <>
          <UnitSwitch/>
          <Button
            onClick={handleCancelClick}
            icon={<RightOutlined/>}
            size='small'
            type= 'link'
          />
        </>
      ) : (
        <>
          <div className="text-value">{value}</div>
          <Button
            icon={<EditOutlined />}
            onClick={handleEditClick}
            size='small'
            type= 'link'
          />
        </>
      )}
    </div>
  );
};

const UserProfileCard: React.FC<UserProfileProps> = ({ user, setUserProfile, pref_unit }) => {
  const handleEditFirstName = (value: string) => {
    setUserProfile({ ...user, first_name: value });
  };

  const handleEditLastName = (value: string) => {
    setUserProfile({ ...user, last_name: value });
  };

  return (
    <div className='profile-wrapper'>
      <Card className="user-profile-card">
        <div className="avatar-container">
          <Avatar size={64}>{user.avatar || "AD"}</Avatar>
        </div>
        <div className="role-container">
          <Title level={4} className="role-title">
            {user.user_role}
          </Title>
        </div>
        <EditableText labelName="First Name" labelValue={user.first_name} onEdit={handleEditFirstName} email={user.email}/>
        <EditableText labelName="Last Name" labelValue={user.last_name} onEdit={handleEditLastName} email={user.email} />
        <div className="form-field-container">
          <Text className="label">Email</Text>
          <div className="text-value">{user.email}</div>
        </div>
        <EditableDropDown name="Unit" value={pref_unit}/>
      </Card>
    </div>
    
  );
};

export default UserProfileCard;
