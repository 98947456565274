/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import {Polygon, System, Response} from 'detect-collisions'

const round = ({ x, y }) => ({ x: Math.round(x), y: Math.round(y) })

interface PolygonInfo {
    id: string,
    x: number,
    y: number,
    angle?: number,

}

interface PolygonInfoWithPoints extends PolygonInfo {
    angle: number,
    points: {x: number, y: number}[]
}

export default class CollisionDetection {

    private system : System
    private objects : Map<string, Polygon>
    private ids : Map<Polygon, string>

    constructor() {
        this.system = new System()
        this.objects = new Map<string, Polygon>()
        this.ids = new Map<Polygon, string>()
    }

    setup(polygonMap) {
        this.system = new System();
        this.objects.clear();
        this.ids.clear();

        for (const [id, box] of polygonMap) {
            const object = new Polygon({x:box.x, y:box.y}, box.points.map(round), {angle: box.angle})

            if (object.convexPolygons){
                object.convexPolygons.forEach((p, i) => {
                    p.setAngle(box.angle)
                })
            }
            this.system.insert(object)
            this.objects.set(id, object)
            this.ids.set(object, id)
        }

    }

    updateSetup(polygon: PolygonInfo) {
        const object = this.objects.get(polygon.id)
        if (object) {
            object.setPosition(polygon.x, polygon.y)
            const angle = polygon.angle
            if (angle !== undefined) {
                if (object.convexPolygons){
                    object.convexPolygons.forEach((p, i) => {
                        p.setAngle(angle)
                    })
                }
                object.setAngle(angle)
            }
            this.system.updateBody(object)
        }
    }

    addObject(polygon: PolygonInfoWithPoints) {
        const object = new Polygon({x:polygon.x, y:polygon.y}, polygon.points.map(round), {angle: polygon.angle})
        if (object.convexPolygons){
            object.convexPolygons.forEach((p, i) => {
                p.setAngle(polygon.angle)
            })
        }
        this.system.insert(object)
        this.objects.set(polygon.id, object);
        this.ids.set(object, polygon.id);
    }

    removeObject(id: string) {
        const object = this.objects.get(id)
        if (object) {
            this.system.remove(object)
            this.objects.delete(id)
            this.ids.delete(object)
        }
    }

    check() {
        const collisions = []

        this.system.checkAll((response)=>{
            const id1 = this.ids.get(response.a)
            const id2 = this.ids.get(response.b)
            if (id1 !== id2) {
                collisions.push([id1, id2])
            }
        })

        return collisions
    }

    checkOne(id) {
        const collisions = []
        const object = this.objects.get(id)
        if (object){
            this.system.checkOne(object, (response: Response) => {
                collisions.push([id, this.ids.get(response.b)])
            })
        }
        return collisions
    }


}