/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {Fragment, PropsWithChildren, useCallback, useContext} from "react";
import "../styles/Configuration.css"
import {Button, Divider, notification, Select, Space, Switch, Tag, Modal, Spin} from "antd";
import {setDefault, syncConfig, visualizeLoad, checkConnectivity, getFeConfig, updateConnection} from "../api";
import {DataContext, DataContextProvider, Request, Response} from "@q4us-sw/q4us-ui-v2/dist/util";
import {Table} from "@q4us-sw/q4us-ui-v2/dist/table";
import { Form } from "@q4us-sw/q4us-ui-v2/dist/form";
import { DefaultArgs } from "@q4us-sw/q4us-ui-v2/dist/form/FormV2";
import {Formatter} from "@q4us-sw/q4us-ui-v2";
import {Trans} from "react-i18next";
import {unstable_batchedUpdates} from "react-dom";
import {HttpStatusCode} from "axios";
import {ReloadOutlined} from "@ant-design/icons/lib";
import { PreferenceContext, UnitType } from "./user/UserPreference";


interface Data {
    rows: {
        [key: string]: any;
    }[];
    productionLines: {[key:string]: string}[];
    defaultConfig: {[key:string]: string} | undefined
}

interface EditSettingsProps {
    title: string;
    btnText: string;
    schema: string;
    id: string;
    row?: any;
    disabled?: boolean;
    initialValue?: any;
    fetch?: any;
    sort?: any;
    filter?: any;
}

interface EditSettingsModalProps {
    title: string;
    schema: string;
    open: boolean;
    closeCallback: () => void;
    id: string;
    row: any;
    initialValue?: any;
    fetch: any;
    sort?: any;
    filter?: any;
}

const EditSettingsModal: React.FunctionComponent<EditSettingsModalProps> = (props) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    return (
        <Modal
            title= {"Update Production Server Connection"}
            open={props.open}
            onCancel={() => props.closeCallback()}
            footer={null}
            destroyOnClose
        >
            {/* @ts-ignore */}
            <Spin spinning={loading}>
                {/* @ts-ignore */}
                <Form
                    register={(reg) => reg}
                    {...DefaultArgs}
                    formatRegister={(reg: any) => reg}
                    layout={"horizontal"}
                    schema={props.schema}
                    config={async (schema: string) => {
                        const res = (await getFeConfig(schema)) || {};
                        const { data: { rows = [] } = {} } = res;
                        return rows;
                    }}
                    initialValue={props.initialValue}
                    submit={async (values: { [key: string]: any }) => {
                        setLoading(true);
                        const response = await updateConnection(
                            values as any,
                            props.id
                        );

                        setLoading(false);
                        if (response?.status === 200) {
                            props.closeCallback();
                            notification.success({
                                message: 'Connection updated successfully',
                            });
                            await props.fetch?.({
                                sort: props.sort,
                                filter: props.filter,
                            } as any);
                        } else {
                            notification.error({ message: "Failed to update connection"});
                        }
                        return Promise.resolve(true);
                    }}
                    disableAccept={true}
                />
            </Spin>
        </Modal>
    );
};

const EditSettingsForm: React.FunctionComponent<EditSettingsProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    return (
        <Fragment>
            <Button onClick={() => setOpen(true)} disabled={props.disabled}>Update</Button>
            <EditSettingsModal
                title= {props.title}
                schema={props.schema}
                open={open}
                closeCallback={() => setOpen(false)}
                id={props.id}
                row={props.row}
                initialValue={props.initialValue}
                fetch = {props.fetch}
            />
        </Fragment>
    )
}

export const Configurations: React.FunctionComponent<PropsWithChildren> = () => {
    const prefCtx = React.useContext(PreferenceContext)
    const [data, setData] = React.useState<Data>({rows: [], defaultConfig: undefined, productionLines: []} );
    const [values, setValues] = React.useState<any|undefined>(data.defaultConfig)
    const [unitType,setUnitType] = React.useState(prefCtx?.prefInfo?.pref?.units || UnitType.metric)
    const ctx = useContext(DataContext)
    React.useEffect(()=>{
        setUnitType(prefCtx?.prefInfo?.pref?.units || UnitType.metric)
        ctx.fetch?.({
            sort: [{"is_default": "desc"}],
        } as Request)
    },[prefCtx?.prefInfo.pref.units])
    const load = React.useCallback(async (req: Request)=>{
        const {data = {rows: []} as Response} = await visualizeLoad(req).catch(e => {
            console.error(e)
            setData({rows: [], defaultConfig: undefined, productionLines: []} )
        }) || {}
        if (data.rows.length !== 0) {
            const lines = data.rows.map((row:{production_line: string, friendly_name: string})=>{
                return {value:row.friendly_name, key: row.production_line}
            })
            const defaultConfigRow = data.rows.filter((row:{is_default: boolean, production_line: string})=>{
                return row.is_default
            })
            const defaultConfig = (defaultConfigRow.length === 0) ? undefined : {key: defaultConfigRow[0].production_line, value: defaultConfigRow[0].friendly_name}

            unstable_batchedUpdates(() => {
                setData((prevState)=>(
                    {
                        ...prevState,
                        rows: data.rows,
                        defaultConfig: defaultConfig,
                        productionLines: lines
                    }
                ))
                setValues(defaultConfig)
            })


        }
    },[data])

    const onChange = useCallback((value: string| string[]|undefined, selected: {[key:string]: string}[])=>{
        const selected_lines = selected.map(({key, value})=>{
            return key
        })

        setValues(value)
        ctx.fetch?.({
            sort: [{"is_default": "desc"}],
            filter: [{name: "production_line", value: selected_lines, comparator: "include"}],

        } as Request)

    },[])

    React.useEffect(()=>{
        load(
            {
                tableName: 'configuration_view',
                sort: [{"production_line": "asc"}]
            }
        ).catch(e=>console.error(e));

    }, [])

    const SetDefault: Formatter = (props)=> {
        return <React.Fragment>
            <DataContext.Consumer>
                    {d=> <Switch size={'small'} checked={props.value} onChange={async ()=>{
                        const res = await setDefault(d.rows[props.row].production_line)
                        if (res?.data?.data.set.rowCount === 1) {
                            notification.success({message: <Trans i18nKey={`notification.set_default_success`} values={{ productionline: d.rows[props.row].friendly_name }} defaults={`Set {{productionline}} as default.`}/>})
                            d.fetch?.({
                                sort: d.sort,
                                filter: d.filter
                            } as Request)
                        } else {
                            notification.error({message: <Trans i18nKey={`notification.set_default_error`} defaults={'Error: Something wrong!'}/>})
                        }
                    }} />}
                </DataContext.Consumer>
        </React.Fragment>
    }

    const JsonResolver: Formatter = (props) => {
        return <React.Fragment>
            <div>
                <ul>
                    {Object.entries(props.value).map(([key, value]) => {
                         let displayValue = value;
                         if ((key === 'MaxX' || key === 'MaxY')) {
                            displayValue = prefCtx?.convertMMtoFIS(value);   
                         }
                        return (
                        
                        <li key={key}>
                            <Tag key={key} color="default">{`${key} : ${displayValue}`}</Tag>
                        </li>
                    )})}
                </ul>
            </div>
        </React.Fragment>
    }

    const CheckConnectivity: Formatter = (props)=> {
        return <React.Fragment>
            <DataContext.Consumer>
                {d=> <Button size={'medium'} icon={<ReloadOutlined />} onClick={async ()=>{
                    const res = await checkConnectivity(d.rows[props.row].prod_server_connection);
                    if (res?.data === HttpStatusCode.Ok) {
                        notification.success({message: <Trans
                            i18nKey="notification.check_connectivity_success"
                            values={{ productionline: d.rows[props.row].production_line }}
                            defaults={`Connection to {{productionline}} successful`}
                            
                          />})
                    } else {
                        notification.error({message: <Trans
                            i18nKey="notification.check_connectivity_error"
                            values={{
                                productionline: d.rows[props.row].production_line,
                                status: res?.data,
                              }}
                            defaults={`Connection to {{productionline}} failed with status {{status}}`}
                            
                          />})
                    }

                }} />}
            </DataContext.Consumer>
        </React.Fragment>
    }

    const EditSettings: Formatter = (props) => {
        return (
            <React.Fragment>
                <DataContext.Consumer>
                    {(res) => (
                        <>
                            <Space>
                                <EditSettingsForm
                                    title= "Settings"
                                    btnText = "Update"
                                    schema={'update_connection_form'}
                                    id={res.rows[props.row].production_line}
                                    row={res.rows[props.row]}
                                    initialValue={
                                        {'host' : res.rows[props.row].prod_server_connection.Host,
                                        'port' : res.rows[props.row].prod_server_connection.Port}
                                    }
                                    fetch  = {res.fetch}
                                    sort = {res.sort}
                                    filter = {res.filter}
                                />
                            </Space>
                        </>

                    )}
                </DataContext.Consumer>
            </React.Fragment>
        );
    };

    return <React.Fragment>
            <div className={"wrapper"}>
                <div className={"action-container"}>
                    <div className={'select-project'}>
                        <Select
                        key={data.defaultConfig?.key}
                        mode="multiple"
                        maxTagCount= {2}
                        defaultValue={data.defaultConfig?.value}
                        showArrow={true}
                        options={data.productionLines}
                        value={values}
                        onChange={(value, selected)=> {
                            onChange(value, selected)
                        }}
                    />
                        <Button><Trans i18nKey={`button.configurations.Compare`} defaults={'Compare'}/></Button>
                    </div>
                    <div className={'add-button'}>
                        <Button type="primary" className="action-button" disabled={true}><Trans i18nKey={`button.configurations.Add_new_production_line`} defaults={'Add new production line'}/></Button>
                        <Button danger onClick={async ()=>{
                            const res = await syncConfig()
                            if (res?.data?.data.update.rowCount > 0) {
                                notification.success({message: <Trans i18nKey={`notification.sync_success`} defaults={`Sync Successful.`}/>})
                                ctx.fetch?.({
                                    sort: ctx.sort,
                                } as Request)
                                load(
                                    {
                                        tableName: 'configuration_view',
                                        sort: [{"production_line": "asc"}]
                                    }
                                ).catch(e=>console.error(e));
                            } else {
                                notification.error({message: <Trans i18nKey={`notification.sync_error`} defaults={'Error in sync!'}/>})
                            }
                        }
                        }><Trans i18nKey={`button.configurations.Sync_configuration`} defaults={'Sync configuration'}/></Button>
                    </div>
                </div>
                <Divider style={{margin:'10px 0'}}/>
                <div className={"table-container"}>
                    <PreferenceContext.Consumer>{ctx=>
                    <Table key={'configs'}
                    schema="configuration"
                    size="small"
                    rowKey={"production_line"}
                    scroll={{y: 'calc(100vh - 210px)'}}
                    register={(registry) => registry}
                    formatRegister={(registry) => {
                        registry.register("ACTION", SetDefault)
                            .register("JSON", JsonResolver)
                            .register("BUTTON", CheckConnectivity)
                            .register("UPDATE", EditSettings)
                        return registry
                    }}/>
                    }
                    </PreferenceContext.Consumer>
                    
                </div>
            </div>
    </React.Fragment>
}

export const ConfigurationView: React.FunctionComponent<PropsWithChildren<any>> = (props) => {

    return <React.Fragment>
        <DataContextProvider save={async (d) => true} delete={async (d) => true} data={async (req) => {
            const res = await visualizeLoad({
                tableName: 'configuration_view',
                filter: req.filter || [{name: "is_default", value: true, comparator: "="}],
                sort: req.sort,
            }) as Response

            return res as Response
        }}>
            <Configurations/>
        </DataContextProvider>
    </React.Fragment>
}