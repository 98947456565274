/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import jwtDecode from "jwt-decode";

import { Form, Input, Button, Modal, Space, Typography } from 'antd';
import {AuthConfig, AuthProps, IAuth} from "./auth";
import { PoweroffOutlined } from "@ant-design/icons";

class AuthImpl implements IAuth{
    refreshToken: string = "";
    token?: string;
    listeners: ((state: boolean)=>void)[] = []
    props?: AuthConfig;
    user?: string

    configure(props:AuthConfig){
        this.props = props;
        const key = `${this.props.storageKey || 'q4us-auth'}.refreshToken`;
        if(this.props.storage === "local" && localStorage != null){
            this.refreshToken = localStorage.getItem(key) || "";
        }else if(window != null){
            this.refreshToken = window.sessionStorage.getItem(key) || "";
        }
    }

    saveRefreshToken(refreshToken:string): void{
        const key = `${this.props?.storageKey || 'q4us-auth'}.refreshToken`;
        if(this.props?.storage === "local" && localStorage != null){
            localStorage.setItem(key,refreshToken);
        }else if(window != null){
            window.sessionStorage.setItem(key,refreshToken);
        }
    }

    async fetchTokenFromRefreshToken(): Promise<string>{
        if(this.props){
            const {refreshToken = "",token} = await this.props.refresh(this.refreshToken) || {}
            this.token = token;
            this.refreshToken = refreshToken;
            this.saveRefreshToken(refreshToken)
            return token;
        }else{
            return Promise.reject("Not Configured")
        }
    }

    async getToken(): Promise<string> {

        if(this.token){
            const {exp}: any = jwtDecode(this.token)
            if(this.props?.isExpired){
                if(await this.props.isExpired(this.token))
                    return await this.fetchTokenFromRefreshToken();
                else
                    return this.token;
            }else{
                const now = new Date();
                if((now.getTime() +1) >= (exp - 2) * 1000){
                    return await this.fetchTokenFromRefreshToken();
                }else
                    return this.token;
            }


        }else if(this.refreshToken && this.refreshToken.length > 0){
            return await this.fetchTokenFromRefreshToken();
        }
        return Promise.reject("Not Signed In");
    }


    async signIn(userName: string, password: string, clientId?:string): Promise<boolean> {
        if(this.props){
            const {token,refreshToken = ""} = await this.props.signIn?.(userName,password,clientId) || {}
            this.token = token;
            this.refreshToken = refreshToken;
            this.user = userName;
            this.notify();
            return token != null;
        }else{
            return Promise.reject("Not Configured")
        }
    }
    async signInWithCode(authorizationCode: string, redirectUri: string){
        if(this.props){
            const {token,refreshToken = ""} = await this.props.signInWithCode?.(authorizationCode,redirectUri) || {}
            this.token = token;
            this.refreshToken = refreshToken;
            if(token){
                const {preferred_username}: any = jwtDecode(token,)
                this.user = preferred_username
            }
            this.notify();
            return token != null;
        }else{
            return Promise.reject("Not Configured")
        }
    }

    async signOut(): Promise<boolean> {
        if(this.props){
            const state = await this.props.signOut();
            if(state) {
                this.token = undefined;
                this.refreshToken = "";
                this.user = undefined;
            }
            this.notify();
            return state;
        }else{
            return Promise.reject("Not Configured")
        }
    }

    subscribe(listener: (state: boolean) => void): void {
        this.listeners.push(listener)
    }

    notify(){
        this.listeners.forEach(o=>{
            o(this.token != null)
        })
    }

    isSignedIn(): boolean {
        return this.token != null;
    }

    isConfigured(): boolean {
        return this.props !=null;
    }

    getUser(): string | undefined {
        return this.user;
    }

}

export const Auth = new AuthImpl();