/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {ReactNode} from "react";
import {getFeConfig} from "../../api";
import {FormElementProps} from "@q4us-sw/q4us-ui-v2/dist/form/def";
//import {resolve} from "@q4us-sw/q4us-ui/lib/Cell";
import '../../styles/InfoGroup.css'
import {Trans} from "../reactive/Trans";
export interface InfoGroupProps {
    schema: string,
    data: { [key: string]: any }
    label: string,
    customRenderValue?: { [key: string]: (value: any, row: any, index: number, column: FormElementProps, editable?: boolean) => ReactNode }
    customRenderAction?: { [key: string]: (value: any, row: any, index: number, column: FormElementProps, editable?: boolean) => ReactNode }
}


    export const InfoGroup: React.FunctionComponent<InfoGroupProps> = (props) => {

    const [cfg, setCfg] = React.useState<FormElementProps[]>([])

    const load = React.useCallback(async () => {
        const {data: {rows = []} = {}} = await getFeConfig(props.schema)
        setCfg(rows);
    }, [props])

    React.useEffect(() => {
        load()
    }, [])

    return <div className={`info-group`}>
        <table className={`info-group-table`}>
            <thead>
            <tr>
                <td colSpan={3}>
                    <div className={`info-title`}>{props.label}</div>
                </td>
            </tr>
            </thead>
            <tbody>
            {props.data != null && cfg?.map((o,i) => {

                const {customRenderAction = {}, customRenderValue = {}} = props;
                const {[o.name]: action = (value: any, row: any, index: number, column: FormElementProps, editable?: boolean) => undefined} = customRenderAction;
                const {
                    [o.name]: value = (value: any, row: any, index: number, column: FormElementProps, editable?: boolean) => undefined} = customRenderValue;

                return <tr>
                    <td style={{maxWidth: 450}} >
                        <div className={`cell info-label`}><Trans i18nKey={`settings.form.${o.title}`} defaults={o.title}/></div>
                    </td>
                    <td>
                        <div className={`cell .data-cell`}>{value(props.data[o.name], props.data,i,o,false )}</div>
                    </td>
                    <td>
                        <div className={`cell`}>{action(props.data[o.name], props.data,i,o,false )}</div>
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    </div>
}
