/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import './styles/index.css'
import {Breadcrumb, Button, Empty, Modal, notification, Tooltip, Tree,Switch ,Checkbox, Radio  } from "antd";
import {ItemType} from "antd/lib/breadcrumb/Breadcrumb";
import {Trans,ErrorTrans} from "../reactive/Trans";
import {
    EditOutlined,
    HomeFilled,
    EyeInvisibleOutlined,
    EyeOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined, BugOutlined
} from "@ant-design/icons";
import {RecentItems} from "./RecentItems";
import {ButtonType, Keys, RecentItemTypes, ShortCutKey} from "./defs";
import {Tab, Tabs} from './Tabs'
import {CreateProject} from "../CreateProject";
import {Projects, ProjectTreeProvider} from "./Projects";
import {EditProject} from "../EditProject";
import {TrussFeatures} from "./TrussFeatures"
import {RecipeView} from "./RecipeView/RecipeView"
import {EditContext, EditContextProvider} from "../recipeVisualize/util";
import {fetchDefaultConfig, fetchErrorReportTrussInfo, recipeRecalculate} from "../../api";
import {EditServiceObj} from "../recipeVisualize/EditService";
import {StatusCodes} from "http-status-codes";
import {
    RecipeDetailContext,
    RecipeDetailContextProvider,
    ErrorSelectionContextProvider,
    ErrorSelectionContext,
    ErrorType,
    LayerSelectionContext,
    LayerSelectionContextProvider,
    ProjectTreeCollapseContextProvider, ProjectTreeCollapseContext, FileUploadSettingContextProvider
} from "./util";
import { DataNode } from "antd/es/tree";
import {RecipeContext ,RecipeContextProvider} from "../recipeVisualize/util";
import { getRecipe,getRecipeForConfig} from "../../api";
import {initialLayerMapping, LayerGroups} from "../../components/recipeVisualize/def"
import {CloneLatestVersion} from "./RecipeView/CloneLatestVersion"
import { TrussVisualization } from "../trussview/TrussVisualization"
import { PreferenceContext } from "../user/UserPreference";
import {ErrorReportModal} from "../user/ErrorReport/ErrorReportModal";
import {TrussInfo} from "./TrussInfo";
import {EventListenerContext} from "../listeners/keybord";

export enum TabTypes {
    Collapse = 'collapse',
    Projects = 'projects',
    Layers = 'layers',
    Errors = 'errors',
    Warnings = 'warnings',

}

export const ProjectTabs = [
    {key: TabTypes.Projects, label: 'Projects'},
    {key: TabTypes.Layers, label: 'Layers'},
    {key: TabTypes.Errors, label: 'Errors'},
    {key: TabTypes.Warnings, label: 'Warnings'}

];
export type ErrorWarnings = {
    errors: string[]; // Assuming errors is an array of strings, change the type accordingly
    warnings: string[]; // Assuming warnings is an array of strings, change the type accordingly
  };
export const RecipeEditButtons: React.FC = () => {
    const {editMode, setEditMode} = React.useContext(EditContext)
    const {registerHandlers, unregisterHandlers} = React.useContext(EventListenerContext)
    const location = useLocation()
    const [confirmOpen, setConfirmOpen] = React.useState(false)

    const{recipeInfo, load} = React.useContext(RecipeDetailContext)
    const recipeCtx = React.useContext(RecipeContext)

    const loadRecipeDetails = React.useCallback(()=>{
        load(location.state.recipe, location.state.timestamp_year)
    }, [location])

    const handleConfirmationKeyPress = React.useCallback((event: KeyboardEvent)=>{

        if (event.code === ShortCutKey.Cancel) {
            modalOnCancel();
        } else if (event.code === ShortCutKey.Done) {
            modalOnOk();
        }
    }, [])

    const onCancel = React.useCallback(()=>{
        if (EditServiceObj.dirty()) {
            setConfirmOpen(true)
        } else {
            EditServiceObj.cancelEditMode()
            setEditMode(false)
        }
    }, [])

    const onOk = React.useCallback(async ()=>{
        const id = location.state.recipe
        const timestamp_year = location.state.timestamp_year
        const recipe = EditServiceObj.exportRecipe()

        const res = await recipeRecalculate({id:id, timestamp_year: timestamp_year, recipe: recipe})

        if (res.status === StatusCodes.OK) {
            notification.success({message: <Trans i18nKey={'notification.Recipe_recalculation_started'} defaults={"Recipe recalculation started"}/>});
            EditServiceObj.cancelEditMode()
            setEditMode(false)
            loadRecipeDetails()
        } else {
            notification.error({message: <Trans i18nKey={'notification.Error_in_recipe_recalculation'} defaults={"Error in recipe recalculation"}/>})
        }
    }, [location])

    const modalOnOk = React.useCallback(()=>{
        EditServiceObj.cancelEditMode()
        setEditMode(false)
        setConfirmOpen(false)
    }, [])

    const modalOnCancel = React.useCallback(()=>{
        setConfirmOpen(false)
    }, [])

    const handleKeyPress = React.useCallback((event: KeyboardEvent)=>{
        if (editMode) {
            if (!confirmOpen) {
                if (event.code === ShortCutKey.Cancel) {
                    onCancel();
                } else if (event.code === ShortCutKey.Done) {
                    onOk();
                }
            }
        } else if (event.code === ShortCutKey.Edit && !recipeInfo.is_deployed && recipeInfo.status === 'completed' && recipeCtx?.recipe) {
            setEditMode(true);
        }

    },[editMode, confirmOpen, recipeInfo.is_deployed, recipeInfo.status, recipeCtx?.recipe])

    React.useEffect(()=> {
        registerHandlers('keyup', 'edit-key-handler', handleKeyPress)

        return () => {
            unregisterHandlers('keyup', 'edit-key-handler')
        }
    }, [editMode, confirmOpen, recipeInfo.is_deployed, recipeInfo.status, recipeCtx?.recipe])

    React.useEffect(()=> {

        if (confirmOpen) {
            document.addEventListener('keydown', handleConfirmationKeyPress)
        }
    }, [confirmOpen])


    return <div>
        {!editMode ?
            <Tooltip title={ recipeInfo.is_deployed ? <Trans i18nKey={'edit.recipe.tooltip.deployed'} defaults={"Recipe is deployed, Editing is not allowed"}/> : <Trans i18nKey={'edit.recipe.tooltip.notdeployed'} defaults={"Edit recipe {{key}}"} values={{key: Keys.E}}/>} >
                <div>
                <Button disabled={recipeInfo.is_deployed || recipeInfo.status !== 'completed' || !recipeCtx?.recipe} onClick={() => {setEditMode(!editMode)}}
                        icon={<EditOutlined/>}/>
                </div>
            </Tooltip> :
            <div className={"edit-confirm-buttons"}>
                <Tooltip title={<Trans i18nKey={'edit.recipe.tooltip.cancel'} defaults={"Cancel {{key}}"} values={{key: Keys.Esc}}/>}>
                    <Button onClick={onCancel}><Trans i18nKey={'edit.recipe.confirm.button.cancel'} defaults={"Cancel"}/></Button>
                </Tooltip>
                <Tooltip title={<Trans i18nKey={'edit.recipe.tooltip.done'} defaults={"Done {{key}}"} values={{key: Keys.A}}/>} >
                    <Button onClick={onOk} type="primary" ><Trans i18nKey={'edit.recipe.confirm.button.done'} defaults={"Done"}/></Button>
                </Tooltip>
            </div>}

        <Modal title="You have unsaved edits"
               open={confirmOpen}
               keyboard={false}
               onOk={modalOnOk}
               onCancel={modalOnCancel}
               afterClose={()=> document.removeEventListener('keydown', handleConfirmationKeyPress)}
               footer={[
                   <Tooltip key="cancel-tooltip" title={<Trans i18nKey={'edit.recipe.tooltip.cancel'} defaults={"Cancel {{key}}"} values={{key: Keys.Esc}}/>}>
                       <Button key="cancel" onClick={modalOnCancel}>
                           <Trans i18nKey={'edit.recipe.confirm.button.cancel'} defaults={"Cancel"}/>
                       </Button>
                   </Tooltip>,
                   <Tooltip key="continue-tooltip" title={<Trans i18nKey={'edit.recipe.tooltip.continue'} defaults={"Continue {{key}}"} values={{key: Keys.A}}/>}>
                       <Button key="continue" type="primary" onClick={modalOnOk}>
                           <Trans i18nKey={'edit.recipe.confirm.button.continue'} defaults={"Continue"}/>
                       </Button>
                   </Tooltip>,
               ]}
        >
            <p>All unsaved progress will be lost. Are you sure you want to exit?</p>
        </Modal>

    </div>
}
interface TrussCheckTabsProps {
    isProjectDetailsPage: boolean;
  }
const TrussCheckTabs: React.FC<TrussCheckTabsProps> = ({ isProjectDetailsPage }) => {
    const location = useLocation();
    const recipeCtx = React.useContext(RecipeContext)
    const {collapse} = React.useContext(ProjectTreeCollapseContext)
    
    const [tabs, setTabs] = React.useState<{key:TabTypes , label: any ,disabled?:boolean}[]>([
        { key: TabTypes.Projects, label: <Trans i18nKey={`tabs.vertical.Projects`} defaults={'Projects'}/> },
        { key: TabTypes.Layers, label: <Trans i18nKey={`tabs.vertical.Layers`} defaults={'Layers'}/> },
        { key: TabTypes.Collapse, label: '' },
      ])
    const [recipedata, setRecipeData] = React.useState<ErrorWarnings>({ errors: [], warnings: [] });
    React.useEffect(() => {
        
        if (isProjectDetailsPage && recipeCtx?.recipe != undefined) {
            
            const errorClassName = recipedata?.errors?.length > 0 ? 'red-text' : '';
            const warningClassName = recipedata?.warnings?.length > 0 ? 'amber-text' : '';

            setTabs([
                { key: TabTypes.Projects, label: <Trans i18nKey={`tabs.vertical.Projects`} defaults={'Projects'}/> },
                { key: TabTypes.Layers, label: <Trans i18nKey={`tabs.vertical.Layers`} defaults={'Layers'}/> },
                { key: TabTypes.Errors, label: (
                  <span className={errorClassName}>
                    <Trans i18nKey={`tabs.vertical.Errors`} defaults={'Errors'}/> ({recipedata?.errors?.length || 0})
                  </span>
                ) },
                { key: TabTypes.Warnings, label: (
                  <span className={warningClassName}>
                    <Trans i18nKey={`tabs.vertical.Warnings`} defaults={'Warnings'}/> ({recipedata?.warnings?.length || 0})
                  </span>
                ) },
                { key: TabTypes.Collapse, label: '' },
              ]);
        } else {
            // Reset tabs to the default state when on other pages
            setTabs([
                { key: TabTypes.Projects, label: <Trans i18nKey={`tabs.vertical.Projects`} defaults={'Projects'}/> },
                { key: TabTypes.Layers, label: <Trans i18nKey={`tabs.vertical.Layers`} defaults={'Layers'}/> ,disabled:true},
                {key: TabTypes.Errors, label: <Trans i18nKey={`tabs.vertical.Errors`} defaults={'Errors'}/> ,disabled:true},
                {key: TabTypes.Warnings, label: <Trans i18nKey={`tabs.vertical.Warnings`} defaults={'Warnings'}/>,disabled:true},
                { key: TabTypes.Collapse, label: '' },
            ]);
        }
    }, [recipedata])
    React.useEffect(() => {
        
        if (isProjectDetailsPage) {
            const fetchData = async () => {
                try {
                    const data = recipeCtx?.recipe
                    setRecipeData({errors:data?.errors ,warnings:data?.warnings})
                }
                catch(error) 
                {
                    console.error("Error fetching data:", error);
                }
                
            }
            fetchData();
        }

    }, [location,recipeCtx])

    return (
      <div className={`tc-projects ${collapse? 'collapse': ''}`}>
        <Tabs default={TabTypes.Projects} options={tabs}>
            {!collapse && <React.Fragment>
                <Tab for={TabTypes.Projects}>
                    <Projects />
                </Tab>

                {isProjectDetailsPage && (
                    <>
                        <Tab for={TabTypes.Layers}>
                            <LayerDataViewer/>
                        </Tab>

                        <Tab for={TabTypes.Errors}>
                            <ErrorDataViewer type={'error'} />
                        </Tab>
                        <Tab for={TabTypes.Warnings}>
                            <ErrorDataViewer type={'warning'} />
                        </Tab>
                    </>
                )}
            </React.Fragment>}

        </Tabs>
      </div>
    );
  };


export const TrussCheck: React.FC<any> = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const [isRecipeNeed,setIsRecipeNeed ] =React.useState(false)
    const [paramsArray, setParamsArray] = React.useState<string[]>([]);
    const [openErrorReport, setOpenErrorReport] = React.useState<boolean>(false)
    const [defaultConfig, setDefaultConfig] = React.useState<any>();
    const [defaultERValues, setDefaultERValues] = React.useState<any>();
    const data =useParams();

    const loadDefaultConfig = async () => {
        const {data} = await fetchDefaultConfig() || {};
        setDefaultConfig(data);
    }

    const loadErrorReportTrussInfo = async () => {
        const values = {
            project: location?.state?.project || 'undefined',
            truss: location?.state?.truss || 'undefined',
            recipe: location?.state?.recipe || 'undefined'
        }
        const {data} = await fetchErrorReportTrussInfo(values)
        setDefaultERValues({values: values, labels: data});
    }

    React.useEffect(() => {
        const dataParts = data?.['*']?.split('/');
        if (dataParts?.length === 4) {
          const [project, truss, config, recipe] = dataParts;
          setParamsArray([project, truss, config, recipe]);
          setIsRecipeNeed(true);
    
        }
        else
        {
            setIsRecipeNeed(false);
        }
      }, [location]);

    return <FileUploadSettingContextProvider>
        <ProjectTreeProvider>
        <ProjectTreeCollapseContextProvider>
        <ErrorSelectionContextProvider>
            <LayerSelectionContextProvider>
        <EditContextProvider>
            <RecipeDetailContextProvider>
                <RecipeDetailContext.Consumer>{ctx =>
            <RecipeContextProvider getRecipe={async () => {
                return getRecipeForConfig({ id: location?.state?.recipe, timestamp_year: location?.state?.timestamp_year, config:location?.state?.config });
            }} recipeState={ctx.recipeInfo.status} isRecipeNeed={isRecipeNeed} ignore={false}>
                
        <div className={"tc"}>
            <div className={"tc-main"}>
                <div className={"tc-toolbar"}>
                    <EditContext.Consumer>
                        {editCtx => !editCtx.editMode && <Breadcrumb
                            separator={null}
                            items={
                                location.pathname
                                    .split("/")
                                    .slice(3)
                                    .reduce((acc: ItemType[], cur, i) => {
                                        return [...acc, {
                                            title: <Trans i18nKey={["trusscheck", "crumbs", `${i}`, "label"].join(".")}
                                                          values={{path: cur}}
                                                          defaults={"{{path}}"}
                                            />,
                                            key: [acc.pop()?.key, cur].join("/")

                                        } as ItemType]

                                    }, [{
                                        title: <HomeFilled/>,
                                        key: location.pathname
                                            .split("/")
                                            .slice(0, 3).join("/")

                                    }] as ItemType[])
                            }
                            itemRender={(r, params) => {
                                return <div className={"bc-item"} key={r.key}
                                            onClick={() => navigate(`${r.key}`, {state: {...(location.state || {})}})}>
                                    <div className={"bc-arrow-back"}/>
                                    <div className={"bc-link"}>
                                        <span>{r.title}</span>
                                    </div>
                                    <div className={"bc-arrow"}/>
                                </div>

                            }}
                        />}
                    </EditContext.Consumer>
                    <ErrorReportModal open={openErrorReport} closeCallback={()=>setOpenErrorReport(false)}
                                      config={defaultConfig} defaultValues={defaultERValues}></ErrorReportModal>
                    <div>
                        <Routes>
                            <Route path={"/:project/:truss/:config/:recipe"}
                                   element={
                                       (location?.state?.is_recipe || location?.state?.is_recipe == null) ?
                                           <div className={'truss-feature-info-wrapper'}>
                                               <TrussFeatures/>
                                               {ctx!.recipeInfo.status === "completed" && <TrussInfo/>}
                                           </div> : null
                                   }/>
                        </Routes>
                    </div>
                    <div className={"tc-space"}/>
                    <Routes>
                        <Route path={"/:project/:truss/:config/:recipe"} element={
                            location?.state?.is_recipe || location?.state?.is_recipe == null ?
                                <div style={{display: 'flex'}}>
                                    <Tooltip title={'Report Error'}>
                                        <div style={{margin: '7px'}}>
                                            <Button icon={<BugOutlined/>} onClick={async()=> {
                                                await loadDefaultConfig().catch(e => {console.log(e)});
                                                await loadErrorReportTrussInfo().catch(e => {console.log(e)});
                                                setOpenErrorReport(true)}}/>
                                        </div>
                                    </Tooltip>
                                    <div style={{margin: '7px'}}><RecipeEditButtons/></div></div>:<Button disabled={true} icon={<EditOutlined/>} title="Cannot Edit, This Is Collision Recipe Only" ></Button>
                        }/>
                        <Route path={"/:project"} element={<EditProject buttonType={ButtonType.Icon}/>}/>
                        <Route path={"/:project/:truss"} element={<Tooltip title={'Report Error'}>
                            <div>
                                <Button icon={<BugOutlined/>} onClick={async()=> {
                                    await loadDefaultConfig().catch(e => {console.log(e)});
                                    await loadErrorReportTrussInfo().catch(e => {console.log(e)});
                                    setOpenErrorReport(true)}}/>
                            </div>
                        </Tooltip>}/>
                    </Routes>
                </div>
                <div className={"tc-view"}>

                    <Routes>
                        <Route path={"/"} element={<RecentItems type={RecentItemTypes.Projects}/>}/>
                        <Route path={"/:project"} element={<RecentItems type={RecentItemTypes.Trusses}/>}/>
                        <Route path={"/:project/:truss"} element={<RecentItems type={RecentItemTypes.Configs}/>}/>
                        <Route path={"/:project/:truss/:config"}
                               element={<RecentItems type={RecentItemTypes.Recipes}/>}/>
                    </Routes>
                    <div className={"tc-center"}>
                        <Routes>
                            <Route path={"/"} element={<CreateProject/>}/>
                            <Route path={"/:project"} element={<EditProject buttonType={ButtonType.Text}/>}/>
                            <Route path={"/:project/:truss"} element={<TrussVisualization truss_id={location?.state?.truss} timestamp_year={location?.state?.timestamp_year}/>}/>
                            <Route path={"/:project/:truss/:config"}
                                   element={<CloneLatestVersion/>}/>
                            <Route path={"/:project/:truss/:config/:recipe"}
                                   element={<RecipeView/>}/>
                        </Routes>
                    </div>
                </div>

            </div>

            <TrussCheckTabs isProjectDetailsPage={isRecipeNeed}/>

            </div>
            </RecipeContextProvider>}
            </RecipeDetailContext.Consumer>
            </RecipeDetailContextProvider>
        </EditContextProvider>
        </LayerSelectionContextProvider>
        </ErrorSelectionContextProvider>
        </ProjectTreeCollapseContextProvider>
    </ProjectTreeProvider>
        </FileUploadSettingContextProvider>

    // return <Routes>
    //     <Route path={""} element={<Link to={"project-id"}>Project</Link>}/>
    //     <Route path={":project"} element={<Link to={"truss-id"}>Truss</Link>}/>
    //     <Route path={":project/:truss"} element={<Link to={"config-id"}>Config</Link>}/>
    //     <Route path={":project/:truss/:config"} element={<Link to={"recipe-id"}>Recipe</Link>}/>
    //     <Route path={":project/:truss/:config/:recipe"} element={<div>Recipe</div>}/>
    // </Routes>

}

export const CollapseButton: React.FunctionComponent<any> = (props)=>{

    const ctx = React.useContext(ProjectTreeCollapseContext)

    return <React.Fragment>
        {ctx.collapse ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
    </React.Fragment>

}

export const ErrorDataViewer: React.FunctionComponent<{ type: 'error' | 'warning' } > = (props) => {
    const prefCtx = React.useContext(PreferenceContext);
    const errorCtx = React.useContext(ErrorSelectionContext)
    const location = useLocation();
    
    const translate = React.useCallback((data: any): DataNode[] => {
        let arr: DataNode[] = []
        // Add error to the tree data
        const handleClearErrors = () => {  
            errorCtx?.clear({type: ErrorType.Error});  
          };
        const handleClearWarrnings = () => {          
            errorCtx?.clear({type: ErrorType.Warning});         
          }
        if (props.type === 'error' && data.errors && data.errors.length > 0) {
            const errorNodes = data.errors.map((error: any, index: number) => ({
                title: <span className={"tdv-child-entry errors"} ><ErrorTrans i18nKey={`error_translations.${error.baseMessage}`} defaults={error.message}
                values={prefCtx?.processDimensions(error.baseParams)}/></span>,
                key: `${index}_${error.targetId}`,
                type: 'error'
            } as DataNode));
            arr.push({
                title: (<div className={"tdv-root-entry"}>
                <span className={"tdv-root-entry"}>
                <Trans i18nKey={'truss-check.recipe.tree.errors'} defaults={"Errors"}/> ({data.errors.length}) </span>
                        <Button type="primary" className="acknowledge-button" onClick={handleClearErrors}>
                            <Trans i18nKey={'truss-check.recipe.button.clear_error'} defaults={"Clear Highlighted Errors"}/>
                        </Button>
                        </div>),
                key: "errors",
                children: errorNodes,
            } as DataNode);
        }

        // Add warnings to the tree data
        if (props.type === 'warning' && data.warnings && data.warnings.length > 0) {
            const warningNodes = data.warnings.map((warning: any, index: number) => ({
                title: <span className={"tdv-child-entry warnings"} > <ErrorTrans i18nKey={`error_translations.${warning.baseMessage}`} defaults={warning.message}
                values={prefCtx?.processDimensions(warning.baseParams)}/></span>,
                key: `${index}_${warning.targetId}`,
                type: 'warning'
            } as DataNode));
            arr.push({
                title: (
                    <div className={"tdv-root-entry"}>
                        <span><Trans i18nKey={'truss-check.recipe.tree.warnings'} defaults={"Warnings"}/> ({data.warnings.length}) </span>
                            <Button type="primary" className="acknowledge-button" onClick={handleClearWarrnings}>
                                <Trans i18nKey={'truss-check.recipe.button.clear_warnings'} defaults={"Clear Highlighted Warnings"}/>
                            </Button>
                    </div>
                ),
                key: "warnings",
                children: warningNodes,
            } as DataNode);
        }
        return arr;
    }, [])
    
    const handleNodeSelect = (selectedKeys:any, { node }:any) => {
        // Access the node's key (which is the unique identifier you set for each node)
        const indexOfUnderscore = node.key.indexOf('_');
        if (indexOfUnderscore !== -1) {
            const TYPE_ID = node.key.substring(indexOfUnderscore + 1);
            
            errorCtx?.set({id:node.key , key:TYPE_ID ,message: node.title.props.children,className:node.title.props.className ,type:node.type});

        }    
      };


    return (
        
        <RecipeContext.Consumer>
            {ctx => ctx ? (<div className={"tdv-panel"}>
                
                <div className={"wrapper"}>
                    {
                        ctx?.recipe &&
                        <Tree
                            showLine
                            showIcon={false}
                            treeData={translate(ctx.recipe)}
                            onSelect={handleNodeSelect}
                            defaultExpandAll={true}
                            
                        />
                    }

                </div>

            </div>) : (
                <Empty />
            )}
        </RecipeContext.Consumer>
        
    )
}
interface LayerMapping  {
    [key: string]: {
        selectAll: number;
        data: {
            name: string;
            selected: number;
            id: string;
        }[];
    };
};
interface SelectedCategories {
    data: string[];
    size: number;
}
export const LayerDataViewer: React.FunctionComponent<any> = () => {
    const layerCtx = React.useContext(LayerSelectionContext);
    const [layerMapping, setLayerMapping] = React.useState<LayerMapping>(initialLayerMapping);
    const [selectedCategory, setSelectedCategory] = React.useState<string>('all');
    const [selectedCategories, setSelectedCategories] = React.useState<SelectedCategories>({data:["Grabbers", "Sidetool", "Member", "NailPlate", "BlockTool", "PressTool", "RemoveTool", "Id"],size:8});
    const location = useLocation()
    React.useEffect(() => {
        const updatedLayerMapping : LayerMapping = { ...initialLayerMapping };

        if ( layerCtx?.selectedComponent.length == 0) {

            for (const category in updatedLayerMapping) {
                if (updatedLayerMapping.hasOwnProperty(category)) {
                    const { data } = updatedLayerMapping[category];

                    // Set all items in the data to selected: 1
                    updatedLayerMapping[category].data = data.map((item) => ({
                        ...item,
                        selected: 1
                    }));

                    // Set the selectAll property to 1
                    updatedLayerMapping[category].selectAll = 1;
                }
            }
        }
        setLayerMapping(updatedLayerMapping);

    }, [location.pathname]);
    React.useEffect(() => {
        const selectedCategory = getMatchingCategory(selectedCategories);
        setSelectedCategory(selectedCategory);
    }, [selectedCategories])


    const getMatchingCategory = React.useCallback((selectedCategories: SelectedCategories) => {
        for (const group of LayerGroups) {
            if (
                selectedCategories?.data.every((category) => group.selectSet.includes(category)) &&
                selectedCategories.size === group.selectSet.length &&
                selectedCategories?.data.length === group.selectSet.length
            ) {
                return group.name;
            }
        }
        return 'unknown'; // Return a default value if no matching category is found
    }, [selectedCategories]);

    const handleRadioChange = React.useCallback((e: any) => {
        const selectedValue = e.target.value;
        const update = e.target.update ? e.target.update : false;

        const selectedLayers = LayerGroups.find((group) => group.name === selectedValue);
        if (selectedLayers && !update) {
            const updatedLayerMapping = { ...layerMapping };
            for (const category in updatedLayerMapping) {
                if (updatedLayerMapping.hasOwnProperty(category)) {
                    const { data } = updatedLayerMapping[category];
                    data.forEach((item) => {
                        // Set selected based on whether the item's id is in the selectedLayers.layers array
                        item.selected = selectedLayers.layers.includes(item.id) ? 1 : 0;
                    });
                }
            }
            setLayerMapping(updatedLayerMapping);
        }
    }, []);
    React.useEffect(() => {
        const updatedLayerMapping = { ...layerMapping };
        let isupdate = false;
        let additional = 0;
        let updatedSelectedCategories =[]
        for (const category in updatedLayerMapping) {
            const currentSelectAll = updatedLayerMapping[category].selectAll;
            if (updatedLayerMapping.hasOwnProperty(category)) {
                const { data } = updatedLayerMapping[category];
                // Calculate selectAll based on data selected values
                const selectAll = data.every((item) => item.selected === 1) ? 1 : 0;
                updatedLayerMapping[category].selectAll = selectAll;
                if(currentSelectAll !== selectAll){
                    isupdate = true;
                }
                if(selectAll === 1){
                    updatedSelectedCategories.push(category);
                }else
                {
                    if (data.some((item) => item.selected === 1)) {
                        additional++;
                    }
                }

            }
        }
        setSelectedCategories({data:updatedSelectedCategories,size:updatedSelectedCategories.length+additional});
        if(isupdate){

            setLayerMapping(updatedLayerMapping);
        }
        layerCtx?.clear(layerMapping);
    }, [layerMapping]);
    const handleLayerToggle = React.useCallback((category: string, layer: any) => {
        const updatedLayerMapping = { ...layerMapping };
        const { data } = updatedLayerMapping[category];

        data.forEach((item) => {
            if (item.id === layer.id) {
                item.selected = item.selected === 1 ? 0 : 1;
            }
        });

        const allSelected = data.every((item) => item.selected === 1);
        updatedLayerMapping[category].selectAll = allSelected ? 1 : 0;

        setLayerMapping(updatedLayerMapping);
    }, []);

    const hasSelectedItems = React.useCallback((category: string) => {
        const { data } = layerMapping[category];
        return data.some((item) => item.selected === 1) && data.some((item) => item.selected === 0);
    }, []);
    const translate = React.useCallback((): DataNode[] => {
        let arr: DataNode[] = []

        const handleToggle = (checked: boolean, childKeys: number[],category:any) => {
            if (checked) {
                layerMapping[category].data = layerMapping[category].data.map(item => ({
                    ...item,
                    selected: 1
                }));

                layerMapping[category].selectAll = 1;
                setLayerMapping({ ...layerMapping });
            } else {
                layerMapping[category].data = layerMapping[category].data.map(item => ({
                    ...item,
                    selected: 0
                }));
                layerMapping[category].selectAll = 0;
                setLayerMapping({ ...layerMapping });

                //
            }
        };

        let layerNumber = 0;
        for (const category in layerMapping) {
            if (layerMapping.hasOwnProperty(category)) {
                const layerNodes = (layerMapping as LayerMapping)[category].data.map((layer) => ({
                    title: (
                        <div key={layer.id} className={"tdv-child-entry"}>
                        <span>
                            {<Trans i18nKey={`LayersPage.CompName.${layer.name}`} defaults={layer.name}/>}
                            {layer.selected === 1 ? (
                                <EyeOutlined
                                    style={{ fontSize: '16px', cursor: 'pointer' ,position: 'absolute',right: '16px',}}
                                    onClick={() => handleLayerToggle(category, layer)}
                                />
                            ) : (
                                <EyeInvisibleOutlined
                                    style={{ fontSize: '16px', cursor: 'pointer',position: 'absolute',right: '16px' }}
                                    onClick={() => handleLayerToggle(category, layer)}
                                />
                            )}
                        </span>



                    </div>

                    ),
                    key: `${layer.id}_${layerNumber++}`,
                    id: layer.id,
                    type: 'layer',
                } as DataNode));

                arr.push({
                    title: (<div className={"tdv-root-entry"}>
                    <span >
                        {<Trans i18nKey={`LayersPage.LayerGroupsSub.${category}`} defaults={category}/>} </span>

                        <Checkbox
                            className="layer-switch"
                            indeterminate={hasSelectedItems(category)}
                            checked={layerMapping[category].selectAll === 1}
                            onChange={(e) => {
                                handleToggle(e.target.checked, layerNodes.map((node) => Number(node.key)), category);
                            }}
                        />

                            </div>),
                    key: `${category}`,
                    children: layerNodes,
                } as DataNode);

            }
        }

        return arr;
    }, [])

    const handleNodeSelect = (selectedKeys: any, { node }: any) => {
        if (node?.id) {
            // Toggle the selected value for the clicked node
            const updatedLayerMapping = { ...layerMapping };
            for (const category in updatedLayerMapping) {
                if (updatedLayerMapping.hasOwnProperty(category)) {
                    const { data } = updatedLayerMapping[category];
                    data.forEach((item) => {
                        if (item.id === node.id) {
                            item.selected = item.selected === 1 ? 0 : 1;
                        }
                    });
                    // Check if all selected items in data have a value of 1
                    const allSelected = data.every((item) => item.selected === 1);
                    // Update the selectAll property based on allSelected
                    updatedLayerMapping[category].selectAll = allSelected ? 1 : 0;

                }
            }

            // Update the state with the modified layerMapping
            setLayerMapping(updatedLayerMapping);

        }
    };


    return (
     <div className={"tdv-panel"}>
                <div className={"header"} style={{ margin: '3px', height: '45px', display: 'flex', justifyContent: 'center' }}>
                <Radio.Group value={selectedCategory} onChange={handleRadioChange}>
                    {LayerGroups.map((group) => (
                        <Radio key={group.name} value={group.name}>
                            {<Trans i18nKey={`LayersPage.LayerGroups.${group.name}`} defaults={group.name}/> }
                        </Radio>
                    ))}
                </Radio.Group>
            </div>
                <div className={"wrapper"}>
                    {

                        <Tree
                            showLine
                            showIcon={false}
                            treeData={translate()}
                            onSelect={handleNodeSelect}
                            defaultExpandAll={false}

                        />
                    }

                </div>

            </div>

    )
}
