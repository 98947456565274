/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import {Button, Descriptions, Divider, Modal, Tooltip, notification, Switch,Dropdown,Popconfirm} from "antd";
import {Trans} from "../reactive/Trans";
import {useLocation} from "react-router-dom";
import './styles/TrussFeatures.css';
import {DownOutlined} from "@ant-design/icons";
import {fetchTrussFeatures, getFeConfig, recalculateRecipe} from '../../api';
// @ts-ignore
import {Form} from "@q4us-sw/q4us-ui-v2/dist/form";
// @ts-ignore
import {DefaultArgs} from "@q4us-sw/q4us-ui-v2/dist/form/FormV2";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import {RecipeDetailContext} from "./util";
import {EditContext} from "../recipeVisualize/util";


interface TrussFeatureData {
    trussType: {
        label: string
        tooltip: string
    },
    config: any[],
    features: Record<string, any>
}

interface TrussFeatureEditProps {
    features: any,
    setOpen: (value: boolean) => void
}

export const TrussFeatures: React.FunctionComponent<PropsWithChildren<any>> = (props) => {
    const location = useLocation();
    const [trussFeatures, setTrussFeatures] = React.useState<TrussFeatureData>()
    const [isOpen, setOpen] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);

    const{recipeInfo} = React.useContext(RecipeDetailContext)
    const[configset, setConfigset] = React.useState<[]>([]);
    const{load} = React.useContext(RecipeDetailContext)
    const [selectedEnumLabel, setSelectedEnumLabel] = React.useState<string|undefined>(trussFeatures?.trussType.label);
    const [showPopconfirm, setShowPopconfirm] = React.useState(false);
    const [selectedEnumValue, setSelectedEnumValue] = React.useState<{ value: string; label: string }>({ value: '', label: '' });
    const loadRecipeDetails = React.useCallback(()=>{
        load(location.state.recipe, location.state.timestamp_year)
    }, [location, load])

    const {setEditMode} = React.useContext(EditContext)

    React.useEffect(() => {
        // Fetch the data from getFeConfig(schema) and populate the menuItems state
        const fetchData = async () => {
          try {
            const res = await getFeConfig('truss_feature_edit') || {}
            const {data: {rows = []} = {}} = res;
            setConfigset(rows[0].enums)
          } catch (error) {
            console.error("Error fetching data: ", error);
          }
        };
        fetchData();
      }, []);

    const view = React.useCallback(async () => {
        
        const {data: {features, trussTypes = []}} = await fetchTrussFeatures(location.state.recipe, location.state.timestamp_year);
        const trussType = trussTypes.find((entry: any) => entry.truss_type === features.trussType)

        const res = await getFeConfig('truss_features');
        const {data: {rows = []} = {}} = res;
        setTrussFeatures({
            trussType: {
                label: trussType?.base_message || 'Not Available',
                tooltip: trussType?.description || 'Truss type'
            },
            config: rows,
            features: features
        })
        setSelectedEnumLabel(trussType?.base_message || 'Not Available')
        setOpen(true);
    }, [trussFeatures,location]);

    const onChange = (checked: boolean) => {
        setEdit(checked)
    };

    return <React.Fragment>
        <Button className={"truss-feature-view-button"} onClick={view}><Trans i18nKey={'edit.truss_features.button.label'} defaults={"Truss Features"}/></Button>
        
        <Modal
            title={<Trans i18nKey={'edit.truss_features.button.label'} defaults={"Truss Features"}/>}
            width={'40vw'}
            open={isOpen}
            onCancel={() => {setOpen(false); setEdit(false);setShowPopconfirm(false);}}
            footer={null}
            closable={true}
            destroyOnClose
            maskClosable={false}
        >
        <div>
          <Descriptions bordered layout="horizontal" column={1}>
            {trussFeatures?.config.map(({ name, title }) => (
              <Descriptions.Item label={<Trans i18nKey={`edit.truss_features.type.${title}`} defaults={"Truss Type"}/>}>
                {name === 'trussType' ? (
                  <Popconfirm
                    title={(
                      <>
                        <Trans i18nKey={`edit.truss_features.confirm.title`} values={{ type: selectedEnumValue.value }} components={{ strong: <strong /> }} defaults={"Are you sure you want to change truss type to <strong>{{type}}</strong> ?"}/>
                        <br />
                        <span style={{ fontWeight: 'bold' }}>
                        <Trans i18nKey={`edit.truss_features.confirm.description`}  defaults={"Please note: Changes to Truss Type will recalculate the recipe from initial truss data. Any changes done to the recipe will be lost."}/>
                        </span>
                      </>
                    )}
                    open={showPopconfirm}
                    onConfirm={async () => {
                      const req = {
                        configuration: location.state.config,
                        project_id: location.state.project,
                        recipe_id: location.state.recipe,
                        timestamp_year: location.state.timestamp_year,
                        truss_design_info: { trussType: selectedEnumValue.value },
                        truss_id: location.state.truss
                      };
                      await recalculateRecipe(req).then((resp) => {
                        if (resp.data) {
                          notification.success({ message: <Trans i18nKey={'notification.Successfully_Re-calculating_Recipe'} defaults={"Successfully Re-calculating the Recipe"}/>});
                          loadRecipeDetails();

                        } else {
                          notification.error({ message: <Trans i18nKey={'notification.Error_Recipe_Calculation'} defaults={"Error in Recipe Calculation"}/>});
                        }
                      });
                      setSelectedEnumLabel(selectedEnumValue.label);
                      setShowPopconfirm(false); // Close the Popconfirm after confirmation
                      setEditMode(false);
                      setOpen(false);
                    }}
                    onCancel={() => {
                      
                      setSelectedEnumValue({ value: '', label: '' })
                      setShowPopconfirm(false); // Close the Popconfirm if canceled
                    }}
                    okText={<Trans i18nKey={`Yes`} defaults={"Yes"}/>}
                    cancelText={<Trans i18nKey={`No`} defaults={"No"}/>}
                  >

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span className={'dropdown-container'}>{selectedEnumLabel}</span>
                      <Dropdown disabled={recipeInfo.is_deployed}
                        menu={{
                          className: 'dropdown-container-menu',
                          items: configset.map(([enumValue, enumLabel]: [string, string]) => ({
                            key: enumValue,
                            onClick: () => {
                              setSelectedEnumValue({ value: enumValue, label: enumLabel}); // Store the selected enumValue
                              

                              setShowPopconfirm(true); // Show Popconfirm on click
                            },
                            label: (
                              <span title={enumValue} className={'dropdown-container'}>
                                {enumLabel}
                              </span>
                            ),
                          })),
                        }}
                        trigger={"click"}
                      >
                        <Tooltip title={recipeInfo.is_deployed ? <Trans i18nKey={'edit.truss_features.tooltip.deployed'} defaults={"Recipe is deployed, cannot change Truss type"}/> : <Trans i18nKey={'edit.truss_features.tooltip.notdeployed'} defaults={"Please select the new truss type from drop down"}/>} >
                          <Button
                            className={"truss-feature-edit-button"}
                            disabled={recipeInfo.is_deployed}
                            style={{ marginLeft: '8px' }}
                            icon={<DownOutlined style={{ fontSize: '10px' }} />}
                          >
                            <Trans i18nKey={'edit.truss_features.button.Edit'} defaults={"Edit"}/>
                          </Button>
                        </Tooltip>
                      </Dropdown>
                    </div>
                  </Popconfirm>
                ) : (
                  <span>{trussFeatures?.features[name] || '-'}</span>
                )}
              </Descriptions.Item>

            ))}
          </Descriptions>
        </div>
        </Modal>
    </React.Fragment>
}