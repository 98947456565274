/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import LanguageDetector from 'i18next-browser-languagedetector';
import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';


const languageDetector = new LanguageDetector(null, {
    order: ['cookie', 'localStorage'],
    lookupCookie: 'i18next-eas',
    lookupLocalStorage: 'i18nextLng-eas',
    caches: ['localStorage', 'cookie'],
});

const initI18n = async (translations:any) => {

    await i18n
        .use(languageDetector)
        .use(initReactI18next)
        .init<any>({
            resources: translations, // Provide the translations directly as resources
            fallbackLng: 'en',
            ns: ['translations'],
            debug: false, // Debug should be disabled for production
            defaultNS: 'translations',
            react: {
                useSuspense: false,
            },
            interpolation: {
                escapeValue: false,
            },
            initImmediate: true,
        } as InitOptions, () => {});
        
        // Access the loaded translations
        const loadedTranslations = i18n.getResourceBundle(
            i18n.language,
            'translations'
        );

    };

export default initI18n;
