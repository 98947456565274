/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {RefObject} from "react";
import {
    APSofwareTypes, ChordGrabberOnOffDistance,
    Configuration,
    FreeTransformLayers,
    LayerDefs,
    MovableLayers,
    RemovableLayers, ToolFlags,
    toolShapeNames
} from "./def";
import CollisionDetection from "./CollisionDetection";
import {ToolName, ToolShapes, ToolShapesForCollision} from "./collisionDetection/def";
import {convertToCoordinatesArray, getToolHeight, scalePolygonHeight} from "./collisionDetection/util";
import {SideToolTypes} from "./svg-layers/SideTool";
import {
    calculateCenterPoint,
    calculateConvexHull,
    calculateVerticesOnOrigin,
    computeAngleForTool,
    convertPointsToArray, Flags,
    getAllGrabbers,
    getAllSideTools, getCartTypeInUse,
    GetIntersectionPointsOfCurrentComponentWithVerticalLine, getRemoveDirection, getSideToolAngleForVisualize,
    isFreeTransformEnabledTool,
    moveCenterTo,
    rotatePolygonPoints,
    ToolTimberCompatible
} from "./util";
import {PressToolShapes} from "./svg-layers/PressTool";
import ld from "lodash";

export interface RemoveToolEntryExitElements {
    element: Element,
    disX: number,
    disY: number,
    angle: number
}

export interface PressToolElements {
    element: Element,
    x: number,
    y: number,
    angle: number
}

export interface RemoveToolSafetyElement {
    element: Element,
    disX: number,
    disY: number,
}

export interface MouseEventStatus {
    componentId: any,
    componentWasMoved: boolean,
    target: Element | null,
    component: any,
    id: any
    isMovable: boolean
    transformValue: string,
    removeToolEntryExitElements?: RemoveToolEntryExitElements[] | undefined,
    removeToolSafetyElement: RemoveToolSafetyElement | undefined
    pressToolElements: PressToolElements[] | undefined

}

export interface OngoingDrag {
    componentId: any,
    target: Element | null,
    startPosition: {toolX: number, toolY: number},
    distanceToMouseClickFromToolMidPoint: {disX: number, disY: number}

}

export interface EntryExitPositions {
    x: number,
    y: number,
    angle: number
}

export interface ITool {
    angle: number,
    type: number,
    id: number,
    x: number,
    y: number,

    [key: string]: any
}

export interface IComponent {
    angle: number,
    type: number,
    id: number,
    x: number,
    y: number,
    isRemovable?: boolean,
    freeTransform?: boolean,
    autoCorrectY?: boolean,
    layer: string,
    component_id: string,
    entryPositions?: EntryExitPositions[],
    exitPositions?: EntryExitPositions[],
    onMovePositions?: EntryExitPositions[],
    safetyArea: { points: { x: number, y: number }[], x: number, y: number },

    [key: string]: any
}

export interface ITimber {
    angle: number,
    type: number,
    id: number,
    x: number,
    y: number,
    originalInputId: number,
    vertices: { x: number, y: number }[],

    [key: string]: any
}

export interface INailPlates extends ITimber {
}

export interface Recipe {
    AllGrabbers?: ITool[],
    AllSideTools?: ITool[],
    NailPlates: INailPlates[],
    BlockTools: ITool[],
    Timbers: ITimber[],
    PressTools: ITool[],
    Grabbers: ITool[],
    SideTools: ITool[],
    RemoveTools: ITool[]

    [key: string]: any
}

export interface ToolShapeDetails {
    points: { x: number, y: number }[],
    x: number,
    y: number,
    angle: number
}


class EditService {

    private mouseEventStatus: MouseEventStatus | null = null
    private ongoingDrag: OngoingDrag | null = null
    private moves: Map<string, { x: number, y: number }> = new Map()
    private timberAssignment: Map<any, any> = new Map() //new timber assignment for tool
    private freeTransform: Map<number, boolean> = new Map()
    private autoCorrectY: Map<number, boolean> = new Map()
    private recipe?: Recipe | null = null
    private selectedTimber: ITimber | null = null
    private selectedNailPlate: INailPlates | null = null
    private selectedComponent: IComponent | null = null
    private multipleComponentSelection: Map<string, any> = new Map()
    private deletions: Map<string, any> = new Map()
    private propsData: Record<string, any> = {}
    private configuration: Configuration | null = null
    private pendingEdits: Map<string, any> = new Map();
    private toolShapeMaps: Record<string, Map<string, ToolShapeDetails>> = {}

    private baseCollision = new CollisionDetection()
    private midgrabberBaseCollision = new CollisionDetection()
    private pressBaseCollision = new CollisionDetection()
    private beamGrabberBaseCollision = new CollisionDetection()
    private sideToolFinalizedCollision = new CollisionDetection()
    private sideToolUnFinalizedCollision = new CollisionDetection()
    private withFootCollision = new CollisionDetection()
    private withToolCollision = new CollisionDetection()
    private removeToolNeckCollision = new CollisionDetection()
    private removeToolSafetyAreaCollision = new CollisionDetection()
    private pressWithToolCollision = new CollisionDetection()
    private pressToolMouthDepthCollision = new CollisionDetection()
    private collisionForValidateMovements = new CollisionDetection()

    private collisions = new Set()
    private dragCollisions = new Set()

    private allGrabbers: ITool[] = []
    private allSideTools: ITool[] = []
    private removeTools: ITool[] = []
    private removeDirection: string | undefined
    private cartTypeInUse: string | undefined


    clearToolShapeMaps() {
        for (const key in this.toolShapeMaps) {
            if (this.toolShapeMaps.hasOwnProperty(key)) {
                this.toolShapeMaps[key].clear();
            }
        }
    }

    getOngoingDrag() {
        return this.ongoingDrag
    }

    getSelectedComponent() {
        return this.selectedComponent
    }

    setSelectedComponent(component: IComponent) {
        this.selectedComponent = component
    }

    setFreeTransform(id: number, value: boolean) {
        this.freeTransform.set(id, value)
    }

    setAutoCorrectY(id: number, value: boolean) {
        this.autoCorrectY.set(id, value)
    }

    deleteComponent() {
        const component = this.selectedComponent
        if (component != null) {
            this.deletions.set(component.component_id, component)
            this.selectedComponent = null;

            const toolName = component.component_id.split('_')[0]
            this.updateCollisionSystemOnDelete(component.component_id, toolName, component)

            for (const key in this.toolShapeMaps) {
                const shapeID = `${component.component_id}_${key.split('_')[1]}`

                if (key.includes(toolName)) {
                    this.toolShapeMaps[key].delete(shapeID)
                }
            }
        }
    };

    isDeleted(component_id: string): boolean {
        return this.deletions.has(component_id)
    }


    removeDeletedMultiPress(component: IComponent) {
        component.multipress?.map((item, index) => {
            document.querySelector(`g[id=${LayerDefs.MultiPressTool.toLowerCase()}_${component.nailplateId}_${index}]`)?.remove()
            document.querySelector(`g[id=${LayerDefs.MultiPressToolUpperShape.toLowerCase()}_${component.nailplateId}_${index}]`)?.remove()
        })
    }

    setPropertyChange(id, obj) {
        this.propsData[id] = {...obj}

        const lowerCaseId = id.toLowerCase()
        this.removeCollisionsByComponent(lowerCaseId)

        //update the toolShapeMap for collision
        this.updateToolShapeMapsForPropertyChanges(lowerCaseId, obj.angle, obj.x, obj.y)

        //check collision when rotating
        this.collisionDetectionForToolDragAndRotation(lowerCaseId, obj.x, obj.y, obj.angle)

    }

    updateToolShapeMapsForPropertyChanges(componentId: string, angle: number, x: number, y: number) {
        const toolName = componentId.split('_')[0]
        let toolAngle = angle
        if (toolName === ToolName.SideTool && this.selectedComponent) {
            toolAngle = getSideToolAngleForVisualize(this.selectedComponent.type, angle) * (Math.PI / 180)
        }
        for (const key in this.toolShapeMaps) {
            if (key.includes(toolName)) {
                const shapeID = `${componentId.toLowerCase()}_${key.split('_')[1]}`
                const shape = this.toolShapeMaps[key].get(shapeID)
                if (shape) {
                    this.toolShapeMaps[key].set(shapeID, {...shape, angle: toolAngle, x: x, y: y})
                }
            }
        }
    }

    removePropertyChange(id) {
        delete this.propsData[id]

    }

    getPropertyChange(id) {
        const movement = this.moves.get(id.toLowerCase())
        const {component, ...ovr } = this.timberAssignment.get(id.toLowerCase()) || {}
        const comp = this.propsData[id]
        return {...comp, ...movement, ...ovr}

    }

    applyPropertyChange() {
        if (this.recipe) {
            for (const componentId of Object.keys(this.propsData)) {
                const layer = componentId.replace(/_\d+$/, '')
                const props = this.propsData[componentId]
                const id = Number(props.id)
                switch (layer) {
                    case LayerDefs.BeamGrabber:
                    case LayerDefs.MidGrabber: {
                        if (this.recipe.AllGrabbers) {
                            this.recipe.AllGrabbers = this.recipe.AllGrabbers.map(o => {
                                if (o.id == id) {
                                    return {...o, ...props}
                                }
                                return o;

                            })
                        }
                        this.recipe.Grabbers = this.recipe.Grabbers.map(o => {
                            if (o.id == id) {
                                return {...o, ...props}
                            }
                            return o;

                        })
                    }
                        break
                    case LayerDefs.SideTool: {
                        if (this.recipe.AllSideTools) {
                            this.recipe.AllSideTools = this.recipe.AllSideTools.map(o => {
                                if (o.id == id) {
                                    return {...o, ...props}
                                }
                                return o;

                            })
                        }
                        this.recipe.SideTools = this.recipe.SideTools.map(o => {
                            if (o.id == id) {
                                return {...o, ...props}
                            }
                            return o;

                        })
                    }
                        break
                    case LayerDefs.BlockTool:
                        this.recipe.BlockTools = this.recipe.BlockTools.map(o => {
                            if (o.timberId == id) {
                                return {...o, ...props}
                            }
                            return o;
                        })
                        break
                    case LayerDefs.NailPlate:
                        this.recipe.NailPlates = this.recipe.NailPlates.map(o => {
                            if (o.id == id) {
                                return {...o, ...props}
                            }
                            return o;
                        })
                        break
                    case LayerDefs.PressTool:
                        this.recipe.PressTools = this.recipe.PressTools.map(o => {
                            if (o.nailplateId == id) {
                                const updatedTool = {...o, ...props}
                                if (o.angle !== props.angle && updatedTool.positionAtNailPlateTable) {
                                    delete updatedTool.positionAtNailPlateTable
                                }
                                return updatedTool
                            }
                            return o;
                        })
                        break
                    case LayerDefs.RemoveTool:
                        if (this.isRemoveToolHasDirection()) {
                            this.recipe.RemoveTools[this.removeDirection][this.cartTypeInUse] = this.removeTools.map(o => {
                                if (o.id == id) {
                                    return {...o, ...props}
                                }
                                return o;
                            })

                        } else {
                            this.recipe.RemoveTools = this.recipe.RemoveTools.map(o => {
                                if (o.id == id) {
                                    return {...o, ...props}
                                }
                                return o;
                            })
                        }
                        break
                    case LayerDefs.TimberDiagonal:
                    case LayerDefs.TimberBeam:
                        this.recipe.Timbers = this.recipe.Timbers.map(o => {
                            if (o.id == id) {
                                return {...o, ...props}
                            }
                            return o;
                        })
                        break
                }

            }

        }


    }


    applyDeletions() {
        // Delete removed components
        if (this.recipe) {
            if (this.recipe.AllGrabbers) {
                this.recipe.AllGrabbers = this.recipe.AllGrabbers?.filter(grabber =>
                    !this.deletions.has(`beamgrabber_${grabber.id}`) &&
                    !this.deletions.has(`midgrabber_${grabber.id}`))
            }

            this.recipe.Grabbers = this.recipe.Grabbers?.filter(grabber =>
                !this.deletions.has(`beamgrabber_${grabber.id}`) &&
                !this.deletions.has(`midgrabber_${grabber.id}`))

            this.recipe.BlockTools = this.recipe.BlockTools?.filter(tool =>
                !this.deletions.has(`blocktool_${tool.timberId}`))

            this.recipe.PressTools = this.recipe.PressTools?.filter(tool =>
                !this.deletions.has(`presstool_${tool.nailplateId}`))

            if (this.recipe.AllSideTools) {
                this.recipe.AllSideTools = this.recipe.AllSideTools.filter(tool =>
                    !this.deletions.has(`sidetool_${tool.id}`))
            }

            if (this.recipe.hasOwnProperty("SideTools")) {
                this.recipe.SideTools = this.recipe.SideTools.filter(tool =>
                    !this.deletions.has(`sidetool_${tool.id}`))
            }

            if (this.recipe.RemoveTools && this.recipe.RemoveTools.length > 0) {
                if (this.isRemoveToolHasDirection()) {
                    this.recipe.RemoveTools[this.removeDirection][this.cartTypeInUse] = this.removeTools.filter((tool) => !this.deletions.has(`removetool_${tool.id}`))
                } else {
                    this.recipe.RemoveTools = this.recipe.RemoveTools.filter((tool) => !this.deletions.has(`removetool_${tool.id}`))
                }
            }
        }
    }

    startDrag = (target: any, componentId: any, transformValue: any, viewerRef: RefObject<any>) => {

        const ctm = viewerRef.current?.props.value

        const pointerX = viewerRef.current?.state.pointerX
        const pointerY = viewerRef.current?.state.pointerY
        const x = (pointerX - ctm.e) / ctm.a
        const y = (pointerY - ctm.f) / ctm.d

        const match = transformValue.match(/translate\(([-+]?[\d.]+),\s*([-+]?[\d.]+)\)/)
        const toolX = Number(match[1])
        const toolY = Number(match[2])
        const startPosition = {toolX, toolY}

        //calculate distance to initial mouse click position from middle point of tool.
        const distanceToMouseClickFromToolMidPoint = {disX: x-toolX, disY: -y - toolY}
        this.ongoingDrag = {target, componentId, startPosition, distanceToMouseClickFromToolMidPoint};
    }
    endDrag = () => this.ongoingDrag = null

    setRecipe(recipe: any) {
        this.recipe = JSON.parse(JSON.stringify(recipe));
    }

    setConfiguration(config: any) {
        this.configuration = JSON.parse(JSON.stringify(config));
    }

    dirty() {
        return this.moves.size > 0 || this.deletions.size > 0 || !(ld.isEmpty(this.propsData));
    }

    removeDrawingElements() {
        const recipe = this.recipe
        for (const key in recipe) {
            if (recipe?.[key] instanceof Array) {
                recipe?.[key].forEach((item: any) => {
                    if (item.DrawingElements) delete item['DrawingElements']

                });
            }
        }
    }

    applyMovement() {
        for (const [componentId, movement] of Array.from(this.moves)) {
            const id = Number(componentId.split("_")[1]);
            const name = componentId.split("_")[0];

            if (name === 'beamgrabber' || name === 'midgrabber') {
                this.recipe?.AllGrabbers?.forEach((tool) => {
                    if (tool.id === id) {
                        tool.x = movement.x;
                        tool.y = movement.y;
                    }
                })
                this.recipe?.Grabbers?.forEach((tool) => {
                    if (tool.id === id) {
                        tool.x = movement.x;
                        tool.y = movement.y;
                    }
                })
            } else if (name === 'sidetool') {
                this.recipe?.AllSideTools?.forEach((tool) => {
                    if (tool.id === id) {
                        tool.x = movement.x;
                        tool.y = movement.y;
                    }
                })
                if (this.recipe?.hasOwnProperty("SideTools")) {
                    this.recipe?.SideTools.forEach((tool) => {
                        if (tool.id === id) {
                            tool.x = movement.x;
                            tool.y = movement.y;
                        }
                    })
                }
            } else if (name === 'removetool') {

                if (this.isRemoveToolHasDirection()) {
                    this.recipe?.RemoveTools[this.removeDirection][this.cartTypeInUse].forEach((tool, index) => {
                        if (index === id) {
                            tool.entryPositions?.forEach((p) => {
                                const difX = tool.x - p.x
                                const difY = tool.y - p.y
                                p.x = movement.x - difX
                                p.y = movement.y - difY
                            })
                            tool.exitPositions?.forEach((p) => {
                                const difX = tool.x - p.x
                                const difY = tool.y - p.y
                                p.x = movement.x - difX
                                p.y = movement.y - difY
                            })
                            tool.onMovePositions?.forEach((p) => {
                                const difX = tool.x - p.x
                                const difY = tool.y - p.y
                                p.x = movement.x - difX
                                p.y = movement.y - difY
                            })
                            tool.x = movement.x
                            tool.y = movement.y
                        }
                    })

                } else {
                    this.recipe?.RemoveTools.forEach((tool, index) => {
                        if (index === id) {
                            tool.entryPositions?.forEach((p) => {
                                const difX = tool.x - p.x
                                const difY = tool.y - p.y
                                p.x = movement.x - difX
                                p.y = movement.y - difY
                            })
                            tool.exitPositions?.forEach((p) => {
                                const difX = tool.x - p.x
                                const difY = tool.y - p.y
                                p.x = movement.x - difX
                                p.y = movement.y - difY
                            })
                            tool.x = movement.x
                            tool.y = movement.y
                        }
                    })
                }

            } else if (name === 'presstool') {
                this.recipe?.PressTools.forEach((tool) => {
                    if (tool.nailplateId === id) {
                        tool.x = movement.x;
                        tool.y = movement.y;
                        if (tool.positionAtNailPlateTable) {
                            delete tool.positionAtNailPlateTable
                        }
                    }
                })
            }
        }
    }

    applyTimberAssignment() {
        for (const [componentId, {component, timberId, angle}] of this.timberAssignment) {

            const id = Number(componentId.split("_")[1]);

            if (this.recipe?.AllGrabbers) {
                let tool = this.recipe?.AllGrabbers.find((e) => e.id == id)
                if (tool) {
                    tool.timberId = timberId
                    tool.angle = angle
                }

            }
            if (this.recipe) {
                this.recipe.Grabbers = this.recipe.Grabbers || [];
                let usedTool = this.recipe.Grabbers.find((e) => e.id == id)
                if (usedTool) {
                    usedTool.timberId = timberId;
                    usedTool.angle = angle
                } else {
                    this.recipe.Grabbers.push({
                        angle: angle,
                        id: id,
                        timberId: timberId,
                        type: component.type,
                        x: component.x,
                        y: component.y
                    })
                }
            }

        }

    }

    cleanRemoveToolInRecipe() {
        if (this.isRemoveToolHasDirection()) {
            this.recipe?.RemoveTools[this.removeDirection][this.cartTypeInUse].forEach((tool) => {
                delete tool.id
                delete tool.timberId
                delete tool.safetyArea
            })

        } else {
            if (this.recipe?.RemoveTools && this.recipe?.RemoveTools.length > 0) {
                this.recipe?.RemoveTools?.forEach((tool) => {
                    delete tool.id
                    delete tool.timberId
                    delete tool.safetyArea
                })
            }
        }
    }

    exportRecipe() {
        this.removeDrawingElements()
        this.applyTimberAssignment()
        this.applyMovement()
        this.applyDeletions()
        this.applyPropertyChange()
        this.propsData = {}
        this.cleanRemoveToolInRecipe()
        return this.recipe
    }

    isComponentUsed(tool: ITool) {
        return tool.timberId >= 0 || !isFreeTransformEnabledTool(tool.type)
    }

    isFreeTransformable(component: IComponent | null) {
        return component ? isFreeTransformEnabledTool(component.type) && FreeTransformLayers.has(component.layer) : false
    }

    getTimberById(timberId: number) {
        let timber = null
        this.recipe?.Timbers.forEach((item) => {
            if (item.id === timberId) {
                timber = item
            }
        })
        return timber
    }
    getNailPlateById(nailPlateId: number) {
        let nailPlate = null
        this.recipe?.NailPlates.forEach((item) => {
            if (item.id === nailPlateId) {
                nailPlate = item
            }
        })
        return nailPlate
    }

    isAutoCorrectYEnabled(component: IComponent | null) {
        return component ? (component.layer === LayerDefs.BeamGrabber && component.freeTransform) : false
    }

    selectComponentById(toolId: string, layer: string, component_id: string) {
        const tool: ITool | null = this.getComponentById(toolId, layer, component_id);
        const comp = this.getPropertyChange(`${layer}_${toolId}`)
        this.selectedComponent = {...tool, ...comp}
        if (tool?.timberId > -1) {
            this.selectedTimber = this.getTimberById(tool?.timberId)
        } else this.selectedTimber = null

        if (tool?.nailplateId > -1){
            this.selectedNailPlate = this.getNailPlateById(tool?.nailplateId)
        } else this.selectedNailPlate = null
    }


    getComponentById(toolId: string, layer: string, component_id: string): ITool | null {
        if (this.recipe) {
            let components: ITool[] = []
            let id = "id"

            switch (layer) {
                case LayerDefs.BeamGrabber:
                case LayerDefs.MidGrabber:
                    components = this.allGrabbers
                    break
                case LayerDefs.SideTool:
                    components = this.allSideTools
                    break
                case LayerDefs.BlockTool:
                    components = this.recipe.BlockTools
                    id = "timberId"
                    break
                case LayerDefs.NailPlate:
                    components = this.recipe.NailPlates
                    break
                case LayerDefs.PressTool:
                    components = this.recipe.PressTools
                    id = "nailplateId"
                    break
                case LayerDefs.RemoveTool:
                    components = this.removeTools
                    break
                case LayerDefs.TimberDiagonal:
                case LayerDefs.TimberBeam:
                    components = this.recipe.Timbers
                    break
            }

            return components.reduce((acc: ITool | null, tool, index) => {
                if (tool[id] === Number(toolId)) {
                    const isRemovable = this.isRemovable(tool, component_id, layer)
                    let freeTransform
                    let autoCorrectY
                    if (layer === LayerDefs.BeamGrabber || layer === LayerDefs.MidGrabber) {
                        freeTransform = this.freeTransform.get(tool.id)
                        autoCorrectY = this.autoCorrectY.get(tool.id)
                    }
                    return {...tool, component_id, isRemovable, freeTransform, autoCorrectY, layer}
                }
                return acc;
            }, null)
        }
        return null;
    }

    isRemoveToolHasDirection() {
        if (this.recipe?.RemoveTools) {
            // checking is remove tool has direction
            if ((this.recipe?.RemoveTools.hasOwnProperty("RemoveToRight") || this.recipe?.RemoveTools.hasOwnProperty("RemoveToLeft")) && this.removeDirection && this.cartTypeInUse) {
                return true
            }
        }
        return false
    }


    processRecipe() {
        if (this.recipe && this.configuration) {

            this.allGrabbers = getAllGrabbers(this.recipe, this.configuration)
            this.allSideTools = getAllSideTools(this.recipe, this.configuration)
            const frameShape = this.configuration?.buildtoolshapes.RemoveTool.DrawingElements.Shapes.find((s) => s.layer === 'FRAME').points

            this.cartTypeInUse = getCartTypeInUse(this.configuration)
            this.removeDirection = getRemoveDirection(this.configuration)
            if (this.isRemoveToolHasDirection()) {
                this.removeTools = this.recipe.RemoveTools[this.removeDirection][this.cartTypeInUse]
            }
            else this.removeTools = this.recipe.RemoveTools

            if (this.removeTools && this.removeTools.length > 0) {
                this.removeTools.forEach((tool, index) => {
                    tool.id = index

                    //assign a timber to remove tool
                    for (const timber of this.recipe?.Timbers) {
                        const collision = new CollisionDetection()
                        collision.setup(new Map().set('timber', {x: 0, y: 0, points: timber.vertices, angle: 0})
                            .set('tool', {
                                x: tool.x,
                                y: tool.y,
                                points: convertToCoordinatesArray(frameShape),
                                angle: tool.angle
                            }))

                        if (collision.check().length > 0) {
                            tool.timberId = timber.id
                            break
                        }
                    }

                    //add safety are to remove tool object
                    const pointArray = convertPointsToArray(frameShape)
                    const grabbingPolygon = moveCenterTo(rotatePolygonPoints(pointArray, tool.angle), tool.x, tool.y)
                    const entryPosition = tool.entryPositions[0]
                    const entryPolygon = moveCenterTo(rotatePolygonPoints(pointArray, entryPosition.angle), entryPosition.x, entryPosition.y)
                    const safetyAreaPolygonVertices = calculateConvexHull([...grabbingPolygon, ...entryPolygon])
                    const {centerX, centerY} = calculateCenterPoint(safetyAreaPolygonVertices)
                    const safetyAreaPolygonInOrigin = calculateVerticesOnOrigin(safetyAreaPolygonVertices, centerX, centerY)

                    tool.safetyArea = {points: safetyAreaPolygonInOrigin, x: centerX, y: centerY}
                })
            }

            this.allGrabbers.forEach((tool) => {
                this.freeTransform.set(tool.id, !this.isComponentUsed(tool))
                this.autoCorrectY.set(tool.id, (!this.isComponentUsed(tool) && tool.type !== ToolFlags.MIDGRABBER))
            })
        }
    }


    isRemovable(component: ITool, component_id: string, layer: string) {
        if (layer === LayerDefs.RemoveTool) return true

        return !this.deletions.has(component_id) &&
            RemovableLayers.has(layer) &&
            (component.timberId > -1 || component.nailplateId > -1)
    }

    getComponentsForMouseEvent = (event: MouseEvent) => {
        return document
            .elementsFromPoint(event.clientX, event.clientY)
            .filter(e => e.tagName === 'polygon')
            .map((polygon) => {
                if (polygon) {
                    const id = polygon.parentElement?.getAttribute('data-component')
                    const layer_group = polygon.parentElement?.getAttribute('data-layer')
                    const component_id = polygon.parentElement?.getAttribute('data-id') || polygon.parentElement?.getAttribute('id')
                    if (layer_group === LayerDefs.RemoveTool) {
                        const transformValue = document.querySelector(`g[id=${component_id}]`)?.getAttribute('transform')
                        return {component_id, layer_group, id, transformValue}
                    }
                    const transformValue = polygon.parentElement?.getAttribute('transform')

                    return {component_id, layer_group, id, transformValue}
                }
            })
            .reduce((acc, e) => e?.layer_group ? acc.set(e.component_id, e) : acc, new Map())
    }

    availableComponents() {
        return Array.from(this.multipleComponentSelection.keys())
    }

    extractTranslateValues(element: Element|null) {

        const transform = element?.getAttribute('transform')

        if (transform) {
            const translate = transform.match(/translate\(([^,]+),\s*([^\)]+)\)/)
            const rotate = transform.match(/rotate\(([-+]?\d+(\.\d+)?)/)

            if (translate && rotate) {
                const x = Number(translate[1]);
                const y = Number(translate[2]);
                const angle = Number(rotate[1])
                return { x, y , angle};
            }
        }

        return null;
    }

    getPressToolLayerElement(nailplateId: number) {
        let pressToolElements: PressToolElements[] = []

        const selectors = [
            `g[id=${LayerDefs.PressToolUpperShape.toLowerCase()}_${nailplateId}]`,
            `g[id=${LayerDefs.PressToolRearPaddle.toLowerCase()}_${nailplateId}]`,
            `g[id=${LayerDefs.PressToolPaddle.toLowerCase()}_${nailplateId}]`,
            `g[id=${LayerDefs.PressTool.toLowerCase()}_${nailplateId}_preview]`,
            `g[id=${LayerDefs.PressToolUpperShape.toLowerCase()}_${nailplateId}_preview]`,
            `g[id=${LayerDefs.PressToolRearPaddle.toLowerCase()}_${nailplateId}_preview]`,
            `g[id=${LayerDefs.PressToolPaddle.toLowerCase()}_${nailplateId}_preview]`

        ];

        selectors.forEach(selector => {
            const element = document.querySelector(selector);
            const transformValues = this.extractTranslateValues(element);

            if (element && transformValues) {
                pressToolElements.push({ element, ...transformValues });
            }
        });

        return pressToolElements
    }

    getRemoveToolEntryExitElement(position: EntryExitPositions[] | undefined, layerGroup: string) {
        let removeToolEntryExitElements: RemoveToolEntryExitElements[] = []

        position?.forEach((p, index) => {
            const element = document.querySelector(`g[id=${layerGroup}_${this.selectedComponent?.id}_${index}]`)
            const currentTransform = element.getAttribute('transform')
            const match = currentTransform?.match(/rotate\(([-+]?\d+(\.\d+)?)/)
            const angle = match ? Number(match[1]) : 0
            //distance from remove tool to grabbing position
            const disX = this.selectedComponent?.x - p.x
            const disY = this.selectedComponent?.y - p.y
            if (element) {
                removeToolEntryExitElements.push({element, disX, disY, angle})
            }
        })

        return removeToolEntryExitElements
    }

    getRemoveToolSafetyElement() {
        if (this.selectedComponent) {
            const tool = this.selectedComponent
            //distance from safety area to grabbing position
            const disX = tool.x - tool.safetyArea.x
            const disY = tool.y - tool.safetyArea.y
            const element = document.querySelector(`g[id=${LayerDefs.RemoveToolSafety}_${this.selectedComponent?.id}]`)

            return {element, disX, disY,}
        }
    }

    pickComponent = (componentId: string) => {
        if (!this.availableComponents().length) return;
        const {id, layer_group, component_id, transformValue} = this.multipleComponentSelection.get(componentId);

        this.selectComponentById(id, layer_group, component_id)

        let removeToolEntryExitElements: RemoveToolEntryExitElements[] | undefined = undefined
        let removeToolSafetyElement = undefined
        let pressToolElements: PressToolElements[] | undefined = undefined

        if (layer_group === LayerDefs.RemoveTool) {

            /** changed as per the requirement of ENH-511 */
            removeToolEntryExitElements = [...this.getRemoveToolEntryExitElement(this.selectedComponent?.entryPositions, LayerDefs.RemoveToolEntry)]

            removeToolSafetyElement = this.getRemoveToolSafetyElement()
        }

        if (layer_group === LayerDefs.PressTool) {
            pressToolElements = this.getPressToolLayerElement(id)
        }

        this.mouseEventStatus = {
            componentId: component_id,
            id: id,
            component: this.selectedComponent,
            isMovable: MovableLayers.has(layer_group),
            componentWasMoved: false,
            target: document.querySelector(`g[id=${component_id}]`),
            transformValue: transformValue,
            removeToolEntryExitElements: removeToolEntryExitElements,
            removeToolSafetyElement: removeToolSafetyElement,
            pressToolElements: pressToolElements
        }
        this.multipleComponentSelection.clear();
    };


    clearSelection() {
        this.selectedTimber = null;
        this.selectedComponent = null;
        this.selectedNailPlate = null;
    }

    getToolShapesMap(shapes: any[], toolShapesForCollision: string[]) {
        const map = new Map<string, any>()
        shapes.forEach(shape => {
            if (toolShapesForCollision.includes(shape.layer)) {
                map.set(shape.layer.toLowerCase(), convertToCoordinatesArray(shape.points));
            }
        });

        return map
    }


    addToToolShapeMaps(mapName: string, id: string, details: any) {
        this.toolShapeMaps[mapName].set(id, details)
    }

    polygonMapForCollisionDetection() {

        const beamGrabberShapes = this.getToolShapesMap(this.configuration?.walltoolshapes.ChordGrabber.DrawingElements.Shapes || [], ToolShapesForCollision.BeamGrabber)
        const midGrabberShapes = this.getToolShapesMap(this.configuration?.walltoolshapes.SpikeGrabber.DrawingElements.Shapes || [], ToolShapesForCollision.MidGrabber)
        this.recipe?.Grabbers?.forEach((tool) => {

            if (tool.type === ToolFlags.MIDGRABBER) {
                for (const [shapeName, points] of midGrabberShapes) {
                    this.addToToolShapeMaps(`${ToolName.MidGrabber}_${shapeName}`, `${ToolName.MidGrabber}_${tool.id}_${shapeName}`, {
                        points: points,
                        x: tool.x,
                        y: tool.y,
                        angle: tool.angle
                    })
                }
            }
            else {
                for (const [shapeName, points] of beamGrabberShapes) {
                    const angle = (tool.type === ToolFlags.UPPER_BEAM_GRABBER) ? tool.angle + 3.1415926536 : tool.angle
                    this.addToToolShapeMaps(`${ToolName.BeamGrabber}_${shapeName}`, `${ToolName.BeamGrabber}_${tool.id}_${shapeName}`, {
                        points: points,
                        x: tool.x,
                        y: tool.y,
                        angle: angle
                    })
                }
            }

        })

        const sideToolShapes = this.getToolShapesMap(this.configuration?.walltoolshapes.SideSupportTool.DrawingElements.Shapes || [], ToolShapesForCollision.SideTool)
        const rightSuctionToolShapes = this.getToolShapesMap(this.configuration?.walltoolshapes.RightSideSuctionGrabber.DrawingElements.Shapes || [], ToolShapesForCollision.SideSuctionTool)
        const leftSuctionToolShapes = this.getToolShapesMap(this.configuration?.walltoolshapes.LeftSideSuctionGrabber.DrawingElements.Shapes || [], ToolShapesForCollision.SideSuctionTool)
        this.recipe?.SideTools?.forEach((tool) => {

            const angle = getSideToolAngleForVisualize(tool.type, tool.angle) * (Math.PI/180)

            switch (tool.type) {
                case SideToolTypes.LeftSideSupportTool:
                case SideToolTypes.RightSideSupportTool:
                    for (const [shapeName, points] of sideToolShapes) {
                        this.addToToolShapeMaps(`${ToolName.SideTool}_${shapeName}`, `${ToolName.SideTool}_${tool.id}_${shapeName}`, {
                            points: points,
                            x: tool.x,
                            y: tool.y,
                            angle: angle
                        })
                    }
                    break
                case SideToolTypes.RightSideSuctionGrabber:
                    for (const [shapeName, points] of rightSuctionToolShapes) {
                        this.addToToolShapeMaps(`${ToolName.SideTool}_${shapeName}`, `${ToolName.SideTool}_${tool.id}_${shapeName}`, {
                            points: points,
                            x: tool.x,
                            y: tool.y,
                            angle: angle
                        })
                    }
                    break
                case SideToolTypes.LeftSideSuctionGrabber:
                    for (const [shapeName, points] of leftSuctionToolShapes) {
                        this.addToToolShapeMaps(`${ToolName.SideTool}_${shapeName}`, `${ToolName.SideTool}_${tool.id}_${shapeName}`, {
                            points: points,
                            x: tool.x,
                            y: tool.y,
                            angle: angle
                        })
                    }
            }
        })

        const pressToolShapes = this.getToolShapesMap(this.configuration?.buildtoolshapes.PressTool.DrawingElements.Shapes || [], ToolShapesForCollision.PressTool)
        this.recipe?.PressTools?.forEach((tool) => {
            for (const [shapeName, points] of pressToolShapes) {
                this.addToToolShapeMaps(`${ToolName.PressTool}_${shapeName}`, `${ToolName.PressTool}_${tool.nailplateId}_${shapeName}`, {
                    points: points,
                    x: tool.x,
                    y: tool.y,
                    angle: tool.angle
                })

                if (tool.multipress) {
                    tool.multipress.forEach((item, index) => {
                        this.addToToolShapeMaps(`${ToolName.MultiPressTool}_${shapeName}`, `${ToolName.MultiPressTool}_${tool.nailplateId}_${index}_${shapeName}`, {
                            points: points,
                            x: item.x,
                            y: item.y,
                            angle: item.angle
                        })
                    })
                }
            }
        })

        const removeToolShapes = this.getToolShapesMap(this.configuration?.buildtoolshapes.RemoveTool.DrawingElements.Shapes || [], ToolShapesForCollision.RemoveTool)
        if (this.removeTools && this.removeTools.length > 0) {
            this.removeTools.forEach((tool, index) => {
                for (const [shapeName, points] of removeToolShapes) {
                    this.addToToolShapeMaps(`${ToolName.RemoveTool}_${shapeName}`, `${ToolName.RemoveTool}_${index}_${shapeName}`, {
                        points: points,
                        x: tool.x,
                        y: tool.y,
                        angle: tool.angle
                    })
                }
                this.toolShapeMaps.removetool_safety.set(`${ToolName.RemoveTool}_${index}_${ToolShapes.Safety}`, {
                    points: tool.safetyArea.points,
                    x: tool.safetyArea.x,
                    y: tool.safetyArea.y,
                    angle: 0
                })
            })
        }

        const blockToolShapes = this.getToolShapesMap(this.configuration?.buildtoolshapes.BlockTool.DrawingElements.Shapes || [], ToolShapesForCollision.BlockTool)
        const blockToolHeight = getToolHeight(blockToolShapes.get('base'))
        this.recipe?.BlockTools?.forEach((tool) => {
            if (tool.useVacuumPicker) {
                const points = blockToolShapes.get(ToolShapes.Vacuumhousing)
                this.addToToolShapeMaps(`${ToolName.BlockTool}_${ToolShapes.Vacuumhousing}`, `${ToolName.BlockTool}_${tool.timberId}_${ToolShapes.Vacuumhousing}`, {
                    points: points,
                    x: tool.x,
                    y: tool.y,
                    angle: tool.angle
                })
            } else {
                const scaleFactor = (tool.timberBreadth + 50) / blockToolHeight
                const points = scalePolygonHeight(blockToolShapes.get(ToolShapes.Base), scaleFactor)
                this.addToToolShapeMaps(`${ToolName.BlockTool}_${ToolShapes.Base}`, `${ToolName.BlockTool}_${tool.timberId}_${ToolShapes.Base}`, {
                    points: points,
                    x: tool.x,
                    y: tool.y,
                    angle: tool.angle
                })
            }
        })

        this.recipe?.NailPlates.forEach((tool) => {
            this.toolShapeMaps.nailplate.set(`${ToolName.NailPlate}_${tool.id}_shape`, {
                points: tool.vertices,
                x: 0,
                y: 0,
                angle: 0
            })
        })

        this.recipe?.Timbers.forEach((tool) => {
            this.toolShapeMaps.timber.set(`${ToolName.Timber}_${tool.id}_shape`, {
                points: tool.vertices,
                x: 0,
                y: 0,
                angle: 0
            })
        })

    }

    scheduleEdit(id, x, y) {
        this.cancelPendingEdit(id);
        this.pendingEdits.set(id, window.setTimeout(() => this.moveComponent(id, x, y), 5))
    }

    cancelPendingEdit(id) {
        if (this.pendingEdits.has(id)) {
            window.clearTimeout(this.pendingEdits.get(id));
            this.pendingEdits.delete(id)
        }
    }

    getInitialCollisions(toolShapeMap: Map<string, ToolShapeDetails>, collisionSystem: CollisionDetection, toolShapeToBeRemoved?: string, _toolShapeMap?: Map<string, ToolShapeDetails>) {
        let componentId = undefined
        for (const [id, shape] of toolShapeMap) {
            if (toolShapeToBeRemoved) {
                componentId = id.match(/^[^\d]+_\d+/)?.[0]
                collisionSystem.removeObject(`${componentId}_${toolShapeToBeRemoved}`)
            }
            collisionSystem.addObject({id: `${id}`, ...shape})
            this.addCollisions(collisionSystem.checkOne(id))
            collisionSystem.removeObject(id)
            if (_toolShapeMap) {
                const t = _toolShapeMap.get(`${componentId}_${toolShapeToBeRemoved}`)
                collisionSystem.addObject({
                    id: `${componentId}_${toolShapeToBeRemoved}`,
                    points: t.points,
                    x: t.x,
                    y: t.y,
                    angle: t.angle
                })
            }
        }
    }

    initCollisionDetection() {

        toolShapeNames.forEach(shapeName => {
            this.toolShapeMaps[shapeName] = new Map<string, ToolShapeDetails>()
        })
        this.processRecipe()
        this.polygonMapForCollisionDetection()

        this.withFootCollision.setup(new Map([...this.toolShapeMaps.beamgrabber_withfoot, ...this.toolShapeMaps.midgrabber_withfoot, ...this.toolShapeMaps.sidetool_withfoot]))
        this.withToolCollision.setup(new Map([...this.toolShapeMaps.beamgrabber_withtool, ...this.toolShapeMaps.midgrabber_withtool, ...this.toolShapeMaps.sidetool_withtool]))
        this.baseCollision.setup(new Map([...this.toolShapeMaps.beamgrabber_base, ...this.toolShapeMaps.sidetool_base, ...this.toolShapeMaps.midgrabber_base]))
        this.midgrabberBaseCollision.setup(new Map([...this.toolShapeMaps.presstool_base, ...this.toolShapeMaps.nailplate,
            ...this.toolShapeMaps.removetool_safety, ...this.toolShapeMaps.multi_presstool_base]))

        this.pressBaseCollision.setup(new Map([...this.toolShapeMaps.presstool_base, ...this.toolShapeMaps.multi_presstool_base]))
        this.beamGrabberBaseCollision.setup(new Map([...this.toolShapeMaps.presstool_withtool, ...this.toolShapeMaps.blocktool_base,
            ...this.toolShapeMaps.blocktool_vacuumhousing, ...this.toolShapeMaps.multi_presstool_withtool, ...this.toolShapeMaps.removetool_safety]))
        this.sideToolFinalizedCollision.setup(new Map([...this.toolShapeMaps.nailplate, ...this.toolShapeMaps.blocktool_base, ...this.toolShapeMaps.presstool_mouthdepth]))
        this.sideToolUnFinalizedCollision.setup(this.toolShapeMaps.presstool_mouthdepth)
        this.removeToolNeckCollision.setup(this.toolShapeMaps.timber)
        this.removeToolSafetyAreaCollision.setup(this.toolShapeMaps.removetool_safety)
        this.pressWithToolCollision.setup(this.toolShapeMaps.beamgrabber_base)
        this.pressToolMouthDepthCollision.setup(new Map([...this.toolShapeMaps.timber, ...this.toolShapeMaps.sidetool_unfinalized, ...this.toolShapeMaps.sidetool_finalized]))

        /**
         * find initial collisions when user enters to the edit mode
         */
        //midgrabber_withTool - Base of other JigTools
        this.getInitialCollisions(this.toolShapeMaps.midgrabber_withtool, this.baseCollision, ToolShapes.Base, this.toolShapeMaps.midgrabber_base)
        //beamgrabber_withTool - Base of other JigTools
        this.getInitialCollisions(this.toolShapeMaps.beamgrabber_withtool, this.baseCollision, ToolShapes.Base, this.toolShapeMaps.beamgrabber_base)
        //sidetool_withTool - Base of other JigTools
        this.getInitialCollisions(this.toolShapeMaps.sidetool_withtool, this.baseCollision, ToolShapes.Base, this.toolShapeMaps.sidetool_base)
        //midgrabber_base - presstool_base, removetool_safety, nailplate
        this.getInitialCollisions(this.toolShapeMaps.midgrabber_base, this.midgrabberBaseCollision)
        //beamgrabber_base - withtool of press, blocktool_base, blocktool_vacuumhousing, removetool_safety
        this.getInitialCollisions(this.toolShapeMaps.beamgrabber_base, this.beamGrabberBaseCollision)
        //sidetool_base - base of press
        this.getInitialCollisions(this.toolShapeMaps.sidetool_base, this.pressBaseCollision)
        //sidetool_base - removetool_safety
        this.getInitialCollisions(this.toolShapeMaps.sidetool_base, this.removeToolSafetyAreaCollision)
        //sidetool_finalized - nailplates, blocktool_base
        this.getInitialCollisions(this.toolShapeMaps.sidetool_finalized, this.sideToolFinalizedCollision)
        //removetool neck - timber
        this.getInitialCollisions(this.toolShapeMaps.removetool_neck, this.removeToolNeckCollision)
        //withfoot - withfoot of other JigTools
        this.addCollisions(this.withFootCollision.check())
        //presstool_mouthdepth - timber, sidetool_finalized, sidetool_unfinalized
        this.getInitialCollisions(this.toolShapeMaps.presstool_mouthdepth, this.pressToolMouthDepthCollision)

    }

    updateCollisionSystemOnDelete(componentId: string, toolName: string, component: ITool) {
        if ([ToolName.BeamGrabber, ToolName.MidGrabber, ToolName.SideTool].includes(toolName)) {
            this.pressToolMouthDepthCollision.removeObject(`${componentId}_${ToolShapes.Finalized}`)
            this.pressToolMouthDepthCollision.removeObject(`${componentId}_${ToolShapes.UnFinalized}`)
            this.pressWithToolCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
            this.withFootCollision.removeObject(`${componentId}_${ToolShapes.WithFoot}`)
            this.baseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
            this.withToolCollision.removeObject(`${componentId}_${ToolShapes.WithTool}`)
        } else if (toolName === ToolName.PressTool) {
            this.midgrabberBaseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
            this.pressBaseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
            this.beamGrabberBaseCollision.removeObject(`${componentId}_${ToolShapes.WithTool}`)
            this.sideToolFinalizedCollision.removeObject(`${componentId}_${ToolShapes.MouthDepth}`)
            this.sideToolUnFinalizedCollision.removeObject(`${componentId}_${ToolShapes.MouthDepth}`)

            if (component.multipress) {
                component.multipress?.forEach((item, index) => {
                    this.midgrabberBaseCollision.removeObject(`multi_${componentId}_${index}_${ToolShapes.Base}`)
                    this.pressBaseCollision.removeObject(`multi_${componentId}_${index}_${ToolShapes.Base}`)
                    this.beamGrabberBaseCollision.removeObject(`multi_${componentId}_${index}_${ToolShapes.WithTool}`)
                })
            }

        } else if (toolName === ToolName.RemoveTool) {
            this.midgrabberBaseCollision.removeObject(`${componentId}_${ToolShapes.Safety}`)
            this.beamGrabberBaseCollision.removeObject(`${componentId}_${ToolShapes.Safety}`)
            this.removeToolSafetyAreaCollision.removeObject(`${componentId}_${ToolShapes.Safety}`)
        } else if (toolName === ToolName.BlockTool) {
            this.beamGrabberBaseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
            this.beamGrabberBaseCollision.removeObject(`${componentId}_${ToolShapes.Vacuumhousing}`)
            this.sideToolFinalizedCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
        }

        this.removeCollisionsByComponent(componentId)
    }

    onMouseDown(event: MouseEvent, editMode: boolean, viewerRef: RefObject<any>) {
        if (!editMode) {
            return
        }
        if (!this.mouseEventStatus) {

            this.multipleComponentSelection = this.getComponentsForMouseEvent(event)

            if (this.availableComponents().length === 1) {
                this.pickComponent(this.availableComponents()[0])
            } else {
                this.clearSelection();
            }

        }
        if ((this.mouseEventStatus && this.mouseEventStatus.isMovable) && (this.selectedComponent?.timberId > -1) ||  (this.selectedComponent?.freeTransform) || (this.selectedComponent?.nailplateId > -1)) {
            this.startDrag(this.mouseEventStatus.target, this.mouseEventStatus.componentId, this.mouseEventStatus.transformValue, viewerRef);
            this.updateCollisionSystemOnMouseDown(this.mouseEventStatus.componentId)
            if (this.selectedComponent && (this.selectedTimber || this.selectedNailPlate)) {
                this.addToCollisionForValidateMovements(this.selectedComponent.x, this.selectedComponent.y, this.selectedComponent.angle, this.selectedComponent.layer.toLowerCase())
            }

        }

    }

    onMouseMove(event: MouseEvent, viewerRef: RefObject<any>) {

        if (!this.ongoingDrag) {
            return
        } else {
            if (!this.mouseEventStatus?.componentWasMoved) {
                this.removeCollisionsByComponent(this.ongoingDrag?.componentId)
            }

            const {x, y} = this.calculateTransformValues(event, viewerRef, '')

            const element = document.querySelector(`g[id=${this.ongoingDrag.componentId}]`);
            const selectedComponent = this.selectedComponent

            if (element != null && selectedComponent && x && this.canMoveToolBy(x, -y, selectedComponent.angle)) {

                const currentTransform = element.getAttribute('transform')
                const match = currentTransform?.match(/rotate\(([-+]?\d+(\.\d+)?)/);
                const angle = match ? Number(match[1]) : selectedComponent.angle * 180 / Math.PI

                this.scheduleEdit(this.ongoingDrag.componentId, x, -y)
                this.collisionDetectionForToolDragAndRotation(this.ongoingDrag?.componentId, x, -y, angle * Math.PI / 180)

                const transform = `rotate(${angle}, ${x}, ${-y}) translate(${x}, ${-y})`
                const toolIdTransform = `translate(${x}, ${-y})`

                element.setAttribute('transform', transform);

                //remove tool entry/exit layer dragging
                if (this.mouseEventStatus?.removeToolEntryExitElements) {
                    this.mouseEventStatus.removeToolEntryExitElements.forEach((e) => {
                        const _x = x - e.disX
                        const _y = -y - e.disY
                        const transform = `rotate(${e.angle}, ${_x}, ${_y}) translate(${_x}, ${_y})`
                        e.element.setAttribute('transform', transform)
                    })
                }
                //remove tool safety area dragging
                if (this.mouseEventStatus?.removeToolSafetyElement) {
                    const safetyElement = this.mouseEventStatus?.removeToolSafetyElement
                    const _x = x - safetyElement?.disX
                    const _y = -y - safetyElement?.disY
                    const safetyAreaTransform = `translate(${_x}, ${_y})`
                    safetyElement?.element.setAttribute('transform', safetyAreaTransform)
                }

                //press tool layer dragging
                if (this.mouseEventStatus?.pressToolElements) {
                    this.mouseEventStatus?.pressToolElements.forEach((e) => {
                        let newX = x
                        if (e.element.id.includes(LayerDefs.PressToolRearPaddle.toLowerCase())) {
                            newX = x - this.selectedComponent?.rearPaddleDistance
                        }
                        const transform = `rotate(${e.angle}, ${x}, ${-y}) translate(${newX}, ${-y})`
                        e.element.setAttribute('transform', transform)
                    })
                }


                // move the tool ID when dragging a tool
                const toolIdElement = document.querySelector(`g[id=${this.ongoingDrag.componentId}_tool_id]`);
                if (toolIdElement != null) {
                    toolIdElement.setAttribute('transform', toolIdTransform);
                }

                const cableElement = document.querySelector(`polygon[id=${this.ongoingDrag.componentId}_cable]`);
                if (cableElement != null) {
                    const points = cableElement.getAttribute('data-points').split(',');

                    const homeX = points[0]
                    const homeY = points[1]
                    const toolX = points[2]
                    const toolY = points[3]
                    const newPoints = `${homeX},${homeY} ${x + Number(toolX)},${-y + Number(toolY)}`

                    cableElement.setAttribute('points', newPoints)
                }
                // @ts-ignore
                this.mouseEventStatus.componentWasMoved = true;
            }
        }

    }

    onMouseUp(event: MouseEvent) {

        if (this.mouseEventStatus) {
            if (this.mouseEventStatus.componentWasMoved) {
                const element = document.querySelector(`g[id=${this.ongoingDrag?.componentId}]`);
                if (element !== null) {
                    const transform = element.getAttribute('transform')
                    const match = transform?.match(/translate\(([-+]?[\d.]+),\s*([-+]?[\d.]+)\)/)
                    const selectedComponent = this.selectedComponent
                    if (match && selectedComponent) {
                        const x = Number(match[1])
                        const y = Number(match[2])

                        //timber assignment
                        if (selectedComponent.freeTransform) {

                            let baseShape: string
                            if (selectedComponent.layer === LayerDefs.MidGrabber) {
                                baseShape = this.configuration?.walltoolshapes.SpikeGrabber.DrawingElements.Shapes?.find((s) => s.layer === 'BASE').points
                            } else if (selectedComponent.layer === LayerDefs.BeamGrabber) {
                                baseShape = this.configuration?.walltoolshapes.ChordGrabber.DrawingElements.Shapes.find((s) => s.layer === 'BASE').points
                            }

                            const timberNode = this.getCollidingTimberForTool(baseShape, selectedComponent, x, y)

                            if (timberNode != null && ToolTimberCompatible(selectedComponent.type, timberNode.type)) {
                                const computedAngle = computeAngleForTool(selectedComponent.type, timberNode.angle, selectedComponent.angle)
                                this.timberAssignment.set(this.ongoingDrag?.componentId, {
                                    component: selectedComponent,
                                    timberId: timberNode.id,
                                    angle: computedAngle
                                })
                                const match = transform?.match(/rotate\(([-+]?\d+(\.\d+)?)/)
                                const angle = match ? match[1] : null
                                let calcY = y;
                                let calcX = x;

                                const toolIdElement = document.querySelector(`g[id=${this.ongoingDrag?.componentId}_tool_id]`);
                                let toolIdTransform = toolIdElement?.getAttribute?.('transform') || `translate(${x}, ${calcY})`
                                let newTransform = `translate(${x}, ${calcY})`;

                                if (selectedComponent.layer === LayerDefs.MidGrabber) {
                                    const {x:_x, y:_y} = this.calculateCorrectedYForMidGrabber(selectedComponent, timberNode, x,y)
                                    if(_x != null && _y != null){
                                        if(Math.abs(x-_x) < Math.abs(y- _y)){
                                            calcX = _x;
                                        } else {
                                            calcY = _y;
                                        }
                                    }else if(_y != null){
                                        calcY = _y;
                                    }else if(_x != null){
                                        calcX = _x;
                                    }
                                    newTransform = `rotate(${computedAngle * 180 / Math.PI}, ${calcX}, ${calcY}) translate(${calcX}, ${calcY})`
                                    toolIdTransform = `translate(${calcX}, ${calcY})`
                                } else {
                                    if (selectedComponent.autoCorrectY) {
                                        calcY = this.calculateCorrectedYPosition(selectedComponent, timberNode, x, y)
                                    }
                                    newTransform = angle ? `rotate(${angle}, ${calcX}, ${calcY}) translate(${calcX}, ${calcY})` : `translate(${calcX}, ${calcY})`
                                    toolIdTransform = `translate(${calcX}, ${calcY})`

                                }
                                this.moveComponent(this.ongoingDrag?.componentId, calcX, calcY)
                                this.collisionDetectionForToolDragAndRotation(this.ongoingDrag?.componentId, calcX, calcY, computedAngle * Math.PI / 180)
                                this.updateToolShapeMapsForPropertyChanges(this.ongoingDrag?.componentId, computedAngle * Math.PI / 180, calcX, calcY)

                                element.setAttribute('transform', newTransform);
                                // move the tool ID in autoCorrectY
                                toolIdElement?.setAttribute?.('transform', toolIdTransform);

                                //adjust beamGrabber cable
                                const cableElement = document.querySelector(`polygon[id=${this.ongoingDrag?.componentId}_cable]`);
                                if (cableElement != null) {
                                    const points = cableElement.getAttribute('data-points').split(',');

                                    const homeX = points[0]
                                    const homeY = points[1]
                                    const toolX = points[2]
                                    const toolY = points[3]
                                    const newPoints = `${homeX},${homeY} ${calcX + Number(toolX)},${calcY + Number(toolY)}`

                                    cableElement.setAttribute('points', newPoints)
                                }
                            }else if (this.timberAssignment.has(this.ongoingDrag?.componentId)){
                                this.timberAssignment.delete(this.ongoingDrag?.componentId)
                            }
                        } else {
                            this.moveComponent(this.ongoingDrag?.componentId, x, y)
                            this.collisionDetectionForToolDragAndRotation(this.ongoingDrag?.componentId, x, y, selectedComponent.angle)
                            this.updateToolShapeMapsForPropertyChanges(this.ongoingDrag?.componentId, selectedComponent.angle, x, y)
                        }
                    }
                }

            }

            if ((this.mouseEventStatus && this.mouseEventStatus.isMovable) && (this.selectedComponent?.timberId > -1) ||  (this.selectedComponent?.freeTransform) || (this.selectedComponent?.nailplateId > -1)) {
                this.updateCollisionSystemOnMouseUp(this.mouseEventStatus.componentId)
                this.removeFromCollisionForValidateMovements(this.mouseEventStatus.component.layer.toLowerCase())
            }

            this.endDrag();
            this.mouseEventStatus = null;
        }
    }

    getCollidingTimberForTool(shapePoints: any, component: IComponent, x: number, y: number) {
        for (const timber of this.recipe?.Timbers) {
            const collision = new CollisionDetection()
            collision.setup(new Map().set('timber', {x: 0, y: 0, points: timber.vertices, angle: 0})
                .set('tool', {x: x, y: y, points: convertToCoordinatesArray(shapePoints), angle: component.angle}))

            if (collision.check().length > 0) {
                return timber
            }
        }
        return null
    }

    moveComponent(componentId: string, x: number, y: number) {
        this.moves.set(componentId, {x, y});

    }

    //Checks if the tool can be moved by the given offsets or given angle(in radian)
    canMoveToolBy = (x: number, y: number, angle: number) => {

        if(this.selectedComponent?.freeTransform || this.selectedComponent?.layer === LayerDefs.SideTool){
            return true;
        }
        if (this.selectedComponent) {
            this.collisionForValidateMovements.updateSetup({id: this.selectedComponent.layer.toLowerCase(), x: x, y: y, angle: angle})
            return this.collisionForValidateMovements.check().length > 0
        }
    }

    getCollisions() {
        const collisions = new Set([...this.collisions, ...this.dragCollisions])
        return new Set([...collisions].flatMap(v => JSON.parse(v)))
    }

    collisionDetectionForToolDragAndRotation(componentId: string, x: number, y: number, angle: number) {
        const toolName = componentId.split('_')[0]
        const collisions = []

        if (toolName === ToolName.MidGrabber || toolName === ToolName.BeamGrabber || toolName === ToolName.SideTool) {
            this.withFootCollision.updateSetup({id: `${componentId}_${ToolShapes.WithFoot}`, x: x, y: y, angle: angle})
            collisions.push(...this.withFootCollision.checkOne(`${componentId}_${ToolShapes.WithFoot}`))
            this.baseCollision.updateSetup({id: `${componentId}_${ToolShapes.WithTool}`, x: x, y: y, angle: angle})
            collisions.push(...this.baseCollision.checkOne(`${componentId}_${ToolShapes.WithTool}`))
            this.withToolCollision.updateSetup({id: `${componentId}_${ToolShapes.Base}`, x: x, y: y, angle: angle})
            collisions.push(...this.withToolCollision.checkOne(`${componentId}_${ToolShapes.Base}`))
        }

        switch (toolName) {
            case ToolName.PressTool:
                this.sideToolFinalizedCollision.updateSetup({id: `${componentId}_${ToolShapes.MouthDepth}`, x: x, y: y, angle: angle})
                this.sideToolUnFinalizedCollision.updateSetup({id: `${componentId}_${ToolShapes.MouthDepth}`, x: x, y: y, angle: angle})
                this.beamGrabberBaseCollision.updateSetup({id: `${componentId}_${ToolShapes.WithTool}`, x: x, y: y, angle: angle})
                this.pressBaseCollision.updateSetup({id: `${componentId}_${ToolShapes.Base}`, x: x, y: y, angle: angle})
                this.midgrabberBaseCollision.updateSetup({id: `${componentId}_${ToolShapes.Base}`, x: x, y: y, angle: angle})
                this.baseCollision.updateSetup({id: `${componentId}_${ToolShapes.Base}`, x: x, y: y, angle: angle})
                collisions.push(...this.baseCollision.checkOne(`${componentId}_${ToolShapes.Base}`))
                this.pressWithToolCollision.updateSetup({id: `${componentId}_${ToolShapes.WithTool}`, x: x, y: y, angle: angle})
                collisions.push(...this.pressWithToolCollision.checkOne(`${componentId}_${ToolShapes.WithTool}`))
                this.pressToolMouthDepthCollision.updateSetup({id: `${componentId}_${ToolShapes.MouthDepth}`, x: x, y: y, angle: angle})
                collisions.push(...this.pressToolMouthDepthCollision.checkOne(`${componentId}_${ToolShapes.MouthDepth}`))
                this.addDragCollisions(collisions)
                break
            case ToolName.MidGrabber:
                this.midgrabberBaseCollision.updateSetup({id: `${componentId}_${ToolShapes.Base}`, x: x, y: y, angle: angle})
                collisions.push(...this.midgrabberBaseCollision.checkOne(`${componentId}_${ToolShapes.Base}`))
                this.addDragCollisions(collisions)
                break
            case ToolName.BeamGrabber:
                this.pressWithToolCollision.updateSetup({id: `${componentId}_${ToolShapes.Base}`, x: x, y: y, angle: angle})
                this.pressBaseCollision.updateSetup({id: `${componentId}_${ToolShapes.Base}`, x: x, y: y, angle: angle})
                collisions.push(...this.pressBaseCollision.checkOne(`${componentId}_${ToolShapes.Base}`))
                this.beamGrabberBaseCollision.updateSetup({id: `${componentId}_${ToolShapes.Base}`, x: x, y: y, angle: angle})
                collisions.push(...this.beamGrabberBaseCollision.checkOne(`${componentId}_${ToolShapes.Base}`))
                this.addDragCollisions(collisions)
                break
            case ToolName.SideTool:
                this.pressToolMouthDepthCollision.updateSetup({id: `${componentId}_${ToolShapes.UnFinalized}`, x: x, y: y})
                this.pressToolMouthDepthCollision.updateSetup({id: `${componentId}_${ToolShapes.Finalized}`, x: x, y: y})
                this.pressBaseCollision.updateSetup({id: `${componentId}_${ToolShapes.Base}`, x: x, y: y})
                collisions.push(...this.pressBaseCollision.checkOne(`${componentId}_${ToolShapes.Base}`))
                this.sideToolFinalizedCollision.updateSetup({id: `${componentId}_${ToolShapes.Finalized}`, x: x, y: y})
                collisions.push(...this.sideToolFinalizedCollision.checkOne(`${componentId}_${ToolShapes.Finalized}`))
                this.sideToolUnFinalizedCollision.updateSetup({id: `${componentId}_${ToolShapes.UnFinalized}`, x: x, y: y})
                collisions.push(...this.sideToolUnFinalizedCollision.checkOne(`${componentId}_${ToolShapes.UnFinalized}`))
                this.removeToolSafetyAreaCollision.updateSetup({id: `${componentId}_${ToolShapes.Base}`, x: x, y: y})
                collisions.push(...this.removeToolSafetyAreaCollision.checkOne(`${componentId}_${ToolShapes.Base}`))
                this.addDragCollisions(collisions)
                break
            case ToolName.RemoveTool:
                const _x = x - this.mouseEventStatus?.removeToolSafetyElement?.disX
                const _y = y - this.mouseEventStatus?.removeToolSafetyElement?.disY
                this.baseCollision.updateSetup({id: `${componentId}_${ToolShapes.Safety}`, x: _x, y: _y})
                collisions.push(...this.baseCollision.checkOne(`${componentId}_${ToolShapes.Safety}`))
                this.removeToolNeckCollision.updateSetup({id: `${componentId}_${ToolShapes.Neck}`, x: x, y: y})
                collisions.push(...this.removeToolNeckCollision.checkOne(`${componentId}_${ToolShapes.Neck}`))
                this.addDragCollisions(collisions)
        }
    }

    removeFromCollisionForValidateMovements(layer: string) {
        this.collisionForValidateMovements.removeObject(layer)
        this.collisionForValidateMovements.removeObject('polygon')
    }

    addToCollisionForValidateMovements(x: number, y: number, angle: number, layer: string) {
        switch (layer) {
            case ToolName.PressTool:
                const pressToolShapes = this.getToolShapesMap(this.configuration?.buildtoolshapes.PressTool.DrawingElements.Shapes || [], ['PRESSPLATE'])
                const pressPlatePoints = pressToolShapes.get(PressToolShapes.PressPlate.toLowerCase())
                this.collisionForValidateMovements.addObject({
                    id: layer,
                    points: pressPlatePoints,
                    x: x,
                    y: y,
                    angle: angle
                })
                this.collisionForValidateMovements.addObject({
                    id: 'polygon',
                    points: [{x:this.selectedNailPlate.x, y: this.selectedNailPlate.y}],
                    x: 0,
                    y: 0,
                    angle: 0
                })
                break
            case ToolName.MidGrabber:
                const midGrabberShapes = this.getToolShapesMap(this.configuration?.walltoolshapes.SpikeGrabber.DrawingElements.Shapes || [], ['WITHFOOT'])
                const midGrabberPoints = midGrabberShapes.get(ToolShapes.WithFoot)
                this.collisionForValidateMovements.addObject({
                    id: layer,
                    points: midGrabberPoints,
                    x: x,
                    y: y,
                    angle: angle
                })
                this.collisionForValidateMovements.addObject({
                    id: 'polygon',
                    points: this.selectedTimber.vertices,
                    x: 0,
                    y: 0,
                    angle: 0
                })
                break
            case ToolName.BeamGrabber:
                const beamGrabberShapes = this.getToolShapesMap(this.configuration?.walltoolshapes.ChordGrabber.DrawingElements.Shapes || [], ['WITHFOOT'])
                const beamGrabberPoints = beamGrabberShapes.get(ToolShapes.WithFoot)
                this.collisionForValidateMovements.addObject({
                    id: layer,
                    points: beamGrabberPoints,
                    x: x,
                    y: y,
                    angle: angle
                })
                this.collisionForValidateMovements.addObject({
                    id: 'polygon',
                    points: this.selectedTimber.vertices,
                    x: 0,
                    y: 0,
                    angle: 0
                })
                break
        }
    }

    updateCollisionSystemOnMouseDown(componentId: string) {
        const toolName = componentId.split('_')[0]

        switch (toolName) {
            case ToolName.MidGrabber:

                const midGrabberShapes = this.getToolShapesMap(this.configuration?.walltoolshapes.SpikeGrabber.DrawingElements.Shapes || [], ToolShapesForCollision.MidGrabber)
                const com = this.getSelectedComponent()
                for (const [shapeName, points] of midGrabberShapes) {
                    this.addToToolShapeMaps(`${ToolName.MidGrabber}_${shapeName}`, `${componentId}_${shapeName}`, {
                        points: points,
                        x: com?.x,
                        y: com?.y,
                        angle: com?.angle
                    })
                }

                this.baseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                const mt = this.toolShapeMaps.midgrabber_withtool.get(`${componentId}_${ToolShapes.WithTool}`)
                this.baseCollision.addObject({
                    id: `${componentId}_${ToolShapes.WithTool}`,
                    points: mt.points,
                    x: mt.x,
                    y: mt.y,
                    angle: mt.angle
                })
                const mb = this.toolShapeMaps.midgrabber_base.get(`${componentId}_${ToolShapes.Base}`)
                this.midgrabberBaseCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: mb.points,
                    x: mb.x,
                    y: mb.y,
                    angle: mb.angle
                })
                this.withToolCollision.removeObject(`${componentId}_${ToolShapes.WithTool}`)
                this.withToolCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: mb.points,
                    x: mb.x,
                    y: mb.y,
                    angle: mb.angle
                })
                break

            case ToolName.BeamGrabber:

                const beamGrabberShapes = this.getToolShapesMap(this.configuration?.walltoolshapes.ChordGrabber.DrawingElements.Shapes || [], ToolShapesForCollision.BeamGrabber)
                const b = this.getSelectedComponent()
                for (const [shapeName, points] of beamGrabberShapes) {
                    this.addToToolShapeMaps(`${ToolName.BeamGrabber}_${shapeName}`, `${componentId}_${shapeName}`, {
                        points: points,
                        x: b?.x,
                        y: b?.y,
                        angle: b?.angle
                    })
                }

                this.baseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                const bt = this.toolShapeMaps.beamgrabber_withtool.get(`${componentId}_${ToolShapes.WithTool}`)
                this.baseCollision.addObject({
                    id: `${componentId}_${ToolShapes.WithTool}`,
                    points: bt.points,
                    x: bt.x,
                    y: bt.y,
                    angle: bt.angle
                })
                const bb = this.toolShapeMaps.beamgrabber_base.get(`${componentId}_${ToolShapes.Base}`)
                this.pressBaseCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: bb.points,
                    x: bb.x,
                    y: bb.y,
                    angle: bb.angle
                })
                this.beamGrabberBaseCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: bb.points,
                    x: bb.x,
                    y: bb.y,
                    angle: bb.angle
                })
                this.withToolCollision.removeObject(`${componentId}_${ToolShapes.WithTool}`)
                this.withToolCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: bb.points,
                    x: bb.x,
                    y: bb.y,
                    angle: bb.angle
                })
                break
            case ToolName.SideTool:
                this.baseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                const st = this.toolShapeMaps.sidetool_withtool.get(`${componentId}_${ToolShapes.WithTool}`)
                this.baseCollision.addObject({
                    id: `${componentId}_${ToolShapes.WithTool}`,
                    points: st.points,
                    x: st.x,
                    y: st.y,
                    angle: st.angle
                })
                const sb = this.toolShapeMaps.sidetool_base.get(`${componentId}_${ToolShapes.Base}`)
                this.pressBaseCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: sb.points,
                    x: sb.x,
                    y: sb.y,
                    angle: sb.angle
                })
                this.removeToolSafetyAreaCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: sb.points,
                    x: sb.x,
                    y: sb.y,
                    angle: sb.angle
                })
                this.withToolCollision.removeObject(`${componentId}_${ToolShapes.WithTool}`)
                this.withToolCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: sb.points,
                    x: sb.x,
                    y: sb.y,
                    angle: sb.angle
                })
                const sf = this.toolShapeMaps.sidetool_finalized.get(`${componentId}_${ToolShapes.Finalized}`)
                this.sideToolFinalizedCollision.addObject({
                    id: `${componentId}_${ToolShapes.Finalized}`,
                    points: sf.points,
                    x: sf.x,
                    y: sf.y,
                    angle: sf.angle
                })
                const su = this.toolShapeMaps.sidetool_unfinalized.get(`${componentId}_${ToolShapes.UnFinalized}`)
                this.sideToolUnFinalizedCollision.addObject({
                    id: `${componentId}_${ToolShapes.UnFinalized}`,
                    points: su.points,
                    x: su.x,
                    y: su.y,
                    angle: su.angle
                })
                break
            case ToolName.RemoveTool:
                const rs = this.toolShapeMaps.removetool_safety.get(`${componentId}_${ToolShapes.Safety}`)
                this.baseCollision.addObject({
                    id: `${componentId}_${ToolShapes.Safety}`,
                    points: rs.points,
                    x: rs.x,
                    y: rs.y,
                    angle: rs.angle
                })
                const rn = this.toolShapeMaps.removetool_neck.get(`${componentId}_${ToolShapes.Neck}`)
                this.removeToolNeckCollision.addObject({
                    id: `${componentId}_${ToolShapes.Neck}`,
                    points: rn.points,
                    x: rn.x,
                    y: rn.y,
                    angle: rn.angle
                })
                break
            case ToolName.PressTool:
                const pb = this.toolShapeMaps.presstool_base.get(`${componentId}_${ToolShapes.Base}`)
                this.baseCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: pb.points,
                    x: pb.x,
                    y: pb.y,
                    angle: pb.angle
                })
                const pw = this.toolShapeMaps.presstool_withtool.get(`${componentId}_${ToolShapes.WithTool}`)
                this.pressWithToolCollision.addObject({
                    id: `${componentId}_${ToolShapes.WithTool}`,
                    points: pw.points,
                    x: pw.x,
                    y: pw.y,
                    angle: pw.angle
                })
                const md = this.toolShapeMaps.presstool_mouthdepth.get(`${componentId}_${ToolShapes.MouthDepth}`)
                this.pressToolMouthDepthCollision.addObject({
                    id: `${componentId}_${ToolShapes.MouthDepth}`,
                    points: md.points,
                    x: md.x,
                    y: md.y,
                    angle: md.angle
                })
                break

        }
    }

    updateCollisionSystemOnMouseUp(componentId: string) {
        const toolName = componentId.split('_')[0]
        switch (toolName) {
            case ToolName.MidGrabber:
                this.withToolCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                const wt = this.toolShapeMaps.midgrabber_withtool.get(`${componentId}_${ToolShapes.WithTool}`)
                this.withToolCollision.addObject({
                    id: `${componentId}_${ToolShapes.WithTool}`,
                    points: wt.points,
                    x: wt.x,
                    y: wt.y,
                    angle: wt.angle
                })
                this.baseCollision.removeObject(`${componentId}_${ToolShapes.WithTool}`)
                const mt = this.toolShapeMaps.midgrabber_base.get(`${componentId}_${ToolShapes.Base}`)
                this.baseCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: mt.points,
                    x: mt.x,
                    y: mt.y,
                    angle: mt.angle
                })
                this.midgrabberBaseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                break
            case ToolName.BeamGrabber:
                this.withToolCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                const t = this.toolShapeMaps.beamgrabber_withtool.get(`${componentId}_${ToolShapes.WithTool}`)
                this.withToolCollision.addObject({
                    id: `${componentId}_${ToolShapes.WithTool}`,
                    points: t.points,
                    x: t.x,
                    y: t.y,
                    angle: t.angle
                })
                this.baseCollision.removeObject(`${componentId}_${ToolShapes.WithTool}`)
                const bt = this.toolShapeMaps.beamgrabber_base.get(`${componentId}_${ToolShapes.Base}`)
                this.baseCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: bt.points,
                    x: bt.x,
                    y: bt.y,
                    angle: bt.angle
                })
                this.pressBaseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                this.beamGrabberBaseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                break
            case ToolName.SideTool:
                this.withToolCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                const s = this.toolShapeMaps.sidetool_withtool.get(`${componentId}_${ToolShapes.WithTool}`)
                this.withToolCollision.addObject({
                    id: `${componentId}_${ToolShapes.WithTool}`,
                    points: s.points,
                    x: s.x,
                    y: s.y,
                    angle: s.angle
                })
                this.baseCollision.removeObject(`${componentId}_${ToolShapes.WithTool}`)
                const st = this.toolShapeMaps.sidetool_base.get(`${componentId}_${ToolShapes.Base}`)
                this.baseCollision.addObject({
                    id: `${componentId}_${ToolShapes.Base}`,
                    points: st.points,
                    x: st.x,
                    y: st.y,
                    angle: st.angle
                })
                this.pressBaseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                this.removeToolSafetyAreaCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                this.sideToolFinalizedCollision.removeObject(`${componentId}_${ToolShapes.Finalized}`)
                this.sideToolUnFinalizedCollision.removeObject(`${componentId}_${ToolShapes.UnFinalized}`)
                break
            case ToolName.RemoveTool:
                this.baseCollision.removeObject(`${componentId}_${ToolShapes.Safety}`)
                this.removeToolNeckCollision.removeObject(`${componentId}_${ToolShapes.Neck}`)
                break
            case ToolName.PressTool:
                this.baseCollision.removeObject(`${componentId}_${ToolShapes.Base}`)
                this.pressWithToolCollision.removeObject(`${componentId}_${ToolShapes.WithTool}`)
                this.pressToolMouthDepthCollision.removeObject(`${componentId}_${ToolShapes.MouthDepth}`)
                break
        }

        this.dragCollisions.forEach(value => {
            this.collisions.add(value);
        })
        this.dragCollisions.clear()

    }

    addCollisions(collisions) {
        collisions.forEach((result) => {
            const ids = result.map(item => item.replace(/_[^_]+$/, ''))
            this.collisions.add(JSON.stringify(ids))
        })
    }

    addDragCollisions(collisions) {
        this.dragCollisions.clear()
        collisions.forEach((result) => {
            const ids = result.map(item => item.replace(/_[^_]+$/, ''))
            if (ids) {
                this.dragCollisions.add(JSON.stringify(ids))
            }
        })
    }

    removeCollisionsByComponent(componentId: string) {
        this.collisions.forEach(item => {
            const parsedArray = JSON.parse(item);
            if (parsedArray.includes(componentId)) {
                this.collisions.delete(JSON.stringify(parsedArray));
            }
        })
    }

    cancelEditMode = () => {
        this.moves.clear();
        this.deletions.clear();
        this.selectedTimber = null;
        this.selectedComponent = null;
        this.multipleComponentSelection.clear();
        this.recipe = null
        this.configuration = null
        this.pendingEdits.clear()
        this.clearToolShapeMaps()
        this.collisions.clear()
        this.dragCollisions.clear()
        this.propsData = {}
        this.timberAssignment.clear()
        this.freeTransform.clear()
        this.autoCorrectY.clear()
        this.removeDirection = undefined
        this.cartTypeInUse = undefined
    };

    calculateTransformValues(e: MouseEvent, viewerRef: RefObject<any>, id: any) {

        const ctm = viewerRef.current?.props.value

        const pointerX = viewerRef.current?.state.pointerX
        const pointerY = viewerRef.current?.state.pointerY
        const x = (pointerX - ctm.e) / ctm.a
        const y = (pointerY - ctm.f) / ctm.d

        const {disX, disY} = this.ongoingDrag?.distanceToMouseClickFromToolMidPoint
        const newToolX = x - disX
        const newToolY = y + disY

        if (this.selectedComponent?.freeTransform) {
            return {x: newToolX, y: newToolY}
        }

        const distanceX = this.ongoingDrag?.startPosition.toolX - newToolX

        const angle = this.selectedTimber ? this.selectedTimber.angle : null
        if (angle != null) {
            if (angle * (180 / Math.PI) === 90) {
                return {
                    x: this.selectedComponent?.x,
                    y: newToolY
                }
            } else {

                return {
                    x: newToolX,
                    y: distanceX * Math.tan(angle) - this.ongoingDrag?.startPosition.toolY,
                };
            }
        } else return {x: newToolX, y: newToolY}
    }

    calculateCorrectedYForMidGrabber(component: IComponent, timber: ITimber, movementX: number, movementY: number) {
        //delta(y)/delta(x) = m = tan(angle) => y = m * delta(x) + y_{p}
        //delta(y)/delta(x) = m = tan(angle) => x = delta(y)/m + x_{p}
        let m = Math.tan(timber.angle);
        return {
            y: timber.angle == Math.PI/2 || Number.isNaN(m) ? undefined: m * (movementX - timber.x) + timber.y,
            x: Number.isNaN(m) ? timber.x : m === 0 ? undefined : (movementY - timber.y)/Math.tan(timber.angle) + timber.x
        }
    }

    calculateCorrectedYPosition(component: IComponent, timber: ITimber, movementX: number, movementY: number) {

        const timberPolygon = timber.vertices
        const startY = 0
        const endY = this.configuration?.walldata.WallDimensions.MaxY || 5000

        // Pass the return array of intersections as reference, check them at new X position
        let intersectionPoints = new Array<{ x: number, y: number }>()
        const hasIntersections = GetIntersectionPointsOfCurrentComponentWithVerticalLine(timberPolygon, movementX, intersectionPoints, startY, endY)

        const {angle: grabberAngle, layer} = component;
        if (hasIntersections && layer === LayerDefs.BeamGrabber) {

            const {angle: TimberAngle, type} = timber
            const a = this.configuration?.walltoolshapes.ChordGrabber.DrawingElements.ContactPoints[0].point.split(',')[1]
            const b = this.configuration?.walltoolshapes.ChordGrabber.DrawingElements.ContactPoints[1].point.split(',')[1]
            const YCorrectionFromGrabToSegment = Number(b) //ChordGrabberEccentricDistanceUnFinalized
            const CircleRadius = Number(a) //ChordGrabberCircleRadius
            const circleSegmentSideHeightAtTouch = CircleRadius * (1 - Math.cos(TimberAngle - grabberAngle)) // Segment touch on normal, Y level
            // being eg (50mm * (1-cos(-47deg)) )=15.9 while circle radius is 50mm this is Y from circle bottom
            const circleSegmentSideDistanceFromTouch = CircleRadius * (Math.sin(TimberAngle - grabberAngle))
            // A being eg (50mm * sin(-47deg) = -36.6 this is X from circle center
            const alongWithTimber = circleSegmentSideDistanceFromTouch / Math.sin(Math.PI / 2 + TimberAngle - grabberAngle)
            // C being the longest side of triangle, 53.66
            const totalHeight = Math.sqrt(Math.pow(alongWithTimber, 2) - Math.pow(circleSegmentSideDistanceFromTouch, 2))
            // B being the B^2 = C^2 - A^2  = sqr(53.66^2 - 36,6^2) = 39.24
            // yCorrection = 7mm + 39.24 - 15.9
            const correction = (YCorrectionFromGrabToSegment + totalHeight - circleSegmentSideHeightAtTouch)

            if (type === APSofwareTypes.UpperBeam)
                return (intersectionPoints ? Math.max(...intersectionPoints.map(({y: a}) => a)) : movementY) + correction + ChordGrabberOnOffDistance.UpperChordGrabberOnOffDistance
            else if (type === APSofwareTypes.DownBeam)
                return (intersectionPoints ? Math.min(...intersectionPoints.map(({y: a}) => a)) : movementY) - correction - ChordGrabberOnOffDistance.DownChordGrabberOnOffDistance

        }
        // No change, or not applicable
        return movementY
    }

}

export const EditServiceObj = new EditService()
