/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import {unstable_batchedUpdates} from 'react-dom'
import {IAuth, TokenResponse} from "../auth/auth";
import { Auth } from "../auth/Authentication"
import {signIn, signOut, refreshToken, signInWithCode, userInfo} from "../../api";
import {notification} from "antd";

Auth.configure({
    signIn: async (userName, passWord) => {
        const {access_token, refresh_token} = await signIn(userName, passWord) || {}
        return {token: access_token, refreshToken: refresh_token} as TokenResponse;
    },
    refresh: async (token) => {
        const {access_token, refresh_token} = await refreshToken(token) || {}
        return {token: access_token, refreshToken: refresh_token} as TokenResponse;
    },
    signOut: async () => {
        await signOut(Auth.refreshToken,Auth.user)
        return true;
    },
    signInWithCode: async (authorizationCode, redirectUri) => {
        const {access_token, refresh_token} = await signInWithCode(authorizationCode, redirectUri)
        return {token: access_token, refreshToken: refresh_token}
    },
    storage: "session",
    storageKey: "recipe_manager.token"
})

export interface AuthContextProps {
    auth: IAuth,
    user?: any,
    setUser?: (data: any) => void
    state?: boolean
}


export const AuthContext = React.createContext<AuthContextProps>({auth: Auth})

export const AuthContextProvider: React.FunctionComponent<PropsWithChildren<any>> = (props) => {

    const [signIn, setSignIn] = React.useState(false)
    const [user, setUser] = React.useState()

    React.useEffect(() => {
        Auth.subscribe(async (state) => {
            if (state) {
                const {data} = await userInfo().catch(console.error)||{};
                unstable_batchedUpdates(() => {
                    setUser(data)
                    setSignIn(!!data)
                })
                
            } else {
                unstable_batchedUpdates(() => {
                    setUser(undefined)
                    setSignIn(false)
                })
            }
        })

    }, [])


    return <AuthContext.Provider value={{auth: Auth, user: user, setUser: setUser, state: signIn}}>
        {props.children}
    </AuthContext.Provider>
}





