/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, useRef} from "react";

export interface EventListenerContextProps {
    registerHandlers: (eventName: string, handlerName: string, handler: (event: KeyboardEvent)=>void)=> void,
    unregisterHandlers: (eventName: string, handlerName: string)=> void,
    oneKeyPress: (event: KeyboardEvent)=> void,
    oneKeyUp: (event: KeyboardEvent)=> void,
    oneKeyDown: (event: KeyboardEvent)=> void
}
const defaultEventListenerContextValues: EventListenerContextProps = {
    registerHandlers: () => {},
    unregisterHandlers: () => {},
    oneKeyPress: () => {},
    oneKeyUp: () => {},
    oneKeyDown: () => {}
}

export const EventListenerContext = React.createContext<EventListenerContextProps>(defaultEventListenerContextValues)

export const EventListenerProvider: React.FunctionComponent<PropsWithChildren> = (props) => {

    const [keyUpHandlers, setKeyUpHandlers] = React.useState<Map<string, {handler: (event: KeyboardEvent) => void}>>(new Map());
    const [keyDownHandlers, setKeyDownHandlers] = React.useState<Map<string, {handler: (event: KeyboardEvent) => void}>>(new Map());
    const [keyPressHandlers, setKeyPressHandlers] = React.useState<Map<string, {handler: (event: KeyboardEvent) => void}>>(new Map());


    const registerHandlers = React.useCallback((eventName: string, handlerName: string, handler: (event: KeyboardEvent) => void) => {

        if (eventName === 'keyup') {
            setKeyUpHandlers((prev) => new Map(prev).set(handlerName, {handler}));
        } else if (eventName === 'keydown') {
            setKeyDownHandlers((prev) => new Map(prev).set(handlerName, {handler}));
        } else if (eventName === 'keypress') {
            setKeyPressHandlers((prev) => new Map(prev).set(handlerName, {handler}));
        }

    },[])

    const unregisterHandlers = React.useCallback((eventName: string, handlerName: string) => {

        if (eventName === 'keyup') {
            setKeyUpHandlers((prev) => {
                const handlers = new Map(prev)
                handlers.delete(handlerName)
                return handlers
            })
        } else if (eventName === 'keydown') {
            setKeyDownHandlers((prev) => {
                const handlers = new Map(prev)
                handlers.delete(handlerName)
                return handlers
            })
        } else if (eventName === 'keypress') {
            setKeyPressHandlers((prev) => {
                const handlers = new Map(prev)
                handlers.delete(handlerName)
                return handlers
            })
        }
    }, [])

    const oneKeyPress = React.useCallback((event: KeyboardEvent)=>{
        if (event.target.id === 'main-ref') {
            keyPressHandlers.forEach(({handler }) => {
                handler(event)
            })
        } else return
    }, [keyPressHandlers])

    const oneKeyUp = React.useCallback((event: KeyboardEvent)=>{
        if (event.target.id === 'main-ref') {
            keyUpHandlers.forEach(({handler }) => {
                handler(event)
            })
        } else return

    }, [keyUpHandlers])

    const oneKeyDown = React.useCallback((event: KeyboardEvent)=>{
        if (event.target.id === 'main-ref') {
            keyDownHandlers.forEach(({handler }) => {
                handler(event)
            })
        } else return
    }, [keyDownHandlers])

    return <EventListenerContext.Provider value={{oneKeyPress, oneKeyDown, oneKeyUp, registerHandlers, unregisterHandlers}}>
        {props.children}
    </EventListenerContext.Provider>
}

export interface listenerRefContextProps {
    setFocus: ()=> void
}
export const listenerRefContext = React.createContext<listenerRefContextProps>({setFocus: ()=>{}})

export const KeyboardListener: React.FC<PropsWithChildren<{}>> = (props)=>{
    const listenerRef = useRef<HTMLDivElement | undefined>(undefined)

    const {oneKeyUp, oneKeyDown, oneKeyPress} = React.useContext(EventListenerContext)

    const setFocus = React.useCallback(()=>{
        const cur = listenerRef?.current
        if (cur) {
            cur.focus()
        }
    }, [listenerRef?.current])

    React.useEffect(()=>{
        setFocus()
    }, [listenerRef?.current])

    return <listenerRefContext.Provider value={{setFocus}}>
            <div id={'main-ref'}
                       ref={listenerRef}
                       tabIndex={0}
                       onKeyPress={oneKeyPress}
                       onKeyUp={oneKeyUp}
                       onKeyDown={oneKeyDown}
            >
                {props.children}
            </div>
    </listenerRefContext.Provider>

}