/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, useState} from "react";
import {
    DataContextProvider,
    DataContext,
    Response,
    Request,
    DataContextProps
} from "@q4us-sw/q4us-ui-v2/dist/util";
import {SelectionContext, SelectionContextProps, Table} from "@q4us-sw/q4us-ui-v2/dist/table";
// @ts-ignore
import {SelectionContextProvider} from "@q4us-sw/q4us-ui-v2/dist/table/Selection";
import {deployRecipe, visualizeLoad, visualizeLoadTrussSummary} from "../api";
import {PageSelector} from "@q4us-sw/q4us-ui-v2/dist/table/Pagination";
import {SorterResult, TableCurrentDataSource, TablePaginationConfig} from "antd/es/table/interface";
import {Formatter} from "@q4us-sw/q4us-ui-v2";
import {Link, useLocation} from "react-router-dom";
import {inlineFilterMap} from "../util/mapping";
import {WildcardFilter} from "@q4us-sw/q4us-ui-v2/dist/table/filters/Wildcard";
import {EnumFilter,EnumFilterProps} from "@q4us-sw/q4us-ui-v2/dist/table/filters/Enum";
import {Button, notification,Tag} from "antd";
import '../styles/RecipeManagerHome.css'
import {
    CheckSquareTwoTone,CloseCircleTwoTone,
    MinusOutlined,
    WarningTwoTone
} from "@ant-design/icons";
import {I18nContext,useTranslation } from "react-i18next";
import {Trans} from "./reactive/Trans";
import ConfigProvider from "antd/lib/config-provider";
import { PreferenceContext } from "./user/UserPreference";
import { IOContext } from "./io/SocketIO";
enum ErrorStatus {
    NO_RECIPE ='no_recipe',
    SUCCESS = 'success',
    ERROR ='error',
    WARNING = 'warning',
    WARNING_ACK = 'warning_ack',
    UNKNOWK = 'unknown',
    UNDEFINED = '-'
}

const LinkResolver: Formatter = (props) => {
    return <DataContext.Consumer>
        {(ctx) => {
        return (
          <Link to={`/view/truss-view?proj=${props?.record?.project_name_temp}&t=${props?.record?.children && props?.record?.children.length > 0 ? props?.record?.children[0]?.truss_id : props?.record?.truss_id}&y=${props?.record?.timestamp_year}&p=${props?.record?.project_id}`}>
            {props?.record?.children && props?.record?.children.length > 0? '':props.value }
          </Link>
        );
      }}
    </DataContext.Consumer>
}
const LinkResolverProj: Formatter = (props) => {
    return <DataContext.Consumer>
        {(ctx) => {
                if (!props?.record?.children || props?.record?.children.length === 0) {
                    return <span>{props.value}</span>; // If children is null or empty, return text
                }

                return (
                    <Link to={`/view/truss-view?proj=${props?.record?.project_name_temp}&t=${props?.record?.children[0]?.truss_id || props?.record?.truss_id}&y=${props?.record?.timestamp_year}&p=${props?.record?.project_id}`}>
                        {props.value}
                    </Link>
                );
            }}
    </DataContext.Consumer>
}

const ErrorWarningIcon: Formatter = (props) => {
    return <React.Fragment>
        {props.value === ErrorStatus.ERROR && <CloseCircleTwoTone twoToneColor="#eb2f96" className={'error-warning-icon'}/>}
        {props.value === ErrorStatus.WARNING && <WarningTwoTone twoToneColor="#de941d" className={'error-warning-icon'}/>}
        {props.value === ErrorStatus.WARNING_ACK && <WarningTwoTone twoToneColor="#87ceeb" className={'error-warning-icon'}/>}
        {props.value === ErrorStatus.SUCCESS && <CheckSquareTwoTone twoToneColor="#52c41a" className={'error-warning-icon'}/>}
        {props.value === ErrorStatus.UNKNOWK && <MinusOutlined />}
        {props.value === ErrorStatus.NO_RECIPE && <MinusOutlined />}
        {props.value === ErrorStatus.UNDEFINED && <MinusOutlined />}
    </React.Fragment>
}
const DeployStatusIcon: Formatter = (props) => {
    return <React.Fragment>
        {props.value && (props.value =='yes'||props.value ==true )&& <CheckSquareTwoTone twoToneColor="#52c41a" className={'error-warning-icon'}/>}
        {!(props.value =='yes'||props.value ==true )&& <MinusOutlined />}

    </React.Fragment>
}
const StatusIcon: Formatter = (props) => {
    let label = '';

    if (props.value === 'Fully') {
        label = 'Fully';
    } else if (props.value === 'Partially') {
        label = 'Partially';
    } else if (props.value === 'None') {
        label = 'None';
    }else{
        label = '-';
    }
    return <Tag key={props.value} className={`status-label ${props.value}`}><Trans i18nKey={`StatusIcon.${label}`} defaults={label} /></Tag>
};
export const RecipeManagerHome: React.FunctionComponent<PropsWithChildren<any>> = (props) => {
    const [checkStrictly, setCheckStrictly] = React.useState(false);
    const registerEnumFilterWithEnums = (enums: any) => (props: any) => (
        <EnumFilter {...props} enums={enums} />
    );
    return <SelectionContextProvider>

        <DataContextProvider save={async (d) => true} delete={async (d) => true} data={async (req) => {


            const res = await visualizeLoadTrussSummary({
                tableName: 'project_with_truss_view',
                filter: [...inlineFilterMap(req.inline?.filter || {})],
                sort: req.sort,
                page: {
                    size: req.page?.size || 100,
                    from: ((req.page?.current || 1) - 1) * (req.page?.size || 100),
                    current: req.page?.current || 1,
                    total: req.page?.total || 0
                },
            })
            return res as Response

        }}>
            <React.Fragment>
                <DataContext.Consumer>
                    
                    {data => <PreferenceContext.Consumer>{ctx=>
                    <ConfigProvider locale={ctx?.prefInfo.pref.antdLang}>
                        <Table key={'table'} schema="truss_details"
                                    size="small"
                                    rowClassName={(record) => record.children ? 'child-row' : 'parent-row'}
                                    rowKey={"id"}
                                    scroll={{y: 'calc(100vh  - 64px - 90px)'}}
                                    register={(registry) => 
                                        registry.register("FILTER__LINK", WildcardFilter)
                                        .register("FILTER__PARENT_LINK", WildcardFilter)
                                        .register("FILTER__ITEM_LINK", WildcardFilter)
                                        .register("FILTER__DEPLOY_STATUS",registerEnumFilterWithEnums([ ["None", <Trans i18nKey={'table.filter.enum.None'} defaults={"None"}/>],["Fully", <Trans i18nKey={'table.filter.enum.Fully'} defaults={"Fully"}/>],["Partially", <Trans i18nKey={'table.filter.enum.Partially'} defaults={"Partially"}/>]]))
                                        .register("FILTER__TRUSS",registerEnumFilterWithEnums([ ["None", <Trans i18nKey={'table.filter.enum.None'} defaults={"None"}/>],["Fully", <Trans i18nKey={'table.filter.enum.Fully'} defaults={"Fully"}/>],["Partially", <Trans i18nKey={'table.filter.enum.Partially'} defaults={"Partially"}/>]]))
                                        }
                                    formatRegister={(registry) =>
                                        registry.register("LINK", LinkResolver)
                                            .register("ITEM_LINK", LinkResolver)
                                            .register("PARENT_LINK", LinkResolverProj)
                                            .register("ERROR_WARNING", ErrorWarningIcon)
                                            .register("DEPLOY_STATUS", StatusIcon)}
                                    rowSelection={{ checkStrictly }}
                                    expandable={{
                                        defaultExpandAllRows: false,
                                        expandIconColumnIndex: 2,
                                        
                                    }}
                    /></ConfigProvider>}</PreferenceContext.Consumer>}
                    
                </DataContext.Consumer>
                <DataContext.Consumer>
                    {data => <SelectionContext.Consumer>
                        { selection => data.page 
                        &&<SelectionHeader selection={selection} data={data} props={{schema: "truss_details",...props}}/>
                        }
                        
                    </SelectionContext.Consumer>}
                </DataContext.Consumer>
            </React.Fragment>

        </DataContextProvider>
    </SelectionContextProvider>
}

export const SelectionHeader: React.FC<{selection:any,data:any,props:any}> = ({ selection ,data,props})=>{
    const location = useLocation();
    const headerCtx = React.useContext(RecipeHomeContext);
    const ctx = React.useContext(IOContext)
    React.useEffect(() => {
        ctx.socket?.on('refresh.projects', (...res: any) => {
            data.fetch?.({
                sort: data.sort,
                inline: data.inline,
                page: data.page
            } as any)
        })
        return () => {
            ctx.socket?.off(`refresh.projects`)
        }
    }, [ctx,props])
    React.useEffect(() => {
        if(data && selection?.selection?.keys?.length)
        {
            const newState:any = {
                deploySetectiondata: selection.selection.data,
                deployData: data,
                deploySelection: selection
            };
            const updatedState = { ...location.state, ...newState };
            location.state = updatedState;
            headerCtx?.set({count :selection?.selection?.keys?.length})
        }else{
            if(location.state)
            {
                const { deploySetectiondata, deployData, deploySelection, ...restState } = location.state;
                location.state = restState;
            }
            headerCtx?.set({count: 0})
            
        }
        

    }, [data,selection]);


    return <PageSelector key={'pagination'} onChange={async (cur, size) => {
        await data.fetch?.({
            sort: data.sort,
            inline: data.inline,
            page: {
                total: data?.page?.total,
                from: ((cur || 1) - 1) * (size || 100),
                current: cur,
                size: size
            }
        } as Request);
        
    }} current={data.page.current} className={''} summary={true} size={data.page.size}
                                        schema={props.schema} total={data.page.total}
                                        height={"small"}>
            
        </PageSelector>  
}

export interface RecipeHomeRequest{
    count: number
}
export interface RecipeHome{
    selectedComponent: number,
    set: (req?: RecipeHomeRequest)=>Promise<void>
}


export const RecipeHomeContext: React.Context<RecipeHome| undefined> = React.createContext<RecipeHome| undefined>(undefined)


export const RecipeHomeContextProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {

    const [selectedComponent, setSelectedComponents] = React.useState<number>(0);
    const location = useLocation();

    const load = React.useCallback(async (req?: RecipeHomeRequest)=>{
        
        if(req){
            
            setSelectedComponents(req.count)
        }

    }, [])
    

    React.useEffect(()=>{
        setSelectedComponents(0)
    }, [location])

    return <RecipeHomeContext.Provider value={{selectedComponent:selectedComponent, set: load }}>
        {props.children}
    </RecipeHomeContext.Provider>

   
  };
export default RecipeManagerHome;
