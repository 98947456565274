/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {useCallback, useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import {Select} from "antd";
import {visualizeLoad} from "../../../api";


export interface CustomDropDownProps {
    type?: string
    value?: any,
    onChange?: (data: any) => void,
    schema: string,
    name: string,
    tableName?: string
    filter?:string
    initiallyDisabled: boolean
    filterValue?: any
    enum_map: string[]
    fetch?: (request:{tableName:string, filter:{[key:string] : any}}) => Promise<AxiosResponse<any>>
}

const CustomDropDown: React.FunctionComponent<CustomDropDownProps> = (props) => {

    const [enums, setEnums] = useState<any[]|undefined>(undefined);

    const fetchEnums = useCallback(async ({tableName, filter, value}:{tableName:string, filter:string|undefined, value:string|undefined})=>{
        if (!props.fetch && (filter && !value)){
            setEnums([]);
            props.onChange?.(undefined)
        }
        else{
            const request = {
                tableName: tableName,
                filter:filter?[{name:filter, value:value, comparator:'='}]:[]
            }
            const response = await props.fetch?.(request) || await visualizeLoad(request);
            const {status, data:{rows=[]}}:{status:number, data:{rows:Array<any>}}  = response;
            if (status===200){
                let enums = [];
                if (filter !== 'truss_id') {
                    enums = rows.map((item):any=>{return {value: item[props.enum_map[0]], label: item[props.enum_map[1]]}});
                    if (enums?.filter((enum_item)=>{return enum_item[0] === String(props.value)}).length==0){
                        props.onChange?.(undefined)
                    }

                }  else {
                    enums = rows.map((item):any=>{
                        const value = item['id'];
                        const label = `${item['version']}-(${item['friendly_name']})`
                        return {value, label}
                    });
                }
                setEnums(enums);

            }
            else{
                setEnums([]);
                props.onChange?.(undefined)
            }
        }
    }, [props.filterValue]);

    useEffect(()=>{
        fetchEnums({tableName: props.tableName || '', filter:props.filter, value:props.filterValue});
    }, [props.filterValue]);

    return  <Select
        showSearch
        style={{ width: 200 }}
        allowClear={true}
        disabled={(props.initiallyDisabled && !props.filterValue)}
        placeholder="Search to Select"
        optionFilterProp={props.name}
        onChange={(e)=>{props.onChange?.(e)}}
        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
        filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
        options={enums}
    />
}

export default CustomDropDown