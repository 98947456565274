/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {Button, Card, Form, Input, notification} from "antd";
import '../../styles/ChangePassword.css'
import {changePassword, ChangePasswordBody, passwordPolicy} from "../../api";
import { Trans } from "../reactive/Trans";
//import {StatusCodes} from "http-status-codes";

export const ChangePassword : React.FunctionComponent<any> = (props)=>{

    const [form] = Form.useForm()
    const [policy, setPolicy] = React.useState(undefined)

    const validator = React.useCallback((rule: any, value: any)=>{
        const newPass = form.getFieldValue(`new_password`)
        const confirm = form.getFieldValue(`confirm_password`)

        if(newPass === confirm)
            return Promise.resolve()
        return Promise.reject(<Trans i18nKey={'change_password.form.Pwd_not_match'} defaults={"Password does not match"}/>)
    }, [])

    React.useEffect(()=>{
        passwordPolicy().then(({data = {}}: any)=>{
            setPolicy(data.policy)
        })
    },[])

    return <React.Fragment>
        <div className={`change-pass`}>
            <Card type={"inner"}
                  title={<Trans i18nKey={'change_password.form.Change_Password'} defaults={"Change Password"}/>}
            >
                <Form
                    form={form}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={async (values: ChangePasswordBody)=>{
                        const {data: {code, message} = {}} = await changePassword(values)
                        if (code===200) {
                            notification.success({message: message || <Trans i18nKey={'notification.Success'} defaults={"Success"}/>})
                            form.resetFields();
                        } else {
                            notification.error({message: message || <Trans i18nKey={'notification.Error'} defaults={"Error"}/>})
                        }
                    }}
                >
                    <Form.Item name={`current_password`} label={<Trans i18nKey={'change_password.form.Current_password'} defaults={"Current password"}/>} required>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item extra={<Trans i18nKey={'change_password.form.policy'} defaults={policy}/>} name={`new_password`} label={<Trans i18nKey={'change_password.form.New_password'} defaults={"New password"}/>}  required rules={[{required:true}]}>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item name={`confirm_password`} label={<Trans i18nKey={'change_password.form.Re-enter_new_password'} defaults={"Re-enter new password"}/>}  required  rules={[{required:true},{validator}]}>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit" className={`btn-change-passwd`}>
                        <Trans i18nKey={'change_password.form.Change_Password'} defaults={"Change Password"}/>
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    </React.Fragment>
}
export default ChangePassword;
