/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React from "react";
import {convertVertices, RecipeContext} from "../util";
import {LayerDefs} from "../def";
import {ErrorSelectionContext, LayerSelectionContext} from "../../trusscheck/util"
import {isSelectedByIdList} from "../util"
import {CollisionContext} from "../collisionDetection/util";

export const NailPlateLayer: React.FunctionComponent<any> = ()=> {

    return <CollisionContext.Consumer>{collisionCtx=>
        <ErrorSelectionContext.Consumer>{errCtx=>
            <LayerSelectionContext.Consumer>{layerCtx=>
                <RecipeContext.Consumer>
                    {ctx => <g key={LayerDefs.NailPlate} id={LayerDefs.NailPlate.toLowerCase()}
                               className={`${layerCtx?.selectedComponent.includes(LayerDefs.NailPlate)? "hide-layer" : ""}`} >
                        {
                            ctx?.recipe.NailPlates.map((tool: {[key:string]: any}) => {
                                const points = convertVertices(tool.vertices)
                                const selectedPlateId = `${'PLATE'}_${tool.id}`;
                                const selectednailPlateId = `${LayerDefs.NailPlate}_${tool.id}`;
                                const isSelected = isSelectedByIdList([selectedPlateId,selectednailPlateId], errCtx)

                                const collide = collisionCtx?.collisions?.has(`${LayerDefs.NailPlate.toLowerCase()}_${tool.id}`)? "collide" : ""

                                return (<g className={`${LayerDefs.NailPlate.toLowerCase()} ${isSelected ? "error" : ""} ${collide}`}
                                           key={`${LayerDefs.NailPlate}_${tool.id}`}
                                           id={`${LayerDefs.NailPlate.toLowerCase()}_${tool.id}`}
                                           data-component={tool.id}
                                           data-layer={LayerDefs.NailPlate}>
                                    <polygon points={points} />
                                </g>)
                            })
                        }
                    </g> }

                </RecipeContext.Consumer>}
            </LayerSelectionContext.Consumer>}
    </ErrorSelectionContext.Consumer>}
    </CollisionContext.Consumer>
}