/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import {Table} from "@q4us-sw/q4us-ui-v2/dist/table";
import {markAsRead, markAllAsRead} from "../api";
import {DataContext, DataContextProvider, Request, Response} from "@q4us-sw/q4us-ui-v2/dist/util";
import {Button, Space, Tooltip} from "antd";
import {
    ExclamationCircleOutlined, ExpandAltOutlined, EyeInvisibleOutlined,
    EyeOutlined
} from "@ant-design/icons";
import '../styles/Notifications.css'
import {visualizeLoad} from "../api";
import {PageSelector} from "@q4us-sw/q4us-ui-v2/dist/table/Pagination";
import {Formatter} from "@q4us-sw/q4us-ui-v2";
import { useNavigate,useLocation } from 'react-router-dom';

interface RecipeState {
    truss: string;
    config: string;
    recipe: number;
    timestamp_year: number;
  }
export const Notifications: React.FunctionComponent<PropsWithChildren<any>> = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const handleLinkClick = (data: any) =>(event: any) => {
         // Prevent the default link behavior
         //({id: props.location.state.recipe, timestamp_year: props.location.state.timestamp_year})
         const newState:RecipeState = {
            truss: data.item,
            config: data.parameters.configuration,
            recipe: data.parameters.recipe,
            timestamp_year: data.timestamp_year,
            ...(location.state || {})
        };
       
        event.preventDefault();
        const url = event.target.getAttribute('href');
        navigate(url,{ state: newState }); // Navigate to the URL using navigate()
        
    };
    const MarkAsRead: Formatter = (props)=> {
        return <React.Fragment>
            <DataContext.Consumer>
                {d=> <Space>
                    {(!d.rows[props.row].is_read) && <Tooltip placement="bottomLeft" title={'Mark as read'}>
                        <Button icon={<EyeOutlined/>} onClick={async ()=> {
                            const res = await markAsRead({
                                id:d.rows[props.row].id,
                                timestamp_year: d.rows[props.row].timestamp_year
                            });
                            if (res?.data?.rowCount === 1) {
                                await d.fetch?.({sort: d.sort, page: d.page})
                            }
                        }}/>
                    </Tooltip>}
                    {(d.rows[props.row].is_read) && <Button icon={<EyeInvisibleOutlined/>}/>}
                </Space>}
            </DataContext.Consumer>
        </React.Fragment>
    }

    const NewMsgIcon: Formatter = (props)=> {
        return <React.Fragment>
            <DataContext.Consumer>
                {d=> <Space>
                    {(!d.rows[props.row].is_read) && <ExclamationCircleOutlined className={`new-message-icon`}/>}
                </Space>}
            </DataContext.Consumer></React.Fragment>}

    const NewString: Formatter = (props)=> {
        
        return <React.Fragment>
            <DataContext.Consumer>
                {d=> <td>
                    {((d.rows[props.row].message) && <td dangerouslySetInnerHTML={{ __html: d.rows[props.row].message }} onClick={handleLinkClick(d.rows[props.row])}></td>) }
                    </td>
                }
            </DataContext.Consumer></React.Fragment>}
    return <DataContextProvider save={async (d) => true} delete={async (d) => true} data={async (req) => {

        const res = await visualizeLoad({
            tableName: 'internal_message',
            sort: [{"id": "desc"}],
            page: {
                size: req.page?.size || 100,
                from: ((req.page?.current || 1) - 1) * (req.page?.size || 100),
                current: req.page?.current || 1,
                total: req.page?.total || 0
            },
        })
        return res as Response

    }}>
        <React.Fragment>
            <DataContext.Consumer>
                {data =>
                    <Table key={'table'} schema="internal_message"
                                size="small"
                                rowKey={"id"}
                                scroll={{y: 'calc(100vh - 64px - 72px)'}}
                                register={(registry) => registry}
                                formatRegister={(registry) => {
                                    registry.register("ACTION", MarkAsRead)
                                    registry.register("NEW_MSG", NewMsgIcon)
                                    registry.register("INLINE_MULTIPLE",NewString)
                                    return registry
                                }}
                />}
            </DataContext.Consumer>
            <DataContext.Consumer>
                {data => data.page && <PageSelector key={'pagination'} onChange={async (cur, size) => {
                    await data.fetch?.({
                        sort: [{"id": "desc"}],
                        page: {
                            total: data?.page?.total,
                            from: ((cur || 1) - 1) * (size || 100),
                            current: cur,
                            size: size
                        }
                    } as unknown as Request);
                }} current={data.page.current} className={''} summary={true} size={data.page.size}
                                                    schema={props.schema} total={data.page.total}
                                                    height={"small"}>
                    <div className={'actions-wrapper'}>
                        <Button size={"small"} onClick={async ()=>{
                            const res = await markAllAsRead();
                            if (res?.data?.rowCount !== 0) {
                                await data.fetch?.({sort: data.sort, page: data.page})
                            }
                        }}>Mark all as read</Button>
                    </div>
                </PageSelector>}
            </DataContext.Consumer>
        </React.Fragment>
    </DataContextProvider>

};

export default Notifications;