/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import {io, Socket} from "socket.io-client"
import React, {PropsWithChildren} from "react";
import { Auth } from "../auth/Authentication"
import { AuthContext } from "../user/AuthProvider";
import {ManagerOptions} from "socket.io-client/build/esm/manager";


export interface IOContextProps{
    socket?: Socket
}

export const IOContext = React.createContext<IOContextProps>({})

export const SocketIO: React.FunctionComponent<PropsWithChildren<any>> = (props)=>{
    const [socket, setSocket] = React.useState<Socket>()
    const auth = React.useContext(AuthContext)

    const connectSocket = () => {
        if (auth.state) {
            Auth.getToken().then((token)=>{
                let _sock:Socket;
                if (process.env.REACT_APP_SERVER_URL) {
                     _sock = io(process.env.REACT_APP_SERVER_URL, {
                        auth:{
                            token
                        },
                        transports: ["websocket", "polling"]
                    })
                } else {
                    _sock = io({
                        auth:{
                            token
                        },
                        transports: ["websocket", "polling"]
                    })
                }

                _sock.on("connect", ()=>console.log("socket connected"))
                _sock.on("disconnect", (reason)=>{
                    console.log("socket disconnected", reason)
                    setTimeout(() => {
                        connectSocket();
                      }, 2000);
                })
                setSocket(_sock)
            })
        }
    }
    React.useEffect(()=>{
        connectSocket();
        return () => {
            socket?.off('connect');
            socket?.off('disconnect');
        };

    },[auth.state])

    return <IOContext.Provider value={{socket}}>
        {props.children}
    </IOContext.Provider>
}