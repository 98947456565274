/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import { Trans as T, TransProps, useTranslation } from "react-i18next";
import { KeyPrefix, Namespace, TFuncKey } from "i18next";
import * as React from "react";
import { DefaultNamespace } from "react-i18next/TransWithoutContext";

export function Trans<
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
>(props: TransProps<K, N, TKPrefix, E>) {
  const { t, i18n } = useTranslation<any, any>(props.ns);

  return T({ ...props, t, i18n });
}

export function ErrorTrans<
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
>(props: TransProps<K, N, TKPrefix, E>) {
  const { t, i18n } = useTranslation<any, any>(props.ns);

  const translatedContent = T({ ...props, t, i18n });
  return <div dangerouslySetInnerHTML={{ __html: translatedContent }} />;
}
