/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {RefObject, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import './styles/index.css'
import './styles/pressTool.css'
import './styles/blockTool.css'
import './styles/removeTool.css'
import './styles/id.css'
import {SpikeGrabberLayer} from "./svg-layers/SpikeGrabber";
import {NailPlateLayer} from "./svg-layers/NailPlate";
import {TimberBeamLayer, TimberDiagonalLayer} from "./svg-layers/Member";
import {SideSuctionGrabberCableLayer, SideToolLayer} from "./svg-layers/SideTool";
import {
    MultiPressToolLayer, MultiPressToolUpperShapeLayer,
    PressToolEntryLayer,
    PressToolEntryUpperShapeLayer,
    PressToolLayer,
    PressToolPaddleLayer,
    PressToolUpperShapeLayer
} from "./svg-layers/PressTool";
import {ConfigContext, EditContext, RecipeContext} from "./util";
import {ChordGrabberCableLayer, ChordGrabberLayer} from "./svg-layers/ChordGrabber";
import {ArmToolContextProvider, BlockToolLayer, BlockToolPostLayer, BlockToolPreLayer} from "./svg-layers/BlockTool";
import {RemoveCartLayer} from "./svg-layers/RemoveCart";
import {
    RemoveToolContextProvider,
    RemoveToolEntryLayer,
    RemoveToolExitLayer,
    RemoveToolLayer, RemoveToolOnMove,
    RemoveToolSafetyAreaLayer
} from "./svg-layers/RemoveTool";
import {MemberIdLayer, NailPlateIdLayer, TimberIdLayer, ToolIdLayer} from "./svg-layers/IdLayer";
import {ReactSVGPanZoom, Tool, TOOL_NONE, TOOL_PAN, Value} from 'react-svg-pan-zoom';
import {ExpandOutlined, ZoomInOutlined, ZoomOutOutlined} from "@ant-design/icons";
import {Button, Modal} from "antd";
import {useWindowSize} from '@react-hook/window-size'
import {EditServiceObj} from "./EditService";
import {ComponentMenu} from "./ContextMenu";
import {ErrorSelectionContext} from "../trusscheck/util"
import {ComponentSidebar} from "./componentSidebar";
import {DynamicMenuContextProvider, DefaultProps as _d, DynamicMenuContext} from "./props/menu";
import {PropertyEditorMouseEvent} from "./props";
import {ItemType} from "antd/es/menu/hooks/useItems";
import {Trans} from "react-i18next";
import {CollisionContext} from "./collisionDetection/util"
import {MassCenterPointLayer} from "./svg-layers/MassCenterPoint";
import {useBlocker, useLocation} from "react-router-dom";
import {ResponsiveMenuContext} from "../../views/MainView";
const hull = require('hull.js')

interface CustomToolbarProps {
    handleZoomIn: () => void
    handleZoomOut: () => void
    handleCenter: () => void
}

interface SelectionResponse {
    id: any,
    message: string,
    isClicked: boolean
}


export const CustomToolbar: React.FunctionComponent<CustomToolbarProps> = (props) => {
    return (
        <div className={'pan-zoom-button'}>
            <Button type="primary" shape="circle" icon={<ZoomInOutlined/>} onClick={props.handleZoomIn}/>
            <Button type="primary" icon={<ExpandOutlined/>} onClick={props.handleCenter}/>
            <Button type="primary" shape="circle" icon={<ZoomOutOutlined/>} onClick={props.handleZoomOut}/>
        </div>
    );
}
export const CustomErrorbar: React.FunctionComponent = () => {
    return (
        <ErrorSelectionContext.Consumer>{errCtx =>
            (
                errCtx ? (
                    <div className={'pan-error-pannel'}>
                        <div className="selected-component">
                            {Object.values(errCtx.selectedComponent || {}).map((comp: any, index) => (
                                comp?.isClicked ? (
                                    <div key={`${comp?.id+comp.type}`}
                                         className={`transparent-card${comp?.className === 'tdv-child-entry errors' ? ' error' : ''}`}>
                                        <span className={"error-warring-text"} style={{color: "black",}}>
                                            {'Component ID : '} {comp?.key}
                                            <br/> {/* Add a line break This is to add all error related things in the future*/}
                                            {'Desciption : '}{comp?.message}
                                        </span>
                                    </div>
                                ) : null
                            ))}
                        </div>
                    </div>
                ) : null
            )}
        </ErrorSelectionContext.Consumer>
    );
}

export const SVGComponent: React.FunctionComponent<any> = () => {

    const {editMode, setEditMode} = React.useContext(EditContext)

    const recipe = useContext(RecipeContext)
    const configuration = useContext(ConfigContext)
    const collisionCtx = useContext(CollisionContext)


    const [value, setValue] = React.useState<Value | null>({
        SVGHeight: 0,
        SVGWidth: 0,
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0,
        endX: undefined,
        endY: undefined,
        f: 0,
        focus: false,
        miniatureOpen: false,
        mode: "idle",
        startX: undefined,
        startY: undefined,
        version: 2,
        viewerHeight: 0,
        viewerWidth: 0
    });
    const [tool, setTool] = React.useState<Tool>(TOOL_NONE)
    const [visible, setVisible] = React.useState(false)
    const [position, setPosition] = useState({x: 0, y: 0});
    const [size, setSize] = useState<{width: number, height: number}>({ width: 500, height: 500 });

    const [selectedComponent, setSelectedComponent] = React.useState<any>(undefined)

    const viewerRef = useRef<ReactSVGPanZoom>(null);
    const divRef = useRef<HTMLDivElement>();

    const recipeCtx = React.useContext(RecipeContext)
    const configCtx = React.useContext(ConfigContext)
    const location = useLocation();
    const menuCtx = React.useContext(ResponsiveMenuContext);

    const blocker = useBlocker(
        ({currentLocation, nextLocation}) => {
            return currentLocation.pathname !== nextLocation.pathname && editMode && EditServiceObj.dirty()
        }
    );

    const handleOk = useCallback(() => {
        return blocker.proceed && blocker.proceed();

    }, [blocker])

    const handleCancel = useCallback(() => {
        menuCtx?.setSelectedKeys('view/truss-check')
        return blocker.reset && blocker.reset();
    }, [blocker])

    const beforeUnloadListener = useCallback((event: BeforeUnloadEvent) => {
        if(EditServiceObj.dirty()) {
            event.preventDefault()
        }
    }, [])

    React.useEffect(() => {
        if (editMode) {
            window.addEventListener("beforeunload", beforeUnloadListener)
        } else {
            window.removeEventListener("beforeunload", beforeUnloadListener)
        }
        return ()=> {
            window.removeEventListener("beforeunload", beforeUnloadListener)
        }
    }, [editMode])

    useEffect(() => {
        if(location.pathname.includes('view/truss-check') && !editMode){
            menuCtx?.setSelectedKeys('view/truss-check')
        }
    }, [location, menuCtx]);

    React.useEffect(() => {
        onEditMode()
    }, [editMode]);

    React.useEffect(() => {
        handleCenter()
    }, [configCtx, viewerRef.current, divRef.current, size]);

    const handleZoomIn = useCallback(() => {
        viewerRef.current?.zoomOnViewerCenter(1.2);
    }, [viewerRef.current])

    const handleZoomOut = useCallback(() => {
        viewerRef.current?.zoomOnViewerCenter(0.8);
    }, [viewerRef.current])

    const handleCenter = useCallback(() => {
        let sVGPointX = 10444
        let sVGPointY = -2500
        let zoomLevel = 0.05
        if (configCtx?.configuration) {
            sVGPointX = configCtx?.configuration.walldata.WallDimensions.MaxX / 2
            sVGPointY = -1 * (configCtx?.configuration.walldata.WallDimensions.MaxY / 2)
            zoomLevel = 1 / (configCtx?.configuration.walldata.WallDimensions.MaxX / (size.width))
        }
        viewerRef.current?.setPointOnViewerCenter(sVGPointX, sVGPointY, zoomLevel);
    }, [configCtx, viewerRef.current, size])

    const onEditMode = useCallback(() => {
        if (editMode) {
            setTool(TOOL_NONE)
            EditServiceObj.cancelEditMode()
            EditServiceObj.setRecipe(recipe?.recipe)
            EditServiceObj.setConfiguration(configuration?.configuration)
            EditServiceObj.initCollisionDetection()
            collisionCtx?.setCollisions(EditServiceObj.getCollisions())
        } else {
            setTool(TOOL_PAN)
            collisionCtx?.setCollisions(undefined)
            setSelectedComponent(undefined)
        }
    }, [editMode])


    const handleMouseDown = useCallback((e: MouseEvent) => {
        setVisible(false)
        EditServiceObj.onMouseDown(e, editMode, viewerRef)
        if (EditServiceObj.availableComponents().length > 1) {
            setPosition({x: e.clientX, y: e.clientY})
        }
    }, [editMode])

    const handleMouseMove = useCallback((e: any)=>{
       if(editMode) {
            EditServiceObj.onMouseMove(e, viewerRef)
           if (EditServiceObj.getOngoingDrag()) {
               collisionCtx?.setCollisions(EditServiceObj.getCollisions())
           }
        }
    },[editMode, collisionCtx])

    const handleMouseUp = useCallback((e: any)=>{
        if(editMode) {
            EditServiceObj.onMouseUp(e)
            if (EditServiceObj.getSelectedComponent()) {
                collisionCtx?.setCollisions(EditServiceObj.getCollisions())
            }
        }
    },[collisionCtx])

    const onClick = useCallback((e: MouseEvent) => {
        if (EditServiceObj.availableComponents().length > 1) {
            setVisible(true)
        }
        setSelectedComponent(EditServiceObj.getSelectedComponent())
    }, [])

    useEffect(() => {
        const divElement = divRef.current

        const updateSize = () => {
            if (divElement) {
                const { width, height } = divElement.getBoundingClientRect()
                setSize({ width, height })
            }
        }

        const resizeObserver = new ResizeObserver(updateSize)

        if (divElement) {
            resizeObserver.observe(divElement)
        }

        //Initial size update
        updateSize()

        // Cleanup the observer on component unmount
        return () => {
            if (divElement) {
                resizeObserver.unobserve(divElement)
            }
        }

    }, [divRef.current])

    return <ConfigContext.Consumer>
        {ctx => ctx?.configuration && recipeCtx?.recipe &&
            <React.Fragment>

                {visible && <ComponentMenu x={position.x} y={position.y} setSelectedComponent={setSelectedComponent}/>}
                {selectedComponent && <ComponentSidebar setSelectedComponent={setSelectedComponent} selectedComponent={selectedComponent}/>}
                <CustomErrorbar/>
                <CustomToolbar handleZoomOut={handleZoomOut} handleZoomIn={handleZoomIn} handleCenter={handleCenter}/>
                <div style={{height: '100%', width: "100%"}} ref={divRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onClick={onClick}
                    onMouseLeave={handleMouseUp}
                >
                    <ArmToolContextProvider>
                        <RemoveToolContextProvider>
                            <DynamicMenuContextProvider
                                {..._d}
                                enable={editMode}
                                items={(e) => {
                                    const obj: Map<any, any> = EditServiceObj.getComponentsForMouseEvent(e)
                                    return Array.from(obj.keys()).map(o => ({
                                        title: <Trans
                                            i18nKey={["trusscheck", "property", "editor", obj.get(o).layer_group, o].join(".")}
                                            defaults={o.toUpperCase()}/>,//o.toUpperCase(),
                                        label: o.toUpperCase(),
                                        key: o,
                                        ...obj.get(o)
                                    } as ItemType<any>))
                                }}
                                fetchDataForAction={(e, items, type)=>{
                                    switch (type) {
                                        case "context_menu":
                                        {
                                            const {id, layer_group, component_id} = items.find(o => o.key === e.key)
                                            const comp = EditServiceObj.getComponentById(id, layer_group, component_id)
                                            const ovr = EditServiceObj.getPropertyChange(`${layer_group}_${id}`) || {}
                                            return {...comp, ...ovr, id}
                                        }
                                        default:
                                            return undefined
                                    }

                            }}
                            eventHandlerComponent={<PropertyEditorMouseEvent/>}
                        >
                                <DynamicMenuContext.Consumer>{
                                    onCtx=> <div className={(onCtx?.type === 'context_menu' && (!!onCtx.data) || (onCtx?.trigger && onCtx.items.length > 0))? "click-disable": ""}
                                    >
                                        <ReactSVGPanZoom
                                            detectAutoPan={false}
                                            onChangeTool={tool => setTool(tool)}
                                            value={value}
                                            onChangeValue={value => {
                                                setValue({...value, viewerWidth: size.width, viewerHeight: size.height})
                                            }}
                                            width={size.width} height={size.height}
                                            background={'#FFFFFF'}
                                            miniatureProps={{
                                                position: 'none',
                                                background: '',
                                                width: 0,
                                                height: 0
                                            }}
                                            tool={tool}
                                            toolbarProps={{
                                                position: 'none'
                                            }}
                                            ref={viewerRef}
                                            preventPanOutside={false}
                                        >

                                            <svg
                                                viewBox={`0 0 ${ctx?.configuration.walldata.WallDimensions.MaxX} ${ctx?.configuration.walldata.WallDimensions.MaxY}`}
                                                className={"svg_component"}

                                            >
                                                <g key={`editable_${editMode}`} transform="scale(1,-1)">

                                                    <ChordGrabberCableLayer />
                                                    <ChordGrabberLayer />
                                                    <SpikeGrabberLayer />
                                                    <SideSuctionGrabberCableLayer />
                                                    <SideToolLayer />
                                                    <TimberBeamLayer />
                                                    <TimberDiagonalLayer />
                                                    <NailPlateLayer />
                                                    <BlockToolPostLayer />
                                                    <BlockToolPreLayer />
                                                    <BlockToolLayer />
                                                    <PressToolPaddleLayer />
                                                    <PressToolUpperShapeLayer />
                                                    <MultiPressToolUpperShapeLayer/>
                                                    <PressToolEntryUpperShapeLayer />
                                                    <PressToolEntryLayer />
                                                    <PressToolLayer />
                                                    <MultiPressToolLayer/>
                                                    <RemoveToolSafetyAreaLayer/>
                                                    <RemoveToolLayer />
                                                    {/*<RemoveToolExitLayer />*/ /** as per the requirement of ENH-511 */}
                                                    <RemoveToolEntryLayer />
                                                    {/*<RemoveToolOnMove/>*/ /** as per the requirement of ENH-511 */}
                                                    <RemoveCartLayer />
                                                    <NailPlateIdLayer />
                                                    <TimberIdLayer />
                                                    <MemberIdLayer />
                                                    <ToolIdLayer />
                                                    <MassCenterPointLayer/>
                                                </g>
                                            </svg>

                                        </ReactSVGPanZoom>
                                    </div>
                                }</DynamicMenuContext.Consumer>


                            </DynamicMenuContextProvider>
                        </RemoveToolContextProvider>
                    </ArmToolContextProvider>
                    <Modal
                        title={<Trans i18nKey={`modal.leave-trusscheck.title`} defaults={'Are you sure you want to leave?'}/>}
                        open={blocker && blocker.state === "blocked"}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        okText={<Trans i18nKey={`modal.leave-trusscheck.button.continue`} defaults={'Continue'}/>}
                        cancelText={<Trans i18nKey={`modal.leave-trusscheck.button.cancel`} defaults={'Cancel'}/>}
                    >
                        <p><Trans i18nKey={`modal.leave-trusscheck.content`} defaults={'Any edits to the recipe will be lost.'}/></p>
                    </Modal>
                </div>
            </React.Fragment>}
    </ConfigContext.Consumer>
}
