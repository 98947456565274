/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import { Skeleton } from "antd";
import React, {PropsWithChildren} from "react";
import {Auth} from "./Authentication";
import { useLocation, useNavigate, useParams} from "react-router-dom";
export interface AuthCfgResponse{
    redirect_uri: string,
    auth_server_endpoint: string,
    client_id: string,
    [key:string]: any
}

export interface AuthorizationCodeProps{
    configs: ()=>Promise<AuthCfgResponse>
    code?: string
}


export const AuthorizationCode: React.FunctionComponent<PropsWithChildren<AuthorizationCodeProps>> = (props)=>{
    const [signedIn, setSignedIn] = React.useState(Auth.isSignedIn())
    const [authenticated, setAuthenticated] = React.useState(false);
    const location = useLocation();

    const pathArr = React.useCallback(()=>{
        return location.pathname.split("/").filter(item => item !== "")
    }, [location])

    const authenticate = async () => {
        if (!Auth.isSignedIn() && !props.code) {
          await redirect();
        } else if (!Auth.isSignedIn() && props.code) {
          const cfg = await props.configs();

          if(cfg?.redirect_uri && location.pathname.length > 1 && pathArr().length === 6 && location.pathname.startsWith("/view/truss-check")) {
              cfg.redirect_uri = `${cfg.redirect_uri}${location?.pathname}`;
          }
          await Auth.signInWithCode(props.code, cfg?.redirect_uri);
      
          if (Auth.isSignedIn()) {
            setSignedIn(true);
            setAuthenticated(true);
                
          } else {
            await redirect(cfg);
          }
        }
      
      };
      

    const redirect = async (cfg?: AuthCfgResponse)=>{
        if(!cfg){
            cfg = await props.configs();
        }
        const params = new URLSearchParams();
        if(cfg?.redirect_uri && location.pathname.length > 1 && pathArr().length === 6 && location.pathname.startsWith("/view/truss-check"))
            cfg.redirect_uri = `${cfg.redirect_uri}${location?.pathname}`;

        params.append("response_type", "code")
        params.append("client_id", `${cfg?.client_id}`)
        window.location.assign(`${cfg?.auth_server_endpoint}?${params.toString()}&redirect_uri=${cfg?.redirect_uri}`)
    }


    React.useEffect(()=>{
        Auth.subscribe(async (state)=>{
            if(!state){
                await redirect();
            }
        })
        authenticate().catch(e=>console.error(e))

    }, [props])

    return (
        <React.Fragment>
            <Skeleton loading={!signedIn} active={!signedIn}>
                {authenticated && props.children}
            </Skeleton>
        </React.Fragment>
    );
}
