/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Trans} from "../reactive/Trans";
import {List} from "antd";
import {LoadRecentRequest, RecentItemTypes} from "./defs";
import {loadRecent} from "../../api";
import './styles/RecentItems.css'
import {ProjectTreeDataContext} from "./Projects";

export const RecentItemContext: React.Context<LoadRecentRequest> = React.createContext<LoadRecentRequest>({type: RecentItemTypes.Projects})

export const RecentItems: React.FC<{type: RecentItemTypes}> = (props)=>{

    const [data, setData] = React.useState<[]>()
    const params = useParams()
    const location = useLocation();
    const navigate = useNavigate();
    const ctx = React.useContext(ProjectTreeDataContext)


    React.useEffect(()=>{
        loadRecent({...(location.state || {}), ...props}).then(o=>setData(o?.data?.rows || []))
    }, [ctx])

    return <div className={"tc-recent"}>
        <div className={"tc-recent-title"}>
            <Trans i18nKey={["trusscheck","recent", props.type, "label" ].join(".")} values={{...params}}/>
        </div>
        <div className={"tc-recent-list"}>
            <List
                size={'small'}
                split={false}
                bordered = {false}
                dataSource={data}
                renderItem={(item: {label:string, key?: string| number,timestamp_year?: number}) => (
                    <List.Item key={item.key || item.label}>
                        <div className={"tc-recent-list-item"} onClick={()=>navigate(item.label, {state: {...(location.state || {}), [props.type]: item.key || item.label, timestamp_year: item.timestamp_year}})}>
                            <span>{item.label}</span>
                        </div>
                    </List.Item>
                )}
            />
        </div>
    </div>
}
