/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {useCallback, useContext, useState} from "react";
import {RecipeContext} from "../recipeVisualize/util";
import {Button, Descriptions, DescriptionsProps, Modal, Space, Tag, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import './styles/trussInfo.css'
import {Trans} from "../reactive/Trans";
import {PreferenceContext, UnitType} from "../user/UserPreference";
import {convertInchesToMMIfPreferred,
    convertKGToLbIfPreferred,
    convertMMtoInchesIfPreferred
} from "./util";
import {TrussInfoInRecipe, TrussUnit} from "./defs";

function isInStringEnum(value: string, enumeration: any): boolean {
    return Object.values(enumeration).includes(value);
}

export const TrussInfo: React.FunctionComponent = ()=>{

    const recipeCtx = React.useContext(RecipeContext)
    const prefCtx = React.useContext(PreferenceContext)
    const [open, setOpen] = useState<boolean>(false)
    const [unitType,setUnitType] = React.useState<string>(prefCtx?.prefInfo?.pref?.units || UnitType.metric)

    React.useEffect(()=>{
        setUnitType(prefCtx?.prefInfo?.pref?.units || UnitType.metric)
    },[prefCtx?.prefInfo.pref.units])

    const items = useCallback((trussInfo: {[key: string]: any})=>{

        const trussUnit = trussInfo.trussUnits
        let items: DescriptionsProps['items'] = []

        for (const [key, value] of Object.entries(trussInfo)) {

            if (isInStringEnum(key, TrussInfoInRecipe)) {

                let children: React.ReactNode

                switch (key) {
                    case TrussInfoInRecipe.Thickness:
                    case TrussInfoInRecipe.Camber:
                        children = (trussUnit === TrussUnit.Metrics)? convertMMtoInchesIfPreferred(value as number, unitType) : convertInchesToMMIfPreferred(value, unitType)
                        break
                    case TrussInfoInRecipe.MassCenterPoint:
                        if (typeof value === 'object' && value !== null) {
                            children = Object.entries(value).map((value)=>
                                    <p className={'child-content'}><Tag>{value[0].toUpperCase()}</Tag>{` ${value[1]}`}</p>
                                )
                        }
                        break
                    case TrussInfoInRecipe.CalculatedMass:
                        children = convertKGToLbIfPreferred(value, unitType)
                        break
                    case TrussInfoInRecipe.Width:
                    case TrussInfoInRecipe.Height:
                        children = convertMMtoInchesIfPreferred(value as number, unitType)
                        break
                    case TrussInfoInRecipe.TrussLabel:
                    case TrussInfoInRecipe.Item:
                    case TrussInfoInRecipe.Order:
                    case TrussInfoInRecipe.Config:
                    case TrussInfoInRecipe.Configuration:
                    case TrussInfoInRecipe.TrussType:
                    case TrussInfoInRecipe.Plates:
                    case TrussInfoInRecipe.Lumber:
                    case TrussInfoInRecipe.TrussUnits:
                        children = value
                        break
                }

                items.push({
                    key: key,
                    label: key,
                    children: children,
                })
            }
        }
        return items
    }, [unitType])

    return <React.Fragment>
        <Modal
            title={<p>{recipeCtx?.recipe?.title} <span className={'truss-info-label'}>{`(${recipeCtx?.recipe?.item})`}</span></p>}
            width={'40vw'}
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            closable={true}
            destroyOnClose
            maskClosable={true}

        >
            <div className={'truss-info-content'}>
            <Descriptions
                column={1}
                layout={'horizontal'}
                size={'small'}
                bordered={true}
                items={items(recipeCtx?.recipe?.trussInfo || {})} />
            </div>
        </Modal>
        <div>
            <Tooltip title={<Trans i18nKey={'truss_info.tool_tip'} defaults={"Truss Information"}/>}>
                <Button
                    icon={<InfoCircleOutlined/>}
                    onClick={()=> setOpen(true)}
                />
            </Tooltip>
        </div>
    </React.Fragment>
}