/* Copyright (C) Trussmatic Oy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

import React, {PropsWithChildren, useCallback, useContext} from "react";
import "../styles/Configuration.css"
import {visualizeLoad} from "../api";
import {DataContext, DataContextProvider, Request, Response} from "@q4us-sw/q4us-ui-v2/dist/util";
import {Table} from "@q4us-sw/q4us-ui-v2/dist/table";
import {unstable_batchedUpdates} from "react-dom";
import {Formatter} from "@q4us-sw/q4us-ui-v2";
import {Tag} from "antd";
import {inlineFilterMap } from "../util/mapping";
import {JsonArrayResolver} from "./JsonResolver";
import {DateFormatter} from "./PurgeJobStatus";

export const JobStatus: React.FunctionComponent<PropsWithChildren> = () => {

    const [data, setData] = React.useState<Data>({rows: [], defaultConfig: undefined, productionLines: []} );
    const load = React.useCallback(async (req: Request)=>{
        const {data = {rows: []} as Response} = await visualizeLoad(req).catch(e => {
            console.error(e)
            setData({rows: []} )
        }) || {}
        if (data.rows.length !== 0) {

            unstable_batchedUpdates(() => {
                setData((prevState)=>(
                    {
                        ...prevState,
                        rows: data.rows
                    }
                ))
            })
        }
    },[data])

    

    return <React.Fragment>
        <div className={"table-container"}>

            <Table key={'configs'}
                   schema="deployed_job_notification_view"
                   size="small"
                   rowKey={"id"}
                   scroll={{y: 'calc(100vh - 128px)'}}
                   register={(registry) => registry}
                   formatRegister={(registry) =>
                    registry.register("JSON_ARRAY", (props) => <JsonArrayResolver value={props.value} />)
                        .register("DATE", DateFormatter)
                  }
            />
        </div>
    </React.Fragment>
}

export const JobStatusView: React.FunctionComponent<PropsWithChildren<any>> = (props) => {

    return <React.Fragment>
        <DataContextProvider save={async (d) => true} delete={async (d) => true} data={async (req) => {
            const res = await visualizeLoad({
                tableName: 'deployed_job_notification_view',
                filter: [...inlineFilterMap(req.inline?.filter || {})],
                sort: (req.sort && req.sort.length > 0) ? req.sort: [{"id": "desc"}],
            }) as Response

            return res as Response
        }}>
            <JobStatus/>
        </DataContextProvider>
    </React.Fragment>
}